import styled from 'styled-components';

import { Link } from '+/components/Link';

export const Counter = styled.div`
  text-align: center;
`;

export const CounterValue = styled.div`
  color: var(--color-theme-accent);
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: clamp(3.3rem, 1rem + 12.3vw, 8.938rem);
  line-height: 1;
  margin-bottom: 0.35em;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: clamp(4.375rem, 2.716rem + 8.3vw, 8.938rem);
  }
`;

export const Digit = styled.span<{ isPunctuation?: boolean }>`
  width: ${props => (props.isPunctuation ? '0.5ch' : '0.93ch')};
  display: inline-block;
  text-align: center;
`;

export const Text = styled.div`
  font-family: var(--font-tertiary);
  font-style: italic;
  font-size: clamp(1.625rem, 1.046rem + 2.89vw, 2.313rem);
  line-height: 1.4;
  max-width: 18em;
  margin: 0 auto 1em;

  em,
  i,
  a {
    font-weight: inherit;
    color: var(--color-theme-accent);
  }

  b,
  strong {
    color: var(--color-theme-accent);
  }

  a {
    text-decoration: underline;
    border-bottom: none;
    transition-property: background-color, color, text-decoration-color;

    &:hover {
      text-decoration-color: transparent;
    }
  }
`;

export const Button = styled.button`
  display: inline-flex;
  font-family: var(--font-secondary);
  font-weight: 700;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid var(--color-theme-accent);
  padding: 1em 1.5em 0.97em;
  letter-spacing: 0.015em;
  font-size: clamp(0.75rem, 0.523rem + 1.14vw, 1.375rem);
  line-height: 1;
  margin-top: 1.75rem;
  align-items: center;
  gap: 0.375em;
  transition: all 0.1s ease-out;
  transition-property: background-color, color;

  &:hover {
    background: var(--color-theme-accent);
    color: var(--color-theme-accent-inverse);
  }

  > span {
    pointer-events: none;
  }

  svg {
    height: 0.85em;
    width: 0.85em;
  }
`;

export const CTALink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
