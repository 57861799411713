import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import MendedHeartSvg from '+/images/mended-heart.inline.svg';

const pulseAnimation = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.8;
  }
`;

export const LoadingIndicator = styled(motion.aside)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 999;
  background: var(--color-neutral-800);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MendedHeart = styled(MendedHeartSvg)`
  height: 6rem;
  width: 6rem;
  animation: ${pulseAnimation} 1.5s ease-out infinite;
  transform-origin: 50% 50%;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  path {
    fill: var(--color-primary-500);
  }
`;
