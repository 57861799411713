import React from 'react';
import * as Styled from './BadgesWithText.styles';
import { Markdown } from '+/components/Markdown';
import { getImageFilePath } from '+/utils/getImageFilePath';
import { t } from '+/utils/textDictionary';

export const BadgesWithText = ({
  badgesWithTextData: { text, badgeItems },
}: {
  badgesWithTextData: BadgesWithTextComponent;
}) => {
  return (
    <Styled.Section>
      <h2 className="visually-hidden">{t('Widget.Badges.A11yHeading')}</h2>
      <Styled.Container>
        <Styled.BadgeList>
          <Styled.BadgeListContent>
            {badgeItems.map(badge => (
              <Styled.BadgeLink href={badge.url} target="_blank">
                <Styled.BadgeItem
                key={badge.image.id}
                src={getImageFilePath(badge.image)}
                alt={badge.altText}
                loading="lazy"
              />
              </Styled.BadgeLink>
            ))}
          </Styled.BadgeListContent>
        </Styled.BadgeList>
        {text && (
          <Styled.Text>
            <Styled.TextContent>
              <Markdown>{text}</Markdown>
            </Styled.TextContent>
          </Styled.Text>
        )}
      </Styled.Container>
    </Styled.Section>
  );
};
