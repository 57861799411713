/**
 * Scrolls the window to a position of another element
 * @param element - HTMLElement to scroll window to
 * @param offest - Number to offset the position of the scroll
 * @param direction - Whether the scroll should only move up, down, or both
 * @param breakPointToTop - Horizontal breakpoint at which the scroll should just go to the top
 * @param behavior - Smooth or auto
 * @return null or void
 * */

const scrollToLocation = (
  element: HTMLElement,
  offset = 0,
  direction: 'up' | 'down' | 'both' = 'both',
  breakPointToTop: number | null = null,
  behavior: 'smooth' | 'auto' = 'smooth',
): null | void => {
  if (breakPointToTop !== null && window.innerWidth > breakPointToTop) {
    window.scrollTo({
      top: 0,
      behavior,
    });
    return;
  }

  const elementRectTop = element?.getBoundingClientRect().top;
  const bodyRectTop = document.body.getBoundingClientRect().top;
  const elementPosition = (bodyRectTop - elementRectTop) * -1 - offset;

  if ((elementRectTop > 0 && direction === 'up') || (elementRectTop < 0 && direction === 'down')) {
    return;
  }

  window.scrollTo({
    top: elementPosition,
    behavior,
  });
};

export default scrollToLocation;
