import styled, { css, keyframes } from 'styled-components';

import { REPORT_COLORS } from '../utils/reportColors';

import { ImageItem as ImageItemComponent } from '+/components/ImageItem';
import {
  ModalBody as ModalBodyComponent,
  ModalPortal as ModalPortalComponent,
} from '+/components/Modal';
import { Content } from '+/components/Modal/ModalContent.styles';

export const LegitimizerDot = styled.span<{ reportType: string }>`
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background: ${props => REPORT_COLORS[props.reportType]};
  border-radius: 50%;
  margin-right: 0.25rem;
`;

export const LegitimizerItemIconWrapper = styled.span`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  vertical-align: middle;
`;

export const LegitimizerItem = styled.div`
  padding-top: 0.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-right: 0.25rem;
    padding-top: 0;

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

export const Legitimizers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1.5rem;

  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 0.8125rem;

  padding-right: 1rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-top: 2rem;
  }
`;

const backgroundGradient = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -400% 0%;
  }
`;

export const ImageItem = styled(ImageItemComponent)``;

interface ImageItemWrapperProps {
  showGraphicImagery: boolean;
}

export const ImageItemWrapper = styled.div<ImageItemWrapperProps>`
  max-width: 45%;
  float: right;
  margin: 0 0 3% 3%;
  background: var(--color-neutral-700);

  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-size: 200% 200%;
  background-position: left center;
  animation: ${backgroundGradient} 1.25s linear infinite;
  overflow: hidden;

  ${props =>
    !props.showGraphicImagery &&
    css`
      animation: none;

      ${ImageItem} {
        filter: contrast(150%) saturate(150%) blur(20px);
      }
    `}

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: unset;
    margin: 0;
  }
`;

export const DotModalContentExtras = styled.div`
  width: 100%;
  padding-bottom: 1rem;

  ${Legitimizers} {
    display: block;
    padding-right: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: inline-block;
    width: 40%;
    max-height: 50vh;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0;
    overflow: hidden;
    vertical-align: top;
  }
`;

export const DotModalContentDecription = styled.p`
  display: block;
  width: 100%;
  font-size: 0.875rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: inline-block;
    width: 60%;
    max-height: 50vh;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: auto;
    vertical-align: top;
    font-size: inherit;
  }
`;

export const DotModalContent = styled.div`
  width: 100%;
  max-width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-height: 50vh;
    /* overflow-y: auto; */
    display: flex;
    align-items: center;
  }
`;

export const AudioPlayerWrapper = styled.div`
  padding-bottom: 2rem;
`;

export const ModalBody = styled(ModalBodyComponent)`
  padding: 3rem 2rem;
  font-size: 1rem;
`;

export const ModalPortal = styled(ModalPortalComponent)`
  ${Content} {
    max-width: 900px;
  }
`;
