import styled from 'styled-components';

import { Link } from '+/components/Link';

export const Title = styled.div`
  margin: 0 0 2.4rem 0;
  text-align: center;
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 0.9375rem;
  text-transform: uppercase;
`;

export const SocialLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.625rem;
  height: 5.625rem;
  padding: 0.5rem;
  border-radius: 100%;

  transform: scale(1);

  transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const SocialLinks = styled.div<{ socialIconLength: number}>`
  display: flex;
  justify-content: center;
  gap: 1.8rem;
  padding: 0 1rem;
  flex-wrap: wrap;

  @media (max-width: ${props => props.theme.breakpoints.sm})
    and (min-width: ${props => props.theme.breakpoints.xs}) {
      display: ${props => props.socialIconLength % 2 == 0 ? 'grid' : ''};
      grid-template-columns: 25% 25%
  }
`;

export const SocialIconGroup = styled.div<{ hasFilledColorTheme?: boolean }>`
  padding: 5.25rem 0 6rem 0;
  color: var(--color-theme-base-inverse);
  background: ${props => (props.hasFilledColorTheme ? 'var(--color-theme-base)' : '')};

  ${SocialLink} {
    background: ${props =>
      props.hasFilledColorTheme ? 'var(--color-theme-base-dark)' : 'var(--color-theme-accent)'};
    color: var(--color-theme-accent-inverse);
  }
`;
