import React from 'react';
import * as Styled from './Alert.styles';
import { Icon } from '+/components/Icon';
import { ICONS } from '+/utils/constants/icons';

interface Props {
  children: React.ReactNode;
}

export const Alert = ({ children }: Props) => {
  return (
    <Styled.Alert role="alert">
      <Icon path={ICONS.ERROR.path} color={'var(--color-primary-500)'} size={16} />
      <Styled.Msg>{children}</Styled.Msg>
    </Styled.Alert>
  );
};
