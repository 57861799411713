import styled, { css } from 'styled-components';

interface ModalBodyProps {
  isPadded?: boolean;
}

export const ModalBody = styled.div<ModalBodyProps>`
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;

  ${props =>
    props.isPadded &&
    css`
      padding: var(--modal-padding);
    `}
`;
