export const getElementScrollbarWidth = (element: HTMLElement): number =>
  element.offsetWidth - element.clientWidth;

export const getScrollbarWidth = (element?: HTMLElement): number => {
  if (element) return getElementScrollbarWidth(element);

  if (typeof window === 'undefined' || typeof document === 'undefined') return 0;

  return window.innerWidth - document.documentElement.clientWidth;
};
