import { parser } from '+/utils/contentParser';

const defaultTextDictionary: TextDictionary = {
  '&AudioPlayer.Play': 'Play',
  '&AudioPlayer.Pause': 'Pause',
  '&VideoWithTimeline.Play': 'Play',
  '&VideoWithTimeline.Pause': 'Pause',
  '&VideoWithTimeline.Continue': 'Continue',
  '&VideoWithTimeline.Stop': 'I’ve seen enough',
  '&VideoWithTimeline.WarningMessage': '**WARNING:** Graphic footage ahead.',
  '&Progress.ValueLabel': 'Signed',
  '&Progress.TotalLabel': 'Goal',
};

const isAS = (text: string): boolean => {
  if (!text) return false;
  return Boolean(text.match(/:advstu/));
};

const praseKeyAssignmentsToOverrides = (keyAssignments: string | null) => {
  if (!keyAssignments) return null;

  const overrides = {};

  keyAssignments.split('\n').forEach(assignment => {
    const [key, value] = assignment.split(':=');
    overrides[key.trim()] = value.trim();
  }, {});

  return overrides;
};

let language: SupportedLanguage = 'en-US';
const showMeTheKeys: boolean =
  typeof window !== 'undefined' && window.location.search.includes('showMeTheKeys');

let textDictionary: TextDictionary = {};
const textDictionaryByLanguageCache: PartialTextDictionaryByLanguage = {};

const overridesByLanguageCache: PartialTextDictionaryByLanguage = { [language]: {} };
let overrides = overridesByLanguageCache[language];

export const addToDictionary: TextDictionaryExports['addToDictionary'] =
  partialTextDictionaryToAdd => {
    textDictionary = {
      ...textDictionary,
      ...partialTextDictionaryToAdd,
      ...overrides,
    };
  };

export const setLanguage: TextDictionaryExports['setLanguage'] = newLanguage => {
  const previousLanguage: SupportedLanguage = language;
  language = newLanguage;

  // Cache previous dictionary in case language switches back
  textDictionaryByLanguageCache[previousLanguage] = {
    ...(textDictionaryByLanguageCache[previousLanguage] || {}),
    ...textDictionary,
  };

  // Initialize dictionary in the correct language
  if (!overridesByLanguageCache[language]) overridesByLanguageCache[language] = {};
  overrides = overridesByLanguageCache[language];
  textDictionary = {
    ...defaultTextDictionary,
    ...(textDictionaryByLanguageCache[language] || {}),
    ...overrides,
  };
};

/**
 * Supplements text dictionary with additional custom assignments
 */
export const addOverrides: TextDictionaryExports['addOverrides'] = (
  keyAssignments,
  cache = true,
) => {
  const newOverrides: WritableTextDictionary = praseKeyAssignmentsToOverrides(keyAssignments);

  if (!newOverrides) return;

  if (cache) {
    overridesByLanguageCache[language] = {
      ...(overridesByLanguageCache[language] || {}),
      ...newOverrides,
    };
  }
  textDictionary = { ...textDictionary, ...newOverrides };
};

export const t: TextDictionaryExports['t'] = (key, replacements = {}) => {
  if (showMeTheKeys) {
    return `&${key}`;
  }

  const text: string = textDictionary[`&${key}`];

  if (isAS(text)) {
    return parser(text, 'key');
  }

  return Object.keys(replacements).reduce(
    (accum, rkey) => accum.replace(new RegExp(`%${rkey}`, 'g'), replacements[rkey]),
    text || '',
  );
};
