import styled, { css, keyframes } from 'styled-components';

import { FormBox } from '../FormWrapper/FormWrapper.styles';
import {
  Body as FormBody,
  Container as FormContainer,
} from '../../styles/styledComponents/Form.styles';
import { FinePrint } from '../DonateForm/DonateForm.styles';

import { PlayButton } from '+/components/VideoModal/VideoModal.styles';
import { subtleZoomOutAnimationCSS } from '+/styles/reusable/animations';
import { globalHeadingStyles } from '+/styles/reusable/textStyles';

interface BackgroundImageProps {
  animate?: boolean;
  sequenceLayout?: boolean;
}

const maxWidth = '123rem';
const MOBILE_MEDIA_HEIGHT = 'clamp(25rem, max(40vh, 62vw), 40rem)';

const NOTCH_HEIGHTS = {
  center: '12vw',
};

const zIndex = {
  base: 1,
  background: 1,
  topShade: 2,
  bottomShade: 2,
  sideShades: 3,
  foreground: 2,
  photoCredit: 4,
  pitch: 4,
  logo: 5,
};

export const Background = styled.div`
  position: relative;
  top: 0;
  left: 0;
`;

const imageFade = css`
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: ${maxWidth};
    height: min(30vh, 294px);
    background-image: linear-gradient(to bottom, rgba(29, 37, 44, 0) 0%, rgba(29, 37, 44, 1) 100%);
    background-size: 100% 100%;
    background-repeat: repeat-x;
    background-position: center bottom;
    z-index: ${zIndex.bottomShade};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    pointer-events: none;
    z-index: ${zIndex.topShade};
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.3) 32%,
      rgba(0, 0, 0, 0.1) 45%,
      rgba(0, 0, 0, 0.07) 54%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const BackgroundFigure = styled.figure`
  position: absolute;
  z-index: ${zIndex.background};
  width: 100%;
  pointer-events: none;
  overflow: hidden;
  max-width: ${maxWidth};
  margin: 0 0 0 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.2s ease-out;

  ${imageFade}

  &:has(${PlayButton}:hover) {
    opacity: 0.75;
  }
`;

const actionStepMobileImageZoomOut = keyframes`
  0% {
    transform: scale(1.1) translateY(5%);
  }
  100% {
    transform: scale(1) translateY(5%);
  }
`;

export const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;
  transform-origin: 35% 60%;
  max-width: none;
  height: 90vw;
  width: auto;
  margin-left: 80%;
  transform: ${props => (props.sequenceLayout ? 'scale(1) translateY(5%)' : '')};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 250%;
    height: auto;
    margin-top: -5%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 130.213160333642261%;
    margin-left: -10%;
    margin-top: min(calc(20vh - 15%), 0px);
    transform: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin-left: -5%;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: auto;
    height: 810px;
    margin-left: 0;
  }

  ${props =>
    props.animate &&
    (props.sequenceLayout
      ? css`
          animation: ${actionStepMobileImageZoomOut} 7s;

          @media (min-width: ${props.theme.breakpoints.lg}) {
            ${subtleZoomOutAnimationCSS}
            transform: none;
          }
        `
      : subtleZoomOutAnimationCSS)};
`;

export const BackgroundVideo = styled(BackgroundImage)`
  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
`;

export const Foreground = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 810px;
  padding: clamp(90px, 12.857vh, 120px) 0 50px 0;
  margin: 0 auto;
  position: relative;
  z-index: ${zIndex.foreground};
  max-width: 1500px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Pitch = styled.div`
  color: var(--color-white);
  text-align: center;
  width: 100%;
  padding: 0 20px 40px;
  margin: min(38rem, 42vw) auto 0;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-left: 0;
    margin-right: 0;
    margin-top: clamp(25.125rem, 35vh, 32rem);
    width: 55%;
    max-width: 51.5625rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    flex-grow: 1;
    padding: 0;
    width: auto;
    margin-top: clamp(25.125rem, 45vh, 32rem);
  }

  ul,
  ol {
    text-align: left;
  }
`;

export const PitchContent = styled.div`
  max-width: 480px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
`;

export const PitchShadow = styled.div`
  background: var(--color-neutral-800);
  opacity: 0.5;
  filter: blur(1rem);
  position: absolute;
  width: calc(100% + 1rem);
  height: calc(100% - 0.5rem);
  margin: auto;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateY(calc(0.6875rem - 50%)) translateX(-50%);
`;

export const PitchText = styled.div`
  font-size: 1.0625rem;
  padding-top: 1.25em;

  h2 {
    ${globalHeadingStyles}
    line-height: 1;
    font-size: 1.6875rem;
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 2rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.125rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) and (min-height: 700px) {
      font-size: 2.7625rem;
    }
  }
`;

export const ExpandableTextWrapper = styled.div`
  margin: 1.5rem 0 0 0;
`;

export const Form = styled.div`
  max-width: 540px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin: 0;
  }
`;

export const PhotoCredit = styled.figcaption`
  color: var(--color-white);
  font-size: 0.6875rem;
  position: absolute;
  text-align: left;
  line-height: 1;
  height: 0;
  width: 0;
  z-index: ${zIndex.photoCredit};
  top: var(--height-nav);
  right: 0;
  transform: rotate(-90deg);

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    top: 9.5rem;
    left: 0;
    right: auto;
    transform: rotate(90deg);
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    top: auto;
    right: 0;
    left: auto;
    bottom: 70px;
    transform: rotate(-90deg);
  }
`;

export const PhotoCreditContainer = styled.div<{ isRightAligned?: boolean }>`
  bottom: 0;
  right: 0;
  position: absolute;
  white-space: nowrap;
  padding: 0.375rem;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    right: ${props => (props.isRightAligned ? '0' : 'auto')};
    left: ${props => (props.isRightAligned ? 'auto' : '0')};
    text-align: ${props => (props.isRightAligned ? 'right' : 'left')};
    padding: 0.625rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    padding: 1rem 1.5rem;
  }

  > * {
    &:before {
      background: var(--color-neutral-800);
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      z-index: -1;
      padding: 0 0.5em;
      margin: 0 -0.5em;
      filter: blur(1em);
    }
  }
`;

export const PhotoCreditText = styled.span`
  position: relative;
  opacity: 0.55;
`;

export const PhotoDescription = styled.div`
  display: none;
  position: relative;
  font-size: 0.75rem;
  font-style: italic;
  margin-bottom: 0.5rem;
  white-space: normal;
  line-height: 1.15rem;

  svg {
    position: absolute;
    left: -20px;
    margin-top: 3px;
  }

  span {
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: block;
  }

  &:before {
    filter: blur(3em);
  }
`;

export const MediaWithForm = styled.section`
  position: relative;
  background: var(--color-neutral-800);
  color: var(--color-white);
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  z-index: ${zIndex.base};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const ActionStep = styled.section`
  width: 100%;
  overflow-x: hidden;
  padding-block-start: ${MOBILE_MEDIA_HEIGHT};
  position: relative;
  background: var(--color-black);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    // Adding 2px to offset the slight negative top margin set on sections
    min-height: calc(100vh + 2px);
    padding-block-start: 0;
    overflow-x: unset;
  }
`;

export const ActionStepBackgroundMediaContainer = styled.div`
  width: 100%;
  height: ${MOBILE_MEDIA_HEIGHT};
  position: absolute;
  top: 0;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 50%;
    height: 100%;
    left: 0;
  }
`;

export const ActionStepBackgroundMedia = styled.div<{
  alignment: 'left' | 'right';
  hasCredit: boolean;
}>`
  height: 100%;

  ${imageFade}

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    position: sticky;
    width: 100%;
    height: 100vh;
    z-index: 0;
    top: 0;
  }

  &:after {
    display: none;
    opacity: 0.5;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      display: ${props => (props.hasCredit ? 'block' : 'none')};
    }
  }

  img,
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;

    transition: opacity 0.2s ease-out;
  }

  &:has(${PlayButton}:hover) {
    img,
    video {
      opacity: 0.75;
    }
  }

  ${Pitch} {
    margin: 0;
    position: absolute;
    bottom: ${NOTCH_HEIGHTS.center};
    left: 0;
    width: 100%;
    padding-bottom: 0.625rem;
    z-index: ${zIndex.pitch};

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      top: 50%;
      left: 50%;
      bottom: auto;
      transform: translate(-50%);
    }
  }

  ${PhotoCredit} {
    top: 8px;

    ${PhotoCreditContainer} {
      bottom: 4px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      transform: none;
      width: 100%;
      line-height: normal;

      * {
        white-space: normal;
      }

      ${PhotoCreditContainer} {
        margin: 0 1rem 1rem;
        bottom: 0;
        display: inline-block;
      }
    }
    @media (min-width: ${props => props.theme.breakpoints.xxl}) {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      transform: none;
    }
  }
`;

export const ActionStepBackgroundMediaRef = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    min-height: ${MOBILE_MEDIA_HEIGHT};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: calc(-7vw - 1vh);
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 0;
  }
`;

export const ActionStepFormContainer = styled.div`
  background: transparent;
  margin-block-start: ${`-${NOTCH_HEIGHTS.center}`};
  position: relative;
  z-index: ${zIndex.foreground};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    // Adding 2px to offset the slight negative top margin set on sections
    min-height: calc(100vh + 2px);
    width: 50%;
    clip-path: none;
    margin-block-start: 0;
    padding-block-start: 0;
  }

  ${Form}, ${FormBox} {
    clip-path: polygon(0 0, 50% ${NOTCH_HEIGHTS.center}, 100% 0, 100% 100%, 0 100%);
    transform: translateZ(0);

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      clip-path: none;
    }
  }

  ${Form} {
    max-width: none;
    height: 100%;
  }

  ${FormBox} {
    padding-block-start: calc(2rem + 6vw);
    max-width: none;
    height: 100%;
    --form-side-padding: 1.5em;
    min-height: 0;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding-block-start: 4.75rem;
      --form-side-padding: 4.5em;
      min-height: 100vh;
    }
  }

  ${FormContainer} {
    justify-content: center;
  }

  ${FormBody} {
    flex-grow: unset;
  }

  #Disclosures {
    width: 100%;
    background: var(--color-neutral-100);

    ${FinePrint} {
      color: var(--color-neutral-600);

      a {
        color: inherit;

        :hover {
          color: var(--color-neutral-450);
        }
      }
    }
  }
`;

export const ActionStepLogo = styled.div`
  position: absolute;
  top: 0;
  left: 1.5rem;
  z-index: ${zIndex.logo};
  width: 125px;
  height: 80px;
  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 150px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 279px;
    height: var(--height-nav);
  }

  // media query copied from THL.org
  @media (min-width: 1024px) {
    left: 2.5rem;
  }

  img {
    height: auto;
    display: block;
    width: 100%;
    height: auto;
  }

  a {
    display: block;
    width: 100%;
  }
`;

export const ActionStepLogoIcon = styled.div`
  position: absolute;
  top: 1rem;
  left: calc(50% - 1.1rem);
  width: 2.2rem;
  margin-inline: auto;
  z-index: ${zIndex.logo};
  filter: drop-shadow(0 0 1rem var(--color-neutral-700));

  img {
    height: auto;
    display: block;
    width: 100%;
    height: auto;
  }

  a {
    display: block;
  }
`;
