import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: flex-start;
  color: var(--color-neutral-600);
  display: flex;
  justify-content: center;
  font: italic 0.875rem var(--font-primary);
  line-height: 1;

  svg {
    margin: 0 0.25rem 0 0;
  }
`;
