import React from 'react';

import { ImageItem } from '+/components/ImageItem';
import { VideoItem } from '+/components/VideoItem';

export function isImageItem(itemData: MediaItem): itemData is ImageItem {
  return itemData.__typename === 'Directus_image_items';
}

export function isVideoItem(itemData: MediaItem): itemData is VideoItem {
  return itemData.__typename === 'Directus_video_items';
}

export const MediaItem = ({
  mediaItemData,
  sequenceLayout,
  animate,
  ...props
}: {
  mediaItemData: MediaItem;
  sequenceLayout?: boolean;
  animate?: boolean | string;
}) => {
  if (isImageItem(mediaItemData)) {
    // Pass through props, such as style class names and event listeners
    return <ImageItem {...props} imageItemData={mediaItemData} />;
  }
  if (isVideoItem(mediaItemData)) {
    // Pass through props, such as style class names and event listeners
    return <VideoItem {...props} videoItemData={mediaItemData} />;
  }
  return null;
};
