import React from 'react';
import * as Styled from '+/styles/styledComponents/Form.styles';

interface EveryActionCustomTextAreaProps {
  title: string; // The label text
  name: string; // The input value
  queryString: string;
  maxLength?: number;
  isTouched: boolean;
  isHidden: boolean;
  isRequired: boolean;
  error?: string;
  value: string;
  handleChange: () => void;
  handleBlur: () => void;
  className?: string;
}

export const EveryActionCustomTextArea = ({
  title,
  name,
  queryString,
  value,
  maxLength,
  error,
  isRequired,
  isTouched,
  isHidden,
  handleChange,
  handleBlur,
  className,
}: EveryActionCustomTextAreaProps) => {
  return (
    <Styled.CustomFieldRow isHidden={isHidden} className={className}>
      <Styled.InputLabel floating errored={isTouched && !!error}>
        <Styled.Textarea
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={title}
        />
        <Styled.InputLabelText floating>{title}</Styled.InputLabelText>
        {isTouched && !!error && <Styled.InputError>{error}</Styled.InputError>}
      </Styled.InputLabel>
    </Styled.CustomFieldRow>
  );
};
