export const getImageFilePath = (image: {
  storage: string | null;
  filenameDisk: string | null;
}): string | null => {
  if (!image?.storage || !image?.filenameDisk) {
    return;
  }

  let host;
  if (image.storage === 'aws') {
    host = process.env.REMOTE_FILE_PATH;
  } else if (image.storage === 'local') {
    host = process.env.LOCAL_FILE_PATH;
  } else {
    host = image.storage;
  }
  return `${host}/${image.filenameDisk}`;
};
