import { useWindowWidth } from './useWindowWidth';

import { breakpoints } from '+/styles/themes/mainTheme';

/* eslint-disable indent */
const getIsBelowBreakpoint =
  (breakpoint: number) =>
  (width: number): boolean =>
    (width || (typeof window !== 'undefined' && window.screen.width)) < breakpoint;
/* eslint-enable indent */

export const isSmallMobile = getIsBelowBreakpoint(breakpoints.sm.value);
export const isMobile = getIsBelowBreakpoint(breakpoints.md.value);

const useIsBelowBreakpointFunction = isFunction => {
  const { windowWidth } = useWindowWidth();

  return isFunction(windowWidth);
};

export const useIsSmallMobile = () => useIsBelowBreakpointFunction(isSmallMobile);
export const useIsMobile = () => useIsBelowBreakpointFunction(isMobile);
