import { useLocation } from '@gatsbyjs/reach-router';

import getFromLocationState from './getFromLocationState';

const useFromLocationState = (name: string): string | undefined => {
  const location = useLocation();

  return getFromLocationState(name, location);
};

export default useFromLocationState;
