import React from 'react';

import * as Animations from './HighlightHeading.animations';
import * as Styled from './HighlightHeading.styles';

import { splitHeaderText } from '+/utils/splitHeaderText';

export const HighlightHeading = (highlightHeadingData: HighlightHeadingComponent) => {
  const headingParts = splitHeaderText(highlightHeadingData.text);
  const [accentColor, setAccentColor] = React.useState(null);

  const ref = React.useRef();
  React.useEffect(() => {
    if (ref.current) {
      setAccentColor(
        window.getComputedStyle(ref.current).getPropertyValue('--color-theme-accent').trim(),
      );
    }
  }, []);

  return (
    <>
      {headingParts.map(
        (part, index): React.ReactNode => (
          <React.Fragment key={`${part}${index}`}>
            {part === '↲' ? (
              <br />
            ) : (
              <Styled.HighlightHeadingPart
                ref={ref}
                variants={Animations.TitlePartVariants}
                initial="contracted"
                animate={highlightHeadingData.highlight ? 'expanded' : 'contracted'}
                custom={{
                  index: index + 1,
                  length: headingParts.length,
                }}
                accentColor={accentColor}
                className={highlightHeadingData.className}
              >
                {part}
              </Styled.HighlightHeadingPart>
            )}
          </React.Fragment>
        ),
      )}
    </>
  );
};

HighlightHeading.defaultProps = {
  text: '',
};
