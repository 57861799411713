import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';

import * as Styled from './Layout.styles';

import { LoadingIndicator } from '+/components/LoadingIndicator';
import { mainTheme, GlobalStyles } from '+/styles/themes/mainTheme';
import { Header } from '+/components/Header';
import { Footer } from '+/components/Footer';
import { LoadingContext, MediaCreditContext, RememberMeProvider } from '+/contexts';
import { UserContextProvider } from '+/contexts/UserContext';
import { LoadingController } from '+/utils/LoadingController';
import { CreditHolder } from '+/utils/CreditHolder';

interface LayoutProps {
  children: React.ReactNode;
  layoutData: LayoutComponent;
}

const duration = 0.5;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

export const Layout = ({ children, layoutData }: LayoutProps) => {
  const [loading, setLoading] = React.useState(true);
  const { header, footer, title } = layoutData;

  return (
    <ThemeProvider theme={mainTheme}>
      <ParallaxProvider>
        <UserContextProvider>
          <RememberMeProvider>
            <MediaCreditContext.Provider value={new CreditHolder()}>
              <LoadingContext.Provider value={new LoadingController(loading, setLoading)}>
                <GlobalStyles />
                {header && <Header headerData={header} />}
                {title && <h1 className="visually-hidden">{title}</h1>}
                <Styled.Layout
                  as={motion.div}
                  key={layoutData.path}
                  variants={variants}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                >
                  {children}
                </Styled.Layout>
                {footer && <Footer footerData={footer} />}
                <AnimatePresence>
                  {loading && (
                    <LoadingIndicator
                      key="loading-indicator"
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    />
                  )}
                </AnimatePresence>
              </LoadingContext.Provider>
            </MediaCreditContext.Provider>
          </RememberMeProvider>
        </UserContextProvider>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
