import React from 'react';

interface EveryActionCustomMarkupProps {
  markup: string;
}

export const EveryActionCustomMarkup = ({ markup }: EveryActionCustomMarkupProps) => {
  if (!markup) return null;
  return <div dangerouslySetInnerHTML={{ __html: markup.replace(/\n/g, '') }}></div>;
};
