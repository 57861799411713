import React from 'react';

import * as Styled from './PrivacyPolicyLink.styles';

const PrivacyPolicyLink = React.memo(
  ({
    prefixText,
    linkText,
    linkHref = 'https://thehumaneleague.org/privacy-policy',
    suffixText = '.',
  }: PrivacyPolicyLinkComponent) => (
    <>
      <Styled.IconByName iconName="LOCK" iconProps={{ size: '0.7875rem', inline: true }} />
      &nbsp;{prefixText}&nbsp;
      <Styled.Link href={linkHref} target="_blank">
        {linkText}
      </Styled.Link>
      {suffixText}
    </>
  ),
);

export const privacyPolicyLinkLanguageLookup: PrivacyPolicyLinkLanguageLookup = Object.freeze({
  'es-MX': () => (
    <PrivacyPolicyLink
      prefixText="Respetamos tu privacidad. Consulta nuestro"
      linkText="aviso de privacidad"
      linkHref="https://thehumaneleague.mx/politica-de-privacidad"
    />
  ),
  'en-US': () => (
    <PrivacyPolicyLink prefixText="We respect your privacy. View our" linkText="privacy policy" />
  ),
});
