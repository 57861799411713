import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import { DotModal } from '../DotModal';

import * as Styled from './DotGrid.styles';

import analyticsEvent from '+/utils/analyticsEvent';

/**
 * Returns true if the dot should NOT be displayed, false if it SHOULD be displayed.
 */
const isFiltered = (
  filterState: USDAViolationsFilterReducerState,
  dot: USDAViolationData,
): boolean => {
  switch (filterState.filter) {
    case 'incidentType':
      return !dot.incidentTypes.includes(filterState.filterValue);
    case 'supplier':
      return dot.supplier !== filterState.filterValue;
    case 'state':
      return dot.state !== filterState.filterValue;
    default:
      return false;
  }
};

export const DotGrid = ({ dotGridData, filterState, showGraphicImagery }: DotGridComponent) => {
  const [activeDotId, setActiveDotId] = useState(null);
  const clearActiveDotId: () => void = React.useCallback((): void => setActiveDotId(null), []);
  const currentDot: USDAViolationData | null = React.useMemo(
    (): USDAViolationData | null => dotGridData[activeDotId] || null,
    [activeDotId],
  );

  return (
    <Styled.DotGrid>
      {dotGridData.map(dot => {
        const disabled = isFiltered(filterState, dot);

        return (
          <Styled.Dot
            key={dot.id}
            disabled={disabled}
            incidentTypes={dot.incidentTypes}
            highlighted={!disabled && dot.worstOfTheWorst}
            isActive={activeDotId === dot.id}
            onClick={() => {
              analyticsEvent({
                triggerAction: 'Click',
                targetElement: 'USDA Dot',
                targetElementVariation: dot.worstOfTheWorst ? 'worstOfTheWorst' : '',
                simpleName: 'Click USDA Dot',
              });

              setActiveDotId(dot.id);
            }}
            aria-label={`${dot.supplier && `${dot.supplier}: `}${dot.incidentTypes
              .map(type => type)
              .join(', ')} - ${dot.date}`}
          />
        );
      })}
      <AnimatePresence>
        {currentDot && (
          <DotModal
            onClose={clearActiveDotId}
            dotModalData={currentDot}
            showGraphicImagery={showGraphicImagery}
          />
        )}
      </AnimatePresence>
    </Styled.DotGrid>
  );
};

DotGrid.defaultProps = {
  showGraphicImagery: true,
};
