/*
 * This function retrieves content from advocate studio.
 * It takes a Advocate Studio code, of the form [id]X[api_code],
 * And a key and returns either a stored or cached value.
 */

// localStorage key for AS code and context identifier
const keyForCode = (code, contextKey) => `as_${code}${contextKey ? ':' : ''}${contextKey || ''}`;
const promises = [];
export const promisesStatus = { finished: true, aSLoaded: false };
let forceUpdate;

// Post request to AS
const post = code =>
  fetch('https://thl.advocate.studio/app/', {
    // fetch('https://rjfi1qfvdj.execute-api.us-east-1.amazonaws.com/default/advocate-studio-mock', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `r=api-${code}`,
  })
    .then(response => response.json())
    .catch(() => '');

// Register requests in an object, to avoid unnecessary refetching.
let requestRegister = {};

// Cleared stored requests, used in tests.
export const clearRequestRegister = () => {
  requestRegister = {};
};

// If a request has been made, use the promise returned.
// Otherwise, post a new request.
// Note, this will prevent content refetching until page is reloaded.
const retrieveStoredOrPost = async (code, contextKey) => {
  const registerKey = keyForCode(code, contextKey);

  if (requestRegister[registerKey]) {
    return undefined;
  }
  const postPromise = post(code);
  requestRegister[registerKey] = postPromise;
  promises.push(postPromise);
  promisesStatus.finished = false;
  const numPromises = promises.length;
  postPromise.then(() =>
    Promise.all(promises).then(() => {
      if (promises.length === numPromises) {
        promisesStatus.finished = true;
        promisesStatus.aSLoaded = true;
        if (forceUpdate) forceUpdate();
      }
    }),
  );
  const resObj = await postPromise;
  const {
    // phrase_main: phraseMain,
    cache = 0,
    // phrase_support: phraseSupport, // unused
    // targetEmailAddress, // unused
    // targetTwitterReply, // unused
  } = resObj;

  const expiry = new Date();
  expiry.setHours(expiry.getHours() + cache);

  localStorage.setItem(
    keyForCode(code, contextKey),
    JSON.stringify({
      cachedContent: resObj,
      expirationDate: expiry,
    }),
  );

  return {};
};

export const setForceUpdate = func => {
  forceUpdate = func;
};

export default (code, options = {}) => {
  if (typeof localStorage === 'undefined') return '';
  const { contextKey, messagePart = 'phrase_main' } = options;
  let cachedContent;
  let expirationDate;

  try {
    const storedObject = JSON.parse(localStorage.getItem(keyForCode(code, contextKey)) || '{}');
    // eslint-disable-next-line prefer-destructuring
    cachedContent = storedObject.cachedContent;
    // eslint-disable-next-line prefer-destructuring
    expirationDate = storedObject.expirationDate;
    // eslint-disable-next-line no-empty
  } catch (error) {
    console.log(error);
  }

  const isExpired = expirationDate && new Date(expirationDate).getTime() < new Date().getTime();

  let content;

  try {
    // Look for cached un-expired content
    if (!cachedContent || isExpired) {
      // If none, post to api (or retrieve ongoing post)
      retrieveStoredOrPost(code, contextKey);
      content = '';
    } else {
      content = cachedContent[messagePart];
    }
    // eslint-disable-next-line no-empty
  } catch (_e) {}

  return content || '';
};
