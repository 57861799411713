import React, { useEffect, useRef } from 'react';
import FocusTrap from 'focus-trap-react';

import * as Styled from './ModalContent.styles';
import { ModalWidths } from './ModalContent.styles';

import { Icon } from '+/components/Icon';
import { ICONS } from '+/utils/constants/icons';

export const ModalContent = (modalContentData: ModalContentComponent) => {
  const overlay = useRef(null);

  const onKeydown = (e: KeyboardEvent) => {
    // close modal on esc
    if (modalContentData.onClose && e.key === 'Escape') {
      modalContentData.onClose();
    }
  };

  const onContentWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalContentData.onClose && overlay && overlay.current && overlay.current === e.target) {
      e.stopPropagation();
      modalContentData.onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeydown);

    return () => window.removeEventListener('keydown', onKeydown);
  }, []);

  return (
    <Styled.ContentWrapper
      onClick={onContentWrapperClick}
      fullscreen={modalContentData.width === ModalWidths.FullScreen || modalContentData.fullscreen}
      className={modalContentData.className}
    >
      <FocusTrap
        aria-modal="true"
        tabIndex={-1}
        role={modalContentData.role}
        focusTrapOptions={{ initialFocus: false }}
      >
        <Styled.ContentInsideFocusTrap>
          <Styled.Content
            key="modal-content"
            initial={{ opacity: 0, top: '45%' }}
            animate={{ opacity: 1, top: '50%' }}
            exit={{ opacity: 0, top: '45%' }}
            transition={{ duration: 0.75, delay: 0.1, ease: [0.19, 1, 0.22, 1] }}
            width={modalContentData.width as ModalWidths}
          >
            <Styled.ContentContainer
              themeColor={modalContentData.themeColor}
              notched={modalContentData.notched}
              bordered={modalContentData.bordered}
            >
              {modalContentData.children}
              {!!modalContentData.onClose && (
                <Styled.CloseButton
                  onClick={e => {
                    e.stopPropagation();
                    modalContentData.onClose();
                  }}
                >
                  <Icon path={ICONS.CLOSE.path} color={modalContentData.closeColor} size={16} />
                </Styled.CloseButton>
              )}
            </Styled.ContentContainer>
          </Styled.Content>
          {modalContentData.overlay && (
            <Styled.Overlay
              ref={overlay}
              key="modal-overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            />
          )}
        </Styled.ContentInsideFocusTrap>
      </FocusTrap>
    </Styled.ContentWrapper>
  );
};

ModalContent.defaultProps = {
  role: 'dialog',
  fullscreen: false,
  overlay: true,
  notched: true,
  bordered: false,
  width: ModalWidths.Small,
  closeColor: 'var(--color-neutral-500)',
  theme: 'light',
};
