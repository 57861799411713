import { useEffect } from 'react';
/* Hook to detect the click and trigger function on outside of a given ref */

export const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: Function): void => {
  const handleClick = (e: MouseEvent) => {
    const eTarget = e.target as HTMLElement;
    if (ref.current && !ref.current.contains(eTarget)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
