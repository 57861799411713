//
const supplementErrorRenderer = (view: EveryActionView) => {
  if (!view.subviews) {
    return;
  }
  Object.values(view.subviews).forEach((subview: any) => {
    subview.renderFeedbackWithErrors = subview.renderFeedbackWithErrors || (() => {});
    supplementErrorRenderer(subview);
  });
};

export default supplementErrorRenderer;
