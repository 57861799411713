import React from 'react';

import { Button } from '+/components/Button';
import * as Styled from '+/styles/styledComponents/Form.styles';

interface EveryActionCustomSubmitButtonProps {
  buttonText: string;
  handleSubmit: () => void;
  handlePreSubmit: () => void;
  isDisabled: boolean;
}

export const EveryActionCustomSubmitButton = ({
  buttonText,
  handleSubmit,
  handlePreSubmit,
  isDisabled,
}: EveryActionCustomSubmitButtonProps) => (
  <Styled.CustomFieldRow centered marginTop="1.5rem">
    <Button
      disabled={isDisabled}
      onClick={() => {
        if (handlePreSubmit) handlePreSubmit();
        handleSubmit();
      }}
      narrow
    >
      {buttonText}
    </Button>
  </Styled.CustomFieldRow>
);

