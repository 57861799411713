import React from 'react';

import * as Styled from './TextButton.styles';

export interface TextButtonProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  small?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

export const TextButton = (props: TextButtonProps) => (
  <Styled.Button
    onClick={props.onClick}
    small={props.small}
    className={props.className}
    type={props.type}
  >
    {props.children}
  </Styled.Button>
);

TextButton.defaultProps = {
  onClick: undefined,
  small: false,
  className: undefined,
  type: 'button',
};
