import React from 'react';
import * as Styled from '+/components/DonateForm/DonateForm.styles';

export const Disclosures = ({ controller }: { controller: EveryActionModelController }) => (
  <Styled.FinePrint>
    <p>
      {/* TODO: Donor Charter page doesn't exist yet */}
      We value and respect our donors. Read our{' '}
      <a href="https://thehumaneleague.org/commitment-to-donors" target="_blank" rel="noopener">
        commitment to donors
      </a>
      .
    </p>
    <p>
      View{' '}
      <a href="https://thehumaneleague.org/privacy-policy" target="_blank" rel="noopener">
        Privacy Policy
      </a>
      .
      {controller.getCountry() === 'US' && (
        <React.Fragment>
          &nbsp;View{' '}
          <a href="https://thehumaneleague.org/state-disclosures" target="_blank" rel="noopener">
            State Disclosures
          </a>
          .
        </React.Fragment>
      )}
    </p>
  </Styled.FinePrint>
);
