import React, { useState, useRef, useEffect, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';

import { ImageItem } from '../ImageItem';

import * as Styled from './MediaWithForm.styles';
import { PhotoCredit } from './PhotoCredit.component';

import { VideoModal } from '+/components/VideoModal';
import { HighlightHeading } from '+/components/HighlightHeading';
import { MediaItem } from '+/components/MediaItem';
import { Markdown } from '+/components/Markdown';
import { FormMap } from '+/components/FormMap';
import { isImageItem } from '+/components/MediaItem/MediaItem.component';
import { ExpandableText } from '+/components/ExpandableText';
import { Link } from '+/components/Link';
import { LoadingContext, MediaCreditContext } from '+/contexts';
import { CreditHolder } from '+/utils/CreditHolder';
import { replaceStringWithLocationState } from '+/utils/replaceStringWithLocationState';
import scrollToLocation from '+/utils/scrollToLocation';
import { debounce } from '+/utils/debounce';
import { getImageFilePath } from '+/utils/getImageFilePath';
import { breakpoints } from '+/styles/themes/mainTheme';

const mediaComponents = {
  Directus_image_items: Styled.BackgroundImage,
  Directus_video_items: Styled.BackgroundVideo,
};

const ANIMATION_DURATION = 2;

const ParallaxWrapper = ({ children, isMobile }) => {
  const [, setTriggerRerender] = useState(false);
  const [vw, setVw] = useState(0);
  const [vh, setVh] = useState(0);

  const updateViewportDimensions = () => {
    setVw(document.documentElement.clientWidth || 0);
    setVh(document.documentElement.clientHeight || 0);
  };

  useEffect(() => {
    updateViewportDimensions();
  }, []);

  useEffect(() => {
    const handleScrollAndResize = () => {
      // Immediately trigger a re-render of the parallax component on scroll/resize to prevent animation issues
      setTriggerRerender(prevState => !prevState);
      updateViewportDimensions();
    };

    window.addEventListener('scroll', handleScrollAndResize);
    window.addEventListener('resize', handleScrollAndResize);

    return () => {
      window.removeEventListener('scroll', handleScrollAndResize);
      window.removeEventListener('resize', handleScrollAndResize);
    };
  });

  return isMobile ? (
    <Parallax
      speed={-35}
      rootMargin={{
        top: 0,
        right: 0,
        // Line of best fit computed via linear least-squares using a wide array of mobile screen sizes.
        // This offset is necessary to ensure the image appears centered across different mobile devices.
        bottom: 1.37296 * vw + 1.76661 * vh - 1226.18,
        left: 0,
      }}
      disabled={!isMobile}
    >
      {children}
    </Parallax>
  ) : (
    children
  );
};

export const MediaWithForm = ({
  mediaWithFormData,
  advanceFormSequence,
  step,
  isSequence,
  logo,
  logoIcon,
  logoUrl,
}: {
  mediaWithFormData: MediaWithFormComponent;
  advanceFormSequence?: () => void;
  step?: number;
  isSequence?: boolean;
  logo?: ImageItem;
  logoIcon?: ImageItem;
  logoUrl?: string;
}) => {
  const {
    pitchTitle,
    pitchText,
    pitchVisibility,
    pitchExpandableText,
    pitchExpandableTextUsage,
    pitchPreExpandableText,
    pitchExpandableTextLabel,
    pitchVideo,
    pitchVideoCaption,
    pitchVideoCaptionButtonText,
    pitchVideoCaptionButtonUrl,
    mediaItem: mediaItemData,
  } = mediaWithFormData;
  const formRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth < breakpoints.lg.value : undefined,
  );
  const formData = mediaWithFormData.form;
  const oddStep = !!(step % 2);
  // Memoize based on form ID to prevent photo credit from changing alignment during form sequence transitions
  const photoCreditIsRightAligned = useMemo(
    () => !!(step % 2),
    [`${formData.__typename}_${formData?.id || ''}`],
  );

  // Custom check for mobile window width. Other implementations in the codebase use the useWindowWidth hook,
  // which here causes the Formik form to re-render on every window width change
  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    const debounceHandleResize = debounce(() => {
      setIsMobile(window.innerWidth < breakpoints.lg.value);
    }, 200);

    window.addEventListener('resize', debounceHandleResize);
    return () => {
      window.removeEventListener('resize', debounceHandleResize);
    };
  }, []);

  const variants = !isMobile
    ? {
        initial: {
          opacity: 0.25,
          scale: 1.25,
          x: oddStep ? '150%' : 0,
        },
        animate: {
          opacity: 1,
          scale: 1,
          x: oddStep ? '100%' : 0,
        },
        exit: {
          scale: 1.125,
          x: oddStep ? '-50%' : '150%',
          opacity: 0.25,
        },
      }
    : {
        initial: {
          opacity: 0,
          scale: 1.125,
          x: 0,
        },
        animate: {
          opacity: 1,
          scale: 1,
          x: 0,
        },
        exit: {
          scale: 1.125,
          opacity: 0,
          x: 0,
        },
      };

  const StyledMedia = mediaComponents[mediaItemData.__typename] || Styled.BackgroundImage;
  const loadingContext: LoadingController = React.useContext(LoadingContext);

  const closeOrEndVideo = (): void => {
    // Wait for player to unmount / close and then scroll to top of form
    setTimeout(
      () => scrollToLocation(formRef.current, 0, 'both', breakpoints.lg.value, 'smooth'),
      400,
    );
  };

  // Pass hero image as "supporting image" so we can use it elsewhere
  if (isImageItem(mediaItemData) && 'supportingImage' in formData) {
    formData.supportingImage = mediaItemData;
  }

  const renderLogoIcon = () => (
    <img
      src={getImageFilePath(logoIcon.image)}
      alt={logoIcon.altText}
      width={logoIcon.image.width}
      height={logoIcon.image.height}
    />
  );

  // Allows for rendering pitch content in either the foreground or the background
  const renderPitch = () => {
    if (
      (isMobile && pitchVisibility === 'desktop-only') ||
      (!isMobile && pitchVisibility === 'mobile-only')
    ) {
      return <Styled.Pitch />;
    }
    return (
      <Styled.Pitch>
        <Styled.PitchContent>
          {!!pitchVideo && (
            <VideoModal
              video={pitchVideo}
              caption={pitchVideoCaption}
              captionButtonText={pitchVideoCaptionButtonText}
              captionButtonUrl={pitchVideoCaptionButtonUrl}
              onClose={closeOrEndVideo}
              handleOnEnded={() => closeOrEndVideo}
            />
          )}
          {pitchTitle?.trim() && (
            <HighlightHeading
              highlight={loadingContext.isLoaded()}
              text={replaceStringWithLocationState(pitchTitle)}
            />
          )}
          {pitchText &&
            ((!isMobile && pitchExpandableTextUsage !== 'always') ||
              pitchExpandableTextUsage === 'never' ||
              (!pitchPreExpandableText && !pitchExpandableText)) && (
              <Styled.PitchText>
                <Styled.PitchShadow />
                <Markdown>{replaceStringWithLocationState(pitchText)}</Markdown>
              </Styled.PitchText>
            )}
          {pitchPreExpandableText &&
            ((isMobile && pitchExpandableTextUsage === 'on_mobile') ||
              pitchExpandableTextUsage === 'always') && (
              <Styled.PitchText>
                <Markdown>
                  {replaceStringWithLocationState(pitchPreExpandableText.toString())}
                </Markdown>
              </Styled.PitchText>
            )}
          {pitchExpandableText &&
            ((isMobile && pitchExpandableTextUsage === 'on_mobile') ||
              pitchExpandableTextUsage === 'always') && (
              <Styled.ExpandableTextWrapper>
                <ExpandableText label={pitchExpandableTextLabel}>
                  <Markdown>{pitchExpandableText}</Markdown>
                </ExpandableText>
              </Styled.ExpandableTextWrapper>
            )}
        </Styled.PitchContent>
      </Styled.Pitch>
    );
  };

  const creditHolder = React.useMemo(() => new CreditHolder(), []);

  if (isSequence) {
    return (
      <Styled.ActionStep>
        <AnimatePresence initial={false}>
          <Styled.ActionStepBackgroundMediaContainer
            as={motion.div}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
            transition={{ duration: ANIMATION_DURATION * 1.125, ease: [0.19, 1, 0.22, 1] }}
            key={`action-step-background-media-${mediaWithFormData.id}`}
          >
            <Styled.ActionStepBackgroundMedia
              alignment={oddStep ? 'right' : 'left'}
              hasCredit={
                !!(
                  isImageItem(mediaItemData) &&
                  (mediaItemData.description || mediaItemData.photoCredit)
                )
              }
            >
              <MediaCreditContext.Provider value={creditHolder}>
                <Styled.ActionStepBackgroundMediaRef>
                  <ParallaxWrapper isMobile={isMobile}>
                    <StyledMedia
                      as={MediaItem}
                      mediaItemData={{
                        controls: false,
                        autoplay: true,
                        muted: true,
                        loop: true,
                        ...mediaItemData,
                      }}
                      loadBeforeReveal
                      sequenceLayout
                      animate={
                        isImageItem(mediaItemData) && loadingContext.isLoaded() && step === 0
                      }
                    />
                  </ParallaxWrapper>
                </Styled.ActionStepBackgroundMediaRef>
                <PhotoCredit
                  description={isImageItem(mediaItemData) && mediaItemData.description}
                  photoCredit={isImageItem(mediaItemData) && mediaItemData.photoCredit}
                  isRightAligned={photoCreditIsRightAligned}
                />
              </MediaCreditContext.Provider>
              {renderPitch()}
              {logo && !oddStep && (
                <Styled.ActionStepLogo>
                  {logoUrl ? (
                    <Link href={logoUrl}>
                      <ImageItem imageItemData={logo} />
                    </Link>
                  ) : (
                    <ImageItem imageItemData={logo} />
                  )}
                </Styled.ActionStepLogo>
              )}
              {logoIcon && oddStep && isMobile && (
                <Styled.ActionStepLogoIcon>
                  {logoUrl ? <Link href={logoUrl}>{renderLogoIcon()}</Link> : renderLogoIcon()}
                </Styled.ActionStepLogoIcon>
              )}
            </Styled.ActionStepBackgroundMedia>
          </Styled.ActionStepBackgroundMediaContainer>
        </AnimatePresence>
        <Styled.ActionStepFormContainer
          as={motion.div}
          initial={false}
          animate={!isMobile ? { opacity: 1, x: oddStep ? '0' : '100%' } : { opacity: 1, x: 0 }}
          transition={{
            duration: !isMobile ? ANIMATION_DURATION : 0,
            ease: [0.19, 1, 0.22, 1],
          }}
        >
          <Styled.Form ref={formRef}>
            <FormMap formData={formData} advanceFormSequence={advanceFormSequence} />
          </Styled.Form>
        </Styled.ActionStepFormContainer>
      </Styled.ActionStep>
    );
  }

  return (
    <Styled.MediaWithForm>
      <Styled.Background>
        <Styled.BackgroundFigure>
          <MediaCreditContext.Provider value={creditHolder}>
            <StyledMedia
              as={MediaItem}
              mediaItemData={{
                controls: false,
                autoplay: true,
                muted: true,
                loop: true,
                ...mediaItemData,
              }}
              animate={isImageItem(mediaItemData) && loadingContext.isLoaded()}
              loadBeforeReveal
            />
            <PhotoCredit
              description={isImageItem(mediaItemData) ? mediaItemData.description : null}
              photoCredit={isImageItem(mediaItemData) && mediaItemData.photoCredit}
            />
          </MediaCreditContext.Provider>
        </Styled.BackgroundFigure>
      </Styled.Background>
      <Styled.Foreground>
        {renderPitch()}
        <Styled.Form ref={formRef}>
          <FormMap formData={formData} advanceFormSequence={advanceFormSequence} />
        </Styled.Form>
      </Styled.Foreground>
    </Styled.MediaWithForm>
  );
};

MediaWithForm.defaultProps = {
  advanceFormSequence: undefined,
  step: undefined,
  isSequence: undefined,
  logo: undefined,
  logoIcon: undefined,
  logoUrl: undefined,
};
