import React from 'react';

import * as Styled from './ColorTheme.styles';

import { ColorThemeContext } from '+/contexts';

export const ColorTheme = ({ children, colorTheme }) => {
  if (colorTheme === 'inherit') {
    return children;
  }
  return (
    <ColorThemeContext.Provider value={colorTheme}>
      <Styled.ColorTheme colorTheme={colorTheme}>{children}</Styled.ColorTheme>
    </ColorThemeContext.Provider>
  );
};
