import React from 'react';
import useFromLocationState from './useFromLocationState';
import {
  EveryActionCustomAdvocacyField,
  EveryActionCustomCheckbox,
  EveryActionCustomFieldset,
  EveryActionCustomPhoneField,
  EveryActionCustomSelect,
  EveryActionCustomTextArea,
  EveryActionCustomTextField,
  EveryActionCustomMarkup,
} from './EveryActionCustomElement';

export const EveryActionCustomElementMap = (element, formikMethods, suppliedOptions) => {
  const {
    title,
    name,
    queryString,
    options,
    required,
    default_value: defaultValue,
    defaultCountryCode,
    subject,
    messages,
    children,
    markup,
  } = element;

  const { values, errors, touched, setFieldValue, handleChange, handleBlur } = formikMethods;

  const inputListeners = { handleChange, handleBlur };
  const valueFromState = useFromLocationState(name);
  switch (element.type) {
    case 'textfield':
      if (element.maxlength && element.maxlength < 150) {
        return (
          <EveryActionCustomTextField
            title={title}
            name={name}
            key={name}
            isHidden={!errors[name] && !!valueFromState && valueFromState === values[name]}
            error={errors[name]}
            isTouched={touched[name]}
            value={values[name]}
            className={`cst-form__form__text-field--${name}`}
            {...inputListeners}
          />
        );
      }
      return (
        <EveryActionCustomTextArea
          title={title}
          name={name}
          key={name}
          isHidden={!errors[name] && !!valueFromState && valueFromState === values[name]}
          queryString={queryString}
          isRequired={required}
          error={errors[name]}
          isTouched={touched[name]}
          value={values[name]}
          className={`cst-form__form__textarea--${name}`}
          {...inputListeners}
        />
      );

    case 'checkbox':
      return (
        <EveryActionCustomCheckbox
          title={title}
          name={name}
          key={name}
          queryString={queryString}
          isRequired={required}
          defaultValue={defaultValue}
          error={errors[name]}
          isTouched={touched[name]}
          value={values[name]}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          className={`cst-form__form__checkbox--${name}`}
        />
      );
    case 'international_phone':
      return (
        <EveryActionCustomPhoneField
          title={title}
          name={name}
          key={name}
          queryString={queryString}
          isRequired={required}
          defaultCountryCode={defaultCountryCode}
          options={[]}
          error={errors[name]}
          isTouched={touched[name]}
          value={values[name]}
          className={`cst-form__form__phone-field--${name}`}
          {...inputListeners}
        />
      );
    case 'select':
      return (
        <EveryActionCustomSelect
          title={title}
          name={name}
          key={name}
          options={suppliedOptions[options]}
          error={errors[name]}
          isTouched={touched[name]}
          value={values[name]}
          className={`cst-form__form__select-field--${name}`}
          {...inputListeners}
        />
      );
    case 'advocacy':
      return (
        <EveryActionCustomAdvocacyField
          title={title}
          name={name}
          key={name}
          subject={subject}
          messages={messages}
          isTouched={touched[name]}
          value={values[name]}
          className={`cst-form__form__advocacy-field--${name}`}
          {...inputListeners}
        />
      );
    case 'fieldset':
      return (
        <EveryActionCustomFieldset title={title} key={title}>
          {children.map(child =>
            EveryActionCustomElementMap(child, formikMethods, suppliedOptions),
          )}
        </EveryActionCustomFieldset>
      );
    case 'markup':
      return <EveryActionCustomMarkup markup={markup} key={markup} />;
    case 'submit':
      return null;
    default:
      return null;
  }
};
