import React from 'react';
import { withFormik } from 'formik';
import { useLocation } from '@gatsbyjs/reach-router';

import { FormError } from '../FormError';

import { CustomEveryActionForm } from './CustomEveryActionForm.component';
import validatorBuilder from './validatorBuilder';
import getFromLocationState from './getFromLocationState';

import { personalDataStore } from '+/utils/personalDataStore';
import scrollToLocation from '+/utils/scrollToLocation';
import { UserContext } from '+/contexts/UserContext';
import { dimensions } from '+/styles/themes/mainTheme';

interface CustomEveryActionFormContainerProps {
  customEveryActionFormData: CustomEveryActionFormComponent;
  overrideData?: {};
  advanceFormSequence?: () => void;
}

export default ({
  customEveryActionFormData,
  overrideData,
  advanceFormSequence,
}: CustomEveryActionFormContainerProps) => {
  const [formHelpers, setFormHelpers] = React.useState({
    formElements: null,
    submissionUrl: null,
    suppliedOptions: null,
  });
  const [isSubmitted, setSubmitted] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);
  const { userData, setUserData } = React.useContext(UserContext);
  const location = useLocation();
  const formErrorRef = React.useRef();

  const { formElements, submissionUrl, suppliedOptions } = formHelpers;

  const showErrorScreen = () => {
    setSubmitError(true);
    scrollToLocation(formErrorRef.current, dimensions.heightNav.value, 'up');
  };

  React.useEffect(() => {
    // Don't return promise to avoid issues with typescript.
    (async function fetchAndSet() {
      const result = await fetch(
        `https://secure.everyaction.com/v2/Forms/${customEveryActionFormData.formId}`,
      );
      const json = await result.json();
      const {
        form_elements: formElements,
        metadata,
        submissionUrl,
      }: { form_elements?: any; metadata: any; submissionUrl?: string } = json;
      setFormHelpers({
        formElements,
        submissionUrl,
        suppliedOptions: (metadata && metadata.options) || {},
      });
    })();
  }, []);

  const submitForm = async (values, actions) => {
    const mappedEveryActionData = Object.keys(values).reduce((accum, key) => {
      const camelCaseKey = `${key.charAt(0).toLowerCase()}${key.slice(1)}`;
      return values[key] ? { ...accum, [camelCaseKey]: values[key] } : accum;
    }, {});

    setUserData({ ...userData, ...mappedEveryActionData });

    const formData = new URLSearchParams();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    await fetch(submissionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData,
    })
      .catch(() => showErrorScreen())
      .then(async (response: Response) => {
        if (!response) {
          showErrorScreen();
          return;
        }
        const body = await response.json();
        // Error if errors in json response.
        if (response.ok && !body?.errors?.length) {
          setSubmitted(true);
          if (advanceFormSequence) advanceFormSequence();
        } else {
          showErrorScreen();
        }
      });
  };

  const isReady = !!formElements;

  if (isReady) {
    const schema = validatorBuilder.build(formElements);
    const formKeys = Object.keys(schema.fields);

    const mappedUserData = Object.keys(userData).reduce((accum, key) => {
      const pascalCaseKey = `${key.charAt(0).toUpperCase()}${key.slice(1)}`;
      return formKeys.includes(pascalCaseKey)
        ? { ...accum, [pascalCaseKey]: userData[key] }
        : accum;
    }, {});

    const storedPersonalData = Object.keys(personalDataStore).reduce(
      (accum, key) =>
        formKeys.includes(key) ? { ...accum, [key]: personalDataStore[key] } : accum,
      {},
    );
    const WrappedForm = withFormik<
      {
        isSubmitted: boolean;
        formElements: any;
        suppliedOptions: any;
        customEveryActionFormData: any;
      },
      any
    >({
      handleSubmit: submitForm,
      mapPropsToValues: () =>
        formKeys.reduce(
          (accum, key) => ({
            ...accum,
            [key]: getFromLocationState(key, location) || accum[key],
          }),
          { ...mappedUserData, ...storedPersonalData, ...overrideData },
        ),
      validationSchema: schema,
    })(CustomEveryActionForm);

    if (submitError)
      return (
        <div ref={formErrorRef} style={{ height: '100%' }}>
          <FormError handleRetry={() => setSubmitError(false)} />
        </div>
      );

    return (
      <WrappedForm
        formElements={formElements}
        customEveryActionFormData={customEveryActionFormData}
        suppliedOptions={suppliedOptions}
        isSubmitted={isSubmitted}
      />
    );
  }
  return null;
};
