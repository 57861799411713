import React from 'react';

import { Button } from '+/components/Button';
import { Link } from '+/components/Link';
import { ICONS } from '+/utils/constants/icons';

interface MarkdownButtonProps {
  to?: string;
  color?: ColorThemeName;
  big?: boolean;
  narrow?: boolean;
  monochrome?: boolean;
  endIcon?: string;
  block?: boolean;
  children: JSX.Element;
}

export const MarkdownButton = (props: MarkdownButtonProps) => {
  const { to, block, endIcon, big, narrow, monochrome, color } = props;
  const buttonAttrs = {
    color,
    big,
    narrow,
    monochrome,
    endIcon: endIcon ? ICONS[endIcon] : undefined,
    block,
  };

  const Wrapper = to
    ? wrapperProps => <Link href={to}>{wrapperProps.children}</Link>
    : React.Fragment;
  return (
    <Wrapper>
      <Button {...buttonAttrs}>{props.children}</Button>
    </Wrapper>
  );
};

MarkdownButton.defaultProps = {
  narrow: false,
  big: false,
  monochrome: false,
  block: false,
  to: undefined,
  endIcon: undefined,
  color: undefined,
};
