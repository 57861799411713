import React from 'react';

export const getInitialViolationsFilterState = (): USDAViolationsFilterReducerState => ({
  filter: null,
  filterValue: null,
});

export const violationsFilterReducer: React.Reducer<
  USDAViolationsFilterReducerState,
  USDAViolationsFilterReducerAction
> = (state: USDAViolationsFilterReducerState, action: USDAViolationsFilterReducerAction) => {
  switch (action.type) {
    case 'set':
      if (action.filter === state.filter && action.filterValue === state.filterValue) {
        return getInitialViolationsFilterState();
      }
      return { filter: action.filter, filterValue: action.filterValue };
    case 'clear':
      return getInitialViolationsFilterState();
    default:
      throw new Error('Invalid action type');
  }
};
