import React from 'react';

import * as Styled from './Section.styles';

import { ComponentMap } from '+/components/ComponentMap';
import { ColorTheme } from '+/components/ColorTheme';
import { ErrorCatcher } from '+/components/ErrorCatcher';
import { MediaItem } from '+/components/MediaItem';

const mediaComponents = {
  Directus_image_items: Styled.BackgroundImage,
  Directus_video_items: Styled.BackgroundVideo,
};

export const Section = ({
  sectionData: {
    id,
    sectionId,
    colorTheme,
    width,
    notch,
    padding,
    textAlignment,
    backgroundMedia: mediaItemData,
    components,
  },
}: {
  sectionData: SectionComponent;
}) => {
  const StyledMedia = mediaItemData
    ? mediaComponents[mediaItemData.__typename]
    : Styled.BackgroundImage;

  /* eslint-disable indent */
  const videoItemDataOverrides =
    mediaItemData && mediaItemData.__typename === 'Directus_video_items'
      ? {
          controls: false,
          autoplay: true,
          muted: true,
          loop: true,
        }
      : {};
  /* eslint-enable indent */

  return (
    <ColorTheme colorTheme={colorTheme}>
      <ErrorCatcher>
        <Styled.Section
          key={id}
          id={sectionId || `spb${id}`}
          colorTheme={colorTheme}
          notch={notch}
          padding={padding}
          textAlignment={textAlignment}
        >
          {mediaItemData && (
            <Styled.BackgroundMedia colorTheme={colorTheme} notch={notch} padding={padding}>
              <StyledMedia
                as={MediaItem}
                mediaItemData={{
                  ...mediaItemData,
                  ...videoItemDataOverrides,
                }}
              />
              {(mediaItemData as ImageItem).photoCredit && (
                <Styled.PhotoCredit notch={notch} colorTheme={colorTheme}>
                  Photo: {(mediaItemData as ImageItem).photoCredit}
                </Styled.PhotoCredit>
              )}
            </Styled.BackgroundMedia>
          )}

          <Styled.SectionContents width={width} hasMedia={!!mediaItemData}>
            {components.map((componentData, index) => (
              <ComponentMap componentData={componentData} key={index} />
            ))}
          </Styled.SectionContents>
        </Styled.Section>
      </ErrorCatcher>
    </ColorTheme>
  );
};
