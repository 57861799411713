import styled from 'styled-components';

export const Alert = styled.div`
  padding: 0.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 1;

  svg {
    margin-top: 0.25rem;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: var(--color-primary-200);
    clip-path: polygon(100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%, 0 0);
    z-index: -2;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: var(--color-primary-100);
    clip-path: polygon(100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%, 0 0);
    z-index: -1;
  }
`;

export const Msg = styled.span`
  margin-left: 0.75rem;
  font-size: 0.875rem;
  font-family: var(--font-tertiary);
  font-style: italic;
  color: var(--color-neutral-600);
`;
