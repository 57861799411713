import React, { useEffect, useRef, useState } from 'react';
import { useCountUp } from 'use-count-up';
import { AnimatePresence, motion } from 'framer-motion';

import * as Styled from './Progress.styles';

import { debounce } from '+/utils/debounce';
import MendedHeartSvg from '+/images/mended-heart-cropped.inline.svg';

const sanitizeValue = (value: number, total: number): number => {
  if (value > total) {
    return total;
  }
  if (value < 0) {
    return 0;
  }
  return value;
};

export const Progress = ({
  value,
  total,
  totalLabel,
  valueLabel,
  prefix,
  postfix,
  animationDuration = 1,
  isOnScreen = false,
  isEnabled = true,
}: Progress) => {
  const sanitizedValue = sanitizeValue(value, total);
  const countUp = useCountUp({
    duration: animationDuration - 0.2,
    end: sanitizedValue,
    isCounting: isOnScreen,
    start: 0,
    thousandsSeparator: ',',
  });

  const percentComplete = isOnScreen ? Math.round((sanitizedValue / total) * 100) : 0;
  const progressRef = useRef(null);
  const indicatorRef = useRef(null);
  const totalRef = useRef(null);
  const [progressWidth, setProgressWidth] = useState(0);
  const [isIndicatorBelowMinimumThreshold, setIsIndicatorBelowMinimumThreshold] = useState(true);
  const [isGoalVisible, setIsGoalVisible] = useState(false);
  const [indicatorTextAlign, setIndicatorTextAlign] = useState('left');

  useEffect(() => {
    setProgressWidth(progressRef.current?.offsetWidth);

    if (typeof window === 'undefined') {
      return null;
    }

    const debounceHandleResize = debounce(() => {
      setProgressWidth(progressRef.current?.offsetWidth);
    }, 200);

    window.addEventListener('resize', debounceHandleResize);
    return () => {
      window.removeEventListener('resize', debounceHandleResize);
    };
  }, [progressRef.current?.offsetWidth]);

  useEffect(() => {
    if (isOnScreen) {
      countUp.reset();
    }
  }, [isOnScreen]);

  useEffect(() => {
    const indicatorOffsetWidth = indicatorRef.current?.offsetWidth || 1;
    const totalOffsetWidth = totalRef.current?.offsetWidth || 1;
    if ((progressWidth * percentComplete) / 100 < indicatorOffsetWidth / 2) {
      setIndicatorTextAlign('left');
    } else if (progressWidth - (progressWidth * percentComplete) / 100 < totalOffsetWidth) {
      setIndicatorTextAlign('right');
    } else {
      setIndicatorTextAlign('center');
    }

    setIsIndicatorBelowMinimumThreshold(
      (progressWidth * percentComplete) / 100 < indicatorOffsetWidth / 2,
    );

    setIsGoalVisible(
      (progressWidth * percentComplete) / 100 + indicatorOffsetWidth / 2 >
        progressWidth - totalOffsetWidth,
    );
  }, [percentComplete, progressWidth]);

  if (!isEnabled) {
    return (
      <Styled.Progress>
        <Styled.ProgressHr />
      </Styled.Progress>
    );
  }

  return (
    <Styled.Progress ref={progressRef} animationDuration={animationDuration}>
      <Styled.ProgressBar>
        <Styled.ProgressBarPlaceholder>
          <Styled.ProgressBarCompleted percentComplete={percentComplete} />
        </Styled.ProgressBarPlaceholder>
      </Styled.ProgressBar>
      <Styled.ProgressIndicator
        percentComplete={percentComplete}
        isIndicatorBelowMinimumThreshold={isIndicatorBelowMinimumThreshold}
        indicatorTextAlign={indicatorTextAlign}
        ref={indicatorRef}
      >
        <Styled.ProgressIndicatorIcon
          isIndicatorBelowMinimumThreshold={isIndicatorBelowMinimumThreshold}
        >
          <MendedHeartSvg />
        </Styled.ProgressIndicatorIcon>
        <Styled.ProgressLabel>{valueLabel}</Styled.ProgressLabel>
        <AnimatePresence>
          {value && (
            <Styled.ProgressValue as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {prefix}
              {countUp.value}
              {postfix}
            </Styled.ProgressValue>
          )}
        </AnimatePresence>
      </Styled.ProgressIndicator>
      <Styled.ProgressTotal ref={totalRef} isGoalVisible={isGoalVisible}>
        <Styled.ProgressLabel>{totalLabel}</Styled.ProgressLabel>
        <AnimatePresence>
          {total && (
            <Styled.ProgressValue as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {prefix}
              {total.toLocaleString()}
              {postfix}
            </Styled.ProgressValue>
          )}
        </AnimatePresence>
      </Styled.ProgressTotal>
    </Styled.Progress>
  );
};
