import React from 'react';

import { Page } from '+/components/Page';
import { setForceUpdate } from '+/utils/getASContent';

/*
 * This component takes cleaned up page data and sends it to the page component.
 * It acts as an interface between client and server side renderings, doing anything needed for both.
 */

export const PageManager = ({ pageData }: { pageData: PageComponent }) => {
  if (JSON.stringify(pageData).includes('"status":"archived"')) {
    throw new Error('Component is archived!');
  }
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  setForceUpdate(forceUpdate);
  return <Page pageData={pageData} />;
};
