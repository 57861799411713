import React from 'react';
import { t } from '+/utils/textDictionary';

export const QuantityDisplayTab = ({ controller }: { controller: EveryActionModelController }) => {
  return (
    <a href="#ContributionInformation" className="quantity-tab">
      <span className="steps__label">{t('Widget.QuantityDisplayTab.Text')}</span>
      <div>
        <span>{controller.getTotalAmountWithCurrencySymbol()}</span>
        <span>{controller.getMonthly() && '/mo'}</span>
      </div>
    </a>
  );
};
