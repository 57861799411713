import styled, { css } from 'styled-components';

export const Accordion = styled.div<{ isInline?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isInline ? 'row' : 'column')};
  gap: 0.5rem;

  & > * {
    flex: 1;
  }
`;

export const Header = styled.button<{ isActive: boolean }>`
  display: block;
  width: 100%;
  padding: 1.25em 2.5em 1.25em 1em;
  line-height: 1;
  text-align: left;
  background: var(${props => (props.isActive ? '--color-secondary-500' : '--color-neutral-700')});
  color: var(${props => (props.isActive ? '--color-neutral-800' : '--color-white')});
  font-family: var(--font-secondary);
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.1s ease-out;
  position: relative;
  height: 3.5em;
  font-size: 0.9375rem;
  z-index: var(--z-index-accordion);

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible,
  &:hover {
    background: var(${props => (props.isActive ? '--color-secondary-500' : '--color-neutral-800')});
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -0.4em;
    width: 0;
    height: 0;
    border-width: 0.4em 0.5em 0.4em 0;
    border-color: transparent currentColor transparent transparent;
    border-style: solid;
    transition: all 0.1s ease-out;

    ${props =>
      props.isActive &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;

export const HeaderText = styled.span`
  position: absolute;
  width: calc(100% - 3.75em);
  top: 50%;
  transform: translateY(-50%);
`;

export const Item = styled.div<{ isInline?: boolean }>`
  position: relative;
`;

export const Content = styled.div<{ isInline?: boolean; maxHeight?: number }>`
  overflow: hidden;
  height: 0;
  opacity: 0;
  max-height: ${props => (!!props.maxHeight ? `${props.maxHeight.toString()}px` : 'none')};

  ${props =>
    props.isInline &&
    css`
      position: absolute;
      width: 100%;
      background: var(--color-neutral-800);
    `};
`;

export const ContentPadding = styled.div`
  padding: 1rem;
`;
