import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  maxWidth?: number;
  padded?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
  margin: 0 auto;
  padding-top: 1rem;
  ${props =>
    props.padded &&
    css`
      padding-left: var(--form-side-padding);
      padding-right: var(--form-side-padding);
    `}
`;

export const Title = styled.div`
  font-size: 1.5625rem;
  font-family: var(--font-tertiary);
  font-style: italic;
  color: var(--color-theme-base-inverse);
  text-align: center;
  font-weight: 500;
  margin: 1.25rem -0.75rem 0;
  letter-spacing: -0.02rem;
  line-height: 1.6;

  > * {
    display: inline-block;
  }
`;

export const Copy = styled.div`
  color: var(--color-theme-base-inverse);
  font-size: 1.0625rem;
  text-align: center;
  padding: 0.5rem 0 1.5rem;
  margin: 0 -0.75rem;

  &:empty {
    display: none;
  }

  p {
    margin: 1em 0;
  }
`;

export const Alerts = styled.div`
  margin: 1rem 0 0 0;
  width: 100%;
`;
