import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { link } from '+/styles/reusable/linkStyles';
import { Container as FormContainer } from '+/styles/styledComponents/Form.styles';

export enum ModalWidths {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  FullScreen = 'fullscreen',
}

interface ContentProps {
  width: ModalWidths;
}

export const Content = styled(motion.div)<ContentProps>`
  position: relative;
  top: 50%;
  left: 50%;

  max-height: 100%;
  ${props => {
    switch (props.width) {
      case ModalWidths.Small:
        return css`
          max-width: 600px;
        `;
      case ModalWidths.Medium:
        return css`
          max-width: 800px;
        `;
      case ModalWidths.Large:
        return css`
          max-width: 1000px;
        `;
      default:
        return css`
          max-width: 600px;
        `;
    }
  }}

  transform: translate(-50%, -50%);

  z-index: var(--z-index-modal-content);

  filter: drop-shadow(0 2rem 3rem rgba(0, 0, 0, 0.3));

  a {
    ${link}
  }

  ${FormContainer} {
    max-width: none;
  }
`;

interface ContentContainerProps {
  themeColor: 'light' | 'dark';
  notched: boolean;
  bordered: boolean;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  font-family: var(--font-primary);

  overflow: hidden;

  display: flex;
  flex-direction: column;

  .image-with-text {
    padding: 0;

    > div::after {
      display: none;
    }

    button {
      @media (max-width: ${props => props.theme.breakpoints.md}) {
        width: 100%;
      }
    }
  }

  ${props => {
    switch (props.themeColor) {
      case 'dark': {
        return css`
          background: var(--color-neutral-800);
          color: var(--color-white);
        `;
      }
      default: {
        return css`
          background: var(--color-white);
          color: var(--color-neutral-600);
        `;
      }
    }
  }}

  ${props =>
    props.notched &&
    css`
      clip-path: polygon(100% 0, 100% calc(100% - 2.5rem), calc(100% - 2.5rem) 100%, 0 100%, 0 0);
    `};
  -webkit-overflow-scrolling: touch;

  ${props =>
    props.bordered &&
    css`
      > section {
        border-bottom-width: 10px;
        border-bottom-style: solid;
      }
    `}
`;

export const ContentInsideFocusTrap = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  z-index: var(--z-index-modal-overlay);
  overflow: hidden;
  padding: 1rem;
  margin: 0;
  position: relative;
  overflow-y: auto;
`;

interface ContentWrapperProps {
  fullscreen: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-modal-base);
  -webkit-overflow-scrolling: touch;
  overflow-wrap: break-word;

  ${props =>
    props.fullscreen &&
    css`
      ${ContentContainer} {
        clip-path: unset;
      }

      ${Content} {
        height: 100%;
        width: 100%;
        max-width: 100%;

        overflow-y: auto;
      }

      ${ContentInsideFocusTrap} {
        padding: 0;
      }
    `}
`;

export const CloseButton = styled.button`
  appearance: none;
  padding: 0.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export const Overlay = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-modal-overlay);
  backdrop-filter: blur(3px);
`;
