import ReactDOM from 'react-dom';

/**
 * Renders a react component via  portal to the node corresponding to the provided selector.
 */

export const RenderTo = ({
  children,
  atSelector,
  skippable, // Should an error here break the form?
}: {
  children: Array<JSX.Element> | JSX.Element;
  atSelector: string;
  skippable?: boolean;
}) => {
  if (!document.querySelector(atSelector)) {
    console.warn(atSelector);
    if (skippable) return '';
  }
  return ReactDOM.createPortal(children, document.querySelector(atSelector));
};
