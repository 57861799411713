import React, { useContext, useState } from 'react';

import { CustomEveryActionForm } from '../CustomEveryActionForm';
import { Markdown } from '../Markdown';
import { MediaItem } from '../MediaItem';
import { Link } from '../Link';

import * as Styled from './TrackedSweetenerForm.styles';
import { ImagePositions } from './TrackedSweetenerForm.styles';
import enUsDictionary from './TrackedSweetenerForm.dictionary.en-US';

import * as FormStyled from '+/styles/styledComponents/Form.styles';
import { personalDataStore } from '+/utils/personalDataStore';
import { ModalContext } from '+/contexts/ModalContext';
import { addToDictionary, t } from '+/utils/textDictionary';

addToDictionary(enUsDictionary);

// Parse comma-delimited ID strings to programmatically select activist code options in the form
const formatOverrides = idString =>
  idString
    ?.split(',')
    .map(codeId => codeId.trim())
    .reduce((accum, codeId) => ({ ...accum, [`Interest_${codeId}`]: true }), {});

export const TrackedSweetenerForm = ({
  trackedSweetenerFormData,
  advanceFormSequence,
}: {
  trackedSweetenerFormData: TrackedSweetenerFormComponent;
  advanceFormSequence?: () => void;
}) => {
  const {
    sweetenerBundle: { downloadLink, acceptActivistCodes, declineActivistCodes, message, image },
    acceptButtonText,
    declineButtonText,
    headerText,
    bodyText,
    imagePositioning,
  } = trackedSweetenerFormData;

  const { displayedModal, setDisplayedModal, onModalClose } = useContext(ModalContext);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const acceptCodeOverrides = formatOverrides(acceptActivistCodes);
  const declineCodeOverrides = formatOverrides(declineActivistCodes);
  const activistCodeTaggerFormData = {
    __typename: 'Directus_custom_every_action_forms',
    formId: 'b33m4kuIXEeFrx4QvBMNOw2',
    headerCopy: '',
    headerTitle: '',
    customCss: '',
    supportingImage: null,
    handlePreSubmit: () => {
      // Functions called regardless of the success/failure of the form submission
      if (displayedModal) {
        setDisplayedModal(null);
        if (onModalClose) onModalClose();
      }
      if (advanceFormSequence) advanceFormSequence();
      // Triggers fallback Thank You screen
      setFormSubmitted(true);
    },
  };

  const formattedBodyText = personalDataStore.FirstName
    ? (bodyText || message || '').replace('%Name', personalDataStore.FirstName)
    : bodyText || message;

  const recordInteraction = () => {
    localStorage.setItem('lastInteractedWithSweetener', new Date().toString());
  };

  return (
    <FormStyled.Container>
      <Styled.SweetenerForm imagePositioning={imagePositioning as ImagePositions}>
        {!formSubmitted ? (
          <>
            {image && <MediaItem mediaItemData={image} />}
            <Styled.Content>
              {headerText && <h2>{headerText}</h2>}
              {formattedBodyText && <Markdown>{formattedBodyText}</Markdown>}
              <Styled.ButtonContainer>
                <Styled.AcceptButton onClick={recordInteraction}>
                  <Link href={downloadLink}>
                    <CustomEveryActionForm
                      customEveryActionFormData={{
                        ...activistCodeTaggerFormData,
                        submitText: acceptButtonText || t('TrackedSweetenerForm.Accept'),
                      }}
                      overrideData={acceptCodeOverrides}
                    />
                  </Link>
                </Styled.AcceptButton>
                <Styled.DeclineButton onClick={recordInteraction}>
                  <CustomEveryActionForm
                    customEveryActionFormData={{
                      ...activistCodeTaggerFormData,
                      submitText: declineButtonText || t('TrackedSweetenerForm.Decline'),
                    }}
                    overrideData={declineCodeOverrides}
                  />
                </Styled.DeclineButton>
              </Styled.ButtonContainer>
            </Styled.Content>
          </>
        ) : (
          <FormStyled.Header>
            <h2>{t('TrackedSweetenerForm.Thanks')}</h2>
          </FormStyled.Header>
        )}
      </Styled.SweetenerForm>
    </FormStyled.Container>
  );
};
