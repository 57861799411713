/**
 * Examines the dom tree and throws an error if it expects to find nodes that are absent.
 */

import { base as baseSelectors } from '../selectors';

class EAExpectationError extends Error {
  name = 'EAExpectationError';
}

const expectToFind = (selector: string) => {
  const entity = document.querySelector(selector);
  if (!entity) {
    throw new EAExpectationError(`${selector} not found.`);
  }
};

export default () => {
  Object.values(baseSelectors).map(selector => expectToFind(selector));
};
