import getWindowSize from './getWindowSize';
import { MouseCoordinates } from './useMousePosition';

export interface MappedMouseCoordinates extends MouseCoordinates {}

// (Assuming your MouseCoordinates are within window size)
// Returns a value from -0.5 to 0.5 for both x and y
// depending on how far the mouse is in either direction
export const mapMouseCoordinatesToCenter = (
  mouseCoordinates: MouseCoordinates,
): MappedMouseCoordinates => {
  const windowSize = getWindowSize();

  return {
    x: ((mouseCoordinates.x - windowSize.width / 2) / windowSize.width) * -1,
    y: ((mouseCoordinates.y - windowSize.height / 2) / windowSize.height) * -1,
  };
};
