import React from 'react';

import * as Styled from './Toggle.styles';

export const Toggle = (toggleData: ToggleComponent) => {
  const onToggleChange = React.useCallback(event => {
    toggleData.setToggle(event.target.checked);
  }, []);

  return (
    <Styled.ToggleLabel
      isInverseDot={toggleData.isInverseDot}
      className={toggleData.className}
      isSquared={toggleData.isSquared}
    >
      {toggleData.children ? <>{toggleData.children}&nbsp;</> : null}
      <Styled.ToggleInput type="checkbox" checked={toggleData.toggle} onChange={onToggleChange} />
      <Styled.ToggleSlider />
    </Styled.ToggleLabel>
  );
};
