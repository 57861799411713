import { css, keyframes } from 'styled-components';

export const subtleZoomOutAnimationKeyframes = keyframes`
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const subtleZoomOutAnimationCSS = css`
  animation: ${subtleZoomOutAnimationKeyframes} 7s;
`;

const heartBeatAnimationKeyframes = keyframes`
0% {
  transform: scale(0.85) translateY(8%);
}
15% {
  transform: scale(1) translateY(8%);
}
45% {
  transform: scale(0.85) translateY(8%);
}
100% {
  transform: scale(0.85) translateY(8%);
}
`;

export const heartBeatAnimationCSS = css`
  ${heartBeatAnimationKeyframes} 1.25s ease-out infinite
`;
