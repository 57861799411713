import styled from 'styled-components';

export const PaymentMethodsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-neutral-200);
  padding: 1.5rem;

  img {
    margin: 0 0.5rem;
  }
`;
