/*
 * Adds amount options with customized behavior.
 */

import React, { RefObject } from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './StandardAmountOptions.styles';

import { t } from '+/utils/textDictionary';
import { PickerGroup, PickerButton } from '+/components/Picker';

export const StandardAmountOptions = ({
  controller,
  amounts,
  isRecurring,
  onCustom,
  customValue,
  firstAmountButtonRef,
  customInputRef,
  selectedAmount,
  handleAmountChange,
  handleCustomChange,
  handleOtherInputBlur,
  setToCustom,
}: {
  controller: EveryActionModelController;
  amounts: Array<number>;
  isRecurring: boolean;
  onCustom: boolean;
  firstAmountButtonRef: RefObject<HTMLInputElement>;
  customInputRef: RefObject<HTMLInputElement>;
  selectedAmount: number;
  customValue?: string;
  handleAmountChange: (e: any) => void;
  handleCustomChange: (e: any) => void;
  handleOtherInputBlur: () => void;
  setToCustom: () => void;
}) => (
  <PickerGroup>
    {amounts.map((amount, idx) => {
      const minimumFractionDigits = amount - Math.floor(amount) !== 0 ? 2 : 0;
      return (
        <PickerButton
          checkmark={false}
          key={`${amount}, ${isRecurring}: ${idx}`}
          name="custom_widget_amount"
          isSelected={amount === controller.getSelectedAmount() && !onCustom}
          ref={idx === 0 ? firstAmountButtonRef : null}
          onChange={() => handleAmountChange(amount)}
        >
          <Styled.SmallCurrency>{controller.getCurrencySymbol()}</Styled.SmallCurrency>
          {amount.toLocaleString(undefined, { minimumFractionDigits })}
          {isRecurring && <Styled.SmallLabel>{t('PerMonth.Abbreviation')}</Styled.SmallLabel>}
        </PickerButton>
      );
    })}
    <Styled.OtherWrapper>
      <PickerButton
        checkmark={false}
        name="custom_widget_amount"
        data-idf="amount-options-custom-button"
        onChange={() => setToCustom()}
        isSelected={onCustom}
        aria-label="Custom amount"
      >
        <Styled.CustomLabel>{t('Widget.AmountOptions.CustomLabel')}</Styled.CustomLabel>
      </PickerButton>
      <AnimatePresence>
        {onCustom && (
          <Styled.CustomInputWrapper
            key="custom-input-wrapper"
            className="bfrb"
            initial={{ width: 'calc(0% - 0rem)' }}
            animate={{ width: 'calc(50% - .5rem)' }}
            exit={{ width: 'calc(0% - 0rem)' }}
            transition={{ duration: 0.25, delay: 0.2 }}
          >
            <Styled.CustomInputLabel
              key="custom-input-label"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.45, duration: 0.2 }}
            >
              <span>{controller.getCurrencySymbol()}</span>
              <Styled.CustomInput
                ref={customInputRef}
                onChange={e => handleCustomChange(e)}
                value={customValue || ''}
                type="number"
                step="1"
                min="0"
                pattern="^\d*(\.\d{0,2})?$"
                aria-label={t('Widget.AmountOptions.A11yCustomLabel')}
                placeholder={selectedAmount.toString()}
                onBlur={() => handleOtherInputBlur()}
              />
            </Styled.CustomInputLabel>
          </Styled.CustomInputWrapper>
        )}
      </AnimatePresence>
    </Styled.OtherWrapper>
  </PickerGroup>
);
