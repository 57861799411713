import React from 'react';
import { Markdown } from '+/components/Markdown';
import * as Styled from './Text.styles';

export const Text = ({ textItemData: { text, twoColumn } }: { textItemData: TextItem }) => {
  return (
    <Styled.Text twoColumn={twoColumn}>
      <Markdown>{text}</Markdown>
    </Styled.Text>
  );
};
