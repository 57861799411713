import React, { useContext } from 'react';
import styled from 'styled-components';

import { t } from '+/utils/textDictionary';
import { Button } from '+/components/Button';
import analyticsEvent from '+/utils/analyticsEvent';
import {
  FormAlertContext,
  REMEMBER_ME_COOKIE_REGEX,
  handleDeleteUserData,
  postUserData,
  useRememberMe,
} from '+/contexts';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';
import { deleteUserData } from '+/contexts/RememberMe/api';

const StyledLowerCase = styled.span`
  text-transform: lowercase;
`;

export const SubmitButton = ({
  controller,
  openModal,
  upsellModalOptOut,
}: {
  controller: EveryActionModelController;
  openModal?(): void;
  upsellModalOptOut: boolean;
}) => {
  const { state: rememberMeState } = useRememberMe();
  const { saveUserData } = rememberMeState;
  const { addAlert, removeAlert } = useContext(FormAlertContext);

  if (!controller.onFinalStep()) {
    return null;
  }

  const alertErrorMessage = t('Widget.FormErrors.MissingFields');

  const addAuthError = () => {
    const authCheckbox = document.querySelector('label.authorize-payment-label');
    const existingAuthError = document.querySelector('small.authorize-payment-error');
    if (!!authCheckbox && !existingAuthError) {
      const authError = document.createElement('small');
      authError.className = 'authorize-payment-error error';
      authError.textContent = t('Widget.FormErrors.AuthPayment');
      authCheckbox.insertAdjacentElement('afterend', authError);
    }
  };

  const removeAuthError = () => {
    const authError = document.querySelector('small.authorize-payment-error');
    if (authError) {
      authError.remove();
    }
  };

  const getVals = () => {
    const postcodes = document.getElementsByName('PostalCode');
    const postcode = (postcodes[0] as HTMLInputElement)?.value;

    const localities = document.getElementsByName('City');
    const locality = (localities[0] as HTMLInputElement)?.value;

    const states = document.getElementsByName('StateProvince');
    const state = (states[0] as HTMLInputElement)?.value;

    const address2s = document.getElementsByName('AddressLine2');
    const address2 = (address2s[0] as HTMLInputElement)?.value;

    return { postcode, locality, state, address2 };
  };

  const handleRememberMe = () => {
    // post user data if necessary
    postUserData(
      {
        ...getVals(),
        firstName: controller.getFirstName(),
        lastName: controller.getLastName(),
        address1: controller.getAddressLine1(),
        email: controller.getEmailAddress(),
        country: controller.getCountry(),
        mobilePhone: controller.getMobilePhone(),
      },
      saveUserData,
    );

    // delete user data if necessary
    handleDeleteUserData(saveUserData);
  };

  const handleClick = e => {
    e.preventDefault();
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Submit Started',
      simpleName: 'Start donation submission',
    });
    controller.renderFeedback();
    const updatedErrors = controller.getErrors();

    handleRememberMe();

    if (updatedErrors.length) {
      addAlert(alertErrorMessage);
      if (
        !!updatedErrors.find(err => err.text === 'Must authorize payment') &&
        controller.bankSelected()
      ) {
        addAuthError();
      }
    } else if (controller.isUpsellEligible() && !upsellModalOptOut) {
      removeAlert(alertErrorMessage);
      removeAuthError();
      openModal();
      analyticsEvent({
        eventTypeName: 'CustomFormEvent',
        targetElement: 'EveryActionForm',
        targetElementVariation: 'Donation',
        triggerAction: 'Upsell Displayed',
        simpleName: 'Display upsell modal',
      });
    } else {
      analyticsEvent({
        eventTypeName: 'CustomFormEvent',
        targetElement: 'EveryActionForm',
        targetElementVariation: 'Donation',
        triggerAction: 'Upsell Skipped',
        simpleName: 'Skip displaying upsell modal',
      });
      removeAlert(alertErrorMessage);
      removeAuthError();
      controller.submit();
    }
  };

  const amount = t('Widget.SubmitButton.Submit', {
    amount: controller.getTotalAmountWithCurrencySymbol(),
  });

  const buttonTextFrequency = controller.getMonthly() ? (
    <StyledLowerCase>{t('Widget.SubmitButton.Monthly')}</StyledLowerCase>
  ) : (
    <span> {t('Widget.SubmitButton.Once')}</span>
  );

  // aria-hidden prevents strange behavior by screen readers. We're telling
  // screen readers to ignore the actual text and read out the
  // aria-label property on the element.
  const buttonText = (
    <span aria-hidden>
      {amount}
      {buttonTextFrequency}
    </span>
  );
  const ariaLabel = `${amount} ${
    controller.getMonthly() ? t('Widget.SubmitButton.MonthlyLabel') : t('Widget.SubmitButton.Once')
  }`;

  return (
    <Button
      big
      block
      color={ColorThemeNames.RedAccented}
      onClick={e => handleClick(e)}
      aria-label={ariaLabel}
    >
      {buttonText}
    </Button>
  );
};

SubmitButton.defaultProps = {
  openModal: () => {},
};
