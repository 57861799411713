import styled, { css } from 'styled-components';

interface IconSVGProps {
  inline?: boolean;
  iconColorTheme?: string;
}

export const SVG = styled.svg<IconSVGProps>`
  fill: ${props => {
    const colors = {
      secondary: 'var(--color-neutral-800)',
      monochrome: 'var(--color-black)',
    };
    if (props.iconColorTheme) {
      return colors[props.iconColorTheme] || 'var(--color-white)';
    }
    return props.color || 'currentColor';
  }};

  ${props =>
    props.inline
      ? css`
          display: inline-block;
          vertical-align: middle;
        `
      : css`
          display: block;
        `}
`;
