import styled, { css } from 'styled-components';

import { getColorThemeVariables } from '+/utils/getColorThemeVariables';

export const ColorTheme = styled.span<{
  colorTheme?: ColorThemeName;
}>`
  display: contents;
  ${({ colorTheme }) =>
    colorTheme &&
    css`
      ${getColorThemeVariables(colorTheme)}
    `}
`;
