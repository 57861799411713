import { css, keyframes } from 'styled-components';

// Labels

export const inputLabel = css`
  position: relative;
  font-size: 0.813rem;
  color: var(--color-neutral-600);
  line-height: 1.4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
`;

export const inputLabelFloating = css`
  font-size: 0;
  color: transparent;
  margin-top: 1.125rem;
`;

export const inputLabelErrored = css`
  color: var(--color-theme-base-danger);
`;

export const inputLabelTextBase = css`
  color: var(--color-theme-base-inverse);
  font-size: 0.813rem;
  text-align: left;
  top: -1.25rem;
  left: 0;
`;

export const floatingLabel = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    ${inputLabelTextBase};
    font-size: 0.73125rem;
  }
`;

export const inputLabelTextFloatingStart = css`
  position: absolute;

  top: 0;
  left: 0;

  display: flex;

  font-family: var(--font-tertiary);
  font-weight: 400;
  font-style: italic;
  font-size: 0.7rem;

  text-align: left;
  text-transform: lowercase;

  color: var(--color-neutral-600);
  opacity: 0;
`;

export const inputLabelTextFloatingAnimation = css`
  animation-name: ${floatingLabel};
  animation-delay: 0.05s;
  animation-duration: 0.35s;
  animation-fill-mode: forwards;
`;

// Errors

export const inputError = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.6875rem;
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: var(--color-theme-base-danger);
  padding-top: 0.5em;

  &:before {
    content: '✕';
    color: var(--color-theme-base-danger);
    font-weight: bold;
    font-size: 0.875rem;
    margin-right: 0.25rem;
    display: inline-block;
    margin-top: -0.125rem;
  }
`;

// Inputs

export const textyInput = css`
  display: block;
  height: var(--input-texty-height);
  padding: 0 0.9em;
  width: 100%;
  background: var(--color-neutral-100);
  color: var(--color-neutral-600);
  font-family: var(--font-primary);
  font-size: 1rem;
  border: none;
  border-bottom: 0.125rem solid var(--color-neutral-300);
  box-shadow: none !important;
  line-height: var(--input-texty-height);
  transition: border-bottom-color 0.2s, color 0.1s;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: var(--color-neutral-400);
    transition: color 0.1s;
  }

  &:hover {
    &::placeholder {
      color: var(--color-neutral-500);
    }
  }

  &:focus,
  &:focus-visible,
  :root &:focus-visible {
    border-bottom-color: var(--color-neutral-500);
    outline: none;
  }
`;

export const inputNumber = css`
  ${textyInput};
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const inputSelect = css`
  ${textyInput};
  appearance: none;
  border-radius: 0;
  background: var(--color-neutral-100) var(--image-select-arrow) no-repeat right 0.5rem center;
  padding-right: 2.5625rem;
  letter-spacing: -0.03em;
`;

export const textarea = css`
  ${textyInput};
  padding-top: 0.65em;
  padding-bottom: 0.65em;
  line-height: 1.65;
  min-height: 8em;
  height: auto;
`;

// Checkboxes

export const checkboxLabelText = css`
  position: relative;
  padding-left: 1.75em;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.375;
  color: var(--color-theme-base-inverse);

  &:before,
  &:after {
    height: 1.1875rem;
    left: 0;
    position: absolute;
    top: 0.05rem;
    width: 1.1875rem;
    content: '';
    transition: all 0.1s ease-out;
    cursor: pointer;
  }

  &:before {
    background: var(--color-white);
    border: 1px solid var(--color-neutral-400);
  }

  &:after {
    background: transparent var(--image-checkmark) no-repeat center center / 68.75% auto;
    opacity: 0.8;
    transform: scale(0);
  }
`;

export const checkbox = css`
  position: absolute;
  opacity: 0;
  z-index: -9999;

  &:not(:checked) + span:hover:before {
    border-color: var(--color-neutral-600);
  }

  &:checked + span {
    &:after {
      transform: scale(1);
    }
  }

  &:focus-visible + span:before {
    // apply browser default focus ring
    // https://css-tricks.com/copy-the-browsers-native-focus-styles/
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`;
