import React from 'react';

import * as Styled from './Intro.styles';

import { orchestrationDurationInSeconds } from '+/components/FullscreenSlideshow';
import { LoadingContext } from '+/contexts';

export const Intro = (): JSX.Element => {
  const loadingContext: LoadingController = React.useContext(LoadingContext);
  const isLoaded = loadingContext.isLoaded();

  const [shouldHighlight, setShouldHighlight]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = React.useState(false);

  // Delay highlighting the text until this stage fades in
  React.useEffect((): (() => void) | undefined => {
    if (isLoaded) {
      const highlightDelayTimer: number = window.setTimeout(
        (): void => setShouldHighlight(true),
        orchestrationDurationInSeconds * 1000,
      );

      return (): void => window.clearTimeout(highlightDelayTimer);
    }

    return undefined;
  }, [isLoaded]);

  return (
    <Styled.Intro>
      In January 2022, the United States Department of Agriculture (USDA) released official records
      of slaughterhouse violations at poultry plants across the country.
      <br />
      <br />
      Between April and September of 2021, the USDA conducted inspections at{' '}
      <Styled.HighlightHeading
        text="300 poultry slaughterhouses"
        highlight={shouldHighlight}
      />{' '}
      in the US, including plants operated by industry giants like Tyson Foods, Pilgrim’s Pride,
      Sanderson Farms, Perdue Foods, and Koch Foods. This is what the inspectors found—
      <Styled.HighlightHeading
        text="read it in their own words."
        highlight={shouldHighlight}
      />{' '}
    </Styled.Intro>
  );
};
