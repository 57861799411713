const updateFastAction = (
  controller: EveryActionModelController,
  { fill_source: fillSource, fill_dict: fillDict }: { fill_source: any; fill_dict: any },
) => {
  if (fillSource === 'FastAction') {
    controller.setFastActionLoggedIn(true);
    controller.setFastActionName(`${fillDict.FirstName} ${fillDict.LastName}`);
  }
};

export default updateFastAction;
