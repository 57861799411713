import React from 'react';

import * as Styled from './PrivacyPolicyLink.styles';
import { privacyPolicyLinkLanguageLookup } from './PrivacyPolicyLink.component';

import { LanguageContext } from '+/contexts/LanguageContext';

export const PrivacyPolicyLink = () => {
  const language: string = React.useContext(LanguageContext);

  const privacyPolicyTextAndLinkComponent = React.useMemo(
    () => (privacyPolicyLinkLanguageLookup[language] || privacyPolicyLinkLanguageLookup['en-US'])(),
    [language],
  );

  return <Styled.PrivacyPolicyLink>{privacyPolicyTextAndLinkComponent}</Styled.PrivacyPolicyLink>;
};
