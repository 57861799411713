import styled, { css, keyframes } from 'styled-components';

import { getAccentedVariant, getFilledVariant } from '../themes/colorThemes';

import { getColorThemeVariables } from '+/utils/getColorThemeVariables';
import * as sharedStyles from '+/styles/reusable/inputStyles';
import { Markdown } from '+/components/Markdown/Markdown.styles';

export enum FormBaseShades {
  Default = 'default',
  Light = 'light',
  Dark = 'dark',
}

export enum FormBackgroundStyles {
  Default = 'default',
  Shaded = 'shaded',
  Filled = 'filled',
}

export const FORM_NOTCH_HEIGHTS = {
  center: '6vw',
  centerMobile: '12vw',
};

export const getTwoToneTheming = (formBackgroundStyle, colorTheme) => {
  const accentedColorTheme =
    formBackgroundStyle === FormBackgroundStyles.Filled
      ? getAccentedVariant(colorTheme)
      : colorTheme || 'inherit';

  const formColorTheme =
    formBackgroundStyle === FormBackgroundStyles.Filled
      ? getFilledVariant(colorTheme)
      : colorTheme || 'inherit';

  const formBaseShade =
    formBackgroundStyle === FormBackgroundStyles.Shaded
      ? FormBaseShades.Dark
      : FormBaseShades.Default;

  return { accentedColorTheme, formColorTheme, formBaseShade };
};

export const formColorTransitionParams = '0.75s ease-in-out';

// Horizontally aligns contents. Includes Header and Body.
export const Container = styled.div<{ customCss?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  width: 100%;
  color: var(--color-theme-base-inverse);
  max-width: var(--form-container-width);
  ${props => props.customCss}
  transition: color ${formColorTransitionParams};
`;

export const PreContent = styled.div``;

// Notched

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
  `;

export const Content = styled.div<{ colorTheme?: ColorThemeName; baseShade?: FormBaseShades }>`
  ${props =>
    !!props.colorTheme &&
    css`
      ${getColorThemeVariables(props.colorTheme)}
    `};

  transition: background ${formColorTransitionParams};
  ${() =>
    css`
      clip-path: polygon(50% ${FORM_NOTCH_HEIGHTS.centerMobile}, 100% 0, 100% 100%, 0 100%, 0 0);
      // Chrome has issues with clip path that adding a z translate helps.
      transform: translateZ(0);
      margin-top: 6vw;
      padding-top: ${FORM_NOTCH_HEIGHTS.centerMobile};
      padding-bottom: 4.5rem;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        margin-top: 0;
        clip-path: polygon(50% ${FORM_NOTCH_HEIGHTS.center}, 100% 0, 100% 100%, 0 100%, 0 0);
        padding-top: ${FORM_NOTCH_HEIGHTS.center};
      }
    `}
  ${({ baseShade }) => {
    if (baseShade === FormBaseShades.Dark)
      return css`
        background: var(--color-theme-base-dark);

        &:before {
          animation: ${fadeIn} ${formColorTransitionParams};
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(${FORM_NOTCH_HEIGHTS.center} + 15rem);
          pointer-events: none;
          background: linear-gradient(var(--color-neutral-250) 0%, rgba(255, 255, 255, 0) 100%);
          z-index: -1;
        }
      `;
    return css`
      background: var(--color-theme-base);
    `;
  }}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: var(--form-header-side-padding);
  padding-right: var(--form-header-side-padding);
  margin-top: 2.5em;
  margin-bottom: 2em;
  text-align: center;
  max-width: var(--main-container-width);

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: calc(
      var(--form-container-width) + var(--form-header-side-padding) +
        var(--form-header-side-padding)
    );
  }

  ${Markdown} {
    text-align: center;
  }
`;

// Text beneath the header
export const Text = styled(Header)`
  margin-top: 0.5rem;
  margin-bottom: 1.75rem;
  font-size: 0.9rem;
  padding-left: calc(var(--form-side-padding));
  padding-right: calc(var(--form-side-padding));

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 2rem;
    font-size: 1rem;
  }
`;

export const Icon = styled.div`
  align-self: center;
`;

// Sets max width. Horizontally centers contents.
export const Body = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: space-around;
`;

export const Section = styled.section<{ bare?: boolean; border?: string }>`
  padding-left: var(--form-side-padding);
  padding-right: var(--form-side-padding);
  width: 100%;

  ${props =>
    props.border &&
    props.border !== 'none' &&
    css`
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
    `}

  ${props =>
    props.bare &&
    css`
      padding-left: 0;
      padding-right: 0;

      .SelectAmount {
        .at-radios {
          display: flex;
          flex-wrap: wrap;
          width: calc(100% + 10px);
        }

        .label-amount {
          width: 48%;
          margin: 0 10px 10px 0 !important;
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;

          &.tooLong {
            line-height: auto;
          }
        }
      }
    `};
`;

// Row

interface CustomFieldRowProps {
  centered?: boolean;
  isHidden?: boolean;
  marginTop?: string;
  marginBottom?: string;
}

export const CustomFieldRow = styled.div<CustomFieldRowProps>`
  display: flex;
  padding-bottom: 1.125rem;
  gap: 1em;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};

  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}

  ${props =>
    props.centered &&
    css`
      justify-content: center;
      width: 100%;
    `}
`;

// Labels

interface InputLabelProps {
  floating?: boolean;
  errored?: boolean;
  fullWidth?: boolean;
}

export const InputLabel = styled.label<InputLabelProps>`
  width: 100%;
  input {
    width: 100%;
  }

  ${sharedStyles.inputLabel}

  ${props =>
    props.floating &&
    css`
      ${sharedStyles.inputLabelFloating};
    `};

  ${props =>
    props.errored &&
    css`
      ${sharedStyles.inputLabelErrored};
    `};
`;

interface InputLabelTextProps {
  floating?: boolean;
}

// Inputs

export const TextyInput = styled.input`
  ${sharedStyles.textyInput}
`;

export const Select = styled.select`
  ${sharedStyles.inputSelect};
`;

export const Textarea = styled.textarea`
  ${sharedStyles.textarea};
`;

export const UnstyledTextarea = styled.textarea`
  background: none;
  resize: none;

  line-height: 1.4;

  &:focus {
    outline: none;
  }
`;

export const InputLabelText = styled.span<InputLabelTextProps>`
  ${sharedStyles.inputLabelTextBase};

  ${props =>
    props.floating &&
    css`
      ${sharedStyles.inputLabelTextFloatingStart};

      ${InputLabel} ${TextyInput}:not(:placeholder-shown) ~ &,
      ${InputLabel} ${Textarea}:not(:placeholder-shown) ~ &,
      ${InputLabel} ${Select}:valid ~ & {
        ${sharedStyles.inputLabelTextFloatingAnimation};
      }
    `};
`;

// Checkboxes

export const CheckboxLabel = styled.label`
  text-align: left;
`;

export const CheckboxLabelText = styled.span`
  ${sharedStyles.checkboxLabelText};
`;

export const Checkbox = styled.input`
  ${sharedStyles.checkbox};
`;

// Errors

export const InputError = styled.small`
  ${sharedStyles.inputError}
`;
