import React, { useState, useEffect } from 'react';

import { ColorTheme } from '../ColorTheme';
import { Markdown } from '../Markdown/Markdown.component';

import * as Styled from './MarkdownForm.styles';

import { getTwoToneTheming } from '+/styles/styledComponents/Form.styles';
import { Text } from '+/components/Text';
import * as FormStyled from '+/styles/styledComponents/Form.styles';
import { breakpoints } from '+/styles/themes/mainTheme';
import { debounce } from '+/utils/debounce';

interface MarkdownFormProps {
  markdownFormData: MarkdownFormComponent;
}

export const MarkdownForm = ({ markdownFormData }: MarkdownFormProps) => {
  const { header, headerVisibility, colorTheme, formBackgroundStyle } = markdownFormData;

  const { accentedColorTheme, formColorTheme, formBaseShade } = getTwoToneTheming(
    formBackgroundStyle,
    colorTheme,
  );

  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth < breakpoints.lg.value : undefined,
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    const debounceHandleResize = debounce(function handleResize() {
      setIsMobile(window.innerWidth < breakpoints.lg.value);
    }, 200);

    window.addEventListener('resize', debounceHandleResize);
    return () => {
      window.removeEventListener('resize', debounceHandleResize);
    };
  }, []);

  const isHeaderHidden =
    (isMobile && headerVisibility === 'desktop-only') ||
    (!isMobile && headerVisibility === 'mobile-only');

  return (
    <Styled.MarkdownForm isPadded={!isHeaderHidden}>
      {isHeaderHidden || (
        <ColorTheme colorTheme={accentedColorTheme}>
          <FormStyled.PreContent>
            <FormStyled.Container>
              <FormStyled.Header>
                <Markdown>{header}</Markdown>
              </FormStyled.Header>
            </FormStyled.Container>
          </FormStyled.PreContent>
        </ColorTheme>
      )}
      <ColorTheme colorTheme={formColorTheme}>
        <FormStyled.Content baseShade={!isHeaderHidden && formBaseShade}>
          <FormStyled.Container>
            <Text textItemData={markdownFormData} />
          </FormStyled.Container>
        </FormStyled.Content>
      </ColorTheme>
    </Styled.MarkdownForm>
  );
};
