import React from 'react';

import { ColorTheme } from '../ColorTheme';

import * as Styled from './MarkdownIcon.styles';

import { IconByName } from '+/components/Icon/IconByName.component';
import { Icon } from '+/components/Icon';
import { heartBeatAnimationCSS } from '+/styles/reusable/animations';

interface MarkdownIconProps {
  color?: ColorThemeName;
  center?: boolean;
  animated?: boolean;
  svg?: string;
  children: JSX.Element;
}

const iconAnimations = {
  MENDED_HEART: heartBeatAnimationCSS,
};

export const MarkdownIcon = (props: MarkdownIconProps) => {
  const { color, center, animated, svg } = props;
  const iconName = props.children[0];

  return (
    <ColorTheme colorTheme={color}>
      <Styled.MarkdownIconContainer
        center={center}
        animation={animated && iconAnimations[iconName.toUpperCase()]}
      >
        {svg ? <Icon path={svg} /> : <IconByName iconName={iconName} />}
      </Styled.MarkdownIconContainer>
    </ColorTheme>
  );
};

MarkdownIcon.defaultProps = {
  color: null,
  center: false,
  animated: false,
  svg: null,
};
