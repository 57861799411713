import { getImageFilePath } from '+/utils/getImageFilePath';
import { isPrerendering } from '+/utils/environment';
import { breakpoints } from '+/styles/themes/mainTheme';
import { useIsMobile } from '+/utils/responsive';

export const preloadImageItem = (imageItem: ImageItem): void => {
  const { image, imageMobile, imageWebp, imageWebpMobile } = imageItem;
  const fallbackImageFilePath = getImageFilePath(image);
  const MEDIA_QUERY = `(min-width: ${breakpoints.lg.value}px)`;

  new Promise<void>((resolve, reject) => {
    if (isPrerendering) return;
    const picture = document.createElement('picture');

    if (imageWebpMobile) {
      const webpMobileSource = document.createElement('source');
      webpMobileSource.srcset = getImageFilePath(imageWebpMobile);
      webpMobileSource.type = 'image/webp';
      picture.append(webpMobileSource);
    }
    if (imageWebp) {
      const webpSource = document.createElement('source');
      webpSource.srcset = getImageFilePath(imageWebp);
      webpSource.type = 'image/webp';
      if (imageWebpMobile) {
        webpSource.media = MEDIA_QUERY;
      }
      picture.append(webpSource);
    }
    const source = document.createElement('source');
    source.srcset = fallbackImageFilePath;
    source.media = MEDIA_QUERY;
    picture.append(source);

    const img = document.createElement('img');
    img.src = imageMobile ? getImageFilePath(imageMobile) : fallbackImageFilePath;
    img.onload = () => resolve();
    img.onerror = () => reject();

    picture.append(img);
  });
};

export const preloadVideoItem = (videoItem: VideoItem): void => {
  const isMobile = useIsMobile();
  const { directUrl, directUrlMobile, preload } = videoItem;

  const getVideoSrc = (): string => {
    if (isMobile && !!directUrlMobile) {
      return directUrlMobile;
    }
    return directUrl;
  };

  new Promise((resolve, reject) => {
    if (typeof document === 'undefined') return;
    const vid = document.createElement('video');
    switch (preload) {
      case 'auto':
        vid.onloadeddata = e => resolve(null);
        break;
      case 'metadata':
        vid.onloadedmetadata = e => resolve(null);
      case 'none':
        resolve(null);
        break;
      default:
        reject(null);
        break;
    }
    vid.onerror = e => reject(null);
    vid.src = getVideoSrc();
  });
};
