// For some reason, typescript run through mocha has trouple with this file.

export enum DeprecatedDescriptiveThemeNames {
  Default = 'default',
  Inverse = 'inverse',
  Primary = 'primary',
  Secondary = 'secondary',
  Accent = 'accent',
}

export enum DeprecatedColorThemeNames {
  Grey = 'grey',
  Red = 'red',
  Yellow = 'yellow',
  Blue = 'blue',
  Teal = 'teal',
  Green = 'green',
}

export enum ColorThemeNames {
  GreyAccented = 'greyAccented',
  RedAccented = 'redAccented',
  RedFilled = 'redFilled',
  YellowAccented = 'yellowAccented',
  BlueAccented = 'blueAccented',
  BlueFilled = 'blueFilled',
  TealAccented = 'tealAccented',
  TealFilled = 'tealFilled',
  GreenAccented = 'greenAccented',
  GreenFilled = 'greenFilled',
  GreyFilledBlueAccented = 'greyFilled/blueAccented',
  GreyFilledGreenAccented = 'greyFilled/greenAccented',
  GreyFilledRedAccented = 'greyFilled/redAccented',
  GreyFilledTealAccented = 'greyFilled/tealAccented',
  GreyFilledYellowAccented = 'greyFilled/yellowAccented',
  YellowFilledGreyAccented = 'yellowFilled/greyAccented',
  YellowFilledRedAccented = 'yellowFilled/redAccented',
}
