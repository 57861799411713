import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { navigate } from 'gatsby';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './VideoModal.styles';

import { VideoItem } from '+/components/VideoItem';
import { ColorTheme } from '+/components/ColorTheme';
import analyticsEvent from '+/utils/analyticsEvent';
import PlayButtonSvg from '+/images/play-button.inline.svg';
import { ColorThemeContext } from '+/contexts';

export const VideoModal = ({
  video,
  caption,
  captionButtonText,
  captionButtonUrl,
  onClose,
  targetId = 'video-player-modal',
  handleOnEnded,
}: VideoModal) => {
  const [playDuration, setPlayDuration] = useState(0);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const videoUrl = video.hostedUrl || video.directUrl || null;
  const parentColorTheme = useContext(ColorThemeContext);

  let timerInterval;

  const handleClose = (): void => {
    analyticsEvent({
      eventTypeName: 'VideoModalEvent',
      targetElement: 'VideoModal',
      targetElementVariation: videoUrl,
      triggerAction: 'Video Modal Close',
      value: playDuration.toString(),
      simpleName: 'Close video modal',
      options: {
        playDuration,
      },
    });
    clearInterval(timerInterval);
    setIsVideoModalOpen(false);
    onClose();
  };

  const onKeydown = e => {
    // close modal on esc
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return null;
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, []);

  useEffect((): void => {
    if (!videoUrl || !isVideoModalOpen) {
      return;
    }

    timerInterval = setInterval(() => {
      setPlayDuration(oldCount => oldCount + 1);
    }, 100);

    analyticsEvent({
      eventTypeName: 'VideoModalEvent',
      targetElement: 'VideoModal',
      targetElementVariation: videoUrl,
      triggerAction: 'Video Modal Launch',
      simpleName: 'Launch video modal',
    });
  }, [isVideoModalOpen]);

  // Don't build modal on server
  if (typeof document === 'undefined') return null;

  // Check is we are running in the client or server
  let modalRoot = document.getElementById(targetId);

  // If the modalRoot doesn't exist yet (like in tests or storybook), just create it
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', targetId);
    document.body.appendChild(modalRoot);
  }

  const handleCaptionButtonClick = (): void => {
    if (captionButtonUrl) {
      navigate(captionButtonUrl);
      return;
    }
    handleClose();
  };

  const videoModalPortal = () =>
    ReactDOM.createPortal(
      <AnimatePresence>
        {isVideoModalOpen && (
          <FocusTrap aria-modal="true" tabIndex={-1} focusTrapOptions={{ initialFocus: false }}>
            <Styled.Wrapper>
              <Styled.Overlay
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                onClick={handleClose}
              />
              <Styled.Content>
                <Styled.Video
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: 0.1 }}
                >
                  <VideoItem
                    videoItemData={{
                      ...video,
                      autoplay: true,
                      handleOnEnded: () => {
                        handleOnEnded();
                        handleClose();
                      },
                      loop: false,
                    }}
                  />
                </Styled.Video>

                {caption && (
                  <Styled.Caption
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.2, delay: 0.15 }}
                  >
                    <ColorTheme colorTheme={parentColorTheme}>
                      <Styled.CaptionText>{caption}</Styled.CaptionText>
                      {captionButtonText && (
                        <Styled.CaptionButton
                          onClick={handleCaptionButtonClick}
                          narrow
                          type="button"
                        >
                          {captionButtonText}
                        </Styled.CaptionButton>
                      )}
                    </ColorTheme>
                  </Styled.Caption>
                )}
              </Styled.Content>
              <Styled.Close
                initial={{ opacity: 0, y: -10, x: 10 }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                exit={{ opacity: 0, y: -10, x: 10 }}
                transition={{ duration: 0.2, delay: 0.2 }}
              >
                <Styled.CloseButton onClick={handleClose}>Close</Styled.CloseButton>
              </Styled.Close>
            </Styled.Wrapper>
          </FocusTrap>
        )}
      </AnimatePresence>,
      modalRoot,
    );

  return (
    <>
      {videoModalPortal()}
      <Styled.PlayButton
        onClick={() => setIsVideoModalOpen(true)}
        aria-label="Play video"
        className="thl-clarity--video-start"
      >
        <PlayButtonSvg />
      </Styled.PlayButton>
    </>
  );
};
