import React, { useState, useEffect, useRef } from 'react';

import * as Styled from './Header.styles';

import { useScrollPosition } from '+/utils/useScrollPosition';
import { useOutsideClick } from '+/utils/useOutsideClick';
import { useToggle } from '+/utils/useToggle';
import { Link } from '+/components/Link';
import { IconByName } from '+/components/Icon';
import { getImageFilePath } from '+/utils/getImageFilePath';
import { NoScrollContext } from '+/contexts/NoScrollContext';
import { HeaderContext } from '+/contexts/HeaderContext';

export const Header = ({
  headerData: { mainNavigation, socialNavigation, transparent, logo, logoLink, logoMobile },
}: {
  headerData: Header;
}) => {
  const SCROLL_OPAQUE = -25;
  const SCROLL_HIDE = -125;

  const [opaque, setOpaque] = useState(false);
  const [hide, setHide] = useState(false);
  const [isOverflowMenuOpen, toggleOverflowMenu, setIsOverflowMenuOpen] = useToggle();
  const overflowMenuRef = useRef();

  const noScrollController = React.useContext(NoScrollContext);
  const { isHeaderVisible, isDropShadowEnabled } = React.useContext(HeaderContext);

  useScrollPosition(({ currPos, prevPos }) => {
    setOpaque(currPos.y < SCROLL_OPAQUE);
    setHide(!!(currPos.y <= SCROLL_HIDE && prevPos.y > currPos.y && !isOverflowMenuOpen));
  });

  useEffect(() => {
    if (isOverflowMenuOpen) {
      const resumeScrolling = noScrollController.pauseScrolling();

      return resumeScrolling;
    }

    return undefined;
  }, [isOverflowMenuOpen]);

  const renderMainNavigation = (key: string): JSX.Element | null => {
    if (!mainNavigation || mainNavigation.length === 0) {
      return null;
    }
    return (
      <Styled.MainNavigation>
        {mainNavigation.map(item => (
          <Link
            key={`${key}-nav-link-${item.id}`}
            href={item.uRL}
            onClick={() => setIsOverflowMenuOpen(false)}
          >
            {item.label}
          </Link>
        ))}
      </Styled.MainNavigation>
    );
  };

  const renderSocialNavigation = (key: string): JSX.Element | null => {
    if (!socialNavigation || socialNavigation.length === 0) {
      return null;
    }
    return (
      <Styled.SocialNavigation>
        {socialNavigation.map(
          item =>
            item.icon && (
              <Link
                key={`${key}-nav-link-${item.id}`}
                href={item.uRL}
                label={item.label}
                onClick={() => setIsOverflowMenuOpen(false)}
              >
                <IconByName
                  iconName={item.icon}
                  iconProps={{ color: 'inherit', alt: item.label }}
                />
              </Link>
            ),
        )}
      </Styled.SocialNavigation>
    );
  };

  useOutsideClick(overflowMenuRef, () => isOverflowMenuOpen && setIsOverflowMenuOpen(false));

  return (
    <Styled.Header
      className="Header"
      hide={hide || !isHeaderVisible}
      role="banner"
      position={transparent ? 'fixed' : 'sticky'}
      isDropShadowEnabled={isDropShadowEnabled}
    >
      <Styled.Nav opaque={opaque || !transparent}>
        <Styled.Logo>
          <Link href={logoLink || '#'} label={logo.title}>
            {logoMobile && (
              <Styled.LogoMobile aria-hidden="true">
                <img src={getImageFilePath(logoMobile)} alt={logoMobile.title} />
              </Styled.LogoMobile>
            )}
            {logo && (
              <Styled.LogoDesktop aria-hidden="true">
                <img src={getImageFilePath(logo)} alt={logo.title} />
              </Styled.LogoDesktop>
            )}
          </Link>
        </Styled.Logo>
        <Styled.NavSection>
          {renderMainNavigation('top-main')}
          {renderSocialNavigation('top-social')}
          {mainNavigation && mainNavigation.length > 0 && (
            <>
              <Styled.VeggieBurger onClick={toggleOverflowMenu} isActive={isOverflowMenuOpen}>
                <span />
                <span />
                <span />
              </Styled.VeggieBurger>
              <Styled.OverflowMenu isActive={isOverflowMenuOpen} ref={overflowMenuRef}>
                {renderMainNavigation('overflow-main')}
                {renderSocialNavigation('overflow-social')}
              </Styled.OverflowMenu>
            </>
          )}
        </Styled.NavSection>
      </Styled.Nav>
    </Styled.Header>
  );
};
