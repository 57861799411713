import styled, { css } from 'styled-components';

import { IconByName as IconByNameComponent } from '+/components/Icon';
import { Link as LinkComponent } from '+/components/Link';

const baseFont = css`
  font-family: var(--font-primary);
  font-size: 0.75rem;
  color: var(--color-privacy-policy-link-text);
  text-align: center;
`;

export const IconByName = styled(IconByNameComponent)`
  margin-right: 0.6em;
  margin-left: -0.6em;

  color: var(--color-privacy-policy-link-text-icon);
`;

export const Link = styled(LinkComponent)`
  ${baseFont};

  &:hover,
  &:focus-visible {
    color: var(--color-neutral-800);
  }

  &:active {
    color: var(--color-black);
  }
`;

export const PrivacyPolicyLink = styled.span`
  ${baseFont};

  display: inline-flex;
  align-items: center;
`;
