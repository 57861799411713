import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const ColumnOne = styled.div<{
  isAccented?: boolean;
  isTwoColumn: boolean;
}>`
  // By default, prevent the sub-component from creating any additional columns,
  // so that TwoColumnLayout doesn't end up rendering too many columns
  * {
    columns: 1;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    ${props =>
      props.isAccented &&
      css`
        padding-right: 3rem;
      `}
  }

  // Allow the sub-component to display as two columns on extra-large screens
  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    ${props =>
      props.isTwoColumn &&
      css`
        columns: 2;
      `}
  }
`;
export const ColumnTwo = styled.div<{
  isAccented?: boolean;
  isTwoColumn: boolean;
}>`
  * {
    columns: 1;
  }

  // Remove manual underline around a link if no background accent
  u:has(a) {
    text-decoration: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    ${props =>
      props.isAccented &&
      css`
        padding-left: 3rem;

        u:has(a) {
          text-decoration: revert;

          a { 
            border-bottom: none; 
          }
        }
      `}
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    ${props =>
      props.isTwoColumn &&
      css`
        columns: 2;
      `}
  }
`;

export const Divider = styled.span<{
  isVisible?: boolean;
}>`
  display: block;
  background: var(--color-theme-base-inverse);
  height: 0.625rem;
  width: 50%;
  margin: 0 auto;
  transform: scaleX(${({ isVisible }) => (isVisible ? 1 : 0)});

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 0.625rem;
    height: auto;
    transition: transform 1s var(--ease-out-expo);
    transform: scaleY(${props => (props.isVisible ? 1 : 0)});
  }
`;

export const TwoColumnLayout = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: var(--font-size-md);
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: var(--font-size-default);
  }
  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    font-size: var(--font-size-md);
  }
`;

const twoColumnStylesForContainer = {
  '1:1': css`
    padding-bottom: 8rem 0;
    ${Divider} {
      width: 0.0625rem;
      height: auto;
    }
    ${ColumnOne},
    ${ColumnTwo} {
      width: 50%;
    }
  `,
  '2:1||1:2': css`
    padding-bottom: 0;
    ${Divider} {
      transform-origin: top center;
    }
    ${ColumnOne},
    ${ColumnTwo} {
      padding-bottom: 5rem;
    }
  `,
  '2:1': css`
    ${ColumnOne} {
      width: 66.667%;
    }
    ${ColumnTwo} {
      width: 33.333%;
    }
  `,
  '1:2': css`
    ${ColumnOne} {
      width: 33.333%;
    }
    ${ColumnTwo} {
      width: 66.667%;
    }
  `,
};

export const Container = styled.div<{
  layout: string;
  isAccented?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin: 0 auto;
  width: 88%;
  max-width: 32.5rem;
  max-width: 80vw;
  padding: 5rem 0;

  ${props =>
    props.layout === '1:1' &&
    css`
      ${Divider} {
        height: 0.0625rem;
      }
    `}

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex-direction: row;
    align-items: ${props => (props.isAccented ? 'center' : 'stretch')};
    gap: 5%;
    justify-content: center;
    max-width: 86.25rem;

    ${props => props.layout === '1:1' && twoColumnStylesForContainer['1:1']}

    ${props =>
      (props.layout === '2:1' || props.layout === '1:2') && twoColumnStylesForContainer['2:1||1:2']}

    ${props => props.layout === '2:1' && twoColumnStylesForContainer['2:1']}
    ${props => props.layout === '1:2' && twoColumnStylesForContainer['1:2']}
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    flex-direction: row;
    align-items: ${props => (props.isAccented ? 'center' : 'stretch')};
    gap: 5%;
    justify-content: center;
    max-width: 86.25rem;

    ${props => props.layout === '1:1' && twoColumnStylesForContainer['1:1']}
    ${props =>
      (props.layout === '2:1' || props.layout === '1:2') && twoColumnStylesForContainer['2:1||1:2']}
    ${props => props.layout === '2:1' && twoColumnStylesForContainer['2:1']}
    ${props => props.layout === '1:2' && twoColumnStylesForContainer['1:2']}
  }
`;

export const AccentBackground = styled(motion.div)<{
  accentColumn: 'column_1' | 'column_2';
  columnLayout: string;
}>`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    position: absolute;
    top: 0;
    background: var(--color-secondary-500);
    z-index: -1;
    width: calc(50% + 2rem);
    height: 100%;

    ${props =>
      props.accentColumn === 'column_1'
        ? css`
            clip-path: polygon(calc(100% - 4rem) 0, 100% 50%, calc(100% - 4rem) 100%, 0 100%, 0 0);
            left: 0;
            ${props.columnLayout === '2:1' && 'width: calc(66.667% - 2rem);'}
            ${props.columnLayout === '1:2' && 'width: calc(33.333% + 4rem);'}
          `
        : css`
            clip-path: polygon(100% 0, 100% 100%, 4rem 100%, 0 50%, 4rem 0);
            right: 0;
            ${props.columnLayout === '1:2' && 'width: calc(66.667% - 2rem);'}
            ${props.columnLayout === '2:1' && 'width: calc(33.333% + 4rem);'}
          `}
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    position: absolute;
    top: 0;
    background: var(--color-secondary-500);
    z-index: -1;
    width: calc(50% + 2rem);
    height: 100%;

    ${props =>
      props.accentColumn === 'column_1'
        ? css`
            clip-path: polygon(calc(100% - 4rem) 0, 100% 50%, calc(100% - 4rem) 100%, 0 100%, 0 0);
            left: 0;
            ${props.columnLayout === '2:1' && 'width: calc(66.667% - 2rem);'}
            ${props.columnLayout === '1:2' && 'width: calc(33.333% + 4rem);'}
          `
        : css`
            clip-path: polygon(100% 0, 100% 100%, 4rem 100%, 0 50%, 4rem 0);
            right: 0;
            ${props.columnLayout === '1:2' && 'width: calc(66.667% - 2rem);'}
            ${props.columnLayout === '2:1' && 'width: calc(33.333% + 4rem);'}
          `}
  }
`;
