import { useState, useEffect } from 'react';

const findNearestTarget = (input: number): number => {
  // Manual increment list
  const increments = [
    3000, 4000, 5000, 7500, 10000, 15000, 20000, 25000, 30000, 40000, 50000, 75000, 100000, 150000,
    200000, 250000, 300000, 400000, 500000, 750000, 1000000, 1500000, 2000000,
  ];

  // If input is > 2 million, increment by 1 million and return
  if (input >= increments[increments.length - 1]) {
    return input - (input % 1000000) + 1000000;
  }

  // If input is within increment list
  for (let i = 0; i < increments.length; i++) {
    if (input < increments[i]) {
      return increments[i];
    }
  }

  // In case some magic number exists
  // just return the input
  return input;
};

export const useEveryActionFormProgress = (formId: string, isAutoTarget = true) => {
  // Format comes from EveryAction
  const [progress, setProgress] = useState({
    totalSubmissionProgress: 0,
    totalContributionProgress: 0,
    targetAmount: 0,
    loaded: false,
  });

  useEffect(() => {
    if (!formId) {
      return;
    }
    (async function fetchAndSet() {
      const result = await fetch(`https://secure.everyaction.com/v2/forms/${formId}/progress`);
      const json = await result.json();
      if (!json) {
        return;
      }
      setProgress({
        ...json,
        targetAmount: isAutoTarget
          ? findNearestTarget(json.totalSubmissionProgress)
          : json.targetAmount,
        loaded: true,
      });
    })();
  }, [formId]);

  return progress;
};
