import React, { useState, useEffect, useRef } from 'react';

import * as Styled from './TextHighlight.styles';

import { useOnScreen } from '+/utils/useOnScreen';
import getWindowSize from '+/utils/getWindowSize';
import { useWindowScrollPercentage } from '+/utils/useWindowScrollPercentage';

export const TextHighlight = ({
  highlightText,
  shouldStayOnOnceOn = true,
  duration,
  delay = 0,
  font = 'inherit',
}: TextHighlightComponent) => {
  const textArray = highlightText.split(/(?:\r\n|\r|\n|\\n)/g);
  const highlightRef = useRef(null);
  const isOnScreen = useOnScreen(highlightRef, 0, 0, shouldStayOnOnceOn);
  const [isHighlighted, setIsHighlighted] = useState(false);
  let cumulativeDuration = 0;

  useEffect(() => {
    if (!isOnScreen && !shouldStayOnOnceOn && isHighlighted) {
      setIsHighlighted(false);
    }
    if (highlightRef.current) {
      const twoThirdsFromTop = (getWindowSize().height * 2) / 3;
      const currentHeightFromTop = highlightRef.current.getBoundingClientRect().top;
      if (currentHeightFromTop <= twoThirdsFromTop) {
        setIsHighlighted(true);
      }
    }
  }, [useWindowScrollPercentage()]);

  return (
    <Styled.TextHighlight font={font} ref={highlightRef}>
      {textArray.map((item, index) => {
        const wordArray = item.split(' ');
        const blockDuration = duration ? duration / textArray.length : wordArray.length * 0.1;
        cumulativeDuration += blockDuration;
        return (
          <React.Fragment key={`text-highlight-block${item}-${index}`}>
            {index > 0 && <br />}
            <Styled.TextBlock
              delay={index * (cumulativeDuration - blockDuration) + delay}
              duration={blockDuration < 0.5 ? 0.5 : blockDuration}
              isHighlighted={isHighlighted}
            >
              {wordArray.map((word, idx) => (
                <React.Fragment key={`text-highlight-word${word}-${idx}`}>
                  {idx > 0 && <wbr />}
                  <Styled.Word key={`${word}-${idx}`}>{word}</Styled.Word>
                </React.Fragment>
              ))}
            </Styled.TextBlock>
          </React.Fragment>
        );
      })}
    </Styled.TextHighlight>
  );
};
