import styled from 'styled-components';

export const FixedBannerTarget = styled.div`
  position: fixed;
  z-index: var(--z-index-pinned-or-sticky-call-to-action);
  width: 100%;
  bottom: 0;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`;
