import verifyIntegrity from './verifyIntegrity';
import addAnchors from './addAnchors';
import addContainers from './addContainers';
import { restore } from './valueRestorer';
import removeExistingStylesheets from './removeExistingStylesheets';
import supplementErrorRenderer from './supplementErrorRenderer';
import updateFastAction from './updateFastAction';

import params from '+/utils/queryParams';
import { EveryActionModelController } from '+/components/DonateForm/everyActionModelController';
import { personalDataStore } from '+/utils/personalDataStore';

interface EveryActionAutofill {
  fill_dict: { [k: string]: string };
  fill_source: string;
}

const currencies = {
  EURO: 'EUR',
  EUR: 'EUR',
  USD: 'USD',
  CAD: 'CAD',
};

export const adjustFormAlterFill = (args: EveryActionAutofill) => {
  // eslint-disable-next-line no-param-reassign
  args.fill_dict = {};
};

export const adjustFormPostFill =
  (donateForm, callback) =>
  ({ view, submit }) => {
    // CAUTION: this may be run multiple times by FastAction.
    // Verify that all expected class names are represented in dom
    // Throw error to abort `try` of not.
    // NOTE, many text changes are made on the alterFormDefitionCallback, which occurs first.
    verifyIntegrity();
    const controller: EveryActionModelController = new EveryActionModelController(
      view,
      submit,
      donateForm,
    );

    // Wraps form elements in extra divs
    addContainers(view, controller);
    // Add dom elements with specific ids for injecting widgets
    addAnchors();
    // Removes EA stylesheets
    removeExistingStylesheets();
    // Enables modified css
    document.body.classList.add('modifiedFormCss');
    document
      .querySelector('body')
      .classList.add(controller.isUS() ? 'us-version' : 'international-version');
    // Set default value
    // Store view and submit then signal widgets can be loaded.
    window.nvtag_callbacks.alterFill.push(updateFastAction.bind(null, controller));
    // Add blank renderFormWithErrors
    supplementErrorRenderer(view);

    if (params.currency || donateForm.defaultCurrency) {
      const currencyFromParams = params.currency && currencies[params.currency.toUpperCase()];
      controller.setCurrency(currencyFromParams || donateForm.defaultCurrency);
    }

    if (donateForm.frequencyType === 'Recurring' || controller.defaultFrequency() === 'recurring') {
      controller.setMonthly(true);
    } else if (donateForm.frequencyType === 'One-off' || controller.defaultFrequency() === 'one-time') {
      controller.setMonthly(false);
    }

    // set default value if first postFill event (EA calls postFill after error);
    if (!donateForm.alreadyDefaulted) {
      controller.setSelectedAmountToDefault();
      // eslint-disable-next-line no-param-reassign
      donateForm.alreadyDefaulted = true;
    }

    controller.setIfChanged(personalDataStore);
    if (params.storeKey) {
      restore(controller, params.storeKey);
    }

    callback(controller);
  };
