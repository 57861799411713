import React from 'react';

import { VideoItem } from '+/components/VideoItem';
import * as Styled from './VideoEmbed.styles';

export const VideoEmbed = ({
  videoEmbedData: { videoItem },
}: {
  videoEmbedData: VideoEmbedComponent;
}) => (
  <Styled.VideoEmbed>
    <VideoItem videoItemData={videoItem} />
  </Styled.VideoEmbed>
);
