import b from 'buffer';

export const decryptUserData = async (lookupKey: string, encryptedToken: string) => {
  const secretKey = process.env.GATSBY_SECRET;

  const iv = b.Buffer.from(lookupKey, 'hex');
  const keyBuffer = b.Buffer.from(secretKey, 'utf8');
  const encryptedTokenBuffer = b.Buffer.from(encryptedToken, 'hex');

  try {
    const cryptoKey = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-CBC', length: 128 },
      false,
      ['encrypt', 'decrypt'],
    );

    const decipher = await crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: iv },
      cryptoKey,
      encryptedTokenBuffer,
    );

    const decryptedData = new TextDecoder().decode(decipher);

    return decryptedData;
  } catch (e) {
    console.error('Error in decrypt function: ', e);
    throw e;
  }
};
