import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Button } from '+/components/Button';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  z-index: var(--z-index-modal-base);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
`;

export const Overlay = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.75);
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: var(--z-index-modal-overlay);
  backdrop-filter: blur(3px);
`;

export const Content = styled.div`
  width: 100%;
  max-width: calc((16 / 9) * 80vh);
  padding: 3.5rem 0;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 0 5rem;
  }
`;

export const Video = styled(motion.div)`
  z-index: var(--z-index-modal-content);
  position: relative;
`;

export const Caption = styled(motion.div)`
  position: relative;
  z-index: var(--z-index-modal-content);
  min-height: 20vh;
  padding: 1.875rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex-direction: row;
  }
`;

export const CaptionText = styled.span`
  font-family: var(--font-tertiary);
  font-style: italic;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 1.25rem;
  }
`;

export const CaptionButton = styled(Button)`
  height: 3rem;
  min-width: 9.5rem;
  width: auto;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 1rem;
    height: 3.25rem;
  }
`;

export const Close = styled(motion.div)`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: var(--z-index-modal-content);

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 2rem;
    height: 2rem;
    right: 1.5rem;
    top: 1.5rem;
  }
`;

export const CloseButton = styled.button`
  background: var(--image-close-x) no-repeat center center / contain;
  width: 100%;
  height: 100%;
  display: block;
  text-indent: -999999em;
  cursor: pointer;
  padding: 0;
  opacity: 0.75;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

export const PlayButton = styled.button`
  width: 4.1875rem;
  height: 4.1875rem;
  display: block;
  transition: transform 0.2s ease-out;
  transform-origin: 50% 50%;
  color: var(--color-white);
  margin: -3.375rem auto 3.375rem;
  padding: 0;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 4.6875rem;
    height: 4.6875rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    width: 6.375rem;
    height: 6.375rem;
    margin: -7.5rem auto 7.5rem;
  }

  &::after {
    content: '';
    position: absolute;
    background: var(--color-neutral-800);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
    filter: blur(2.5rem);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    transform: scale(1.1);
  }
`;
