import React, { useRef } from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './TwoColumnLayout.styles';

import { ComponentMap } from '+/components/ComponentMap';
import { useOnScreen } from '+/utils/useOnScreen';
import { useWindowWidth } from '+/utils/useWindowWidth';
import { breakpoints } from '+/styles/themes/mainTheme';
import { promisesStatus } from '+/utils/getASContent';

export const TwoColumnLayout = ({
  twoColumnLayoutData: { layout, column1Components, column2Components, accentColumn },
}: {
  twoColumnLayoutData: TwoColumnLayout;
}) => {
  const ref = useRef();
  const isOnScreen = useOnScreen(ref, -100, 0);
  const { windowWidth } = useWindowWidth();
  const isAccentedLayout = !!accentColumn;

  const isTwoColumn = (component: Array<MappableComponent>) =>
    !!(component[0] as TextItem).twoColumn;

  return (
    <Styled.TwoColumnLayout ref={ref}>
      <Styled.Container layout={layout} isAccented={isAccentedLayout}>
        <Styled.ColumnOne
          isAccented={isAccentedLayout}
          isTwoColumn={isTwoColumn(column1Components)}
        >
          {React.useMemo(
            () =>
              column1Components.map((componentData, i) => (
                <ComponentMap componentData={componentData} key={i} />
              )),
            [column1Components, promisesStatus.finished, promisesStatus.aSLoaded],
          )}
        </Styled.ColumnOne>
        {(isAccentedLayout && windowWidth >= breakpoints.lg.value) || (
          <Styled.Divider isVisible={isOnScreen} />
        )}
        <Styled.ColumnTwo
          isAccented={isAccentedLayout}
          isTwoColumn={isTwoColumn(column2Components)}
        >
          {React.useMemo(
            () =>
              column2Components.map((componentData, i) => (
                <ComponentMap componentData={componentData} key={i} />
              )),
            [column2Components, promisesStatus.finished, promisesStatus.aSLoaded],
          )}
        </Styled.ColumnTwo>
      </Styled.Container>
      <AnimatePresence>
        {isAccentedLayout && isOnScreen && (
          <Styled.AccentBackground
            accentColumn={accentColumn}
            columnLayout={layout}
            initial={{
              opacity: 0,
              x: accentColumn === 'column_1' ? -100 : 100,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            exit={{
              opacity: 0,
              x: accentColumn === 'column_1' ? -100 : 100,
            }}
            transition={{ duration: 1, delay: 0.5, ease: [0.19, 1, 0.22, 1] }}
          />
        )}
      </AnimatePresence>
    </Styled.TwoColumnLayout>
  );
};
