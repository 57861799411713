import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence } from 'framer-motion';

import { MediaWithForm } from '../MediaWithForm';
import { Modal } from '../Modal';
import { getOrCreateRootIfNotExists } from '../Modal/ModalPortal.component';
import { isImageItem, isVideoItem } from '../MediaItem/MediaItem.component';

import * as Styled from './FormSequence.styles';

import { ModalContext } from '+/contexts/ModalContext';
import { HeaderContext } from '+/contexts/HeaderContext';
import analyticsEvent from '+/utils/analyticsEvent';
import scrollToLocation from '+/utils/scrollToLocation';
import { preloadImageItem, preloadVideoItem } from '+/utils/preloadMediaItems';

function isMediaWithForm(
  componentData: MediaWithFormComponent | ModalComponent,
): componentData is MediaWithFormComponent {
  return componentData.__typename === 'Directus_media_with_forms';
}

function isModal(
  componentData: MediaWithFormComponent | ModalComponent,
): componentData is ModalComponent {
  return componentData.__typename === 'Directus_modals';
}

const SequenceModal = ({ modalData, advanceFormSequence }) => {
  const { setOnModalClose } = useContext(ModalContext);

  useEffect(() => {
    // Advance form sequence once modal is closed from any component
    setOnModalClose(() => () => {
      setTimeout(() => {
        advanceFormSequence();
      }, 1);
    });
  });
  return (
    <>
      {ReactDOM.createPortal(
        <AnimatePresence>
          <Styled.SequenceModal>
            <Modal modalData={modalData} key={modalData.id} />
          </Styled.SequenceModal>
        </AnimatePresence>,
        getOrCreateRootIfNotExists('modal-target'),
      )}
    </>
  );
};

export const FormSequence = ({ formSequenceData }: { formSequenceData: FormSequenceComponent }) => {
  const { logo, logoIcon, logoUrl } = formSequenceData;
  const [currentStep, setCurrentStep] = useState(0);
  const [currentNonModalStep, setCurrentNonModalStep] = useState(0);
  const { setIsHeaderVisible, setIsDropShadowEnabled } = React.useContext(HeaderContext);
  const lastStep = formSequenceData.components.length - 1;
  const currentComponent = formSequenceData.components[currentStep];
  const prevComponent = formSequenceData.components[currentStep - 1];
  const mediaWithFormRef = useRef();
  const advanceFormSequence =
    currentStep < lastStep
      ? () => {
          if (isMediaWithForm(formSequenceData.components[currentStep + 1])) {
            scrollToLocation(mediaWithFormRef.current, 0, 'both', null, 'auto');
            setCurrentNonModalStep(prevState => prevState + 1);
          }
          setCurrentStep(prevState => prevState + 1);
        }
      : null;

  useEffect(() => {
    const userInteractedWithSweetenerRecently = () => {
      if (localStorage.lastInteractedWithSweetener) {
        const millisecondsInSixMonths = 6 * 30 * 24 * 60 * 60 * 1000;
        const millisecondsSinceLastInteraction =
          new Date().getTime() - new Date(localStorage.lastInteractedWithSweetener).getTime();
        if (millisecondsSinceLastInteraction < millisecondsInSixMonths) {
          return true;
        }
      }
      return false;
    };

    if (isModal(currentComponent) && userInteractedWithSweetenerRecently()) {
      setTimeout(() => {
        advanceFormSequence();
      }, 1);
    }
  }, [currentComponent]);

  // Keeps the previous media with form on screen if the current component is a modal
  const displayedMediaWithForm = isMediaWithForm(currentComponent)
    ? currentComponent
    : prevComponent;

  useEffect(() => {
    analyticsEvent({
      triggerAction: 'FormSequenceEvent',
      targetElement: 'FormSequence',
      targetElementVariation: '',
      simpleName: `Go to Form Sequence ${formSequenceData.id} Step ${currentStep + 1}/${
        formSequenceData.components.length
      }`,
    });
  }, [currentStep]);

  useEffect(() => {
    setIsHeaderVisible(!(currentNonModalStep % 2));
  }, [currentNonModalStep]);

  // Preload all step images for smooth transitions
  useEffect(() => {
    setIsDropShadowEnabled(false);
    formSequenceData.components.forEach(component => {
      if (isMediaWithForm(component)) {
        const { mediaItem } = component;
        if (isImageItem(mediaItem)) {
          preloadImageItem(mediaItem);
        } else if (isVideoItem(mediaItem)) {
          preloadVideoItem(mediaItem);
        }
      } else if (isModal(component)) {
        component.sections.forEach(section => {
          section.components.forEach(sectionComponent => {
            const trackedSweetenerFormComponent = sectionComponent as TrackedSweetenerFormComponent;
            if (trackedSweetenerFormComponent.sweetenerBundle.image) {
              preloadImageItem(trackedSweetenerFormComponent.sweetenerBundle.image);
            }
          });
        });
      }
    });
  }, []);

  return (
    <>
      {isMediaWithForm(displayedMediaWithForm) && (
        <div ref={mediaWithFormRef}>
          <MediaWithForm
            mediaWithFormData={displayedMediaWithForm}
            advanceFormSequence={advanceFormSequence}
            step={currentNonModalStep}
            logo={logo}
            logoIcon={logoIcon}
            logoUrl={logoUrl}
            isSequence
          />
        </div>
      )}
      {isModal(currentComponent) && (
        <SequenceModal modalData={currentComponent} advanceFormSequence={advanceFormSequence} />
      )}
    </>
  );
};
