import React from 'react';

import * as Styled from './DotModal.styles';

// FIXME: AudioPlayer imports are broken
// import { AudioPlayer } from '+/components/AudioPlayer';
import { Icon } from '+/components/Icon';
import { useIsMobile } from '+/utils/responsive';
import { ICONS } from '+/utils/constants/icons';

export const DotModal = ({
  dotModalData,
  onClose,
  showGraphicImagery,
}: {
  dotModalData: DotModalComponent;
  onClose: () => void;
  showGraphicImagery?: boolean;
}): JSX.Element => {
  const isMobile: boolean = useIsMobile();

  const legitimizers: JSX.Element = (
    <Styled.Legitimizers>
      {dotModalData.incidentTypes.map(incidentType => (
        <Styled.LegitimizerItem key={incidentType}>
          <Styled.LegitimizerItemIconWrapper>
            <Styled.LegitimizerDot reportType={incidentType} />
          </Styled.LegitimizerItemIconWrapper>
          {incidentType}
        </Styled.LegitimizerItem>
      ))}
      {dotModalData.location && (
        <Styled.LegitimizerItem>
          <Styled.LegitimizerItemIconWrapper>
            <Icon path={ICONS.LOCATION.path} viewBox={ICONS.LOCATION.viewBox} size="0.875rem" />
          </Styled.LegitimizerItemIconWrapper>
          {dotModalData.location}
        </Styled.LegitimizerItem>
      )}
      {dotModalData.date && (
        <Styled.LegitimizerItem>
          <Styled.LegitimizerItemIconWrapper>
            <Icon path={ICONS.CALENDAR.path} viewBox={ICONS.CALENDAR.viewBox} size="0.875rem" />
          </Styled.LegitimizerItemIconWrapper>
          {dotModalData.date}
        </Styled.LegitimizerItem>
      )}
    </Styled.Legitimizers>
  );

  return (
    <Styled.ModalPortal onClose={onClose} themeColor="dark">
      <Styled.ModalBody isPadded>
        {/* {dotModalData.audioUrl && (
          <Styled.AudioPlayerWrapper>
            <AudioPlayer audioUrl={dotModalData.audioUrl} />
          </Styled.AudioPlayerWrapper>
        )} */}

        <Styled.DotModalContent>
          <Styled.DotModalContentExtras>
            <Styled.ImageItemWrapper showGraphicImagery={showGraphicImagery}>
              <Styled.ImageItem imageItemData={dotModalData.image} height={800} width={800} />
            </Styled.ImageItemWrapper>
            {isMobile && legitimizers}
          </Styled.DotModalContentExtras>
          <Styled.DotModalContentDecription>
            “{dotModalData.description}”
          </Styled.DotModalContentDecription>
        </Styled.DotModalContent>

        {!isMobile && legitimizers}
      </Styled.ModalBody>
    </Styled.ModalPortal>
  );
};

DotModal.defaultProps = {
  showGraphicImagery: true,
};
