import styled, { css } from 'styled-components';

import { getColorThemeVariables } from '+/utils/getColorThemeVariables';

interface Props {
  color?: ColorThemeName;
  big?: boolean;
  block?: boolean;
  narrow?: boolean;
  minContent?: boolean;
  monochrome?: boolean;
}

export const Button = styled.button<Props>`
  ${({ color }) =>
    color &&
    css`
      ${getColorThemeVariables(color)}
    `}

  color: var(--color-theme-inverse);
  position: relative;
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  display: ${props => (props.block ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  font-family: var(--font-secondary);
  font-size: ${props => (props.big ? '1.22rem' : '0.875rem')};
  letter-spacing: 0.015em;
  min-width: ${props => {
    if (props.minContent) return 'min-content';
    if (props.narrow) return '11.25rem';

    return '16.25rem';
  }};
  height: 4rem;
  line-height: 4rem;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out 0s;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 ${props => (props.minContent ? '2.25rem' : '1.5em')};

  &:focus,
  &:hover {
    background: var(--color-theme-accent-dark);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus:after,
  &:hover:after {
    transform: translate(0.125rem, 0.125rem);
  }
  ${({ monochrome }) => {
    if (monochrome) {
      return css`
        background: var(--color-white);
        color: var(--color-black);
        border: 2px solid var(--color-black);

        &:focus {
          background: var(--color-white);
        }

        &:focus-visible,
        &:hover {
          color: var(--color-theme-accent-dark);
          border-color: var(--color-theme-accent);
          background: var(--color-white);

          * {
            fill: currentColor;
          }
        }
      `;
    }
    return css`
      background: var(--color-theme);
      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom: 1.5rem solid var(--color-theme-dark);
        border-left: 1.5rem solid transparent;
        transition: all 0.2s ease-in-out 0s;
      }
    `;
  }}
`;

export const EndIconWrapper = styled.span`
  margin: 0 0 0 0.5rem;
`;
