import styled, { css } from 'styled-components';

import { textH2 } from '+/styles/reusable/textStyles';

export enum ImagePositions {
  Top = 'top',
  Left = 'left',
  Right = 'right',
  Bottom = 'bottom',
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;

  section {
    padding: 0;
  }

  * {
    margin-top: 0;
  }
`;

export const AcceptButton = styled.div`
  a {
    text-decoration: none;
  }
`;

export const DeclineButton = styled.div`
  button {
    color: inherit;
    background: none;
    text-decoration: underline 3.5px var(--color-theme);
    text-underline-offset: 7px;
    transition: text-decoration 0.2s ease-in-out 0s;

    &:focus,
    &:hover {
      background: none;
      text-decoration-color: var(--color-theme-dark);
      text-decoration-thickness: 4px;
    }

    ::after {
      display: none;
    }
  }
`;

export const SweetenerForm = styled.div<{ imagePositioning: ImagePositions }>`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  width: 100%;

  h2 {
    ${textH2}
    padding-bottom: 0.75rem;
  }

  p {
    font-size: 1.0625rem;
    padding: 0.5rem 0;
    margin: 0 !important;
  }

  img {
    max-width: 45%;
    padding: 1rem 1rem;
  }

  ${props => {
    switch (props.imagePositioning) {
      case ImagePositions.Top:
        return css`
          flex-direction: column;

          ${Content} {
            align-items: center;
            padding: 0.25rem 2rem 1rem;
          }
        `;
      case ImagePositions.Right:
        return css`
          flex-direction: row-reverse;
          ${Content} {
            padding: 2.5rem 1rem 0.5rem 2rem;
          }
        `;
      case ImagePositions.Bottom:
        return css`
          flex-direction: column-reverse;

          ${Content} {
            align-items: center;
            padding: 2rem 2rem 0.25rem;
          }
        `;
      default:
        return css`
          flex-direction: row;
          ${Content} {
            padding: 3rem 2rem 0.5rem 1rem;
          }
        `;
    }
  }}

  /* CustomEveryActionForm style overrides */
  .cstm-form__header {
    display: none;
  }

  .cstm-form__form {
    > div:not(:last-child) {
      display: none;
    }

    button {
      font-size: 1.22rem;
    }
  }
`;
