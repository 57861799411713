import React, { useState, useCallback } from 'react';

interface ContextInterface {
  errors: Array<any>;
  clearErrors(): void;
  addErrors(value: Array<any>): void;
}

export const FormFieldErrorsContext = React.createContext<ContextInterface>({
  errors: [],
  clearErrors: () => {},
  addErrors: () => {},
});

export const FormFieldErrorsProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const addErrors = (errors: Array<any>) => {
    setErrors(errors);
  };

  const contextValue: ContextInterface = {
    errors,
    clearErrors: useCallback(() => clearErrors(), [errors]),
    addErrors: useCallback(errors => addErrors(errors), [errors]),
  };

  return (
    <FormFieldErrorsContext.Provider value={contextValue}>
      {children}
    </FormFieldErrorsContext.Provider>
  );
};
