import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import {
  violationsFilterReducer,
  getInitialViolationsFilterState,
} from '../../reducers/violationsFilterReducer';

import * as Styled from './Violations.styles';

import analyticsEvent from '+/utils/analyticsEvent';
import { Accordion } from '+/components/AccordionMenu';
import { DotFilter } from '+/components/USDAViolationsDisplay/DotFilter';
import { DotGrid } from '+/components/USDAViolationsDisplay/DotGrid';
import { breakpoints } from '+/styles/themes/mainTheme';
import { useWindowWidth } from '+/utils/useWindowWidth';
import { REPORT_EMOJI } from '+/components/USDAViolationsDisplay/utils/reportColors';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

const showGraphicImageryKey = 'show_graphic_imagery';

const typeDescriptions = {
  'Live Bird Boiled Alive':
    'A visibly conscious bird—breathing, blinking, moving—wrongfully enters the scalding tank intended for defeathering. Per federal slaughterhouse regulations, the bird should have been stunned (via electrified water bath) and slaughtered (via a cut to the throat) prior to entering the "scalder."',
  'Dead On Arrival':
    'Birds often arrive at the slaughterhouse already dead, having died in transport from the factory farms where they were raised to "market weight." Because they were not slaughtered by the appropriate means—that is, during the "live-shackle slaughter" process that constitutes "good commercial practice"—they cannot be bought or sold as food and must be condemned.',
  'Live Bird Among Dead Birds':
    'While no laws explicitly prohibit live birds from intermingling with dead birds, the Poultry Products Inspection Act considers a chicken to be "adulterated" if "prepared, packed, or held under insanitary conditions whereby it may have become contaminated with filth, or whereby it may have been rendered injurious to health" (9 CFR 416.1). It is against the law to sell "adulterated" poultry as food.',
  'Operational Error':
    'Inspectors cite a range of instances of breakdown, negligence, and even cruelty, endangering both birds and workers, and obstructing either the slaughterhouse operations or the inspections themselves.',
};

export const Violations = ({
  violationData,
  noBG,
}: {
  violationData: USDAViolationData[];
  noBG?: boolean;
}) => {
  const { windowWidth } = useWindowWidth();

  const [filterState, filterDispatch]: [
    USDAViolationsFilterReducerState,
    React.Dispatch<USDAViolationsFilterReducerAction>,
  ] = React.useReducer(violationsFilterReducer, getInitialViolationsFilterState());

  const isLocalStorageAvailable: boolean = typeof window !== 'undefined' && !!window.localStorage;

  const [showGraphicImagery, setShowGraphicImagery]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = React.useState(
    !isLocalStorageAvailable || window.localStorage.getItem(showGraphicImageryKey) !== 'false',
  );

  React.useEffect(() => {
    if (isLocalStorageAvailable) {
      window.localStorage.setItem(showGraphicImageryKey, `${showGraphicImagery}`);
    }
  }, [showGraphicImagery]);

  if (!violationData) return null;

  const types: string[] = Array.from(
    violationData.reduce((accum, datum) => {
      datum.incidentTypes.forEach(t => accum.add(t));
      return accum;
    }, new Set<string>()),
  );

  const incidentStates: string[] = Array.from(
    new Set<string>(violationData.map(violation => violation.state)),
  ).sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0));

  const suppliers: string[] = Array.from(
    new Set<string>(violationData.map(violation => violation.supplier)),
  ).sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0));

  return (
    <Styled.Wrapper noBG={noBG}>
      <Styled.Container>
        <Styled.Title>The State of Slaughter</Styled.Title>
        <Styled.Subtitle>
          <span>{violationData.length} Violations &bull;</span>{' '}
          <span>April 01–September 30, 2021</span>
        </Styled.Subtitle>
        <Styled.ShowGraphicImageryWrapper>
          <Styled.ShowGraphicImageryToggle
            colorTheme={ColorThemeNames.YellowFilledGreyAccented}
            isInverseDot={true}
            toggle={showGraphicImagery}
            setToggle={toggle => {
              analyticsEvent({
                triggerAction: `Toggle Graphic Imagery ${toggle ? 'On' : 'Off'}`,
                targetElement: 'Graphic Imagery Toggle',
                targetElementVariation: 'USDA Graphic Imagery Toggle',
                simpleName: `${toggle ? 'Enable' : 'Disable'} Grahphic Imagery`,
              });

              setShowGraphicImagery(toggle);
            }}
          >
            Show graphic imagery
          </Styled.ShowGraphicImageryToggle>
        </Styled.ShowGraphicImageryWrapper>
        <Styled.ColumnLayout>
          <Styled.Filters>
            {windowWidth >= breakpoints.md.value && (
              <Accordion
                isInline={windowWidth < breakpoints.xl.value && windowWidth >= breakpoints.md.value}
                initiallyActive="type-of-violation"
              >
                <Accordion.Item id="type-of-violation">
                  <Accordion.Header headerText="Type of Violation" />
                  <Accordion.Content>
                    {types.map(type => (
                      <DotFilter
                        key={type}
                        incidentType={type}
                        handleClick={() => {
                          analyticsEvent({
                            triggerAction: 'Click',
                            targetElement: 'Filter Option',
                            targetElementVariation: type,
                            simpleName: 'Select Filter',
                            context: {
                              filter: type,
                            },
                          });
                          filterDispatch({
                            type: 'set',
                            filter: 'incidentType',
                            filterValue: type,
                          });
                        }}
                        title={type}
                        isActive={
                          filterState.filter === 'incidentType' && filterState.filterValue === type
                        }
                      />
                    ))}
                  </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item id="violations-by-supplier">
                  <Accordion.Header headerText="Violations By Supplier" />
                  <Accordion.Content maxHeight={325}>
                    {suppliers.map(supplier => (
                      <DotFilter
                        key={supplier}
                        incidentType=""
                        handleClick={() => {
                          analyticsEvent({
                            triggerAction: 'Click',
                            targetElement: 'Filter Option',
                            targetElementVariation: supplier,
                            simpleName: 'Select Filter',
                            context: {
                              filter: supplier,
                            },
                          });
                          filterDispatch({
                            type: 'set',
                            filter: 'supplier',
                            filterValue: supplier,
                          });
                        }}
                        title={supplier}
                        isActive={
                          filterState.filter === 'supplier' && filterState.filterValue === supplier
                        }
                      />
                    ))}
                  </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item id="violations-by-state">
                  <Accordion.Header headerText="Violations By State" />
                  <Accordion.Content maxHeight={325}>
                    {incidentStates.map(state => (
                      <DotFilter
                        key={state}
                        incidentType=""
                        handleClick={() => {
                          analyticsEvent({
                            triggerAction: 'Click',
                            targetElement: 'Filter Option',
                            targetElementVariation: state,
                            simpleName: 'Select Filter',
                            context: {
                              filter: state,
                            },
                          });
                          filterDispatch({ type: 'set', filter: 'state', filterValue: state });
                        }}
                        title={state}
                        isActive={
                          filterState.filter === 'state' && filterState.filterValue === state
                        }
                      />
                    ))}
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion>
            )}
          </Styled.Filters>
          <Styled.Violations>
            {windowWidth < breakpoints.md.value && (
              <Styled.FilterForm>
                <label htmlFor="filter-select">
                  <span className="visually-hidden">Filter Violations:</span>
                  <Styled.FilterFormSelect
                    id="filter-select"
                    value={`${filterState.filter}-${filterState.filterValue}`}
                    onChange={event => {
                      const [filter, filterValue] = event.target.value.split(/-(.*)/s);

                      filterDispatch({
                        type: 'set',
                        filter: filter as USDAViolationsFilters,
                        filterValue,
                      });
                    }}
                  >
                    <option value="null-null" disabled>
                      Filter Violations
                    </option>
                    <optgroup label="Type of Violation">
                      {types.map(type => (
                        <option key={`incidentType-${type}`} value={`incidentType-${type}`}>
                          {REPORT_EMOJI[type]} {type}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Violations By Supplier">
                      {suppliers.map(supplier => (
                        <option key={`supplier-${supplier}`} value={`supplier-${supplier}`}>
                          {supplier}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Violations By State">
                      {incidentStates.map(state => (
                        <option key={`state-${state}`} value={`state-${state}`}>
                          {state}
                        </option>
                      ))}
                    </optgroup>
                  </Styled.FilterFormSelect>
                </label>
                {!!filterState.filterValue && (
                  <Styled.FilterClear
                    type="button"
                    onClick={() => filterDispatch({ type: 'clear' })}
                  >
                    Remove Filter
                  </Styled.FilterClear>
                )}
              </Styled.FilterForm>
            )}
            <AnimatePresence>
              {!!filterState.filterValue && (
                <Styled.FilterStatus
                  as={motion.div}
                  initial={{
                    height: 0,
                    opacity: 0,
                  }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{
                    height: 0,
                    opacity: 0,
                  }}
                  transition={{
                    duration: 0.75,
                    ease: [0.19, 1, 0.22, 1],
                  }}
                >
                  {windowWidth >= breakpoints.md.value && (
                    <Styled.FilterStatusTitle>
                      {filterState.filter === 'incidentType' && (
                        <Styled.FilterStatusDot incidentType={filterState.filterValue} />
                      )}
                      {filterState.filterValue}
                      <Styled.FilterClear onClick={() => filterDispatch({ type: 'clear' })}>
                        Remove Filter
                      </Styled.FilterClear>
                    </Styled.FilterStatusTitle>
                  )}

                  {typeDescriptions[filterState.filterValue] && (
                    <Styled.FilterStatusDescription>
                      {typeDescriptions[filterState.filterValue]}
                    </Styled.FilterStatusDescription>
                  )}
                </Styled.FilterStatus>
              )}
            </AnimatePresence>
            <DotGrid
              dotGridData={violationData}
              filterState={filterState}
              showGraphicImagery={showGraphicImagery}
            />
          </Styled.Violations>
        </Styled.ColumnLayout>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

Violations.defaultProps = {
  noBG: false,
};
