import React from 'react';

import { useScrollPosition } from './useScrollPosition';
import { useWindowHeight } from './useWindowHeight';

const getScrollPercentage = (scrollY: number, innerHeight: number): number => {
  if (typeof document === 'undefined' || document.body.offsetHeight === innerHeight) return 0;
  return Math.round((scrollY / (document.body.offsetHeight - innerHeight)) * 100);
};

export const useWindowScrollPercentage = (): number => {
  const { windowHeight } = useWindowHeight();
  const [scrollPercentage, setScrollPercentage] = React.useState(
    getScrollPercentage(0, windowHeight),
  );

  useScrollPosition(
    ({ currPos }) => {
      setScrollPercentage(getScrollPercentage(currPos.y, windowHeight));
    },
    [windowHeight],
    null,
    true,
  );

  return scrollPercentage;
};
