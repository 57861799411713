import React, { useEffect } from 'react';

import * as Styled from './ImageItem.styles';

import { LoadingContext, MediaCreditContext } from '+/contexts';
import { getImageFilePath } from '+/utils/getImageFilePath';
import { isPrerendering } from '+/utils/environment';
import { breakpoints } from '+/styles/themes/mainTheme';

export const ImageItem = ({
  imageItemData,
  loadBeforeReveal,
  height,
  width,
  ...props
}: {
  imageItemData: ImageItem;
  loadBeforeReveal?: boolean;
  height?: number;
  width?: number;
}) => {
  const mediaCreditContext = React.useContext(MediaCreditContext);
  const loadingContext = React.useContext(LoadingContext);

  const { image, altText, photoCredit, imageMobile, imageWebp, imageWebpMobile } = imageItemData;
  mediaCreditContext.addCredit(photoCredit);

  const fallbackImageFilePath = getImageFilePath(image);
  const MEDIA_QUERY = `(min-width: ${breakpoints.lg.value}px)`;

  useEffect(() => {
    if (loadBeforeReveal) {
      loadingContext.addLoadable(
        new Promise((resolve, reject) => {
          if (isPrerendering) return;
          const picture = document.createElement('picture');

          if (imageWebpMobile) {
            const webpMobileSource = document.createElement('source');
            webpMobileSource.srcset = getImageFilePath(imageWebpMobile);
            webpMobileSource.type = 'image/webp';
            picture.append(webpMobileSource);
          }
          if (imageWebp) {
            const webpSource = document.createElement('source');
            webpSource.srcset = getImageFilePath(imageWebp);
            webpSource.type = 'image/webp';
            if (imageWebpMobile) {
              webpSource.media = MEDIA_QUERY;
            }
            picture.append(webpSource);
          }
          const source = document.createElement('source');
          source.srcset = fallbackImageFilePath;
          source.media = MEDIA_QUERY;
          picture.append(source);

          const img = document.createElement('img');
          img.src = imageMobile ? getImageFilePath(imageMobile) : fallbackImageFilePath;
          img.onload = () => resolve();
          img.onerror = () => reject();

          picture.append(img);
        }),
      );
    }
  }, []);
  return (
    <Styled.Picture>
      {imageWebp && (
        <source
          srcSet={getImageFilePath(imageWebp)}
          type="image/webp"
          media={imageWebpMobile && MEDIA_QUERY}
        />
      )}
      {imageWebpMobile && <source srcSet={getImageFilePath(imageWebpMobile)} type="image/webp" />}
      <source srcSet={fallbackImageFilePath} media={MEDIA_QUERY} />
      <img
        {...props}
        src={imageMobile ? getImageFilePath(imageMobile) : fallbackImageFilePath}
        alt={altText}
        height={height}
        width={width}
      />
    </Styled.Picture>
  );
};

ImageItem.defaultProps = {
  height: null,
  width: null,
  loadBeforeReveal: false,
};
