import styled, { createGlobalStyle, css } from 'styled-components';
import * as sharedStyles from '+/styles/reusable/inputStyles';

export const AnchorContainer = styled.div<{ border?: string }>`
  color: var(--color-neutral-600);

  ${props =>
    props.border &&
    props.border !== 'none' &&
    css`
      padding: 2rem;
      border: 1px solid var(--color-neutral-350);
    `}

  ${props =>
    props.border === 'primary' &&
    css`
      border-color: var(--color-primary-300);
    `};

  ${props =>
    props.border === 'secondary' &&
    css`
      border-color: var(--color-secondary-300);
    `};

  ${props =>
    props.border === 'accent' &&
    css`
      border-color: var(--color-accent-500);
    `};

  p:not(:first-child) {
    margin-top: 1.65rem;
  }

  // Hide redundant 'Secure Donation' message injected by EA 
  .secure-processing-label {
    display: none;
  }
`;

export const PaymentMethodsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-neutral-100);
  padding: 1.5rem;

  img {
    margin: 0 0.5rem;
  }
`;

// Thanks
export const FormThanks = styled.div`
  text-align: center;
`;

// Fast Action Sign Up Modal
export const GlobalFastActionSignUpModalStyles = createGlobalStyle`
  html.has-overlay {
    overflow-y: auto !important;
  }
  #fastaction-signup {
    display: none !important;
  }
`;
export const GlobalEADonateFormInputStyles = createGlobalStyle`
  .modifiedFormCss {
    input[type='text'],
    input[type='url'],
    input[type='tel'],
    input[type='color'],
    input[type='email'],
    .input-wrap {
      ${sharedStyles.textyInput}
    }

    input[type='number'] {
      ${sharedStyles.inputNumber}
    }

    .input-wrap {
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;

      iframe {
        height: 1.25rem;
        width: 100%;
      }
    }

    // remove dropdown arrow from prefix input since we are removing dropdown
    #PrefixInput input::-webkit-calendar-picker-indicator {
      display: none !important;
    }

    textarea {
      ${sharedStyles.textarea};
    }

    // Select specific
    select {
      ${sharedStyles.inputSelect};
    }

    // Labels
    label {
      ${sharedStyles.inputLabel}
      padding-bottom: 1.125rem;

      &.error {
        ${sharedStyles.inputLabelErrored}
      }

      .didYouMean {
        display: block;
        text-align: right;

        a {
          color: $accessibilityYellow;
        }
      }

      // tooltip preceeds
      div + .didYouMean {
        margin-top: 1.75em;
      }

      a.at-whatsthis,
      a.change-account {
        color: var(--color-accent-500);
        cursor: pointer;
        font-size: 0.75rem;
        text-decoration: none;
    
        &:hover {
          color: var(--color-accent-700);
        }
      }
    }

    label.hasFloatingLabel {
      ${sharedStyles.inputLabelFloating};

      .floatingLabel {
        ${sharedStyles.inputLabelTextFloatingStart};
      }

      input:not(:placeholder-shown),
      textarea:not(:placeholder-shown),
      select:not(:placeholder-shown),
      input:focus {
        & ~ .floatingLabel {
          ${sharedStyles.inputLabelTextFloatingAnimation};
        }
      }

      &.error {
        .floatingLabel {
          color: var(--color-primary-500);
        }
      }
    }

    input[type='checkbox'] {
      ${sharedStyles.checkbox}

      & + span {
        ${sharedStyles.checkboxLabelText}
      }
    }

    .at-check {
      text-align: left;
      display: block;
    }

    input[type='radio'] {
      position: absolute;
      opacity: 0;
      z-index: -9999;

      & + span {
        position: relative;
        padding-left: 1.75em;
        display: inline-block;
        font-size: 1rem;
        line-height: 1.375;

        &:before,
        &:after {
          height: 1.125rem;
          left: 0;
          position: absolute;
          top: 0.125rem;
          width: 1.125rem;
          content: '';
          transition: all 0.1s ease-out;
          border-radius: 50%;
          cursor: pointer;
        }

        &:before {
          border: 1px solid var(--color-neutral-400);
        }

        &:after {
          transform: scale(0.5);
        }
      }

      &:not(:checked) + span:hover:before {
        border-color: var(--color-neutral-600);
      }

      &:checked + span {
        &:after {
          background: var(--color-neutral-600);
        }
      }

      &:focus + span:before {
        // apply browser default focus ring
        // https://css-tricks.com/copy-the-browsers-native-focus-styles/
        outline: 5px auto Highlight;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    small.error {
      ${sharedStyles.inputError}
    }

    .intl-tel-input.iti-container {
      width: auto;

      .country-list {
        max-width: 237px;
      }
    }

    .iti__country-list {
      max-width: 23rem;
      font-size: 0.8125rem;
      color: var(--color-neutral-600);
    }

    .at-row.YesSignMeUpForUpdatesForBinder {
      padding-top: 0.5rem;
    }
  }
`;
