import { t } from '+/utils/textDictionary';
import { showMeTheKeys, showMeTheOriginals } from '+/utils/queryParams';

// Utility to go through the EveryAction element tree and change all the values to those supplied.
const changeElementText = (element: EveryActionFormElement) => {
  if (element.children) {
    element.children.forEach(changeElementText);
  }
  if (element.title && element.name) {
    const labelKey = `&${element.name}`;
    element.title = showMeTheKeys ? labelKey : t(element.name) || element.title;
  }
};

interface AlterFormDefinitionArgs {
  form_definition: FormDefinition;
}

// Change the values used to generate text for form.
// These are included in form_elements on the form_definition and also in PrimaryResources under the resources key on the form view.
const changeFormElementTextValues = (alterFormDefinitionArgs: AlterFormDefinitionArgs) => {
  if (showMeTheOriginals) {
    return alterFormDefinitionArgs;
  }

  alterFormDefinitionArgs.form_definition.form_elements.forEach(changeElementText);

  const { resources } = alterFormDefinitionArgs.form_definition;

  resources.PrimaryResources = Object.keys(resources.PrimaryResources).reduce((a, k) => {
    const labelKey = `&${k}`;
    // eslint-disable-next-line no-param-reassign
    a[k] = showMeTheKeys ? labelKey : t(k) || resources.PrimaryResources[k];
    return a;
  }, {});

  return alterFormDefinitionArgs;
};

export default changeFormElementTextValues;
