import { RememberMeData } from './RememberMeContext';

export interface RememberMeState {
  userData: RememberMeData | null;
  saveUserData: boolean;
}

export type RememberMeActionTypes = 'updateUserData' | 'updateSaveUserData';

interface UpdateUserData {
  type: 'updateUserData';
  payload: Partial<RememberMeData>;
}

interface UpdateSaveUserData {
  type: 'updateSaveUserData';
  payload: boolean;
}

export type RememberMeAction = UpdateUserData | UpdateSaveUserData;

export const initState = {
  lookupKey: null,
  userData: null,
  saveUserData: false,
};

export const reducer = (state: RememberMeState, action: RememberMeAction) => {
  switch (action.type) {
    case 'updateUserData': {
      return { ...state, userData: { ...state.userData, ...action.payload } };
    }
    case 'updateSaveUserData': {
      return { ...state, saveUserData: action.payload };
    }
    default: {
      throw new Error(
        `Provided action type does not exist — it should be either "updateUserData" or "updateSaveUserData".`,
      );
    }
  }
};
