import React from 'react';

export class ReloadOnError extends React.Component<{ onError: () => void }> {
  state = { error: false };

  componentDidCatch(error, errorInfo) {
    console.error(error);
    this.props.onError();
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  render() {
    if (this.state.error) {
      return null;
    }
    return this.props.children;
  }
}
