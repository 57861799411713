import React from 'react';

import getASContent from '+/utils/getASContent';

interface MarkdownAdvocateStudioProps {
  children: JSX.Element;
}

export const MarkdownAdvocateStudio = (props: MarkdownAdvocateStudioProps) => {
  const text = getASContent(props.children[0], props);
  return <span>{text}</span>;
};
