import styled from 'styled-components';

export const ModalFooter = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: var(--color-neutral-200);
  padding: calc(var(--modal-padding) / 2) var(--modal-padding);
`;
