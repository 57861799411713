import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  position: relative;
  margin: 0 0 2.5rem;
`;

export const TierLabel = styled.span`
  &&& {
    padding: 0;
    position: absolute;
    left: 1rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    font-weight: bold;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      left: 1.5rem;
      font-size: 0.75rem;
    }

    &:before,
    &:after {
      display: none;
    }
  }
`;

export const SmallCurrency = styled.span`
  &&& {
    padding: 0;
    font-size: 1rem;
    top: -0.125rem;
    margin: 0 1px 0 0;
    position: relative;

    &:before,
    &:after {
      display: none;
    }
  }
`;

export const Amount = styled.span`
  font-size: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: inherit;
  }
`;

export const SmallLabel = styled.span`
  &&& {
    padding: 0;
    font-size: 1rem;
    text-transform: lowercase;
    margin: 0 0 0 1px;
    bottom: -0.188rem;
    position: relative;

    &:before,
    &:after {
      display: none;
    }
  }
`;

export const OtherWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  background: var(--color-neutral-100);

  & > *:first-child {
    width: 50%;
    flex-grow: 1;
  }
`;

export const CustomLabel = styled.span`
  &&& {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: no-wrap;

    &:before,
    &:after {
      display: none;
    }
  }
`;

export const CustomInputWrapper = styled(motion.div)`
  width: 1px;
  overflow: hidden;
`;

export const CustomInputLabel = styled(motion.label)`
  &&& {
    padding: 0;
  }

  span {
    position: absolute;
    left: 0.875rem;
    font-size: 2rem;
    line-height: 1;
    top: 0.875rem;
    font-family: var(--font-primary);
    color: var(--color-neutral-500);
  }
`;

export const CustomInput = styled.input`
  &&& {
    height: 4rem;
    font-size: 2rem;
    border-bottom-width: 3px;
    text-align: center;
  }
`;

export const Error = styled.small`
  position: absolute;
  left: 0;
`;

export const TributeLabel = styled.label`
  margin: 1.5rem 0;
`;
