/*
 * Stores photo credits for display.
 * To be passed in as a context, allowing it to grab all credited photos in a section.
 */

export class CreditHolder {
  credits: Array<string>;

  constructor() {
    this.credits = [];
  }

  addCredit(name: string): void {
    if (!this.credits.includes(name)) {
      this.credits.push(name);
    }
  }

  getCredits(): string {
    return this.credits.join(', ');
  }
}
