import React from 'react';
import * as Styled from './SecurityTagline.styles';
import { ICONS } from '+/utils/constants/icons';
import { Icon } from '+/components/Icon';
import { t } from '+/utils/textDictionary';

interface Props {
  tagline?: string;
}

export const SecurityTagline = ({ tagline = t('Widget.NextStep.SecurityTagline') }: Props) => (
  <Styled.Wrapper>
    <Icon path={ICONS.LOCK.path} size={12} color="var(--color-neutral-400)" />
    &nbsp;{tagline}
  </Styled.Wrapper>
);
