import React from 'react';
import { useParallax } from 'react-scroll-parallax';

import * as Styled from './TextOnMedia.styles';
import { Markdown } from '+/components/Markdown';
import { MediaItem } from '+/components/MediaItem';
import { isIOS } from '+/utils/isIOS';

export const TextOnMedia = ({
  textOnMediaData: { headline, text, layout = 'one-column', mediaItem, mediaPosition = 'static' },
}: {
  textOnMediaData: TextOnMediaComponent;
}) => {
  const { ref } = useParallax({
    speed: -35,
    scale: [1.04, 1.04],
    disabled: mediaPosition !== 'parallax' && !isIOS(),
  });
  const isFixed = (): boolean =>
    mediaItem.__typename === 'Directus_image_items' && mediaPosition === 'fixed' && !isIOS();
  const hasHeadline = (): boolean => !!headline;
  const hasText = (): boolean => !!text;
  return (
    <Styled.TextOnMedia mediaItem={isFixed() && mediaItem}>
      <Styled.Container layout={layout}>
        {hasHeadline() && (
          <Styled.Headline layout={layout} hasText={hasText()}>
            <Markdown>{headline}</Markdown>
          </Styled.Headline>
        )}
        {hasText() && (
          <Styled.Text layout={layout} hasHeadline={hasHeadline()}>
            <Markdown>{text}</Markdown>
          </Styled.Text>
        )}
      </Styled.Container>
      <Styled.MediaContainer ref={ref}>
        {!isFixed() && (
          <Styled.Media
            as={MediaItem}
            mediaItemData={{
              ...mediaItem,
              autoplay: true,
              loop: true,
              controls: false,
              muted: true,
              playsInline: true,
            }}
          />
        )}
      </Styled.MediaContainer>
      {!!(mediaItem as ImageItem).photoCredit && (
        <Styled.PhotoCredit>{(mediaItem as ImageItem).photoCredit}</Styled.PhotoCredit>
      )}
    </Styled.TextOnMedia>
  );
};
