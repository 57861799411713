import { getScrollbarWidth } from '../getScrollbarWidth';

// Private, don't let components modify these directly.
const scrollPauses = Symbol('NoScrollController.scrollPauses');
const scrollGutterPauses = Symbol('NoScrollController.scrollPauses');
const maxScrollbarWidth = Symbol('NoScrollController.maxScrollbarWidth');

export const noScrollClassName = 'no-scroll';
export const noScrollGutterClassName = 'no-scroll-gutter';
export const scrollbarWidthHiddenCSSProperty = '--body-scrollbar-width-hidden';

export class NoScrollController {
  [scrollPauses]: symbol[];

  [scrollGutterPauses]: symbol[];

  [maxScrollbarWidth]: number;

  constructor() {
    this[scrollPauses] = [];
    this[scrollGutterPauses] = [];
    this[maxScrollbarWidth] = 0;
  }

  /**
   * pauseScrolling() Pauses scrolling on the main document body
   *
   * @returns {()=>void} Returns a function that will resume scrolling (If there are no other pauses in queue)
   */
  pauseScrolling(addGutter = false): () => void {
    const newScrollPause = Symbol('NoScrollController.pauseScrolling.newScrollPause');
    const noScrollClass: string = addGutter ? noScrollGutterClassName : noScrollClassName;
    const scrollPauseArraySymbol: symbol = addGutter ? scrollPauses : scrollGutterPauses;

    // We store the maximum value while we have active pause items in case the first item doesn't add a gutter
    // but one of the following items does, that following item wouldn't have access to scrollbar width unless
    // we store the value earlier on in the first item when the scrollbar is active.
    const currentScrollbarWidth: number = getScrollbarWidth();
    if (currentScrollbarWidth > this[maxScrollbarWidth])
      this[maxScrollbarWidth] = currentScrollbarWidth;

    this[scrollPauseArraySymbol].push(newScrollPause);
    document.body.classList.add(noScrollClass);

    if (addGutter && this[maxScrollbarWidth]) {
      document.documentElement.style.setProperty(
        scrollbarWidthHiddenCSSProperty,
        `${this[maxScrollbarWidth]}px`,
      );
    }

    // Return resume scrolling function
    return (): void => {
      this[scrollPauseArraySymbol] = this[scrollPauseArraySymbol].filter(
        scrollPause => scrollPause !== newScrollPause,
      );

      // If there are still pause items we don't want to do anything else
      if (this[scrollPauseArraySymbol].length) return;

      document.body.classList.remove(noScrollClass);

      if (addGutter)
        document.documentElement.style.setProperty(scrollbarWidthHiddenCSSProperty, '');

      // Check if the opposite pause array is also empty and reset maxScrollbarWidth if it is.
      // Can cause gutters to be placed when there is no active overflow being hidden if not reset.
      if (this[scrollPauseArraySymbol !== scrollPauses ? scrollGutterPauses : scrollPauses].length)
        this[maxScrollbarWidth] = 0;
    };
  }
}
