import { postEvent } from './aBTestController';
import { analyticsContext } from './analyticsContext';

const analyticsEvent = (
  // event: string,
  // element: string,
  // elementType: string,
  // options: string,
  // additionalParams?: object,
  {
    targetElement,
    eventTypeName,
    targetElementVariation,
    value,
    context = {},
    triggerAction,
    options = {},
    simpleName,
  }: {
    targetElement: string;
    eventTypeName?: string;
    targetElementVariation: string;
    value?: string;
    context?: any;
    triggerAction: string;
    options?: any;
    simpleName: string;
  },
): null | void => {
  if (typeof window === 'undefined') {
    return;
  }
  postEvent({
    eventName: simpleName,
    eventValue: value,
    context: analyticsContext.getContext(),
  });
  window.dataLayer.push({
    event: triggerAction,
    element: targetElement,
    elementType: targetElementVariation,
    ...options,
  });
};

export default analyticsEvent;
