/*
 * Adds amount options with customized behavior.
 */

import React, { useEffect, useRef, useState } from 'react';

import * as Styled from './AmountOptions.styles';
import { StandardAmountOptions } from './StandardAmountOptions';
import { MendedHeartSocietyAmountOptions } from './MendedHeartSocietyAmountOptions';

import analyticsEvent from '+/utils/analyticsEvent';
import { t } from '+/utils/textDictionary';

export const AmountOptions = ({ controller }: { controller: EveryActionModelController }) => {
  const customInputRef = useRef<HTMLInputElement>(null);
  const firstAmountButtonRef = useRef<HTMLInputElement>(null);

  const isRecurring = controller.getMonthly();
  const amounts = controller.getAmountOptions();
  const selectedAmount = controller.getSelectedAmount();
  const isCustom = !amounts.includes(selectedAmount);

  // If we tie this directly to the everyaction model, it won't allow some input values, creating a buggy experience.
  // This lets us reject values only when the user clicks away form the field.
  const [onCustom, setOnCustom] = useState(isCustom);
  const [customValue, setCustomValue] = useState<string>(
    isCustom ? selectedAmount.toFixed(2) : null,
  );
  const customValueAsNumber = parseFloat(customValue);

  // If amount hasn't been changed by the user, and custom value differs from what is in the box, set the custom value.
  if (!controller.amountChanged && isCustom && customValueAsNumber !== selectedAmount) {
    setCustomValue(selectedAmount.toFixed(2));
    // This handles changes apart from the interface. E.g. if the user flips to monthly.
  } else if (!onCustom && isCustom) {
    setCustomValue(selectedAmount.toFixed(2));
    setOnCustom(true);
  }

  const handleAmountChange = amount => {
    setOnCustom(false);
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: `Select ${controller.getCurrencySymbol()}${amount}${
        controller.getMonthly() ? 'r' : ''
      }`,
      simpleName: `Select ${controller.getCurrencySymbol()}${amount}${
        controller.getMonthly() ? 'r' : ''
      }`,
    });
    controller.setSelectedAmount(amount);
  };

  const handleCustomChange = e => {
    if (controller.timeout) {
      clearTimeout(controller.timeout);
    }
    setOnCustom(true);
    controller.setAmountChanged();
    const amount = e.target.value;
    const amountAsNumber = parseFloat(amount);
    if (amountAsNumber > 0) {
      setCustomValue(amount);
      controller.setSelectedAmount(amountAsNumber, false);
    } else {
      setCustomValue(null);
      controller.setSelectedAmount(selectedAmount);
    }
  };

  const setToCustom = () => {
    setOnCustom(true);
    if (customValue) controller.setSelectedAmount(customValueAsNumber);
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Select Other',
      simpleName: 'Select custom donation amount',
    });
  };

  const handleEnableTributeGiftChange = () => {
    const currentSettingOn = controller.getEnableTributeGift();
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: `Toggle Tribute ${currentSettingOn ? 'Off' : 'On'}`,
      simpleName: `${currentSettingOn ? 'Select' : 'Deselect'} tribute`,
    });
    controller.setEnableTributeGift(!currentSettingOn);
  };

  const formatAmount = (amount: string) => {
    // if there's a decimal place, ensure there's 2 places
    // otherwise return a whole number
    if (amount.includes('.') || amount.includes(',')) {
      return parseFloat(amount).toFixed(2);
    }
    return amount;
  };

  const handleOtherInputBlur = () => {
    if (customValue) {
      const formattedAmount = formatAmount(customValue);
      setCustomValue(formattedAmount);
    }
  };

  useEffect(() => {
    if (customInputRef.current) {
      customInputRef.current.focus();
    }
  }, [onCustom]);

  // For a11y, focus on first amount button
  // when the donor goes back to this step (otherwise focus would be stuck on prev "back" button)
  // only if there is no frequency selector
  useEffect(() => {
    if (
      !controller.includesBothFrequencyTypes &&
      controller.getMaxStepReached() > 0 &&
      firstAmountButtonRef.current
    ) {
      firstAmountButtonRef.current.focus();
    }
  }, [controller.getStep()]);

  const amountIsInvalid =
    customValueAsNumber < controller.getMinAmount() ||
    customValueAsNumber > controller.getMaxAmount();
  const showError =
    onCustom && (!customInputRef.current || customInputRef.current.value !== '') && amountIsInvalid;

  const OptionGroup =
    controller.getAmountOptionsType() === 'mended_heart'
      ? MendedHeartSocietyAmountOptions
      : StandardAmountOptions;

  return (
    <>
      <Styled.Wrapper>
        <OptionGroup
          onCustom={onCustom}
          controller={controller}
          amounts={amounts}
          isRecurring={isRecurring}
          customValue={customValue}
          selectedAmount={selectedAmount}
          firstAmountButtonRef={firstAmountButtonRef}
          customInputRef={customInputRef}
          handleAmountChange={handleAmountChange}
          handleCustomChange={handleCustomChange}
          handleOtherInputBlur={handleOtherInputBlur}
          setToCustom={setToCustom}
        />
        {showError && (
          <Styled.Error className="error">
            {
              /* eslint-disable indent */
              customValueAsNumber < controller.getMinAmount()
                ? t('Widget.AmountOptions.MinError', {
                    currency: controller.getCurrencySymbol(),
                    min: `${controller.getMinAmount()}`,
                  })
                : t('Widget.AmountOptions.MaxError', {
                    currency: controller.getCurrencySymbol(),
                    max: `${controller.getMaxAmount()}`,
                  })
              /* eslint-enable indent */
            }
          </Styled.Error>
        )}
      </Styled.Wrapper>

      <Styled.TributeLabel className="at-check">
        <input
          type="checkbox"
          onChange={() => handleEnableTributeGiftChange()}
          checked={controller.getEnableTributeGift()}
        />
        <span>{t('Widget.AmountOptions.InHonor')}</span>
      </Styled.TributeLabel>
    </>
  );
};
