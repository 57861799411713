import styled, { css } from 'styled-components';

export const NotificationBanner = styled.div<{
  isVisible: boolean;
  position: 'top' | 'bottom';
}>`
  padding: clamp(0.5rem, 4vh, 1rem) 1.25rem;
  background: var(--color-theme-accent);
  color: var(--color-theme-accent-inverse);
  text-align: center;
  position: fixed;
  width: 100%;
  transition: all 0.75s var(--ease-out-expo);
  transition-property: transform, box-shadow;
  z-index: var(--z-index-notification-banner);

  ${props =>
    props.position === 'top' &&
    css`
      top: 0;
      transform: translateY(${props.isVisible ? '0' : '-100%'});
    `}

  ${props =>
    props.position === 'bottom' &&
    css`
      bottom: 0;
      transform: translateY(${props.isVisible ? '0' : '100%'});
    `}

    ${props =>
    props.isVisible &&
    css`
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
    `}
`;

export const Text = styled.div`
  font-size: 0.875rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 1rem;
  }
  a {
    border-bottom-color: inherit;

    &:hover {
      color: inherit;
      background: transparent;
      border-bottom-color: inherit;
    }
  }
`;
