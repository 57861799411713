import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: -0.25rem;
`;

export const DropdownWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`;

export const DropdownTrigger = styled.button`
  background: 0;
  border: 0;
  padding: 0.25rem;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  transition: background 0.25s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const DropdownTriggerArrowWrapper = styled.span<{ expanded: boolean }>`
  transition: transform 0.25s ease-in-out;
  margin: 0 0 1px 3px;
  display: block;

  ${props =>
    props.expanded &&
    css`
      transform: rotate(180deg);
    `};
`;

export const DropdownTriggerLogo = styled.img`
  width: 5rem;
`;

export const InfoTrigger = styled.button<{ href: string }>`
  background: 0;
  border: 0;
  padding: 0.25rem;
  margin-left: 0.25rem;
`;

export const InfoIconWrapper = styled.span`
  display: block;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  background: var(--color-white);
  width: calc(200%);
  right: -4px;
  padding: 0.25rem 0;
  margin: 6px 0 0 0;

  a {
    font-family: var(--font-primary);
    font-size: 0.875rem;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    color: var(--color-black);
    line-height: 1.25;

    &:hover {
      background: var(--color-neutral-200);
    }
  }
`;
