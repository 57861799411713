import React from 'react';

export class ErrorCatcher extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean; error?: Error }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
    // eslint-disable-next-line no-console
    console.error('Error: ', this.state.error.toString());
    return null;
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    };
  }
}
