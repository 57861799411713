import styled, { css, Keyframes, keyframes } from 'styled-components';

import { Button as ButtonComponent } from '+/components/Button';
import { Link as LinkComponent } from '+/components/Link';
import { motion } from 'framer-motion';

export const Logo = styled.div`
  position: absolute;
  top: 50%;

  width: 2.375rem;
  height: 2.375rem;

  transform: translateY(-50%);

  background: var(--image-heart-pulse);
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Label = styled.div`
  max-width: 100%;

  overflow: hidden;
`;

export const Link = styled(LinkComponent)`
  &,
  &:visited,
  &:hover,
  &:link,
  &:active {
    text-decoration: none;
    color: var(--color-base-inverse);
  }
`;

export const MobileButton = styled(ButtonComponent)`
  font-size: 1rem;
`;

interface PinnedCallToActionMobileProps {
  color: ColorThemeName;
}

export const MobilePinnedCallToAction = styled(motion.div)<PinnedCallToActionMobileProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: var(--z-index-pinned-or-sticky-call-to-action);

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;

  background-color: rgba(29, 37, 44, 0.9);
  opacity: 0;

  font-size: 1rem;

  transform: translateY(100%);

  transition: transform 0.6s ease-in-out, opacity 0.6s;
`;

interface KeyframesPulseBackgroundProps extends PinnedCallToActionMobileProps {}

const getKeyframesPulseBackground = (props: KeyframesPulseBackgroundProps): Keyframes => {
  switch (props.color) {
    case 'primary':
      return keyframes`
        0% {
          background: var(--color-primary-300);
        }

        50% {
          background: var(--color-primary-500);
        }

        100% {
          background: var(--color-primary-300);
        }
      `;
    case 'secondary':
      return keyframes`
      0% {
        background: var(--color-secondary-300);
      }

      50% {
        background: var(--color-secondary-500);
      }

      100% {
        background: var(--color-secondary-300);
      }
    `;
    case 'default':
    default:
      return keyframes`
        0% {
          background: var(--color-neutral-500);
        }

        50% {
          background: var(--color-neutral-800);
        }

        100% {
          background: var(--color-neutral-500);
        }
      `;
  }
};

interface PinnedCallToActionProps extends PinnedCallToActionMobileProps {
  hovering: boolean;
  position: 'left' | 'right';
}

export const PinnedCallToAction = styled(motion.div)<PinnedCallToActionProps>`
  position: fixed;
  top: 50%;
  transform: translateY(-50%) translateX(-100%);

  ${props =>
    props.position === 'right'
      ? css`
          right: 0;
          padding-right: 1rem;
          padding-left: 1.25rem;

          ${Logo} {
            left: 1.25rem;
          }
        `
      : css`
          left: 0;
          padding-left: 1rem;
          padding-right: 1.25rem;

          ${Logo} {
            right: 1.25rem;
          }
        `}

  z-index: var(--z-index-pinned-or-sticky-call-to-action);

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 4.375rem;

  min-height: 4rem;

  ${props => {
    switch (props.color) {
      case 'primary':
        return css`
          background: var(--color-primary-500);
          color: var(--color-white);

          ${Logo} {
            filter: brightness(0) invert(1);
          }
        `;
      case 'secondary':
        return css`
          background: var(--color-secondary-500);
          color: var(--color-neutral-800);

          ${Logo} {
            filter: brightness(0);
          }
        `;
      default:
        return css`
          background: var(--color-neutral-800);
          color: var(--color-white);

          ${Logo} {
            filter: brightness(0) invert(1);
          }
        `;
    }
  }}

  font-family: var(--font-secondary);
  font-weight: 700;

  white-space: nowrap;

  clip-path: polygon(100% 0, 100% calc(100% - 1.25rem), calc(100% - 1.25rem) 100%, 0 100%, 0 0);

  ${props =>
    props.hovering
      ? css`
          animation: ${getKeyframesPulseBackground(props)} 0.8s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          animation-play-state: running;

          ${Logo} {
            display: none;
          }
        `
      : css`
          ${Label} {
            visibility: hidden;
          }
        `}
`;
