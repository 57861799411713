import { useLocation } from '@reach/router';

const localNameFor = (fieldName: string): string => {
  return (
    {
      EmailAddress: 'emailAddress',
      FirstName: 'firstName',
      LastName: 'lastName',
    }[fieldName] || fieldName
  );
};

const getFromLocationState = (name: string, location: any): string | undefined => {
  if (!location || !location.state) return undefined;

  return location.state[localNameFor(name)];
};

export default getFromLocationState;
