import React from 'react';

import { CreditHolder } from '+/utils/CreditHolder';
import { LoadingController } from '+/utils/LoadingController';
import { DeprecatedDescriptiveThemeNames } from '+/styles/themes/colorThemes.interface';

/**
 * Newly created contexts
 */
export const MediaCreditContext = React.createContext(new CreditHolder());

export const LoadingContext = React.createContext(new LoadingController(true, val => {}));

export const ColorThemeContext = React.createContext<ColorThemeName>(
  DeprecatedDescriptiveThemeNames.Default,
);

/**
 * Pre-existing contexts
 */
export { FormAlertContext, FormAlertProvider } from './FormAlertContext';
export { FormFieldErrorsContext, FormFieldErrorsProvider } from './FormFieldErrorsContext';

export { NoScrollContext } from './NoScrollContext';
export {
  RememberMeContext,
  RememberMeProvider,
  useRememberMe,
  postUserData,
  REMEMBER_ME_COOKIE_REGEX,
  initializeStateWithCookieVal,
  handleDeleteUserData,
} from './RememberMe';
export type { RememberMeActionTypes, RememberMeAction, RememberMeData } from './RememberMe';
