import styled from 'styled-components';
import { Link } from '+/components/Link';

export const Tag = styled.span`
  display: inline-block;
  font-family: var(--font-secondary);
  font-weight: 700;
  text-transform: uppercase;
  background: var(--color-theme-accent-accessible);
  color: var(--color-theme-accent-inverse);
  line-height: 1;
  white-space: nowrap;
  padding: 0.5em 1em;
  font-size: 0.75rem;
  clip-path: polygon(0 0, 0 100%, calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0);
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    background: var(--color-theme-accent);
    font-size: 0.9rem;
  }
`;

export const ActionTag = styled(Link)`
  display: inline-flex;
  margin-bottom: 1.235rem;
  border-bottom: none !important;
  
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 1.5rem;
  }
`;
