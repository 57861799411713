import styled from 'styled-components';

import { ImageItem as ImageItemComponent } from '+/components/ImageItem';
import { Link as LinkComponent } from '+/components/Link';

export const ImageItem = styled(ImageItemComponent)`
  max-width: 420px;
`;

export const Link = styled(LinkComponent)`
  &,
  &:visited,
  &:link,
  &:hover,
  &:active {
    text-decoration: none;
  }
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Text = styled.p`
  font-family: var(--font-tertiary);
  font-style: italic;
  text-align: center;
  color: var(--color-theme-base-inverse);
`;

export const LogoTextAndButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem 0;

  width: 100%;

  padding: 5rem 1.25rem;

  background: var(--image-mission-background);
  background-color: var(--color-theme-base);
  background-size: cover;
  background-position: 50% 50%;
`;

export const DisclaimerText = styled(Text)`
  font-size: 0.8125rem;
  opacity: 0.6875;
`;
