import React, { useRef, useEffect } from 'react';

import * as Styled from './UpsellModal.styles';

import { t } from '+/utils/textDictionary';
import { Markdown } from '+/components/Markdown';
import { ModalPortal, ModalBody, ModalFooter } from '+/components/Modal';
import { TextButton } from '+/components/TextButton';
import { ImageItem } from '+/components/ImageItem';
import analyticsEvent from '+/utils/analyticsEvent';
import { Button } from '+/components/Button';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

const COUNTER_LENGTH = 15;

export const UpsellModal = ({
  controller,
  recordOptOut,
  closeModal,
  upsellModalData,
  fallbackUpsellImage,
}: {
  controller: EveryActionModelController;
  recordOptOut(): void;
  closeModal(): void;
  upsellModalData: UpsellModalComponent;
  fallbackUpsellImage?: ImageItem;
}) => {
  const [counter, setCounter] = React.useState(COUNTER_LENGTH);
  const [showCounter, setShowCounter] = React.useState(true);
  const [newAmount, setNewAmount] = React.useState(controller.getSuggestedUpsellAmount());
  const isUpsellUsingHeroImage = !upsellModalData.upsellImage && !!fallbackUpsellImage;
  const pushUpsellGAEvent = (interaction: string, simpleName) => {
    analyticsEvent({
      triggerAction: interaction,
      targetElement: 'Popup',
      targetElementVariation: 'Upsell',
      simpleName,
      context: {
        amount: controller.getSelectedAmount(),
        recurring: controller.getMonthly(),
      },
    });
  };

  const upsellImage = upsellModalData.upsellImage || fallbackUpsellImage;

  const handleAmountChange = e => {
    setShowCounter(false);
    setNewAmount(parseFloat(e.target.value));
    controller.setSelectedAmount(e.target.value);
  };

  const handleOptIn = () => {
    controller.setSelectedAmount(newAmount);
    // Only submit if amount really has changed
    closeModal();
    if (controller.getSelectedAmount() === newAmount) {
      controller.setMonthly(true);
      pushUpsellGAEvent('Converted', 'Converted by upsell modal');
      controller.submit();
    }
  };

  const handleOptOut = () => {
    recordOptOut();
    closeModal();
    controller.submit();
    pushUpsellGAEvent('Not Converted', 'Declined conversion by upsell modal');
  };

  if (counter === 0 && showCounter) {
    closeModal();
    controller.submit();
    pushUpsellGAEvent('Auto Submitted', 'Autosubmitted donation after upsell modal display');
  }

  useEffect(() => {
    if (counter === COUNTER_LENGTH) {
      // Push display once
      pushUpsellGAEvent('Display', 'Display upsell modal');
    }

    const interval = setInterval(() => setCounter(Math.max(0, counter - 1)), 1000);
    return () => clearInterval(interval);
  });

  const isInvalidAmount =
    newAmount > controller.getMaxAmount() || newAmount < controller.getMinAmount();

  // Blur the input element to close the mobile keyboard when the modal opens
  const inputEl = useRef(null);
  useEffect(() => inputEl.current.blur(), []);

  return (
    <ModalPortal onClose={handleOptOut} closeColor="var(--color-white)">
      <ModalBody>
        {!!upsellImage && (
          <Styled.HeaderImageWrapper>
            <Styled.HeaderImage
              as={ImageItem}
              imageItemData={upsellImage}
              isUpsellUsingHeroImage={isUpsellUsingHeroImage}
            />
            <Styled.PhotoCredit>
              {t('Images.Photo')} {upsellImage.photoCredit}
            </Styled.PhotoCredit>
          </Styled.HeaderImageWrapper>
        )}
        <Styled.ContentWrapper>
          <Styled.Title>{t('Upsell.Title')}</Styled.Title>
          <Styled.Ask>{t('Upsell.Ask')}</Styled.Ask>
          <Styled.Yes>
            <Markdown>{t('Upsell.Yes')}</Markdown>
          </Styled.Yes>
          <Styled.InputWrapper>
            <Styled.InputLabel>
              <span className="prefix">{controller.getCurrencySymbol()}</span>
              <Styled.Input
                className="upsell-modal__input"
                onChange={e => handleAmountChange(e)}
                value={newAmount}
                ref={inputEl}
                type="number"
                step=".01"
                min="0"
                pattern="^\d*(\.\d{0,2})?$"
              />
            </Styled.InputLabel>
            <Button
              color={ColorThemeNames.RedFilled}
              onClick={handleOptIn}
              disabled={isInvalidAmount}
              narrow
            >
              {t('Upsell.MonthlyButtonLabel')}
            </Button>
          </Styled.InputWrapper>
          {isInvalidAmount && (
            <div>
              <small className="error">
                {t('Upsell.Error', {
                  min: `${controller.getMinAmount()}`,
                  max: `${controller.getMaxAmount()}`,
                  cur: controller.getCurrencySymbol(),
                })}
              </small>
            </div>
          )}
          <Styled.NoWrapper>
            <TextButton small onClick={handleOptOut}>
              {t('Upsell.Decline')}
            </TextButton>
          </Styled.NoWrapper>
        </Styled.ContentWrapper>
      </ModalBody>
      {showCounter && (
        <ModalFooter>
          <Styled.Countdown>{t('Upsell.Countdown', { seconds: `${counter}` })}</Styled.Countdown>
        </ModalFooter>
      )}
    </ModalPortal>
  );
};

UpsellModal.defaultProps = {
  fallbackUpsellImage: null,
};
