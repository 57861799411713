import React, { useEffect } from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import * as yup from 'yup';

import { FixedBannerTarget } from './Page.styles';

import { ColorTheme } from '+/components/ColorTheme';
import { Layout } from '+/components/Layout';
import { Section } from '+/components/Section';
import { MetaData } from '+/components/MetaData';
import params from '+/utils/queryParams';
import { ModalsController } from '+/components/ModalsController';
import { NoScrollController } from '+/utils/NoScrollController';
import { NoScrollContext } from '+/contexts/NoScrollContext';
import { LanguageContext } from '+/contexts/LanguageContext';
import { ModalContextProvider } from '+/contexts/ModalContext';
import { HeaderContextProvider } from '+/contexts/HeaderContext';
import { NotificationBanner } from '+/components/NotificationBanner';
import { setLanguage, addOverrides } from '+/utils/textDictionary';
import usePreviousValue from '+/utils/usePreviousValue';
import useIsFirstMountState from '+/utils/useIsFirstMountState';
import { idByLanguage } from '+/utils/googleTagManagerHelper';

export const Page = ({
  pageData: {
    slug,
    sections,
    title,
    metaTitle,
    metaDescription,
    metaRobots,
    metaShareImage,
    urlMaskPage,
    header,
    domain,
    footer,
    keyAssignments,
    modals,
    notificationBanners,
    language,
    colorTheme,
  },
}: {
  pageData: PageComponent;
}) => {
  const noScrollController = React.useMemo(() => new NoScrollController(), []);

  const previousLanguage = usePreviousValue(language, true);
  const isFirstMount = useIsFirstMountState();

  if (isFirstMount || previousLanguage !== language) {
    if (language === 'es-MX') {
      import('yup-locales').then(({ es }) => yup.setLocale(es));
    }

    setLanguage(language);
    addOverrides(keyAssignments);
  }

  const location = useLocation();
  useEffect(() => {
    if (!urlMaskPage) {
      return;
    }
    if (urlMaskPage.slug && urlMaskPage.slug !== slug && !params.unmask) {
      window.history.replaceState(location?.state, null, `/${urlMaskPage.slug}`);
    }
  }, []);

  return (
    <LanguageContext.Provider value={language}>
      <HeaderContextProvider>
        <MetaData
          title={metaTitle}
          description={metaDescription}
          robots={metaRobots}
          domainName={domain.domainName}
          slug={slug}
          image={metaShareImage}
          language={language}
        />
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${idByLanguage[language]}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
            aria-hidden="true"
            title="GTM"
          />
        </noscript>
        <ColorTheme colorTheme={colorTheme}>
          <ModalContextProvider>
            <NoScrollContext.Provider value={noScrollController}>
              <Layout layoutData={{ path: slug, footer, header, title }}>
                {sections.map(sectionData => (
                  <Section sectionData={sectionData} key={sectionData.id} />
                ))}
                {notificationBanners &&
                  notificationBanners.map(banner => (
                    <NotificationBanner key={banner.id} notificationBannerData={banner} />
                  ))}
              </Layout>
            </NoScrollContext.Provider>
            <ModalsController id="modal-target" modals={modals} />
            <FixedBannerTarget id="fixed-banner-target" />
          </ModalContextProvider>
        </ColorTheme>
      </HeaderContextProvider>
    </LanguageContext.Provider>
  );
};
