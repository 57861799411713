import styled, { css } from 'styled-components';

import { link } from '+/styles/reusable/linkStyles';
import { Markdown as MarkdownComponent } from '+/components/Markdown';
import { MediaItem as MediaItemComponent } from '+/components/MediaItem';

interface Props {
  imagePositioning?: string;
  inView: boolean;
}

export const MediaItem = styled(MediaItemComponent)`
  display: block;

  max-width: 40%;

  margin: auto;

  z-index: 2;
`;

export const Markdown = styled(MarkdownComponent)`
  max-width: 550px;
  margin: auto;

  line-height: 1.7;

  font-size: 1.125rem;
  color: inherit;

  z-index: 2;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 0.25rem;
    margin-bottom: 0.8rem;
    margin-top: 1rem;

    line-height: 1.25;

    font-family: var(--font-secondary);
    font-size: 2.15625rem;
    font-weight: 700;

    letter-spacing: -0.02rem;
    text-transform: uppercase;

    strong {
      color: var(--color-theme-accent);
    }
  }

  h3 {
    margin-top: 5rem;

    line-height: 1.45;

    font-family: var(--font-tertiary);
    font-size: 1.6875rem;
    font-weight: 800;
    font-style: italic;
    font-weight: 800;
    font-size: 150%;

    text-transform: revert;
    text-align: center;
  }

  a {
    ${link}
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 1rem;

    h1,
    h2,
    h4,
    h5,
    h6 {
      text-align: center;

      font-size: 1.875rem;
    }

    h3 {
      font-size: 1.5rem;
    }
  }
`;

export const ImageWithTextContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 900px;

  margin: 0 auto;
`;

export const ImageWithText = styled.div<Props>`
  position: relative;

  padding-top: 5em;
  padding-bottom: 5em;
  padding-left: 50px;
  padding-right: 50px;

  width: 100%;
  overflow: hidden;

  background: var(--color-theme-base-light);

  &:not(:first-of-type)::before {
    content: '';

    position: absolute;
    top: 0;
    left: 50%;

    transform: translateX(-50%) scaleX(0);

    width: 45vw;
    min-width: 200px;
    max-width: 650px;

    height: 7px;

    background: var(--color-theme-base-dark);

    opacity: 0;

    transition: transform 0.8s ease-out, opacity 0.8s;

    ${props =>
      props.inView &&
      css`
        transform: translateX(-50%) scaleX(1);

        opacity: 1;
      `}
  }

  ${ImageWithTextContent} {
    ${props => {
      switch (props.imagePositioning) {
        case 'Left':
          return css`
            flex-direction: row;
          `;
        case 'Right':
          return css`
            flex-direction: row-reverse;
          `;
        case 'Bottom':
          return css`
            flex-direction: column-reverse;
          `;
        default:
          return css`
            flex-direction: column;
          `;
      }
    }}

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      flex-direction: column;
    }

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      width: 100%;
    }
  }

  ${MediaItem} {
    ${props => {
      switch (props.imagePositioning) {
        case 'Left':
          return css`
            max-width: 40%;
            @media (min-width: calc(${props.theme.breakpoints.md} + 1px)) {
              margin-right: 10%;
            }
          `;
        case 'Right':
          return css`
            max-width: 40%;
            @media (min-width: calc(${props.theme.breakpoints.md} + 1px)) {
              margin-left: 10%;
            }
          `;
        case 'Top':
          return css`
            margin: 0rem auto 3.5rem auto;
          `;
        case 'Bottom':
          return css`
            margin: 3.5rem auto 0rem auto;
          `;
        default:
          return css``;
      }
    }}

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      max-width: 95%;
    }
  }

  ${Markdown} {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${props => {
        switch (props.imagePositioning) {
          case 'Top':
          case 'Bottom':
            return css`
              text-align: center;
            `;
          default:
            return css``;
        }
      }}
    }
  }

  // Fade in
  ${Markdown} p, ${MediaItem} {
    opacity: 0;
    transform: translateY(3vh);

    transition: transform 0.6s ease-out, opacity 0.5s ease-out;

    ${props =>
      props.inView &&
      css`
        opacity: 1;
        transform: translateY(0vh);
      `}
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    &:nth-of-type(even) {
      background: var(--color-neutral-200);
    }

    &:not(:first-of-type)::before {
      display: none;
    }
  }
`;
