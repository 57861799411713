/*
 * Tracks whether the page is ready to be displayed.
 * Insert promises with addLoadable necessary before display
 * When all inserted promises have resolved, it will call the
 * setLoading function.
 *
 * This function should be passed in to set the state of the
 * relevant component to tell it loading is finished.
 */

export class LoadingController {
  loadablePromises: Array<Promise<void>>;
  loading: boolean;
  setLoading: (value: boolean) => void;

  constructor(loading, setLoading) {
    this.loadablePromises = [];
    this.loading = loading;
    this.setLoading = setLoading;
    if (loading) {
      setTimeout(() => {
        this.awaitAndResolve();
      }, 0);
    }
  }

  awaitAndResolve(): void {
    // Wait for all the current promises to finish running
    Promise.all(this.loadablePromises).then((): void => {
      this.loading = false;
      this.setLoading(false);
    });
  }

  addLoadable(promise: Promise<void>): void {
    this.loadablePromises.push(promise.catch(() => undefined));
  }

  isLoaded(): boolean {
    return !this.loading;
  }
}
