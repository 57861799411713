import { css } from 'styled-components';

export const link = css`
  transition: background-color 0.2s ease-in-out 0s;
  border-bottom: 2px solid var(--color-theme-accent);
  color: inherit;
  text-decoration: none;

  &:hover {
    background-color: var(--color-theme-accent);
  }
`;
