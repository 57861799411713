import styled from 'styled-components';

import { formColorTransitionParams } from '+/styles/styledComponents/Form.styles';

export const ReadOrEditTextButtonText = styled.span`
  margin-left: 0.5rem;
`;

export const ReadOrEditTextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.95em 1.2em 0.92em 1.2em;

  color: var(--color-theme-accent-dark);
  font-weight: 400;
  font-size: 0.8438rem;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 1;

  transition: transform 0.2s ease-in-out, background 0.2s, color ${formColorTransitionParams},
    filter 0.2s ease-in-out;

  svg {
    transition: fill ${formColorTransitionParams};
    fill: var(--color-theme-accent-dark);
  }

  &:hover {
    transform: scale(1.03);
    border-radius: 4px;
    color: var(--color-theme-accent);
    background: var(--color-theme-base);
    box-shadow: 0 0.2em 0.8em 0 rgba(0, 0, 0, 0.09);

    svg {
      fill: var(--color-theme-accent);
    }
  }
`;

export const Link = styled.a`
  color: inherit;
`;

export const MessageError = styled.div`
  margin: -0.7em 0 1em;
`;
