import styled, { css } from 'styled-components';

export const PaymentSelectorWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const PaymentSelectorLabel = styled.span`
  display: inline-block;
  color: var(--color-neutral-600);
  font-size: 0.813rem;
  margin-bottom: 0.25rem;
`;

export const PaymentButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  & > * {
    margin: 0 2px;
    flex-grow: 1;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

interface PaymentButtonProps {
  selected?: boolean;
}

export const PaymentButton = styled.button<PaymentButtonProps>`
  position: relative;
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  background: var(--color-neutral-100);
  border: 1px solid var(--color-neutral-250);
  border-radius: 4px;
  font-size: 0.875rem;
  color: var(--color-neutral-600);
  font-weight: 500;
  height: 2.75rem;
  font-family: var(--font-primary);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    !props.selected &&
    css`
      &:hover {
        background: var(--color-white);
        transition: all 0.2s ease-in-out 0s;
      }
    `}

  ${props =>
    props.selected &&
    css`
      border-color: var(--color-accent-500);
      color: var(--color-accent-700);
      background: var(--color-white);
      font-weight: 700;
    `};

  /* 
   * Invisible psuedo element for setting button width based
   * on bold (active) text width to prevent layout shift of buttons
   * on selection change.
   * https://css-tricks.com/bold-on-hover-without-the-layout-shift/
  */
  &:after {
    content: attr(data-text);
    content: attr(data-text) / '';
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: 700;
  }
`;
