import React from 'react';
import { AnimatePresence } from 'framer-motion';
import ReactDOM from 'react-dom';

import { ColorTheme } from '../ColorTheme';

import * as Styled from './MarkdownModal.styles';

import { getOrCreateRootIfNotExists } from '+/components/Modal/ModalPortal.component';
import { ModalContent } from '+/components/Modal/ModalContent.component';
import { ModalContext } from '+/contexts/ModalContext';

interface MarkdownModalProps {
  children: JSX.Element;
  modalName: string;
  colorTheme?: ColorThemeName;
}

export const MarkdownModal = (props: MarkdownModalProps) => {
  const { displayedModal, setDisplayedModal } = React.useContext(ModalContext);

  return (
    <AnimatePresence>
      {displayedModal === props.modalName && (
        <>
          {ReactDOM.createPortal(
            <ModalContent onClose={() => setDisplayedModal(null)}>
              <ColorTheme colorTheme={props.colorTheme}>
                <Styled.ModalContentContainer>{props.children}</Styled.ModalContentContainer>
              </ColorTheme>
            </ModalContent>,
            getOrCreateRootIfNotExists('modal-target'),
          )}
        </>
      )}
    </AnimatePresence>
  );

  return null;
};
