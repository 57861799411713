export const personalDataStore: Record<string, string> = {};

try {
  if (typeof window !== 'undefined') {
    const { hash } = window.location;
    const pageDataMatch = hash.match(/pageData\[\[(.*)\]\]/);
    if (pageDataMatch) {
      pageDataMatch[1].split('*').forEach(assignment => {
        const arr = assignment.split('=');
        const key = arr[0];
        const value = arr[1];
        personalDataStore[key] = value;
      });
    }
  }
} finally {
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      window.location.hash = window.location.hash.replace(/pageData\[\[.*\]\]/, '');
    }, 1000);
  }
}
