import React from 'react';
import * as Styled from './FormNavigation.styles';
import { NextStep } from '+/components/DonateForm/widgets/nextStep.component';
import { InitialContinueButton } from '+/components/DonateForm/widgets/PaymentMethodSelector';
import { SecurityTagline } from '+/components/SecurityTagline';
import { BackButton } from './BackButton.component';

interface Props {
  controller: EveryActionModelController;
  step: 'initial' | 'other';
}

export const NextStepButtonSet = ({ controller, step = 'other' }: Props) => {
  return (
    <Styled.Wrapper>
      <Styled.NextButtonWrapper>
        {step === 'initial' && <InitialContinueButton controller={controller} />}
        {step === 'other' && <NextStep controller={controller} />}
      </Styled.NextButtonWrapper>
      <BackButton controller={controller} initialStep={step === 'initial'} />
      <Styled.SecurityWrapper>
        <SecurityTagline />
      </Styled.SecurityWrapper>
    </Styled.Wrapper>
  );
};
