import selectors from '../selectors';

const isVisible = (element: HTMLInputElement | HTMLElement): boolean => {
  return (
    getComputedStyle(element).display !== 'none' &&
    (!element.parentElement || isVisible(element.parentElement))
  );
};

const visibleRequiredUnfilled = (): boolean => {
  const eaDonateForm = document.querySelector(selectors.DONATE_FORM_WRAPPER);
  const requiredElements: Array<HTMLInputElement> = Array.from(
    eaDonateForm.querySelectorAll<HTMLInputElement>('input:required'),
  ).filter(isVisible);

  return requiredElements.some(element => element.value === '');
};

export default visibleRequiredUnfilled;
