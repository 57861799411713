import { RefObject, useEffect, useState } from 'react';

/*
 * Finds an element by its name key in a nested tree of elements.
 * @param ref - A RefObject to check if on screen
 * @param margin - Number of pixels of ref required to be considered "on screen"
 * @param threshold - Percent of element visible needed to trigger (0 = 1px, 1= 100%)
 * @param shoudStayOnOnceOn -
 */
export const useOnScreen = (
  ref: RefObject<HTMLElement | HTMLDivElement>,
  margin = 0,
  threshold = 0,
  shouldStayOnOnceOn = false,
): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);
  const rootMargin = `${margin.toString()}px`;
  useEffect(() => {
    const observer: IntersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (!(shouldStayOnOnceOn && isIntersecting)) {
          setIntersecting(entry.isIntersecting);
        }
      },
      {
        rootMargin,
        threshold,
      },
    );

    const { current } = ref;

    if (current) {
      observer.observe(current);
    }
    return () => {
      observer.unobserve(current);
    };
  }, [isIntersecting]);

  return isIntersecting;
};
