import React, { createContext, useState } from 'react';

interface HeaderContextInterface {
  isHeaderVisible: boolean;
  setIsHeaderVisible(value: boolean): void;
  isDropShadowEnabled: boolean;
  setIsDropShadowEnabled(value: boolean): void;
}

export const HeaderContext = createContext<HeaderContextInterface>({
  isHeaderVisible: true,
  setIsHeaderVisible: () => {},
  isDropShadowEnabled: true,
  setIsDropShadowEnabled: () => {},
});

export const HeaderContextProvider = ({ children }) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isDropShadowEnabled, setIsDropShadowEnabled] = useState(true);

  return (
    <HeaderContext.Provider
      value={{ isHeaderVisible, setIsHeaderVisible, isDropShadowEnabled, setIsDropShadowEnabled }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
