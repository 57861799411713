import React from 'react';

import { Violations } from './fullscreenSlideshowStages';

import { LoadingContext } from '+/contexts';
import analyticsEvent from '+/utils/analyticsEvent';

export const USDAViolationsDisplay = ({
  uSDAViolationsDisplayData,
}: {
  uSDAViolationsDisplayData: USDAViolationsDisplayComponent;
}): JSX.Element => {
  const [uSDAViolations, setUSDAViolations]: [
    USDAViolationData[] | null,
    React.Dispatch<React.SetStateAction<USDAViolationData[]>>,
  ] = React.useState(uSDAViolationsDisplayData.uSDAViolationsData);

  const [error, setError]: [any | null, React.Dispatch<React.SetStateAction<any>>] =
    React.useState();

  const loadingContext: LoadingController = React.useContext(LoadingContext);

  // Dynamically load USDA Violations JSON data if it's not already passed as prop
  React.useEffect((): void => {
    if (!uSDAViolations) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore This file is built on launch and TS will complain it doesn't exist yet
      const uSDAViolationsJSONPromise: Promise<void> = import(
        '../../../static/json/usda_violations.json'
      )
        .then((uSDAViolationsResult: USDAViolationData[]): void => {
          const violations: USDAViolationData[] = Array.from(uSDAViolationsResult);
          analyticsEvent({
            triggerAction: 'Load',
            targetElement: 'USDA Grid',
            targetElementVariation: '',
            simpleName: 'Load USDA Grid',
          });
          setUSDAViolations(violations);
        })
        .catch(reason => setError(reason));

      // Display the loading indicator until the JSON is loaded
      loadingContext.addLoadable(uSDAViolationsJSONPromise);
    }
  }, []);

  return <Violations violationData={uSDAViolations} noBG />;
};
