import { useState, useEffect, RefObject } from 'react';
import { useToggle } from '+/utils/useToggle';

export const useVideoPlayer = (videoElement: RefObject<HTMLVideoElement>) => {
  const [isPlaying, togglePlaying, setPlaying] = useToggle();
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let abortController = new AbortController();

    isPlaying ? videoElement.current.play() : videoElement.current.pause();

    return () => {
      abortController.abort();
    };
  }, [isPlaying, videoElement]);

  const handleOnTimeUpdate = (): void => {
    setProgress((videoElement.current.currentTime / videoElement.current.duration) * 100);
  };

  const handleVideoProgress = (e): void => {
    goToPosition(Number(e.currentTarget.value));
  };

  const goToPosition = (value: number) => {
    videoElement.current.currentTime = (videoElement.current.duration / 100) * value;
    setProgress(value);
  };

  const handleReset = (callback?: Function): Function | null => {
    videoElement.current.currentTime = 0;
    setPlaying(false);
    setProgress(0);
    return !callback ? null : callback();
  };

  return {
    isPlaying,
    togglePlaying,
    setPlaying,
    progress,
    duration,
    setDuration,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleReset,
    goToPosition,
  };
};
