import { camelize } from '+/utils/camelizeObject';

const isString = (possibleString: unknown): boolean =>
  typeof possibleString === 'string' || possibleString instanceof String;

const isNumber = (possibleNumber: unknown): boolean => typeof possibleNumber === 'number';

// Change before storing keys
const suggestedAmountsKey = 'suggested_donation_amounts_one_time';
const suggestedAmountsRecurringKey = 'suggested_donation_amounts_recurring';

// Converts nested relationship references into more workable format by getting rid
// of superfluous x_id keys.
// {"slug":"new-test","sections":[{ section_id: {"section_name":"first section"} }]}
// =>
// {"slug":"new-test","sections":[{"section_name":"first section"}]}
const cleanupData = (obj?: DataToClean): object | Array<unknown> | string | number | undefined => {
  if (!obj || isString(obj) || isNumber(obj)) {
    return obj;
  }

  const newObj: DataToCleanArray | DataToCleanObject = Array.isArray(obj)
    ? [...obj]
    : { ...(obj as DataToCleanObject) };

  // Convert from comma separated string to array of numbers
  if (isString(newObj[suggestedAmountsKey])) {
    const newValue = (newObj[suggestedAmountsKey] as string).split(',').map(n => parseInt(n, 10));
    newObj[suggestedAmountsKey] = newValue;
  }
  if (isString(newObj[suggestedAmountsRecurringKey])) {
    const newValue = (newObj[suggestedAmountsRecurringKey] as string)
      .split(',')
      .map(n => parseInt(n, 10));

    newObj[suggestedAmountsRecurringKey] = newValue;
  }

  // Grab item from media
  if (newObj.media && newObj.media[0]) {
    Object.keys(newObj.media[0].item).forEach(key => {
      const camelCase = camelize(key);
      newObj[camelCase] = cleanupData(newObj.media[0].item[key]);
    });
    delete newObj.media;
  }

  const keys = !Array.isArray(newObj) && Object.keys(newObj);
  if (!keys) return newObj;

  // Unnest nested items
  if (keys[0] === 'item') {
    const subObj = newObj.item;
    if (Array.isArray(subObj)) {
      Object.keys(subObj[0]).forEach(key => {
        const camelCase = camelize(key);
        newObj[camelCase] = cleanupData(subObj[0][key]);
      });
    } else {
      Object.keys(subObj).forEach(key => {
        const camelCase = camelize(key);
        newObj[camelCase] = cleanupData(subObj[key]);
      });
    }
  }

  // Grab item from form
  if (newObj.form && newObj.form[0]) {
    newObj.form = cleanupData(newObj.form[0].item) as object;
  }

  keys.forEach(key => {
    const value: DataToClean = newObj[key];
    const camelCase: string = camelize(key);

    if (key !== camelCase) delete newObj[key];

    if (Array.isArray(value)) {
      const subKeys = value.map(subObj => Object.keys(subObj)).flat();
      const idKeys = subKeys.filter((subKey): boolean => /_id$/.test(subKey) || subKey === 'item');

      if (idKeys[0]) {
        newObj[camelCase] = value.map(subObj => cleanupData(subObj[idKeys[0]]));
      } else {
        newObj[camelCase] = value;
      }
    } else {
      newObj[camelCase] = cleanupData(value);
    }
  });
  return newObj;
};

export default cleanupData;
