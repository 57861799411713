const isWindow = typeof window !== 'undefined';

const params: QueryParameters = isWindow
  ? window.location.search
      .replace('?', '')
      .split('&')
      .reduce((accum, assignment) => {
        const [k, v] = assignment.split('=');
        accum[k] = v;
        return accum;
      }, {})
  : {};

if (isWindow) {
  const newParams = Object.assign({}, params);
  delete newParams.storeKey;
  const newQueryString = Object.keys(newParams)
    .filter(k => k)
    .map(k => `${k}=${newParams[k]}`)
    .join('&');
  window.history.replaceState(
    {},
    null,
    `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }${window.location.pathname}${newQueryString ? '?' : ''}${newQueryString}${
      window.location.hash
    }`,
  );
}

export default params;

// Boolean for setting whether to display dictionarty key values in place of dictionary text.
export const showMeTheKeys = isWindow && window.location.search.includes('showMeTheKeys');

// Boolean for skipping all dictionary changes.
export const showMeTheOriginals = isWindow && window.location.search.includes('showMeTheOriginals');
