import React from 'react';

import * as Styled from './DonationBanner.styles';
import enUsDictionary from './DonationBanner.dictionary.en-US';

import { useScrollPosition } from '+/utils/useScrollPosition';
import getWindowSize from '+/utils/getWindowSize';
import { ICONS } from '+/utils/constants/icons';
import { addToDictionary, t } from '+/utils/textDictionary';
import { ColorTheme } from '+/components/ColorTheme';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

addToDictionary(enUsDictionary);

// Used to determine if the donation banner image is on the screen or not
const checkIfInView = (positionY, windowHeight) => positionY - windowHeight >= -windowHeight;

export const DonationBanner = ({
  donationBannerData,
}: {
  donationBannerData: DonationBannerComponent;
}) => {
  const [donationAmount, setDonationAmount]: [
    number,
    React.Dispatch<React.SetStateAction<number>>,
  ] = React.useState(donationBannerData.defaultDonationAmount);

  const [isRecurringDonation, setIsRecurringDonation]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = React.useState(donationBannerData.recurringDonationOption.startsWith('recurring'));

  // Converts the users input field into a integer with a floor of 0 and no ceiling then sets the donation amount.
  const handleDonationAmountInputChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setDonationAmount(
      Math.max(Number.parseInt((event.target as HTMLInputElement).value, 10) || 0, 0),
    );
  };

  // Toggles whether this is a monthly donation or not.
  const toggleRecurringDonationChange = (): void => {
    setIsRecurringDonation(prevIsRecurringDonation => !prevIsRecurringDonation);
  };

  // This attaches the relevant query parameters to the donation URL.
  const donationUrlWithParams: string = React.useMemo((): string => {
    // Need to pass in a base url (2nd arg) in case this is a relative link
    let donationUrlString = '';
    if (typeof window !== 'undefined') {
      const donationUrl = new URL(donationBannerData.donationPageUrl, window.location.origin);
      donationUrl.searchParams.set('amt', `${donationAmount}`);
      donationUrl.searchParams.set('r', isRecurringDonation ? 'true' : 'false');
      donationUrlString = donationUrl.toString();
    }
    return donationUrlString;
  }, [donationBannerData.donationPageUrl, donationAmount, isRecurringDonation]);

  const [donationImageInView, setDonationImageInView]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = React.useState(
    checkIfInView(typeof window !== 'undefined' ? window.scrollY : 0, getWindowSize().height),
  );
  const donationImageRef = React.useRef();

  // Fade in donation image when within view range
  useScrollPosition(
    ({ currPos }) => setDonationImageInView(checkIfInView(currPos.y, getWindowSize().height)),
    [setDonationImageInView],
    donationImageRef,
  );

  return (
    <ColorTheme colorTheme={ColorThemeNames.YellowFilledGreyAccented}>
      <Styled.DonationBanner>
        {donationBannerData.image && (
          <Styled.ImageItemWrapper ref={donationImageRef} inView={donationImageInView}>
            <Styled.ImageItem imageItemData={donationBannerData.image} />
          </Styled.ImageItemWrapper>
        )}
        <Styled.Markdown>{donationBannerData.donationBannerMarkdown}</Styled.Markdown>

        <Styled.DonationSection>
          <Styled.DonationInputContainer>
            <Styled.DonationInputWrapper>
              {t('DonationBanner.CurrencySymbol')}
              <Styled.DonationAmountInput
                value={`${donationAmount}`}
                onChange={handleDonationAmountInputChange}
                type="number"
                min="0"
                max="999999"
                step="0"
              />
              <Styled.DonationAmountInterval isRecurringDonation={isRecurringDonation}>
                {t('DonationBanner.MonthlySuffix')}
              </Styled.DonationAmountInterval>
            </Styled.DonationInputWrapper>
            <Styled.DonationSubmitLink href={donationUrlWithParams}>
              <Styled.DonationSubmitButton
                color={ColorThemeNames.RedFilled}
                endIcon={ICONS.CARET_RIGHT}
                narrow
              >
                {donationBannerData.submitButtonText}
              </Styled.DonationSubmitButton>
            </Styled.DonationSubmitLink>
          </Styled.DonationInputContainer>

          {
            /* eslint-disable indent */
            donationBannerData.recurringDonationOption.includes('recurring') &&
              donationBannerData.recurringDonationOption.includes('one_time') && (
                <label>
                  <Styled.RecurringDonationCheckbox
                    type="checkbox"
                    checked={isRecurringDonation}
                    onChange={toggleRecurringDonationChange}
                  />
                  <Styled.RecurringDonationCheckboxLabelText>
                    {t('DonationBanner.MonthlyGiftText')}
                  </Styled.RecurringDonationCheckboxLabelText>
                </label>
              )
            /* eslint-enable indent */
          }
        </Styled.DonationSection>
      </Styled.DonationBanner>
    </ColorTheme>
  );
};
