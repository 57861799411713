import React from 'react';
import { t } from '+/utils/textDictionary';
import * as Styled from './PaymentMethodSelector.styles';
import payPalLogo from '+/images/paymentMethods/paypal.svg';
import applePayLogo from '+/images/paymentMethods/applepay.svg';
import analyticsEvent from '+/utils/analyticsEvent';

// Provides tabs to toggle between payment methods on the final step.
export const PaymentMethodSelector = ({ controller }) => {
  // Get available payment methods
  const applePayAvailable = controller.applePayAvailable();
  const bankAvailable = controller.bankAvailable();
  const creditCardAvailable = controller.creditCardAvailable();
  const payPalAvailable = controller.payPalAvailable();

  // Get selected payment methods
  const applePaySelected = controller.applePaySelected();
  const bankSelected = controller.bankSelected();
  const creditCardSelected = controller.creditCardSelected();
  const payPalSelected = controller.payPalSelected();

  // Waiting for the controller to update and hide an element is slow.
  // This speeds up the process using css using a css selector (in tab sass file).
  const toggleClasses = () => {
    document
      .querySelector('#PaymentMethodTabRow')
      .classList.remove(`${controller.bankSelected() ? 'credit-card' : 'bank'}-selected`);
    if (controller.isUS()) {
      document
        .querySelector('#PaymentMethodTabRow')
        .classList.add(`${controller.bankSelected() ? 'bank' : 'credit-card'}-selected`);
    }
  };

  const selectCreditCard = e => {
    e.preventDefault();
    controller.setPaymentMethod('creditcard');
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Choose Pay With Credit Card',
      simpleName: 'Select pay with credit card',
    });
    toggleClasses();
  };

  const selectBankAccount = e => {
    e.preventDefault();
    controller.setPaymentMethod('eft');
    controller.transactionFee = controller.getTransactionFee();
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Choose Pay With Bank',
      simpleName: 'Select pay with bank',
    });
    controller.setTransactionFee(false);
    toggleClasses();
  };

  const selectPayPal = e => {
    e.preventDefault();
    controller.setPaymentMethod('paypal');
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Choose Pay With PayPal',
      simpleName: 'Select pay with PayPal',
    });
  };

  const selectApplePay = e => {
    e.preventDefault();
    controller.setPaymentMethod('applepay');
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Choose Pay With ApplePay',
      simpleName: 'Select pay with ApplePay',
    });
  };

  if (
    [creditCardAvailable, bankAvailable, payPalAvailable, applePayAvailable].filter(Boolean)
      .length < 2 &&
    !payPalAvailable &&
    !applePayAvailable
  ) {
    return null;
  }
  return (
    <Styled.PaymentSelectorWrapper>
      <Styled.PaymentSelectorLabel>
        {t('Widget.PaymentMethodSelector.PayWith')}
      </Styled.PaymentSelectorLabel>
      <Styled.PaymentButtonGroup>
        {creditCardAvailable && (
          <Styled.PaymentButton
            id="CCSelectButton"
            selected={creditCardSelected}
            onClick={e => selectCreditCard(e)}
            data-text={t('Widget.PaymentMethodSelector.CreditCard')}
            aria-label={
              creditCardSelected
                ? `${t('Widget.PaymentMethodSelector.CreditCard')} – ${t(
                    'Widget.PaymentMethodSelector.Selected',
                  )}`
                : `${t('Widget.PaymentMethodSelector.PayWith')} ${t(
                    'Widget.PaymentMethodSelector.CreditCard',
                  )}`
            }
          >
            {t('Widget.PaymentMethodSelector.CreditCard')}
          </Styled.PaymentButton>
        )}
        {bankAvailable && (
          <Styled.PaymentButton
            id="BankSelectButton"
            selected={bankSelected}
            onClick={e => selectBankAccount(e)}
            data-text={t('Widget.PaymentMethodSelector.Bank')}
            aria-label={
              bankSelected
                ? `${t('Widget.PaymentMethodSelector.Bank')} – ${t(
                    'Widget.PaymentMethodSelector.Selected',
                  )}`
                : `${t('Widget.PaymentMethodSelector.PayWith')} ${t(
                    'Widget.PaymentMethodSelector.Bank',
                  )}`
            }
          >
            {t('Widget.PaymentMethodSelector.Bank')}
          </Styled.PaymentButton>
        )}
        {payPalAvailable && (
          <Styled.PaymentButton
            id="PayPalSelectButton"
            selected={payPalSelected}
            onClick={e => selectPayPal(e)}
            aria-label={
              payPalSelected
                ? `${t('Widget.PaymentMethodSelector.PayPal')} – ${t(
                    'Widget.PaymentMethodSelector.Selected',
                  )}`
                : `${t('Widget.PaymentMethodSelector.PayWith')} ${t(
                    'Widget.PaymentMethodSelector.PayPal',
                  )}`
            }
          >
            <img src={payPalLogo} alt="PayPal" loading="lazy" />
          </Styled.PaymentButton>
        )}
        {applePayAvailable && (
          <Styled.PaymentButton
            id="ApplePaySelectButton"
            selected={applePaySelected}
            onClick={e => selectApplePay(e)}
            aria-label={
              applePaySelected
                ? `${t('Widget.PaymentMethodSelector.ApplePay')} – ${t(
                    'Widget.PaymentMethodSelector.Selected',
                  )}`
                : `${t('Widget.PaymentMethodSelector.PayWith')} ${t(
                    'Widget.PaymentMethodSelector.ApplePay',
                  )}`
            }
          >
            <img src={applePayLogo} alt="Apple Pay" loading="lazy" />
          </Styled.PaymentButton>
        )}
      </Styled.PaymentButtonGroup>
    </Styled.PaymentSelectorWrapper>
  );
};
