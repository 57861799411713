import styled, { css } from 'styled-components';

import { Button as ButtonComponent } from '+/components/Button';
import { Link as LinkComponent } from '+/components/Link';

export const Link = styled(LinkComponent)`
  &,
  &:visited,
  &:link,
  &:hover,
  &:active {
    text-decoration: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 75%;
    margin-right: 12px;
  }
`;

export const Button = styled(ButtonComponent)`
  min-width: 0;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 100%;
    line-height: initial;

    border: 1px solid var(--color-white);

    &::after {
      display: none;
    }
  }
`;

interface StickyCallToActionProps {
  isShown?: boolean;
}

export const StickyCallToAction = styled.div<StickyCallToActionProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  // This should be above most elements/modals and below the cookie notice
  z-index: var(--z-index-pinned-or-sticky-call-to-action);

  height: 6em;

  background: var(--color-theme-base);
  color: var(--color-theme-base-inverse);

  font-size: 1.0625rem;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  transition: transform 0.6s ease-in-out, opacity 0.6s;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    top: 0;
    bottom: unset;

    height: 60px;

    background: var(--color-theme-accent);
    color: var(--color-theme-accent-inverse);

    font-size: 0.8125rem;
  }

  ${props =>
    props.isShown
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          transform: translateY(100%);

          @media (max-width: ${props.theme.breakpoints.md}) {
            transform: translateY(-100%);
          }
        `}
`;

export const Logo = styled.div`
  width: 28px;
  height: 28px;

  background: var(--image-heart-pulse);
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    filter: brightness(0) invert(1);
  }
`;

export const Text = styled.div`
  flex: 1 1 0;

  max-width: fit-content;

  padding-left: 25px;
  padding-right: 50px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding-right: 1em;
    padding-left: 1.2em;
  }
`;
