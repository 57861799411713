import React from 'react';

import { Button } from '../Button';

import * as Styled from './FormError.styles';
import enUsDictionary from './FormError.dictionary.en-US';

import { addToDictionary, t } from '+/utils/textDictionary';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

addToDictionary(enUsDictionary);

export const FormError = ({ handleRetry }: FormErrorComponent) => (
  <Styled.ErrorContainer>
    <span>{t('FormError.ErrorMessage')}</span>
    <Button onClick={handleRetry} color={ColorThemeNames.RedFilled} narrow>
      {t('FormError.Retry')}
    </Button>
  </Styled.ErrorContainer>
);
