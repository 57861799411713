import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './ExpandableText.styles';

import { Icon } from '+/components/Icon';
import { ICONS } from '+/utils/constants/icons';

interface ExpandableTextProps {
  children: React.ReactNode;
  label: string;
}

export const ExpandableText = (props: ExpandableTextProps) => {
  const { children, label } = props;
  const [expandText, setExpandText] = useState(false);

  return (
    <Styled.Wrapper>
      <AnimatePresence>
        {!expandText && (
          <Styled.Trigger
            key="expanded-text-trigger"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={() => setExpandText(true)}
          >
            <Icon path={ICONS.CARET_RIGHT.path} viewBox={ICONS.CARET_RIGHT.viewBox} color="var(--color-secondary-500)" size={12} />
            <Styled.TriggerText>{label}</Styled.TriggerText>
          </Styled.Trigger>
        )}
        {expandText && (
          <Styled.ExpandedText
            key="expanded-text"
            initial={{ height: '1px' }}
            animate={{ height: 'auto' }}
            transition={{ delay: 0.2, duration: 0.25 }}
          >
            {children}
          </Styled.ExpandedText>
        )}
      </AnimatePresence>
    </Styled.Wrapper>
  );
};
