import React from 'react';
import * as Styled from '+/styles/styledComponents/Form.styles';

interface EveryActionCustomAdvocacyFieldProps {
  title: string; // The label text
  name: string; // The input value
  subject: string;
  isTouched: boolean;
  error?: string;
  value: string;
  handleBlur: () => void;
  handleChange: () => void;
  messages: {
    readonly: boolean;
    value: string;
    intro: string;
    outro: string;
  };
  className?: string;
}

export const EveryActionCustomAdvocacyField = ({
  title,
  name,
  subject,
  messages,
  error,
  value,
  isTouched,
  handleChange,
  handleBlur,
  className,
}: EveryActionCustomAdvocacyFieldProps) => {
  return (
    <div className={className}>
      <Styled.InputLabel floating errored={isTouched && !!error}>
        <Styled.TextyInput
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={title}
        />
        <Styled.InputLabelText floating>{title}</Styled.InputLabelText>
        {isTouched && <Styled.InputError>{error}</Styled.InputError>}
      </Styled.InputLabel>
    </div>
  );
};
