import React from 'react';

interface EveryActionCustomFieldsetProps {
  title: string; // The label text
  children: React.ReactNode;
}

export const EveryActionCustomFieldset = ({ title, children }) => {
  return <div>{children}</div>;
};
