import React from 'react';

import * as Styled from './Icon.styles';

export const Icon = ({
  color,
  path,
  size = 44,
  alt,
  titleId,
  viewBox = '0 0 88 88',
  className,
  transform,
  inline,
  ...props
}: IconProps) => (
  <Styled.SVG
    version="1.1"
    viewBox={viewBox}
    role="img"
    width={size}
    height={size}
    color={color}
    inline={inline}
    aria-labelledby={titleId}
    {...props}
    className={className}
  >
    {alt && <title id={titleId}>{alt}</title>}
    <path clipRule="evenodd" fillRule="evenodd" d={path} style={{ transform }} />
  </Styled.SVG>
);

Icon.defaultProps = {
  inline: false,
  transform: undefined,
};
