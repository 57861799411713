import { useLocation } from '@gatsbyjs/reach-router';

const REPLACEMENT_KEYS = ['firstName', 'lastName', 'donationAmount', 'emailAddress'];

export const replaceStringWithLocationState = (text: string = null): string | null => {
  const location = useLocation();

  // Return  if no text string is provided
  if (!text) {
    return;
  }

  if (!location.state) {
    return text;
  }

  let replacedText = text;

  REPLACEMENT_KEYS.forEach(key => {
    const regex = new RegExp(`%${key}`, 'g');
    const locationStateValue = location?.state[key];
    // If key exists in text string, but not in location state return empty string
    if (regex.test(replacedText) && !location?.state[key]) {
      return '';
    }
    replacedText = replacedText.replace(regex, locationStateValue);
  });

  return replacedText;
};
