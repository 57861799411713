import React from 'react';
import { Truncate } from '+/components/Truncate';

interface MarkdownTruncateProps {
  children: JSX.Element;
  moreText?: string;
  lessText?: string;
  initialHeight?: number;
  isCollapsable?: boolean;
}

export const MarkdownTruncate = (props: MarkdownTruncateProps) => {
  return <Truncate {...props} />;
};
