import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { AnimatePresence, motion } from 'framer-motion';

import * as Styled from './Tooltip.styles';

import { Icon } from '+/components/Icon';
import { ICONS } from '+/utils/constants/icons';

interface TooltipProps {
  teaser: string;
  content: string;
  inline?: boolean;
}

export const Tooltip = ({ teaser, content, inline }: TooltipProps) => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [yContentPosition, setYContentPosition] = useState('bottom');
  const [xContentPosition, setXContentPosition] = useState('right');

  const positionTooltipContent = (target: HTMLElement): void => {
    const viewportOffset = target.getBoundingClientRect();
    target.focus();
    setYContentPosition(viewportOffset.top > window.innerHeight / 2 ? 'top' : 'bottom');
    setXContentPosition(viewportOffset.left > window.innerWidth / 2 ? 'right' : 'left');
  };

  const toggleTooltipContent = (
    e: React.MouseEvent | React.FocusEvent | React.TouchEvent,
  ): void => {
    if (!isContentVisible) {
      positionTooltipContent(e.target as HTMLElement);
    }
    setIsContentVisible(!isContentVisible);
  };

  const hideTooltipContent = (): void => {
    setIsContentVisible(false);
  };

  const showTooltipContent = (e: React.MouseEvent | React.FocusEvent | React.TouchEvent): void => {
    positionTooltipContent(e.target as HTMLElement);
    setIsContentVisible(true);
  };

  return (
    <Styled.Tooltip inline={inline} active={isContentVisible}>
      <Styled.Trigger
        aria-label={teaser}
        tabIndex={0}
        onFocus={showTooltipContent}
        onBlur={hideTooltipContent}
        onClick={e => e.preventDefault()}
        onMouseOver={showTooltipContent}
        onMouseOut={hideTooltipContent}
        onTouchStart={e => toggleTooltipContent(e)}
      >
        <Icon
          path={ICONS.INFO.path}
          viewBox={ICONS.INFO.viewBox}
          transform={ICONS.INFO.transform}
          size={20}
        />
      </Styled.Trigger>
      <AnimatePresence>
        {isContentVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              bounce: 0,
              duration: 0.3,
            }}
          >
            <Styled.Content
              xContentPosition={xContentPosition}
              yContentPosition={yContentPosition}
              onTouchStart={e => {
                e.preventDefault();
                hideTooltipContent();
              }}
            >
              <ReactMarkdown>{content}</ReactMarkdown>
            </Styled.Content>
          </motion.div>
        )}
      </AnimatePresence>
    </Styled.Tooltip>
  );
};

Tooltip.defaultProps = {
  inline: false,
};
