import React, { useState } from 'react';

interface UserDataInterface {
  prefix?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  emailAddress?: string;
  donationAmount?: string;
  country?: string;
  postalCode?: string;
  mobilePhone?: string;
  acceptedPrivacyPolicy?: boolean;
  smsSubscribeMobilePhone?: boolean;
  yesSignMeUpForUpdatesForBinder?: boolean;
}

export interface UserContextInterface {
  userData?: UserDataInterface;
  setUserData?: (value: UserDataInterface) => void;
}

export const UserContext = React.createContext<UserContextInterface>({
  userData: null,
  setUserData: null,
});

export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState({});

  const contextValue: UserContextInterface = React.useMemo(
    () => ({
      userData,
      setUserData,
    }),
    [userData],
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
