import React from 'react';

import { EveryActionCustomElementMap } from './EveryActionCustomElementMap.component';
import { EveryActionCustomSubmitButton } from './EveryActionCustomElement';

import * as FormStyled from '+/styles/styledComponents/Form.styles';
import * as Styled from './CustomEveryActionForm.styles';

import { FormHeader } from '+/components/FormHeader';
import { Markdown } from '+/components/Markdown';
import { ColorTheme } from '+/components/ColorTheme';
import { replaceStringWithLocationState } from '+/utils/replaceStringWithLocationState';
import { t } from '+/utils/textDictionary';

interface CustomEveryActionFormProps {
  formElements: any;
  customEveryActionFormData: CustomEveryActionFormComponent;
  suppliedOptions: any;
  handleSubmit: () => void;
  isSubmitted: boolean;
  isValid: boolean;
  values: any;
  errors: any;
  touched: any;
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
}

export const CustomEveryActionForm = ({
  formElements,
  customEveryActionFormData,
  suppliedOptions,
  isSubmitted,
  ...formikMethods
}: CustomEveryActionFormProps) => {
  const { border, customCss, handlePreSubmit, headerTitle, headerCopy, submitText, colorTheme } =
    customEveryActionFormData;

  const formattedHeaderTitle = replaceStringWithLocationState(headerTitle);
  const formattedHeaderCopy = replaceStringWithLocationState(headerCopy);

  return (
    <ColorTheme colorTheme={colorTheme}>
      <FormStyled.Container customCss={customCss}>
        <Styled.GlobalFastActionSignUpModalStyles />
        <FormHeader
          className="cstm-form__header"
          headerTitle={<h2>{formattedHeaderTitle}</h2>}
          headerCopy={<Markdown>{formattedHeaderCopy}</Markdown>}
          padded
        />
        <FormStyled.Body className="cstm-form__body">
          <FormStyled.Section className="cstm-form__section" border={border}>
            <div className="cstm-form__form">
              {isSubmitted ? (
                <Styled.FormThanks className="cstm-thanks-message">
                  {t('CustomForm.ThankYou')}
                </Styled.FormThanks>
              ) : (
                <>
                  {formElements.map(e =>
                    EveryActionCustomElementMap(e, formikMethods, suppliedOptions),
                  )}
                  <EveryActionCustomSubmitButton
                    buttonText={submitText || 'Submit'}
                    handleSubmit={formikMethods.handleSubmit}
                    handlePreSubmit={handlePreSubmit}
                    isDisabled={!formikMethods.isValid}
                  />
                </>
              )}
            </div>
          </FormStyled.Section>
        </FormStyled.Body>
      </FormStyled.Container>
    </ColorTheme>
  );
};
