import styled from 'styled-components';

export const VideoEmbed = styled.div`
  width: 100%;
  max-width: var(--main-container-width);
  margin: auto;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &:not(:first-child) {
    margin-top: 3rem;
  }
`;
