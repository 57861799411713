import styled from 'styled-components';

import { HighlightHeading as HighlightHeadingComponent } from '+/components/HighlightHeading';

export const HighlightHeading = styled(HighlightHeadingComponent)``;

export const Intro = styled.p`
  width: 100%;
  max-width: 1400px;
  padding: 2em;
  line-height: unset;

  color: var(--color-white);

  text-align: center;

  &,
  ${HighlightHeading} {
    font-size: clamp(0.875rem, min(calc(3vw + 0.75vh), 3.75vh), 2.7625rem);
    font-family: var(--font-secondary);
    font-weight: 700;
  }

  ${HighlightHeading} {
    line-height: 1;
  }
`;
