let aBTestId: string = null;
let variant: number = null;
let isActive: boolean = null;
const EVENT_ENDPOINT = `${process.env.DIRECTUS_URL || 'http://localhost:8055'}/ab_test_events`;
const skipAnalytics = (
  typeof document === 'undefined'
    ? 'true'
    : document.cookie.split('; ').find(row => row.startsWith('skip_thl_analytics=')) || ''
).split('=')[1];

// Hash date for unique session code
const hashCode = str => {
  var h = 0,
    l = str.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + str.charCodeAt(i++)) | 0;
  return String(Math.abs(h));
};

const sessionId: string = hashCode(JSON.stringify(new Date()));
let returnVisit;

const checkIfActive = (startAt: Date, endAt: Date): void => {
  const afterStart = !startAt || new Date() > new Date(startAt);
  const beforeEnd = !endAt || new Date() < new Date(endAt);
  isActive = afterStart && beforeEnd;
};

const alreadySetup = {};

export const setup = (testId: string, startAt: Date, endAt: Date): void => {
  const variantKey = `variant_${testId}`;
  if (alreadySetup[variantKey]) return;
  aBTestId = testId;
  checkIfActive(startAt, endAt);

  if (localStorage.getItem(variantKey)) {
    variant = parseInt(localStorage.getItem(variantKey), 10);
    returnVisit = true;
  } else {
    variant = Math.ceil(Math.random() * 2);
    returnVisit = false;
    localStorage.setItem(variantKey, String(variant));
  }
  alreadySetup[variantKey] = true;
};

export const getVariant = (): number => {
  if (!isActive) return 1;
  return variant;
};

export const postEvent = (eventDetails): void => {
  if (skipAnalytics || !isActive) return;
  const { eventName, eventValue, context } = eventDetails;

  fetch(EVENT_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      event_name: eventName,
      event_value: eventValue,
      context: context,
      ab_tests_id: aBTestId,
      variant,
      session_id: sessionId,
      return_visit: returnVisit,
    }),
  });
};
