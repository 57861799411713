import { useState, useEffect } from 'react';

export const useEveryActionFormData = (formId: string): EveryActionFormData => {
  const [formHelpers, setFormHelpers] = useState({
    formElements: [],
    submissionUrl: '',
    suppliedOptions: {},
    formType: '',
    advocacyFields: {
      message: { readonly: false, value: '' },
      intro: '',
      outro: '',
      subject: { readonly: false, value: '' },
    },
  });

  useEffect(() => {
    (async function fetchAndSet() {
      const URL = `https://secure.everyaction.com/v2/Forms/${formId}`;
      const result = await fetch(URL);
      const json = await result.json();
      const {
        form_elements: formElementsFromEA,
        metadata,
        submissionUrl: submissionUrlFromEA,
        type: formType,
      } = json;

      const randomizedMessageNumber = Math.floor(Math.random() * 100);

      const advocacyFields =
        formElementsFromEA && formElementsFromEA.find(a => a.name === 'AdvocacyFields')
          ? formElementsFromEA.find(a => a.name === 'AdvocacyFields').messages
          : [{}];

      const advocacyFieldValues = advocacyFields[randomizedMessageNumber % advocacyFields.length];

      setFormHelpers({
        formElements: formElementsFromEA,
        submissionUrl: submissionUrlFromEA,
        suppliedOptions: (metadata && metadata.options) || {},
        advocacyFields: advocacyFieldValues,
        formType,
      });
    })();
  }, []);

  return formHelpers;
};
