import styled, { css } from 'styled-components';

import { heartBeatAnimationCSS } from '+/styles/reusable/animations';

export const ICON_SIZE = 41;

export const Progress = styled.div<{ animationDuration?: number }>`
  --progress-animation-duration: ${props => props.animationDuration || 1}s;
  color: var(--color-theme-base-inverse);
  position: relative;
  margin: 2.5rem 0;
  line-height: 1;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin: 2.65rem 0;
  }
`;

export const ProgressHr = styled.hr`
  width: 100%;
  height: 5px;
  background: var(--color-theme);
  border: none;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: 8px;
  }
`;

export const ProgressBar = styled.div`
  position: relative;
  padding-top: ${ICON_SIZE / 2}px;
  width: 100%;
  height: calc(${ICON_SIZE}px + 2.5625rem);
`;

export const ProgressBarPlaceholder = styled.div`
  width: 100%;
  height: 8px;
  background: var(--color-neutral-300);
  border-radius: 0.25rem;
  margin-top: -0.25rem;
`;

export const ProgressBarCompleted = styled(ProgressBarPlaceholder)<{ percentComplete?: number }>`
  width: ${props => props.percentComplete || 0}%;
  background: var(--color-theme);
  transition: width var(--progress-animation-duration) var(--ease-out-expo);
`;

export const ProgressIndicator = styled.div<{
  percentComplete?: number;
  isIndicatorBelowMinimumThreshold: boolean;
  indicatorTextAlign: string;
}>`
  position: absolute;
  text-align: ${props => props.indicatorTextAlign};
  left: ${props => (props.isIndicatorBelowMinimumThreshold ? 0 : props.percentComplete)}%;
  top: 0;
  transition: all var(--progress-animation-duration) var(--ease-out-expo);

  ${props =>
    props.indicatorTextAlign === 'center' &&
    css`
      transform: translateX(-50%);
    `}
  ${props =>
    props.indicatorTextAlign === 'right' &&
    css`
      transform: translateX(-100%);
    `}
`;

export const ProgressIndicatorIcon = styled.div<{
  isIndicatorBelowMinimumThreshold: boolean;
}>`
  background: var(--color-theme);
  border-radius: 50%;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  box-shadow: 0 0 0 0.125rem var(--color-theme-inverse);
  color: var(--color-theme-inverse);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    /* margin: 6% 0 0 6%; */
    width: 75%;
    animation: ${heartBeatAnimationCSS};
    transform-origin: 50% 50%;
    will-change: transform;
    -webkit-backface-visibility: hidden;
  }

  path {
    fill: currentColor;
  }
`;

export const ProgressTotal = styled.div<{
  isGoalVisible: boolean;
}>`
  position: absolute;
  text-align: right;
  right: 0;
  padding-top: calc(${ICON_SIZE}px + 0.1rem);
  top: 0;
  opacity: ${props => (props.isGoalVisible ? 0 : 0.5)};
  transition: opacity var(--progress-animation-duration) var(--ease-out-expo);
`;

export const ProgressLabel = styled.div`
  font-weight: 700;
  font-family: var(--font-secondary);
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0.75rem 0 0.35rem;
`;

export const ProgressValue = styled.div`
  font-style: italic;
  font-size: 1rem;
  font-family: var(--font-tertiary);
`;
