import styled, { css } from 'styled-components';

export const TextHighlight = styled.mark<{ font: string }>`
  background-color: transparent;
  color: inherit;
  ${props =>
    props.font === 'tertiary' &&
    css`
      font-family: var(--font-tertiary);
      font-style: italic;
      text-transform: none;
      letter-spacing: -0.05em;
    `}
  ${props =>
    props.font === 'primary' &&
    css`
      font-family: var(--font-primary);
    `}
  ${props =>
    props.font === 'secondary' &&
    css`
      font-family: var(--font-secondary);
    `}
`;

export const TextBlock = styled.span<{
  delay: number;
  duration: number;
  isHighlighted: boolean;
}>`
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  background-image: linear-gradient(var(--color-theme-accent) 0%, var(--color-theme-accent) 100%);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  transition: background-size ${props => props.duration}s,
    color ${props => props.duration + props.duration / 4}s;
  transition-delay: ${props => props.delay}s;
  padding: 0.025em 0 0.05em;
  line-height: 1.4;

  ${props =>
    props.isHighlighted &&
    css`
      background-color: transparent;
      background-size: 100% 100%;
      color: var(--color-theme-accent-inverse);
    `}
`;

export const Word = styled.span`
  padding: 0 0.4em;
  margin: 0 -0.2em;
`;
