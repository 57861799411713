import styled, { css } from 'styled-components';

interface Props {
  small?: boolean;
}

export const Button = styled.button<Props>`
  position: relative;
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-family: var(--font-secondary);
  font-size: ${props => (props.small ? '0.875rem' : '1.063rem')};
  padding: 0;
  border-bottom: 3px solid var(--color-neutral-400);
  color: var(--color-neutral-500);

  &:hover {
    color: var(--color-neutral-600);
    border-color: var(--color-neutral-500);
  }

  &:focus {
    outline: none;
    border-color: var(--color-primary-500);
  }
`;
