import * as yup from 'yup';

import { t } from '+/utils/textDictionary';
import { urlPattern } from '+/utils/sanitizeFormInputs';

const checkForUrls = () => ({
  name: 'urlSanitized',
  message: ({ label }) => t('EAPetitionForm.URLSanitized', { label: label.toLowerCase() }),
  test: value => !urlPattern.test(value),
});

const validator = isAdvocacyForm => {
  const validationMapping = {
    Prefix: yup.string().label(t('EAPetitionForm.Prefix')).test(checkForUrls()),
    FirstName: yup.string().label(t('EAPetitionForm.FirstName')).test(checkForUrls()).required(),
    MiddleName: yup.string().label(t('EAPetitionForm.MiddleName')).test(checkForUrls()),
    LastName: yup.string().label(t('EAPetitionForm.LastName')).test(checkForUrls()).required(),
    Suffix: yup.string().label(t('EAPetitionForm.Suffix')).test(checkForUrls()),
    EmailAddress: yup.string().email().label(t('EAPetitionForm.EmailAddress')).required(),
    MobilePhone: yup.string().label(t('EAPetitionForm.MobilePhone')).test(checkForUrls()),
    PostalCode: yup
      .string()
      .label(t('EAPetitionForm.PostalCode'))
      .when('Country', {
        is: 'US',
        then: yup.string().required(),
      })
      .test(checkForUrls()),
    Country: yup.string().required().label(t('EAPetitionForm.Country')),
    YesSignMeUpForUpdatesForBinder: yup.boolean().label(t('EAPetitionForm.InfoConsent')),
    SmsSubscribeMobilePhone: yup.boolean().label(t('EAPetitionForm.InfoConsentSms')),
    AcceptedPrivacyPolicy: yup.boolean().when('Country', {
      is: 'US',
      otherwise: yup
        .boolean()
        .label(
          `${t('EAPetitionForm.AcceptPrivacyPolicyPrefix')} ${t(
            'EAPetitionForm.AcceptPrivacyPolicyLinkText',
          )}`,
        )
        .required(t('EAPetitionForm.AcceptPrivacyPolicyErrorNotChecked'))
        .isTrue(t('EAPetitionForm.AcceptPrivacyPolicyErrorNotChecked')),
    }),
  };

  if (isAdvocacyForm) {
    validationMapping.Message0 = yup.string().min(6).required().label(t('EAAdvocacyForm.Message'));
    validationMapping.Subject0 = yup.string().min(6).required().label(t('EAAdvocacyForm.Subject'));
  }

  return yup.object().shape(validationMapping);
};

export default validator;
