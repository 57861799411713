import React from 'react';

import * as Styled from './Footer.styles';

export const Footer = ({ footerData }: { footerData: FooterComponent }) => (
  <Styled.Footer>
    <Styled.SocialLinks>
      {footerData.socialLinks.map(linkItem => (
        <Styled.SocialLink href={linkItem.uRL} key={linkItem.id} target="_blank" rel="noopener">
          <Styled.SocialIcon iconName={linkItem.icon} iconProps={{ alt: linkItem.label }} />
        </Styled.SocialLink>
      ))}
    </Styled.SocialLinks>
    <Styled.FooterLinks>
      {footerData.footerLinks.map(linkItem => (
        <Styled.FooterLink href={linkItem.uRL} key={linkItem.id}>
          {linkItem.label}
        </Styled.FooterLink>
      ))}
    </Styled.FooterLinks>
    <Styled.Copyright>{footerData.copyright}</Styled.Copyright>
  </Styled.Footer>
);
