import React from 'react';

import { FormSequence } from '+/components/FormSequence';
import { ABTest } from '+/components/ABTest';
import { MediaWithForm } from '+/components/MediaWithForm';
import { SocialIconGroup } from '+/components/SocialIconGroup';
import { Hero } from '+/components/Hero';
import { StickyCallToAction } from '+/components/StickyCallToAction';
import { PinnedCallToAction } from '+/components/PinnedCallToAction';
import { ImageWithText } from '+/components/ImageWithText';
import { Text } from '+/components/Text';
import { TwoColumnLayout } from '+/components/TwoColumnLayout';
import { LogoTextAndButton } from '+/components/LogoTextAndButton';
import { PullQuote } from '+/components/PullQuote';
import { DonationBanner } from '+/components/DonationBanner';
import { USDAViolationsDisplay } from '+/components/USDAViolationsDisplay';
import { CustomEveryActionForm } from '+/components/CustomEveryActionForm';
import { TrackedSweetenerForm } from '+/components/TrackedSweetenerForm';
import { MarkdownForm } from '+/components/MarkdownForm';
import { EveryActionAdvocacyForm } from '+/components/EveryActionAdvocacyForm';
import { BadgesWithText } from '+/components/BadgesWithText';
import { VideoWithTimeline } from '+/components/VideoWithTimeline';
import { Counter } from '+/components/Counter';
import { VideoEmbed } from '+/components/VideoEmbed';
import { TextOnMedia } from '+/components/TextOnMedia';

function isMediaWithForm(
  componentData: MappableComponent,
): componentData is MediaWithFormComponent {
  return componentData.__typename === 'Directus_media_with_forms';
}

function isABTest(componentData: MappableComponent): componentData is ABTestComponent {
  return componentData.__typename === 'Directus_ab_tests';
}

function isSocialIconGroup(
  componentData: MappableComponent,
): componentData is SocialIconGroupComponent {
  return componentData.__typename === 'Directus_social_icon_groups';
}

function isHero(componentData: MappableComponent): componentData is HeroComponent {
  return componentData.__typename === 'Directus_heros';
}

function isStickyCallToAction(
  componentData: MappableComponent,
): componentData is StickyCallToActionComponent {
  return componentData.__typename === 'Directus_sticky_call_to_actions';
}

function isImageWithText(
  componentData: MappableComponent,
): componentData is ImageWithTextComponent {
  return componentData.__typename === 'Directus_image_with_texts';
}

function isText(componentData: MappableComponent): componentData is TextItem {
  return componentData.__typename === 'Directus_text_items';
}

function isTwoColumnLayout(componentData: MappableComponent): componentData is TwoColumnLayout {
  return componentData.__typename === 'Directus_two_column_layout';
}

function isLogoTextAndButton(
  componentData: MappableComponent,
): componentData is LogoTextAndButtonComponent {
  return componentData.__typename === 'Directus_logo_text_and_buttons';
}

function isPinnedCallToAction(
  componentData: MappableComponent,
): componentData is PinnedCallToActionComponent {
  return componentData.__typename === 'Directus_pinned_call_to_actions';
}

function isPullQuote(componentData: MappableComponent): componentData is PullQuoteComponent {
  return componentData.__typename === 'Directus_pull_quotes';
}

function isDonationBanner(
  componentData: MappableComponent,
): componentData is DonationBannerComponent {
  return componentData.__typename === 'Directus_donation_banners';
}

function isUSDAViolationDisplay(
  componentData: MappableComponent,
): componentData is USDAViolationsDisplayComponent {
  return componentData.__typename === 'Directus_usda_violations_displays';
}

function isCustomEveryActionForm(
  componentData: MappableComponent,
): componentData is CustomEveryActionFormComponent {
  return componentData.__typename === 'Directus_custom_every_action_forms';
}

function isTrackedSweetenerForm(
  componentData: MappableComponent,
): componentData is TrackedSweetenerFormComponent {
  return componentData.__typename === 'Directus_tracked_sweetener_forms';
}

function isMarkdownForm(componentData: MappableComponent): componentData is MarkdownFormComponent {
  return componentData.__typename === 'Directus_markdown_forms';
}

function isEveryActionAdvocacyForm(
  componentData: MappableComponent,
): componentData is EveryActionAdvocacyFormComponent {
  return componentData.__typename === 'Directus_every_action_advocacy_forms';
}

function isBadgesWithText(
  componentData: MappableComponent,
): componentData is BadgesWithTextComponent {
  return componentData.__typename === 'Directus_badges_with_texts';
}

function isVideoWithTimeline(
  componentData: MappableComponent,
): componentData is VideoWithTimelineComponent {
  return componentData.__typename === 'Directus_video_with_timelines';
}

function isCounter(componentData: MappableComponent): componentData is CounterComponent {
  return componentData.__typename === 'Directus_counters';
}

function isVideoEmbed(componentData: MappableComponent): componentData is VideoEmbedComponent {
  return componentData.__typename === 'Directus_video_embeds';
}

function isTextOnMedia(componentData: MappableComponent): componentData is TextOnMediaComponent {
  return componentData.__typename === 'Directus_text_on_medias';
}

function isFormSequence(componentData: MappableComponent): componentData is FormSequenceComponent {
  return componentData.__typename === 'Directus_form_sequences';
}

export const ComponentMap = ({ componentData }: { componentData: MappableComponent }) => {
  if (isMediaWithForm(componentData)) {
    return <MediaWithForm mediaWithFormData={componentData} />;
  }
  if (isABTest(componentData)) {
    return <ABTest aBTestData={componentData} />;
  }
  if (isSocialIconGroup(componentData)) {
    return <SocialIconGroup socialIconGroupData={componentData} />;
  }
  if (isHero(componentData)) {
    return <Hero heroData={componentData} />;
  }
  if (isStickyCallToAction(componentData)) {
    return <StickyCallToAction stickyCallToActionData={componentData} />;
  }
  if (isPinnedCallToAction(componentData)) {
    return <PinnedCallToAction pinnedCallToActionData={componentData} />;
  }
  if (isImageWithText(componentData)) {
    return <ImageWithText imageWithTextData={componentData} />;
  }
  if (isText(componentData)) {
    return <Text textItemData={componentData} />;
  }
  if (isTwoColumnLayout(componentData)) {
    return <TwoColumnLayout twoColumnLayoutData={componentData} />;
  }
  if (isLogoTextAndButton(componentData)) {
    return <LogoTextAndButton logoTextAndButtonData={componentData} />;
  }
  if (isPullQuote(componentData)) {
    return <PullQuote pullQuoteData={componentData} />;
  }
  if (isDonationBanner(componentData)) {
    return <DonationBanner donationBannerData={componentData} />;
  }
  if (isUSDAViolationDisplay(componentData)) {
    return <USDAViolationsDisplay uSDAViolationsDisplayData={componentData} />;
  }
  if (isCustomEveryActionForm(componentData)) {
    return <CustomEveryActionForm customEveryActionFormData={componentData} />;
  }
  if (isTrackedSweetenerForm(componentData)) {
    return <TrackedSweetenerForm trackedSweetenerFormData={componentData} />;
  }
  if (isEveryActionAdvocacyForm(componentData)) {
    return <EveryActionAdvocacyForm everyActionAdvocacyFormData={componentData} />;
  }
  if (isMarkdownForm(componentData)) {
    return <MarkdownForm markdownFormData={componentData} />;
  }
  if (isBadgesWithText(componentData)) {
    return <BadgesWithText badgesWithTextData={componentData} />;
  }
  if (isVideoWithTimeline(componentData)) {
    return <VideoWithTimeline videoWithTimelineData={componentData} />;
  }
  if (isCounter(componentData)) {
    return <Counter counterData={componentData} />;
  }
  if (isVideoEmbed(componentData)) {
    return <VideoEmbed videoEmbedData={componentData} />;
  }
  if (isTextOnMedia(componentData)) {
    return <TextOnMedia textOnMediaData={componentData} />;
  }
  if (isFormSequence(componentData)) {
    return <FormSequence formSequenceData={componentData} />;
  }

  return null;
};
