import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

export const Markdown = styled(ReactMarkdown)`
  b,
  strong {
    font-weight: 700;
  }

  i,
  em {
    font-style: italic;
  }

  /*  Same vertical margin on all block level immediate children, and on descendant paragraphs */
  & > *:not(:last-child),
  p:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  ul,
  ol {
    padding-left: 1.67rem;
  }

  ul {
    list-style: square;
  }

  ol {
    list-style: decimal;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.1s ease-out;
    border-bottom: 0.125rem solid var(--color-theme-accent);

    &:hover:not(.action-tag) {
      color: var(--color-theme-accent-inverse);
      background: var(--color-theme-accent);
    }
  }
`;

export const HideOnMobile = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: contents;
  }
`;

export const HideOnTablet = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: contents;
  }
`;
