import React from 'react';
import * as Styled from '+/styles/styledComponents/Form.styles';

interface EveryActionCustomCheckboxProps {
  title: string; // The label text
  name: string; // The input value
  queryString: string;
  defaultValue: boolean;
  isRequired: boolean;
  isTouched: boolean;
  error?: string;
  value: boolean;
  handleBlur: () => void;
  setFieldValue: (key: string, value: boolean) => void;
  className?: string;
}

export const EveryActionCustomCheckbox = ({
  title,
  name,
  queryString,
  defaultValue,
  isRequired,
  isTouched,
  error,
  value,
  setFieldValue,
  handleBlur,
  className,
}: EveryActionCustomCheckboxProps) => {
  return (
    <Styled.CustomFieldRow className={className}>
      <Styled.CheckboxLabel>
        <Styled.Checkbox
          type="checkbox"
          name={name}
          value={!!value ? 1 : 0}
          onChange={() => {
            setFieldValue(name, !value);
          }}
          onBlur={handleBlur}
          checked={!!value}
        />
        <Styled.CheckboxLabelText>{title}</Styled.CheckboxLabelText>
        {isTouched && !!error && <Styled.InputError>{error}</Styled.InputError>}
      </Styled.CheckboxLabel>
    </Styled.CustomFieldRow>
  );
};
