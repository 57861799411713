import React from 'react';
import * as Styled from './FormNavigation.styles';
import { SubmitButton } from '+/components/DonateForm/widgets/submitButton.component';
import { SecurityTagline } from '+/components/SecurityTagline';
import { BackButton } from './BackButton.component';

interface Props {
  controller: EveryActionModelController;
  openModal(): void;
  upsellModalOptOut: boolean;
}

export const FinalSubmitButton = ({ controller, openModal, upsellModalOptOut }: Props) => {
  return (
    <Styled.Wrapper stacked={true}>
      <Styled.NextButtonWrapper>
        <SubmitButton
          controller={controller}
          openModal={openModal}
          upsellModalOptOut={upsellModalOptOut}
        />
      </Styled.NextButtonWrapper>
      <BackButton controller={controller} initialStep={false} stacked={true} />
      <Styled.SecurityWrapper>
        <SecurityTagline />
      </Styled.SecurityWrapper>
    </Styled.Wrapper>
  );
};
