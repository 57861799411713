import React from 'react';
import { Markdown } from '+/components/Markdown';
import * as Styled from './NotificationBanner.styles';

import { useShowWhenInScrollPercentage } from '+/utils/useShowWhenInScrollPercentage';

export const NotificationBanner = ({
  notificationBannerData: {
    notificationText,
    position,
    scrollPositionShow,
    scrollPositionHide,
  },
}: {
  notificationBannerData: NotificationBannerComponent;
}) => {
  const isVisible = useShowWhenInScrollPercentage(scrollPositionShow, scrollPositionHide);
  return (
    <Styled.NotificationBanner isVisible={isVisible} position={position}>
      <Styled.Text>
        <Markdown>{notificationText}</Markdown>
      </Styled.Text>
    </Styled.NotificationBanner>
  );
};
