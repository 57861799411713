const context = {
  minutesOnPage: 0,
};

setInterval(() => {
  context.minutesOnPage = context.minutesOnPage + 1;
}, 60 * 1000);

export const analyticsContext = {
  getContext: () => context,
  setContext: (key, value) => {
    context[key] = value;
  },
};
