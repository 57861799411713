/**
 * Adds anchors to load widgets into.
 */
import selectors from '../selectors';

const DIV = 'div';
const SPAN = 'span';

// HELPERS
const alreadyDefined = (id: string) => !!document.querySelector(`#${id}`);

const create = (tag: string, anchor: string, className: string) => {
  const newElement = document.createElement(tag);
  newElement.id = anchor;
  if (className) {
    newElement.classList.add(className);
  }
  return newElement;
};

const addBefore = (selector: string, tag: string, anchor: string, className: string = null) => {
  try {
    if (alreadyDefined(anchor)) {
      return;
    }
    const location = document.querySelector(selector);
    const newNode = create(tag, anchor, className);
    location.parentNode.insertBefore(newNode, location);
  } catch (e) {
    console.warn(
      `Error with adding ${tag}${anchor ? `#${anchor}` : ''}${
        className ? `.${className}` : ''
      } before ${selector}.`,
    );
    console.warn(e);
    throw e;
  }
};

// const addAfter = (selector: string, tag: string, anchor: string, className: string = null) => {
//   if (alreadyDefined(anchor)) {
//     return;
//   }
//   const location = document.querySelector(selector);
//   const newNode = create(tag, anchor, className);
//   location.parentNode.insertBefore(newNode, location.nextSibling);
// };

export const addAsFirstChild = (
  selector: string,
  tag: string,
  anchor: string,
  className: string = null,
) => {
  if (alreadyDefined(anchor)) {
    return;
  }
  try {
    const location = document.querySelector(selector);
    const newNode = create(tag, anchor, className);
    location.prepend(newNode);
  } catch (e) {
    console.warn(
      `Error with adding ${tag}${anchor ? `#${anchor}` : ''}${
        className ? `.${className}` : ''
      } as first child of ${selector}.`,
    );
    console.warn(e);
    throw e;
  }
};

export const addAsLastChild = (
  selector: string,
  tag: string,
  anchor: string,
  className: string = null,
) => {
  if (alreadyDefined(anchor)) {
    return;
  }
  try {
    const location = document.querySelector(selector);
    const newNode = create(tag, anchor, className);
    location.append(newNode);
  } catch (e) {
    console.warn(
      `Error with adding ${tag}${anchor ? `#${anchor}` : ''}${
        className ? `.${className}` : ''
      } as last child of ${selector}.`,
    );
    throw e;
  }
};

export default () => {
  addAsFirstChild(selectors.FORM, DIV, 'CurrencySelector');
  addAsFirstChild(selectors.CONTRIBUTION_FIELDSET, DIV, 'AmountOptions');
  addAsFirstChild(selectors.CONTRIBUTION_FIELDSET, DIV, 'FrequencySelector');
  addAsFirstChild(selectors.CONTACT_FIELDSET, DIV, 'NameFields');
  addAsLastChild(selectors.CONTACT_FIRST_NAME_LABEL, DIV, 'FirstNameSplitter');
  addAsLastChild(selectors.CONTACT_LAST_NAME_LABEL, DIV, 'LastNameSplitter');
  addAsLastChild(selectors.CONTACT_EMAIL_ADDRESS_LABEL, DIV, 'EmailTooltip');
  addAsLastChild(selectors.ADDRESS_ADDRESS_LABEL, DIV, 'AddressTooltip');
  addAsLastChild(selectors.QUANTITY_TAB, DIV, 'QuantityTab');
  addAsLastChild(selectors.CONTRIBUTION_FIELDSET, DIV, 'InitialContinueButton');
  addAsFirstChild(selectors.PAYMENT_FIELDSET, DIV, 'PaymentMethodTabRow');
  addAsFirstChild(selectors.NEXT_BUTTON, DIV, 'NextStepButton');
  addBefore(selectors.FORM_CONTENTS, DIV, 'ExtraSteps');

  // Payment Method Buttons

  // Details section titles
  addBefore(selectors.ADDRESS_DETAILS, DIV, 'MailingAddressAutocomplete');
  addBefore(selectors.CONTACT_FIELDSET, DIV, 'ContactInformationTitle');

  addBefore(selectors.RECIPIENT_INFORMATION_FIELDSET, DIV, 'InHonorOrInMemoryOfSection');

  addAsFirstChild(selectors.PAYMENT_FIELDSET, DIV, 'TransactionFeeCheckbox');

  // Submit button
  addAsLastChild(selectors.PAYMENT_INFO_TAB, DIV, 'Submit');

  // Email subscribe tooltip
  if (document.querySelector(selectors.SMS_SUBSCRIBE_LABEL)) {
    addAsLastChild(selectors.EMAIL_SUBSCRIBE_LABEL, SPAN, 'EmailOptInTooltip');
  }

  // SMS subscribe / opt in tooltip
  if (document.querySelector(selectors.SMS_SUBSCRIBE_ROW)) {
    addBefore(selectors.SMS_SUBSCRIBE_ROW, DIV, 'SmsOptIn');
  }

  if (document.querySelector(selectors.REMEMBER_ME)) {
    addBefore(selectors.REMEMBER_ME, DIV, 'RememberMe');
  }

  // Disclosures
  addAsLastChild(selectors.DONATE_FORM_WRAPPER, DIV, 'Disclosures');
};
