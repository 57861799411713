import React from 'react';
import * as Styled from './FormWrapper.styles';
import { FormAlertProvider, RememberMeProvider } from '+/contexts';
import { FormFieldErrorsProvider } from '+/contexts';

export const FormWrapper = ({ id, children }: { id: string; children: React.ReactNode }) => (
  <RememberMeProvider>
    <FormAlertProvider>
      <FormFieldErrorsProvider>
        <Styled.Wrapper id={id}>
          <Styled.FormBox>{children}</Styled.FormBox>
        </Styled.Wrapper>
      </FormFieldErrorsProvider>
    </FormAlertProvider>
  </RememberMeProvider>
);
