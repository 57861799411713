import styled from 'styled-components';

export const Label = styled.label`
  && {
    font-size: 0.875rem;
    background: var(--color-neutral-200);
    padding: 1.5rem var(--form-side-padding) 1.625rem;
    color: var(--color-neutral-600);
    text-align: left;
    cursor: pointer;
    margin: 0 calc(var(--form-side-padding) * -1) 2.5rem;
    width: calc(100% + var(--form-side-padding) * 2);
  }
`;
