import { addOverrides } from '+/utils/textDictionary';

// Supplements text dictionary with additional custom assignments
const supplementDictionary = (donateForm: DonateFormComponent) => {
  if (!donateForm.keyAssignments) return;

  addOverrides(donateForm.keyAssignments, false);
};

export default supplementDictionary;
