import React, { useContext } from 'react';

import { t } from '+/utils/textDictionary';
import { Button } from '+/components/Button';
import { ICONS } from '+/utils/constants/icons';
import analyticsEvent from '+/utils/analyticsEvent';
import { FormAlertContext } from '+/contexts/FormAlertContext';
import visibleRequiredUnfilled from '+/components/DonateForm/utilities/visibleRequiredUnfilled';
import scrollToLocation from '+/utils/scrollToLocation';
import selectors from '+/components/DonateForm/selectors';
import { breakpoints, dimensions } from '+/styles/themes/mainTheme';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

// Selects a default payment type, since EA expects payment types to be configured on the first page.
export const InitialContinueButton = ({
  controller,
}: {
  controller: EveryActionModelController;
}) => {
  const { addAlert, removeAlert } = useContext(FormAlertContext);
  const errorMessage = t('Widget.FormErrors.MissingFields');

  const handleClick = e => {
    e.preventDefault();
    if (controller.isInvalidAmount() || visibleRequiredUnfilled()) {
      addAlert(errorMessage);
    } else {
      e.stopPropagation();
      removeAlert(errorMessage);
      if (!controller.getPaymentMethod()) {
        controller.setPaymentMethod('creditcard');
      }
      scrollToLocation(
        document.querySelector(selectors.FORM_HEADER_TITLE),
        dimensions.heightNav.value,
        'up',
        breakpoints.lg.value,
      );
    }
    controller.nextStep();
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: `Advance ${controller.getStep()}`,
      simpleName: 'Advance past first step',
      context: { amount: controller.getSelectedAmount(), recurring: controller.getMonthly() },
    });
  };

  const buttonText = t('Widget.InitialContinueButton.ButtonText');

  return (
    <Button
      big
      color={ColorThemeNames.RedAccented}
      aria-label={buttonText}
      endIcon={ICONS.CARET_RIGHT}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
};
