import React, { useContext } from 'react';
import { AnimatePresence } from 'framer-motion';

import { MessageModal } from './MessageModal';
import * as Styled from './EditYourMessageModalButton.styles';

import * as FormStyled from '+/styles/styledComponents/Form.styles';
import { ColorTheme } from '+/components/ColorTheme';
import { ICONS } from '+/utils/constants/icons';
import { Icon } from '+/components/Icon';
import { t } from '+/utils/textDictionary';
import { ColorThemeContext } from '+/contexts';
import { getAccentedVariant } from '+/styles/themes/colorThemes';

export const EditYourMessageModalButton = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  intro,
  outro,
}) => {
  const [isEditingMessage, setIsEditingMessage]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = React.useState(false);

  const startEditing: () => void = React.useCallback((): void => setIsEditingMessage(true), []);
  const stopEditing: () => void = React.useCallback((): void => setIsEditingMessage(false), []);

  const parentColorTheme = useContext(ColorThemeContext);
  const accentedColorTheme = getAccentedVariant(parentColorTheme);
  return (
    <>
      <Styled.ReadOrEditTextButton onClick={startEditing} type="button">
        <Icon
          path={ICONS.CARET_RIGHT_FILLED.path}
          viewBox={ICONS.CARET_RIGHT_FILLED.viewBox}
          size="0.675rem"
        />
        <Styled.ReadOrEditTextButtonText>
          {t('EAAdvocacyForm.ReadOrEditYourMessage')}
        </Styled.ReadOrEditTextButtonText>
      </Styled.ReadOrEditTextButton>
      <Styled.MessageError>
        {((touched.Subject0 && errors.Subject0) || (touched.Message0 && errors.Message0)) && (
          <FormStyled.InputError>{t('EAAdvocacyForm.MessageOrSubjectError')}</FormStyled.InputError>
        )}
      </Styled.MessageError>
      <AnimatePresence>
        {isEditingMessage && (
          <ColorTheme colorTheme={accentedColorTheme}>
            <MessageModal
              messageModalData={{
                touched,
                errors,
                handleChange,
                handleBlur,
                values,
                outro,
                intro,
              }}
              onClose={stopEditing}
            />
          </ColorTheme>
        )}
      </AnimatePresence>
    </>
  );
};
