import React, { useContext } from 'react';
import remarkDirective from 'remark-directive';
import visit from 'unist-util-visit';
import rehypeRaw from 'rehype-raw';

import { Link } from '../Link';

import * as Styled from './Markdown.styles';
import { MarkdownModal } from './MarkdownModal.component';
import { MarkdownModalTrigger } from './MarkdownModalTrigger.component';
import { MarkdownButton } from './MarkdownButton.component';
import { MarkdownIcon } from './MarkdownIcon.component';
import { MarkdownAdvocateStudio } from './MarkdownAdvocateStudio.component';
import { MarkdownHighlight } from './MarkdownHighlight.component';
import { MarkdownTruncate } from './MarkdownTruncate.component';
import { MarkdownEASubmissionCount } from './MarkdownEASubmissionCount.component';
import { MarkdownTag } from './MarkdownTag.component';
import { MarkdownPersonalized, personalize } from './MarkdownPersonalized.component';
import { MarkdownCounter } from './MarkdownCounter.component';
import { MarkdownLineBreak } from './MarkdownLineBreak.component';

import { UserContext } from '+/contexts/UserContext';

// All new renderer keys below should be lowercase
// Duplicate camel case directives (e.g. modalTrigger)
// are for legacy support only.
const defaultRenderers: Record<string, React.FC> = {
  a: Link,
  advstu: MarkdownAdvocateStudio,
  button: MarkdownButton,
  eaSubmissionCount: MarkdownEASubmissionCount,
  easubmissioncount: MarkdownEASubmissionCount,
  highlight: MarkdownHighlight,
  icon: MarkdownIcon,
  modalTrigger: MarkdownModalTrigger,
  modaltrigger: MarkdownModalTrigger,
  modal: MarkdownModal,
  tag: MarkdownTag,
  truncate: MarkdownTruncate,
  personalized: MarkdownPersonalized,
  hideOnMobile: ({ children }) => <Styled.HideOnMobile>{children}</Styled.HideOnMobile>,
  hideonmobile: ({ children }) => <Styled.HideOnMobile>{children}</Styled.HideOnMobile>,
  hideOnTablet: ({ children }) => <Styled.HideOnTablet>{children}</Styled.HideOnTablet>,
  hideontablet: ({ children }) => <Styled.HideOnTablet>{children}</Styled.HideOnTablet>,
  counter: MarkdownCounter,
  lineBreak: MarkdownLineBreak,
  linebreak: MarkdownLineBreak,
};

const tf = node => {
  if (
    node.type === 'textDirective' ||
    node.type === 'leafDirective' ||
    node.type === 'containerDirective'
  ) {
    // eslint-disable-next-line no-param-reassign
    node.data = node.data || {};
    const { data } = node;
    data.hName = node.name;
    data.hProperties = { ...node.attributes };
  }
};

const integrateUserData = (node, userData) => {
  if (node.name === 'personalized') {
    let showFallback = false;
    visit(node, ['text'], text => {
      const personalizedText = personalize((text as any).value, userData);
      // eslint-disable-next-line no-param-reassign
      if (personalizedText) (text as any).value = personalizedText;
      else showFallback = true;
    });
    if (showFallback) {
      visit(node, ['text'], text => {
        // eslint-disable-next-line no-param-reassign
        (text as any).value = '';
      });
      if (node.attributes.fallback)
        node.children.push({ type: 'text', value: node.attributes.fallback });
    }
  }
};

function customDirectives(userData) {
  return tree => {
    visit(tree, ['textDirective'], node => integrateUserData(node, userData));
    visit(tree, tf);
  };
}

export const Markdown = ({ children, className }: { children: any; className?: string }) => {
  const { userData } = useContext(UserContext);
  return (
    <Styled.Markdown
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkDirective, () => customDirectives(userData)]}
      className={className}
      components={defaultRenderers}
    >
      {String(children)}
    </Styled.Markdown>
  );
};

Markdown.defaultProps = {
  className: '',
};
