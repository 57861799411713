import React, { useRef, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import * as Styled from './VideoWithTimeline.styles';

import { useVideoPlayer } from '+/utils/useVideoPlayer';
import { useOnScreen } from '+/utils/useOnScreen';
import { Markdown } from '+/components/Markdown';
import { t } from '+/utils/textDictionary';
import { useIsMobile } from '+/utils/responsive';
import PlayTriangle from '+/images/play-triangle.inline.svg';

export const VideoWithTimeline = ({
  videoWithTimelineData: {
    title,
    videoUrl,
    videoUrlMobile,
    captions,
    timeline,
    timelineStartMobile = '',
    timelineEndMobile = '',
    warningPosition,
    autoplay = true,
  },
}: {
  videoWithTimelineData: VideoWithTimelineComponent;
}) => {
  const containerRef = useRef(null);
  const vidRef = useRef(null);
  const [vidDimensions, setVidDimensions] = useState({ width: 16, height: 9 });
  const [isWarned, setIsWarned] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isManuallyPaused, setIsManuallyPaused] = useState(false);
  const isMobile = useIsMobile();

  const isTitleHidden = useOnScreen(vidRef, 0, 1);
  const isVideoOnScreen = useOnScreen(vidRef, -400);

  const {
    isPlaying,
    progress,
    togglePlaying,
    setPlaying,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleReset,
    setDuration,
    duration,
    goToPosition,
  } = useVideoPlayer(vidRef);

  function fixTimeline() {
    const mobileTimeline = [
      { time: 0, text: timelineStartMobile },
      { time: Math.round(duration), text: timelineEndMobile },
    ];
    const newTimeline = timeline ? JSON.parse(timeline) : [];
    const timelineTimes = newTimeline.map(item => item.time);
    if (!timelineTimes.includes(0)) {
      newTimeline.unshift({ time: 0, text: '' });
    }
    if (!timelineTimes.includes(Math.round(duration))) {
      newTimeline.push({ time: Math.round(duration), text: '' });
    }
    return isMobile ? mobileTimeline : newTimeline;
  }
  const newTimeline = fixTimeline();

  useEffect(() => {
    if (
      !!warningPosition &&
      !isWarned &&
      !isWarningVisible &&
      Math.round((progress / 100) * duration) >= warningPosition
    ) {
      goToPosition(Math.round((warningPosition / duration) * 100));
      setPlaying(false);
      setIsWarningVisible(true);
    }
  }, [progress, isWarned]);

  useEffect(() => {
    setPlaying(isVideoOnScreen && autoplay && !isWarningVisible && !isManuallyPaused);
  }, [isVideoOnScreen]);

  const handlePlayPauseClick = () => {
    setIsManuallyPaused(isPlaying);
    togglePlaying();
  };

  useEffect(() => {
    const video = vidRef.current;
    const updateDimensions = () => {
      setVidDimensions({ width: video.videoWidth, height: video.videoHeight });
    };

    if (video) {
      video.addEventListener('loadedmetadata', updateDimensions);
      video.preload = 'metadata';
    }

    return () => {
      if (video) {
        video.removeEventListener('loadedmetadata', updateDimensions);
      }
    };
  }, [vidDimensions]);

  return (
    <Styled.VideoWithTimeline ref={containerRef}>
      {title && (
        <Styled.Title isVisible={!isTitleHidden}>
          <Markdown>{title}</Markdown>
        </Styled.Title>
      )}
      <Styled.VideoWrapper>
        <Styled.Video
          ref={vidRef}
          controls={false}
          playsInline
          src={isMobile && !!videoUrlMobile ? videoUrlMobile : videoUrl}
          onClick={handlePlayPauseClick}
          isPlaying={isPlaying}
          vidDimensions={vidDimensions}
          preload="auto"
          onTimeUpdate={handleOnTimeUpdate}
          onLoadedMetadata={e => setDuration(e.currentTarget.duration)}
          onEnded={() => handleReset()}
          muted
        />
        <AnimatePresence>
          {!isPlaying && !isWarningVisible && (
            <Styled.PlayButton
              onClick={handlePlayPauseClick}
              as={motion.button}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              aria-label={t('VideoWithTimeline.Play')}
            >
              <PlayTriangle aria-hidden />
            </Styled.PlayButton>
          )}

          {isWarningVisible && (
            <Styled.WarningScreen
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Styled.WarningMessage>
                <Markdown>{t('VideoWithTimeline.WarningMessage')}</Markdown>
              </Styled.WarningMessage>
              <Styled.WarningButtonContainer>
                <Styled.WarningButton
                  onClick={() => {
                    setIsWarningVisible(false);
                    setIsWarned(true);
                    setPlaying(true);
                  }}
                >
                  <PlayTriangle />
                  {t('VideoWithTimeline.Continue')}
                </Styled.WarningButton>
                <Styled.WarningButton
                  onClick={() => {
                    window.scrollTo({
                      left: 0,
                      top:
                        containerRef.current.offsetHeight +
                        (containerRef.current.getBoundingClientRect().top -
                          document.body.getBoundingClientRect().top),
                      behavior: 'smooth',
                    });
                  }}
                >
                  {t('VideoWithTimeline.Stop')}
                </Styled.WarningButton>
              </Styled.WarningButtonContainer>
            </Styled.WarningScreen>
          )}
        </AnimatePresence>
        {!!captions && (
          <Styled.CaptionContainer>
            <AnimatePresence>
              {JSON.parse(captions).map(
                caption =>
                  (progress * duration) / 100 > caption.start &&
                  (progress * duration) / 100 < caption.end &&
                  !isSeeking &&
                  !isWarningVisible && (
                    <Styled.Caption
                      key={`caption-${caption.start}-${caption.end}-${caption.text}`}
                      as={motion.figcaption}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.5 }}
                      dangerouslySetInnerHTML={{ __html: caption.text }}
                    />
                  ),
              )}
            </AnimatePresence>
          </Styled.CaptionContainer>
        )}
        <Styled.ProgressContainer>
          {!!timeline && (
            <AnimatePresence>
              {!!duration && (
                <Styled.ProgressBarTickContainer>
                  {newTimeline.map((tick, i) => (
                    <Styled.ProgressBarTick
                      key={`tick-${tick.time}-${tick.text}`}
                      as={motion.button}
                      initial={{ opacity: 0, y: 15 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4, delay: 0.1 * i }}
                      isPast={progress >= Math.round((tick.time / duration) * 100)}
                      style={{ left: `${Math.round((tick.time / duration) * 100)}%` }}
                      onClick={() => goToPosition(Math.round((tick.time / duration) * 100))}
                    >
                      <Styled.ProgressBarTickLabel>{tick.text}</Styled.ProgressBarTickLabel>
                    </Styled.ProgressBarTick>
                  ))}
                </Styled.ProgressBarTickContainer>
              )}
            </AnimatePresence>
          )}
          <Styled.ProgressBar>
            <Styled.ProgressBarBackground>
              <Styled.ProgressBarSeek />
              <Styled.ProgressBarCompleted
                style={{ transform: `scaleX(${progress / 100})` }}
                isSeeking={isSeeking || !isPlaying}
              />
              <Styled.Seeker
                type="range"
                min="0"
                max="100"
                value={progress}
                onChange={e => handleVideoProgress(e)}
                onMouseDown={() => setIsSeeking(true)}
                onMouseUp={() => setIsSeeking(false)}
                disabled={isWarningVisible}
              />
            </Styled.ProgressBarBackground>
            <Styled.ProgressBarSeekDot
              style={{ left: `${progress}%` }}
              isSeeking={isSeeking || !isPlaying}
            />
          </Styled.ProgressBar>
        </Styled.ProgressContainer>
      </Styled.VideoWrapper>
    </Styled.VideoWithTimeline>
  );
};
