import React, { useEffect } from 'react';
import * as Styled from './ManageRecurringDonationStep.styles';
import analyticsEvent from '+/utils/analyticsEvent';

export const ManageRecurringDonationStep = ({ controller }) => {
  useEffect(() => {
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Display EA Donor Portal Message',
      simpleName: 'Display EA Donor Portal Message',
    });
  }, []);

  const registerPortalClick = () => {
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Go To EA Donor Portal',
      simpleName: 'Go To EA Donor Portal',
    });
  };

  return (
    <>
      <style>{`#FormContents { display: none; }`}</style>
      <Styled.Content>
        {controller.getFirstName() ? `${controller.getFirstName()}, w` : 'W'}e noticed that you
        already have a recurring donation—thank you! You can update your existing gift amount or
        payment method though{' '}
        <a
          onClick={e => registerPortalClick()}
          href="https://secure.everyaction.com/p/pJxla_27FkOHGPp1y_VaSA2"
        >
          our self-service portal
        </a>
        . You'll be asked to enter your email address and will receive a secure link in your inbox.
        If you'd like to make an additional donation, please proceed to the next step.
      </Styled.Content>
    </>
  );
};
