import React from 'react';

/**
 * Must pass a function as children element, will call the function with a
 * boolean value indicating whether the element is being hovered over or not
 */
export const Hover = ({ children, className }: HoverComponent) => {
  const [hovering, setHovering] = React.useState(false);

  return (
    <div
      onFocus={() => {
        setHovering(true);
      }}
      onBlur={() => {
        setHovering(false);
      }}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      className={className}
    >
      {children(hovering)}
    </div>
  );
};
