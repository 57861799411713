import styled, { createGlobalStyle } from 'styled-components';

// Thanks
export const FormThanks = styled.div`
  text-align: center;
`;

// Fast Action Sign Up Modal
export const GlobalFastActionSignUpModalStyles = createGlobalStyle`
  html.has-overlay {
    overflow-y: auto !important;
  }
  #fastaction-signup {
    display: none !important;
  }
`;
