import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FORM_TOP_PADDING = '4.75rem';
export const FORM_TOP_PADDING_MOBILE = '2rem';

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--color-theme-base);
  color: var(--color-theme-accent);
  width: 100%;
  max-width: 540px;
  padding: ${FORM_TOP_PADDING_MOBILE} 0 0;
  position: relative;
  /* Approximate height of Payment step */
  min-height: 48.125rem;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-block-start: ${FORM_TOP_PADDING};
  }
`;
