import { Transition, Variants } from 'framer-motion';

export const desktopVariants: Variants = {
  visible: {
    x: 0,
    maxWidth: '50px',
  },
  hidden: {
    x: 'calc(-100% - 20px)',
    maxWidth: '50px',
  },
  open: {
    x: 0,
    maxWidth: '250px',
  },
};

export const desktopTransition: Transition = {
  type: 'tween',
  duration: 0.5,
  ease: 'easeOut',
};

export const mobileVariants: Variants = {
  visibleMobile: {
    opacity: 1,
    y: '0',
  },

  hiddenMobile: {
    opacity: 0,
    y: '100%',
  },
};

export const mobileTransition: Transition = {
  type: 'tween',
  duration: 0.5,
  ease: 'easeOut',
};
