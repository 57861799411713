import React from 'react';

import useIsFirstMountState from '+/utils/useIsFirstMountState';

const usePreviousValue = <T>(value: T, distinct?: boolean): T => {
  const prevRef = React.useRef<T>(value);
  const curRef = React.useRef<T>(value);
  const isFirstMount: boolean = useIsFirstMountState();

  if (!isFirstMount && (!distinct || curRef.current !== value)) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
};

export default usePreviousValue;
