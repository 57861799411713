import { useWindowScrollPercentage } from './useWindowScrollPercentage';

export const useShowWhenInScrollPercentage = (
  scrollPositionShow: number,
  scrollPositionHide: number,
): boolean => {
  const windowScrollPercentage = useWindowScrollPercentage();

  return (
    windowScrollPercentage >= scrollPositionShow && windowScrollPercentage < scrollPositionHide
  );
};
