import React from 'react';

import { Hover } from '+/components/Hover';

import { useIsMobile } from '+/utils/responsive';
import { useShowWhenInScrollPercentage } from '+/utils/useShowWhenInScrollPercentage';

import * as Styled from './PinnedCallToAction.styles';
import * as Animations from './PinnedCallToAction.animations';

export const PinnedCallToAction = ({
  pinnedCallToActionData,
}: {
  pinnedCallToActionData: PinnedCallToActionComponent;
}) => {
  const isShown = useShowWhenInScrollPercentage(
    pinnedCallToActionData.scrollPositionShow,
    pinnedCallToActionData.scrollPositionHide,
  );

  const isMobile = useIsMobile();

  return isMobile ? (
    <Styled.MobilePinnedCallToAction
      color={pinnedCallToActionData.color}
      variants={Animations.mobileVariants}
      animate={isShown ? 'visibleMobile' : 'hiddenMobile'}
    >
      <Styled.Link href={pinnedCallToActionData.link}>
        <Styled.MobileButton color={pinnedCallToActionData.color} narrow>
          {pinnedCallToActionData.labelMobile}
        </Styled.MobileButton>
      </Styled.Link>
    </Styled.MobilePinnedCallToAction>
  ) : (
    <Styled.Link href={pinnedCallToActionData.link}>
      <Hover>
        {hovering => (
          <Styled.PinnedCallToAction
            color={pinnedCallToActionData.color}
            hovering={hovering}
            position={pinnedCallToActionData.position}
            variants={Animations.desktopVariants}
            animate={hovering ? 'open' : isShown ? 'visible' : 'hidden'}
            transition={Animations.desktopTransition}
          >
            <Styled.Label>{pinnedCallToActionData.label}</Styled.Label>
            <Styled.Logo />
          </Styled.PinnedCallToAction>
        )}
      </Hover>
    </Styled.Link>
  );
};
