import React from 'react';
import { debounce } from './debounce';
import getWindowSize from './getWindowSize';

export interface MouseCoordinates {
  x: number;
  y: number;
}

export interface MouseCoordinatesWithPrevious extends MouseCoordinates {
  prevX: number;
  prevY: number;
}

const useMousePositionReducer: React.Reducer<MouseCoordinatesWithPrevious, MouseCoordinates> = (
  state: MouseCoordinatesWithPrevious,
  action: MouseCoordinates,
) => {
  return {
    x: action.x,
    y: action.y,
    prevX: state.x,
    prevY: state.y,
  };
};

// This is necessary because the browser doesn't expose the mouse position unless
// it is coming from a MouseEvent, so we spoof to the middle of the screen
const getInitialMouseCoordinates = (): MouseCoordinatesWithPrevious => {
  const windowSize = getWindowSize();

  const x = windowSize.width / 2;
  const y = windowSize.height / 2;

  return {
    x,
    y,
    prevX: x,
    prevY: y,
  };
};

export const useMousePosition = (
  active: boolean = true,
  initialState: MouseCoordinatesWithPrevious = getInitialMouseCoordinates(),
  debounceMs: number = 10,
): MouseCoordinates => {
  const [state, dispatch] = React.useReducer(useMousePositionReducer, initialState);

  const debouncedMouseMoveEventListener = React.useCallback(
    debounce((mouseEvent: MouseEvent): void => {
      dispatch({
        x: mouseEvent.pageX,
        y: mouseEvent.pageY,
      });
    }, debounceMs),
    [dispatch],
  );

  let timer = React.useRef();

  const extractDebouncedTimerAndRun = React.useCallback(
    (mouseEvent: MouseEvent): void => {
      timer.current = debouncedMouseMoveEventListener(mouseEvent);
    },
    [debouncedMouseMoveEventListener],
  );

  React.useEffect((): (() => void) => {
    const cleanupEffect = (): void => {
      clearTimeout(timer.current);
      removeEventListener('mousemove', extractDebouncedTimerAndRun);
    };

    if (active) {
      addEventListener('mousemove', extractDebouncedTimerAndRun);
    } else {
      cleanupEffect();
    }

    return cleanupEffect;
  }, [extractDebouncedTimerAndRun, timer, active]);

  return state;
};
