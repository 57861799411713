import React from 'react';

import { Icon } from './Icon.component';

import { ICONS } from '+/utils/constants/icons';

// Only have to pass in the `iconName` and the path/viewbox will be looked up for you
export const IconByName = (iconByNameProps: IconByNameProps): React.ReactElement => {
  const iconData = ICONS[iconByNameProps.iconName.toUpperCase()];

  return (
    iconData && (
      <Icon
        {...(iconByNameProps.iconProps || {})}
        path={iconData.path}
        viewBox={iconData.viewBox}
        transform={iconData.transform}
        className={iconByNameProps.className}
      />
    )
  );
};
