import styled from 'styled-components';

export const FinePrint = styled.div`
  font-style: italic;
  color: var(--color-neutral-350);
  font-size: 0.875rem;
  margin: 1rem 0 0 0;
  text-align: center;

  a {
    color: var(--color-neutral-350);

    &:hover {
      color: var(--color-neutral-200);
    }
  }

  p {
    margin: 0.5rem 0;
  }
`;
