export interface IconConstant {
  path: string;
  viewBox?: string;
  transform?: string;
}

export interface IconsConstants {
  [ICON: string]: IconConstant;
}

// Note, add new options to https://thehumaneleague.atlassian.net/wiki/spaces/THLUS/pages/131825665/Markdown+in+Super+Page+Builder
export const ICONS: IconsConstants = {
  MENDED_HEART: {
    path: 'M56.171 5L56.1149 5.11218L56.0588 5L43.6635 16.8906L31.5486 5H15.9001L3 17.6197V45.2148L43.6074 85.0369L85 45.0465V17.788L71.7072 5H56.171ZM57.9658 11.2818H69.7442L69.8003 11.3379L60.1532 32.9877L47.87 20.985L57.9658 11.2818ZM41.42 74.7729L32.1655 65.6867L40.6908 46.0561L50.1696 55.2544L41.42 74.7729ZM36.316 41.7934L27.6785 61.2558L17.3023 51.104L31.3803 37.026L36.316 41.7934ZM52.6936 49.5335L34.9699 32.0903L39.6813 20.985L45.4022 26.5937L57.5171 38.5964L52.6936 49.5335ZM18.1436 11.2818H29.8098L35.4186 16.8345L30.5951 27.8837L15.7319 13.6936L18.1436 11.2818ZM9.28181 20.1997L11.357 18.1245L27.2298 32.9877L13.1518 47.0657L9.28181 43.2517V20.1997ZM78.6621 43.1395L49.104 71.3516L61.7797 42.8591L61.8358 42.9152V42.803L63.1819 39.8304H78.6621V43.1395ZM78.6621 33.7168H65.8741L74.0068 15.4884L78.7182 20.0876V33.7168H78.6621Z',
  },
  ARROW_POINT_UP: {
    path: 'M52.6052 3.36318L77.5402 32.2388C79.7231 34.7664 77.951 38.75 74.5602 38.75H61.4265C61.1248 65.1032 55.8431 86 30.8685 86C20.7883 86 10.8022 79.5062 5.58123 72.3282C3.95192 70.0882 5.9968 66.8959 8.63788 67.7218C32.4289 75.161 38.745 64.1933 39.1112 38.75H24.6876C21.2915 38.75 19.5274 34.7633 21.7076 32.2388L46.6451 3.36318C48.2139 1.54684 51.0343 1.54438 52.6052 3.36318V3.36318Z',
  },
  CARET_DOWN: {
    path: 'M39.7737 68.9031L5.76305 34.8925C3.41232 32.5417 3.41232 28.7405 5.76305 26.4148L11.4148 20.7631C13.7656 18.4123 17.5667 18.4123 19.8925 20.7631L44 44.8706L68.1075 20.7631C70.4583 18.4123 74.2595 18.4123 76.5852 20.7631L82.2369 26.4148C84.5877 28.7656 84.5877 32.5667 82.2369 34.8925L48.2263 68.9031C45.9256 71.2538 42.1244 71.2538 39.7737 68.9031V68.9031Z',
  },
  CARET_UP: {
    path: 'M48.2263 18.763L82.2369 52.7737C84.5877 55.1244 84.5877 58.9256 82.2369 61.2513L76.5852 66.9031C74.2344 69.2538 70.4333 69.2538 68.1075 66.9031L44 42.7956L19.8925 66.9031C17.5417 69.2538 13.7405 69.2538 11.4148 66.9031L5.76306 61.2513C3.41233 58.9006 3.41233 55.0994 5.76306 52.7737L39.7737 18.763C42.0744 16.4123 45.8756 16.4123 48.2263 18.763V18.763Z',
  },
  CARET_UP_FILLED: {
    path: 'm7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z',
    viewBox: '0 0 20 20',
  },
  CARET_LEFT: {
    path: 'M18.763 39.7737L52.7737 5.76305C55.1244 3.41232 58.9256 3.41232 61.2513 5.76305L66.9031 11.4148C69.2538 13.7656 69.2538 17.5667 66.9031 19.8925L42.7955 44L66.9031 68.1075C69.2538 70.4583 69.2538 74.2595 66.9031 76.5852L61.2513 82.2369C58.9006 84.5877 55.0994 84.5877 52.7737 82.2369L18.763 48.2263C16.4123 45.9256 16.4123 42.1244 18.763 39.7737V39.7737Z',
  },
  CARET_RIGHT: {
    path: 'M348.225,1024C348.225,1024,828.75,519.64,828.75,519.64C828.75,519.64,333.663,0,333.663,0C333.663,0,195.5,104.99199999999996,195.5,104.99199999999996C195.5,104.99199999999996,585.598,519.64,585.598,519.64C585.598,519.64,220.177,908.896,220.177,908.896C220.177,908.896,348.225,1024,348.225,1024',
    viewBox: '0 0 1024 1024',
  },
  CARET_RIGHT_FILLED: {
    path: 'M831 582q40 -30 40 -70t-40 -70l-581 -423q-41 -28 -68 -14.5t-28 63.5v888q1 50 28 63.5t68 -14.5l581 -423v0z',
    viewBox: '0 0 1024 1024',
  },
  CHECK: {
    path: 'M29.8887 76.3339L1.28868 47.7338C-0.429559 46.0156 -0.429559 43.2297 1.28868 41.5113L7.51107 35.2887C9.22931 33.5703 12.0154 33.5703 13.7336 35.2887L33 54.5549L74.2664 13.2887C75.9846 11.5704 78.7707 11.5704 80.4889 13.2887L86.7113 19.5112C88.4296 21.2295 88.4296 24.0154 86.7113 25.7338L36.1113 76.334C34.3929 78.0523 31.6069 78.0523 29.8887 76.3339V76.3339Z',
  },
  CLOSE: {
    path: 'M8.24264 4.00001L4 8.24265L40.0625 44.3051L4.00002 80.3675L8.24266 84.6102L44.3051 48.5477L80.3675 84.6102L84.6102 80.3675L48.5477 44.3051L84.6102 8.24264L80.3676 4L44.3051 40.0625L8.24264 4.00001Z',
  },
  EMAIL: {
    path: 'M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z',
    viewBox: '0 0 24 24',
  },
  ERROR: {
    path: 'M44 83C65.5391 83 83 65.5391 83 44C83 22.4609 65.5391 5 44 5C22.4609 5 5 22.4609 5 44C5 65.5391 22.4609 83 44 83ZM49.489 50.4993L52 21H37.0661L39.511 50.4993H49.489ZM39.1145 66.0113C40.5242 67.3371 42.3304 68 44.533 68C46.6916 68 48.4758 67.3371 49.8855 66.0113C51.2952 64.6413 52 62.984 52 61.0395C52 59.0508 51.2952 57.4156 49.8855 56.134C48.5198 54.8524 46.7357 54.2116 44.533 54.2116C42.3304 54.2116 40.5242 54.8524 39.1145 56.134C37.7048 57.4156 37 59.0508 37 61.0395C37 62.984 37.7048 64.6413 39.1145 66.0113Z',
  },
  INFO: {
    path: 'M512 1024q-218 -5 -362.5 -149.5t-149.5 -362.5q5 -218 149.5 -362.5t362.5 -149.5q218 5 362.5 149.5t149.5 362.5q-5 218 -149.5 362.5t-362.5 149.5v0zM490 846q27 16 61 16q31 0 53.5 -12.5t22.5 -42.5q0 -36 -28 -54.5t-64 -18.5q-31 0 -52 13.5t-21 41.5 q0 40 28 57v0zM582 216q-31 -19 -83 -19q-54 0 -83.5 23t-29.5 67q0 14 3 37t11 59l31 148q3 13 5.5 25.5t2.5 21.5q0 18 -9 24.5t-33 6.5h-17l8 48h218l-58 -275q-6 -26 -9 -43t-3 -33t8 -25.5t21.5 -9.5t23 4t23.5 13l22 -29q-21 -24 -52 -43v0z',
    viewBox: '0 0 1024 1024',
    transform: 'scale(1, -1) translateY(-1024px)',
  },
  LOCK: {
    path: 'M71.5 39H67.75V27.75C67.75 14.6562 57.0938 4 44 4C30.9062 4 20.25 14.6562 20.25 27.75V39H16.5C12.3594 39 9 42.3594 9 46.5V76.5C9 80.6406 12.3594 84 16.5 84H71.5C75.6406 84 79 80.6406 79 76.5V46.5C79 42.3594 75.6406 39 71.5 39ZM55.25 39H32.75V27.75C32.75 21.5469 37.7969 16.5 44 16.5C50.2031 16.5 55.25 21.5469 55.25 27.75V39Z',
  },
  QUESTION: {
    path: 'M83 44C83 65.5391 65.5391 83 44 83C22.4609 83 5 65.5391 5 44C5 22.4609 22.4609 5 44 5C65.5391 5 83 22.4609 83 44ZM47.73 39.83C45.2567 42.0233 41.9433 43.8667 37.79 45.36L37.93 54.04H42.55L44.93 47.81C48.2433 46.5967 51.2067 45.22 53.82 43.68C56.48 42.0933 58.5567 40.25 60.05 38.15C61.59 36.05 62.36 33.6467 62.36 30.94C62.36 26.9267 61.0067 23.9867 58.3 22.12C55.5933 20.2533 51.9067 19.32 47.24 19.32C43.9267 19.32 41.0567 19.74 38.63 20.58C36.2033 21.3733 34.3367 22.47 33.03 23.87C31.7233 25.2233 31.07 26.74 31.07 28.42C31.07 32.0133 33.94 33.81 39.68 33.81C39.9133 32.0367 40.2633 30.38 40.73 28.84C41.2433 27.2533 41.9433 25.9933 42.83 25.06C43.7633 24.08 44.9533 23.59 46.4 23.59C47.8467 23.59 49.0367 24.22 49.97 25.48C50.95 26.6933 51.44 28.4667 51.44 30.8C51.44 34.6267 50.2033 37.6367 47.73 39.83ZM34.36 69.37C35.4333 70.1633 36.81 70.56 38.49 70.56C39.9833 70.56 41.36 70.0933 42.62 69.16C43.9267 68.2267 44.58 66.5933 44.58 64.26C44.58 62.5333 44.0433 61.2733 42.97 60.48C41.8967 59.64 40.5433 59.22 38.91 59.22C37.0433 59.22 35.55 59.8033 34.43 60.97C33.3567 62.09 32.82 63.6067 32.82 65.52C32.82 67.2467 33.3333 68.53 34.36 69.37Z',
  },
  LOCATION: {
    path: 'M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z',
    viewBox: '0 0 24 24',
  },
  CALENDAR: {
    path: 'M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z',
    viewBox: '0 0 24 24',
  },
  CHEVRON_DOWN_THIN: {
    path: 'M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z',
    viewBox: '0 0 24 24',
  },
  // Social
  FACEBOOK: {
    path: 'M33 29.333H22V44h11v44h18.333V44h13.354L66 29.333H51.333v-6.112c0-3.502.704-4.888 4.089-4.888H66V0H52.037C38.852 0 33 5.804 33 16.922v12.411Z',
  },
  INSTAGRAM: {
    path: 'M44 7.931c11.748 0 13.141.044 17.783.257 11.924.542 17.494 6.2 18.037 18.036.212 4.638.253 6.032.253 17.78 0 11.751-.044 13.141-.253 17.78-.547 11.824-6.102 17.493-18.037 18.036-4.642.212-6.028.256-17.783.256-11.748 0-13.141-.044-17.78-.256-11.953-.547-17.493-6.23-18.036-18.04-.213-4.639-.257-6.028-.257-17.78 0-11.748.048-13.138.257-17.78C8.73 14.388 14.285 8.727 26.22 8.184c4.642-.209 6.032-.253 17.78-.253ZM44 0C32.05 0 30.554.051 25.861.264 9.881.997 1.001 9.864.268 25.857.05 30.554 0 32.05 0 44c0 11.95.051 13.45.264 18.143.733 15.98 9.6 24.86 25.593 25.593C30.554 87.95 32.05 88 44 88c11.95 0 13.45-.051 18.143-.264 15.964-.733 24.867-9.6 25.59-25.593C87.948 57.449 88 55.95 88 44c0-11.95-.051-13.446-.264-18.139C87.017 9.896 78.14 1.001 62.146.268 57.45.05 55.95 0 44 0Zm0 21.406c-12.478 0-22.594 10.116-22.594 22.594 0 12.478 10.116 22.598 22.594 22.598 12.478 0 22.594-10.117 22.594-22.598 0-12.478-10.116-22.594-22.594-22.594Zm0 37.26c-8.1 0-14.667-6.563-14.667-14.666 0-8.1 6.567-14.667 14.667-14.667S58.667 35.9 58.667 44c0 8.103-6.567 14.667-14.667 14.667Zm23.489-43.431a5.282 5.282 0 0 0-5.284 5.28 5.282 5.282 0 0 0 5.284 5.28 5.278 5.278 0 0 0 5.276-5.28 5.278 5.278 0 0 0-5.276-5.28Z',
  },
  LINKEDIN: {
    path: 'M18.26 12.833c0 5.064-4.07 9.167-9.093 9.167-5.024 0-9.094-4.103-9.094-9.167 0-5.06 4.07-9.166 9.094-9.166 5.023 0 9.093 4.106 9.093 9.166Zm.073 16.5H0V88h18.333V29.333Zm29.268 0H29.385V88h18.22V57.204c0-17.124 22.106-18.524 22.106 0V88H88V50.853c0-28.893-32.715-27.841-40.4-13.618v-7.902Z',
  },
  PINTEREST: {
    path: 'M44 0C19.701 0 0 19.697 0 44c0 18.641 11.598 34.562 27.966 40.971-.385-3.48-.734-8.818.154-12.617.799-3.435 5.159-21.871 5.159-21.871s-1.317-2.637-1.317-6.534c0-6.116 3.546-10.685 7.96-10.685 3.752 0 5.567 2.82 5.567 6.197 0 3.773-2.402 9.416-3.645 14.648-1.038 4.378 2.196 7.953 6.516 7.953 7.82 0 13.83-8.246 13.83-20.148 0-10.535-7.568-17.901-18.377-17.901-12.518 0-19.866 9.39-19.866 19.092 0 3.78 1.456 7.84 3.274 10.04.36.436.411.821.305 1.265l-1.221 4.986c-.195.807-.638.98-1.474.59-5.497-2.559-8.932-10.592-8.932-17.046 0-13.878 10.083-26.627 29.073-26.627 15.264 0 27.126 10.879 27.126 25.414 0 15.165-9.56 27.368-22.833 27.368-4.458 0-8.65-2.314-10.083-5.053l-2.743 10.461c-.993 3.824-3.674 8.617-5.47 11.535A44.1 44.1 0 0 0 44 88c24.3 0 44-19.7 44-44C88 19.697 68.3 0 44 0Z',
  },
  TWITTER: {
    path: 'M88 16.709a36.046 36.046 0 0 1-10.37 2.842c3.73-2.233 6.593-5.772 7.94-9.988a36.167 36.167 0 0 1-11.467 4.381 18.024 18.024 0 0 0-13.178-5.701c-11.656 0-20.221 10.875-17.589 22.165-15-.752-28.303-7.939-37.209-18.862-4.73 8.115-2.453 18.73 5.584 24.105a17.978 17.978 0 0 1-8.173-2.259c-.198 8.364 5.797 16.189 14.48 17.93-2.541.69-5.324.851-8.155.308 2.296 7.172 8.962 12.39 16.867 12.537-7.59 5.95-17.153 8.61-26.73 7.48a51.11 51.11 0 0 0 27.676 8.11c33.52 0 52.46-28.31 51.315-53.702a36.757 36.757 0 0 0 9.01-9.346Z',
  },
  YOUTUBE: {
    path: 'M71.922 11.675c-13.215-.902-42.647-.899-55.844 0C1.79 12.65.106 21.28 0 44c.106 22.678 1.775 31.346 16.078 32.325 13.2.899 42.63.902 55.844 0C86.21 75.35 87.894 66.72 88 44c-.106-22.678-1.775-31.346-16.078-32.325ZM33 58.667V29.333l29.333 14.641L33 58.667Z',
  },
  VIMEO: {
    path: 'M83.875 36.898c-8.954 19.129-30.569 45.17-44.231 45.17-13.464 0-15.411-28.714-22.763-47.825-3.619-9.405-5.954-7.245-12.738-2.497L.007 26.411C9.9 17.714 19.8 7.612 25.883 7.051c6.85-.656 11.066 4.026 12.643 14.05 2.082 13.175 4.994 33.624 10.076 33.624 3.96 0 13.717-16.221 14.22-22.022.89-8.492-6.245-8.749-12.438-6.098 9.8-32.098 50.574-26.187 33.491 10.293Z',
  },
};
