import React, { useState, useContext, useEffect } from 'react';

import { LanguageContext } from '+/contexts/LanguageContext';

export const useCountries = () => {
  const language: string = useContext(LanguageContext);

  const [countriesList, setCountries]: [
    Array<CountryListItem>,
    React.Dispatch<React.SetStateAction<Array<CountryListItem>>>,
  ] = useState([]);

  useEffect(() => {
    import(`./countriesList.${language}.ts`).then(mod => {
      setCountries(mod.default);
    });
  }, [language]);

  return countriesList;
};
