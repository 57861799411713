import React from 'react';

import * as EveryActionAdvocacyFormStyled from '+/components/EveryActionAdvocacyForm/EveryActionAdvocacyForm.styles';
import * as FormStyled from '+/styles/styledComponents/Form.styles';

export const ThanksStage = (props: EveryActionAdvocacyFormThanksStageComponent) => {
  const { everyActionAdvocacyFormData } = props;

  return (
    <FormStyled.Container>
      <FormStyled.Header>
        <EveryActionAdvocacyFormStyled.Markdown>
          {everyActionAdvocacyFormData.thanksHeader}
        </EveryActionAdvocacyFormStyled.Markdown>
      </FormStyled.Header>
      <FormStyled.Body className="adv-form__body">
        <FormStyled.Section className="adv-form__section">
          <EveryActionAdvocacyFormStyled.Markdown>
            {everyActionAdvocacyFormData.thanksBody}
          </EveryActionAdvocacyFormStyled.Markdown>
        </FormStyled.Section>
      </FormStyled.Body>
    </FormStyled.Container>
  );
};
