import styled, { css, keyframes } from 'styled-components';

import { REPORT_COLORS } from '+/components/USDAViolationsDisplay/utils/reportColors';

const pulse = keyframes`
  0% {
    transform: scale(1.0625);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.75);
    opacity: 0;
  }
`;

export const DotGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.125rem;
`;

export const Dot = styled.button<{
  highlighted: boolean;
  isActive: boolean;
  incidentTypes: string[];
}>`
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 50%;
  background: var(--color-white);
  color: var(--color-white);
  transition: all 0.15s ease-out;
  position: relative;
  outline: none;

  ${props =>
    props.isActive &&
    css`
      box-shadow: 0 0 0 0.1875rem var(--color-white) inset;
    `}

  &:disabled {
    opacity: 0.2;
    cursor: default;
    transform: scale(0.85);
  }

  &:not(:disabled) {
    background: ${props => REPORT_COLORS[props.incidentTypes[0]]};
    ${({ incidentTypes }) =>
      incidentTypes.length > 1 &&
      css`
        background: conic-gradient(
          from 0deg
            ${incidentTypes.map(
              (type, i) =>
                `,${REPORT_COLORS[type]} 0% ${Math.round(((i + 1) / incidentTypes.length) * 100)}%`,
            )}
        );
      `};

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      transition-duration: 0s;
      transform: scale(1);
    }

    ${props =>
      props.highlighted &&
      css`
        &::after,
        &::before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border: 0.0625rem solid ${REPORT_COLORS[props.incidentTypes[0]]};
          animation: 1.5s ${pulse} ease-out infinite;
          border-radius: 50%;
          opacity: 0;
        }

        &::before {
          animation-delay: 0.75s;
          ${props.incidentTypes.length > 1 &&
          css`
            border-color: ${REPORT_COLORS[props.incidentTypes[1]]};
          `};
        }
      `}
  }
`;
