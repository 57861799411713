import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import * as Styled from './MessageModal.styles';

import { ModalPortal } from '+/components/Modal/ModalPortal.component';
import { ModalBody } from '+/components/Modal/ModalBody.component';
import { t } from '+/utils/textDictionary';
import { useIsSmallMobile } from '+/utils/responsive';
import * as FormStyled from '+/styles/styledComponents/Form.styles';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

export const requiredMessageModalFields = Object.freeze(['Subject0', 'Message0']);

export const MessageModal = ({
  messageModalData,
  onClose,
}: {
  messageModalData: MessageModalComponent;
  onClose: () => void;
}): JSX.Element => {
  const [uniqueId]: [string, React.Dispatch<React.SetStateAction<string>>] = React.useState(uuidv4);
  const inputIdSubject: string = React.useMemo(() => `input-${uniqueId}-subject`, [uniqueId]);
  const inputIdMessage: string = React.useMemo(() => `input-${uniqueId}-message`, [uniqueId]);

  React.useEffect(() => {
    /**
     * TextareaAutosize cuts off the last line until you focus or blur the element so in order to
     * prevent that from happening we set `autoFocus` on the textarea and as soon as this component
     * renders we blur to remove focus from it and restore the missing line.
     */
    (document.activeElement as HTMLElement).blur();
  }, []);

  const isMobile: boolean = useIsSmallMobile();

  return (
    <ModalPortal onClose={onClose} fullscreen={isMobile}>
      <ModalBody isPadded>
        <Styled.H3>{t('EAAdvocacyForm.EditYourMessage')}</Styled.H3>

        <Styled.CustomFieldRow>
          <Styled.InputLabel
            fullWidth
            errored={!!messageModalData.errors.Subject0}
            htmlFor={inputIdSubject}
          >
            <Styled.InputLabelText>{t('EAAdvocacyForm.Subject')}:</Styled.InputLabelText>
            <Styled.TextyInput
              name="Subject0"
              onChange={messageModalData.handleChange}
              onBlur={() => messageModalData.handleBlur}
              value={messageModalData.values.Subject0}
              placeholder={`${t('EAAdvocacyForm.Subject')}*`}
              id={inputIdSubject}
            />
            {messageModalData.touched.Subject0 && messageModalData.errors.Subject0 && (
              <FormStyled.InputError>{messageModalData.errors.Subject0}</FormStyled.InputError>
            )}
          </Styled.InputLabel>
        </Styled.CustomFieldRow>

        <Styled.CustomFieldRow>
          <FormStyled.InputLabel
            htmlFor={inputIdMessage}
            fullWidth
            errored={!!messageModalData.errors.Message0}
          >
            <Styled.InputLabelText>{t('EAAdvocacyForm.Message')}:</Styled.InputLabelText>
            <Styled.TextAreaContainer>
              <Styled.MessageIntro>
                {messageModalData.intro || t('EAAdvocacyForm.MessagePrefix')}
              </Styled.MessageIntro>
              <Styled.MessageTextArea
                onChange={messageModalData.handleChange}
                onBlur={() => messageModalData.handleBlur}
                value={`${messageModalData.values.Message0}`}
                name="Message0"
                placeholder={`${t('EAAdvocacyForm.Message')}*`}
                id={inputIdMessage}
                autoFocus
              />
              <Styled.MessageOutro>
                {
                  /* eslint-disable indent */
                  messageModalData.outro
                    ? messageModalData.outro.replace(
                        '[Your information here]',
                        `${
                          messageModalData.values.FirstName || `[${t('EAAdvocacyForm.FirstName')}]`
                        } ${
                          messageModalData.values.LastName || `[${t('EAAdvocacyForm.LastName')}]`
                        }`,
                      )
                    : t('EAAdvocacyForm.MessageSuffix', {
                        name: `${messageModalData.values.FirstName} ${messageModalData.values.LastName}`,
                      })
                  /* eslint-enable indent */
                }
              </Styled.MessageOutro>
            </Styled.TextAreaContainer>
            {messageModalData.touched.Message0 && messageModalData.errors.Message0 && (
              <FormStyled.InputError>{messageModalData.errors.Message0}</FormStyled.InputError>
            )}
          </FormStyled.InputLabel>
        </Styled.CustomFieldRow>

        <Styled.ButtonWrapper>
          <Styled.DoneButton
            big
            minContent
            color={ColorThemeNames.YellowFilledGreyAccented}
            onClick={onClose}
          >
            {t('EAAdvocacyForm.DoneReadingOrEditingYourMessage')}
          </Styled.DoneButton>
        </Styled.ButtonWrapper>
      </ModalBody>
    </ModalPortal>
  );
};
