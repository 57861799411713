import styled, { css } from 'styled-components';

export const MarkdownIconContainer = styled.span<{ center: boolean; animation: string }>`
  display: block;

  ${props =>
    props.center &&
    css`
      display: flex;
      justify-content: space-around;
    `}

  ${props =>
    props.animation &&
    css`
      animation: ${props.animation};
    `}  
  color: var(--color-theme-accent);
`;
