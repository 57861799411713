import restructureFormDefinition from './utilities/formDefinitionRestructurer';
import changeFormElementTextValues from './utilities/changeFormElementTextValues';
import supplementDictionary from './utilities/supplementDictionary';
import catchErrors from './utilities/catchErrors';
import { adjustFormAlterFill, adjustFormPostFill } from './utilities/adjustForm';

export const initialize = (donateForm: DonateFormComponent, markSubmitted, callback, onError) => {
  supplementDictionary(donateForm);

  window.nvtag_callbacks = {
    alterFormDefinition: [changeFormElementTextValues, restructureFormDefinition],
    preSegue: [markSubmitted],
    postFill: [adjustFormPostFill(donateForm, callback)],
    alterFill: [adjustFormAlterFill],
  };

  Object.keys(window.nvtag_callbacks).forEach(key => {
    window.nvtag_callbacks[key] = window.nvtag_callbacks[key].map(func =>
      catchErrors(func, onError),
    );
  });
};
