import React, { useEffect } from 'react';

import { RenderTo } from './renderTo.component';
import { FrequencySelector } from './FrequencySelector';
import { AmountOptions } from './AmountOptions';
import { QuantityDisplayTab } from './quantityDisplayTab.component';
import { Disclosures } from './disclosures.component';
import { TransactionFeeCheckbox } from './TransactionFeeCheckbox';
import { EveryActionDonateFormHeader } from './EveryActionDonateFormHeader';
import { NameSplitter } from './NameSplitter';
import { RememberMe } from './RememberMe';

import { CurrencySelector } from '+/components/DonateForm/widgets/CurrencySelector';
import { InHonorOrInMemoryOf } from '+/components/DonateForm/widgets/InHonorOrInMemoryOf';
import { t } from '+/utils/textDictionary';
import addAnchors from '+/components/DonateForm/utilities/addAnchors';
import addContainers from '+/components/DonateForm/utilities/addContainers';
import addFloatingLabels from '+/components/DonateForm/utilities/addFloatingLabels';
import {
  NextStepButtonSet,
  FinalSubmitButton,
} from '+/components/DonateForm/widgets/FormNavigation';
import { MailingAddressAutocomplete } from '+/components/DonateForm/widgets/MailingAddressAutocomplete';
import { PaymentMethodSelector } from '+/components/DonateForm/widgets/PaymentMethodSelector';
import { ReloadOnError } from '+/components/DonateForm/ReloadOnError';
import { SmsOptIn } from '+/components/DonateForm/widgets/SmsOptIn';
import { ManageRecurringDonationStep } from '+/components/DonateForm/widgets/ManageRecurringDonationStep';
import { initializeStateWithCookieVal, useRememberMe } from '+/contexts';
import { useGeoIpCountry } from '+/utils/hooks/useGeoIpCountry';
import { useDisplayRememberMe } from '+/contexts/RememberMe';

export const Widgets = ({
  controller,
  openModal,
  upsellModalOptOut,
  onError,
}: {
  controller: EveryActionModelController;
  openModal(): void;
  upsellModalOptOut: boolean;
  onError(): void;
}) => {
  const isUS = controller.getCountry() === 'US';
  // Containers / Anchors may be deleted by FastAction
  addContainers(controller.view, controller);
  addAnchors();

  // Wait for all widgets to load (specifically Mailing Address)
  // before adding floating labels
  setTimeout(() => {
    addFloatingLabels();
  }, 0);

  // ** Remember Me code begins ** //
  const displayRememberMe = useDisplayRememberMe();

  const { state, dispatch } = useRememberMe();
  const { userData: rememberMeData, saveUserData } = state;

  const updateRememberMe = (checked: boolean) =>
    dispatch({
      type: 'updateSaveUserData',
      payload: checked,
    });

  const geoIpCountry = useGeoIpCountry();

  useEffect(() => {
    if (geoIpCountry === 'US') {
      updateRememberMe(true);
    } else {
      initializeStateWithCookieVal(() => updateRememberMe(true));
    }
  }, [geoIpCountry]);

  const handleRememberMeChange = () => {
    updateRememberMe(!saveUserData);
  };

  useEffect(() => {
    const rememberMeDataExists = !!rememberMeData;
    const emailInput = document.querySelector('input[name="EmailAddress"]');

    if (rememberMeDataExists) {
      controller.setFirstName(rememberMeData.firstName);
      controller.setLastName(rememberMeData.lastName);
      rememberMeData.mobilePhone && controller.setMobilePhone(rememberMeData.mobilePhone);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (emailInput as HTMLInputElement).value = rememberMeData.email;
    }
  }, [rememberMeData]);
  // ** Remember Me code ends ** //

  // The phone field continues to show an error after a valid phone number
  // is entered. This event handler will force it to run its `renderFeedback`
  // method on every change in the input value.
  const phoneInput = document.querySelector('input[name="MobilePhone"]');
  useEffect(() => {
    if (!!phoneInput && phoneInput.getAttribute('addedListener') !== 'true') {
      phoneInput.setAttribute('addedListener', 'true');
      phoneInput.addEventListener('input', () =>
        controller.view.subviews.ContactInformation.subviews.MobilePhone.renderFeedback(),
      );
    }
  }, [phoneInput]);

  // Remove dropdown that EA automatically adds to the Name Prefix Input
  const prefixInput = document.querySelector('input[name="Prefix"]');
  useEffect(() => {
    if (prefixInput) {
      // dropdown is set via list/datalist
      prefixInput.removeAttribute('list');
    }
  }, [prefixInput]);

  // Add aria-labels to radio button based off image alt text
  // so screen reader users know what they are selecting
  const eCardImages = document.querySelectorAll('.at-ecard-img img');
  const eCardInputs = document.querySelectorAll('.at-ecard input[type="radio"]');
  eCardImages.forEach((image, i) => {
    const alt = image.getAttribute('alt');
    eCardInputs[i].setAttribute('aria-label', alt);
  });

  return (
    <ReloadOnError onError={onError}>
      {controller.includesBothFrequencyTypes() && (
        <RenderTo atSelector="#FrequencySelector">
          <FrequencySelector controller={controller} />
        </RenderTo>
      )}
      <RenderTo atSelector="#Steps">
        <EveryActionDonateFormHeader controller={controller} />
      </RenderTo>
      <RenderTo atSelector="#AmountOptions">
        <AmountOptions controller={controller} />
      </RenderTo>
      {controller.onFirstStep() && (
        <RenderTo atSelector="#CurrencySelector">
          <CurrencySelector controller={controller} />
        </RenderTo>
      )}
      <RenderTo atSelector="#QuantityTab">
        <QuantityDisplayTab controller={controller} />
      </RenderTo>
      <>
        <RenderTo atSelector="#FirstNameSplitter">
          <NameSplitter
            namePartToCheck="FirstName"
            namePartToSplit="MiddleName"
            position="end"
            controller={controller}
            nameLabel="middle name"
          />
          <NameSplitter
            namePartToCheck="FirstName"
            namePartToSplit="Prefix"
            position="start"
            controller={controller}
            nameLabel="title"
          />
        </RenderTo>
        <RenderTo atSelector="#LastNameSplitter">
          <NameSplitter
            namePartToCheck="LastName"
            position="start"
            namePartToSplit="MiddleName"
            controller={controller}
            nameLabel="middle name"
          />
          <NameSplitter
            namePartToCheck="LastName"
            position="end"
            namePartToSplit="Suffix"
            controller={controller}
            nameLabel="suffix"
          />
        </RenderTo>
        {isUS && (
          <RenderTo atSelector="div.StateProvince:not(.Country)" skippable>
            <div className="tooltip StateProvince">
              <a
                className="tooltip__teaser"
                href="https://thehumaneleague.org/state-disclosures"
                target="_blank"
                rel="noreferrer"
              >
                {t('Widget.ViewDisclosures')}
              </a>
            </div>
          </RenderTo>
        )}
        <RenderTo atSelector="#MailingAddressAutocomplete">
          <MailingAddressAutocomplete controller={controller} />
        </RenderTo>
      </>
      {!controller.onFinalStep() && (
        <RenderTo
          atSelector={controller.onFirstStep() ? '#InitialContinueButton' : '#NextStepButton'}
        >
          <NextStepButtonSet
            step={controller.onFirstStep() ? 'initial' : 'other'}
            controller={controller}
          />
        </RenderTo>
      )}
      {controller.onFinalStep() && (
        <RenderTo atSelector="#Submit">
          <FinalSubmitButton
            controller={controller}
            openModal={openModal}
            upsellModalOptOut={upsellModalOptOut}
          />
        </RenderTo>
      )}
      {controller.onManageRecurringDonationStep() && (
        <RenderTo atSelector="#ExtraSteps">
          <ManageRecurringDonationStep controller={controller} />
        </RenderTo>
      )}
      <RenderTo atSelector="#PaymentMethodTabRow">
        <PaymentMethodSelector controller={controller} />
      </RenderTo>
      <RenderTo atSelector="#InHonorOrInMemoryOfSection">
        <InHonorOrInMemoryOf controller={controller} />
      </RenderTo>
      <RenderTo atSelector="#ButtonContainer">
        <p>{t('EmailDisclaimer')}</p>
      </RenderTo>
      <RenderTo atSelector="#TransactionFeeCheckbox">
        <TransactionFeeCheckbox controller={controller} />
      </RenderTo>
      <RenderTo atSelector="#Disclosures">
        <Disclosures controller={controller} />
      </RenderTo>
      {displayRememberMe && (
        <RenderTo atSelector="#RememberMe" skippable>
          <RememberMe handleChange={handleRememberMeChange} checked={saveUserData} />
        </RenderTo>
      )}
      <RenderTo atSelector="#SmsOptIn" skippable>
        <SmsOptIn controller={controller} />
      </RenderTo>
    </ReloadOnError>
  );
};
