import React, { useEffect, useState } from 'react';

interface MarkdownEASubmissionCountProps {
  children?: JSX.Element;
  formId: string;
}

export const MarkdownEASubmissionCount = (props: MarkdownEASubmissionCountProps) => {
  const { children, formId } = props;
  const [submissionCount, setSubmissionCount] = useState(null);

  useEffect(() => {
    if (!formId) {
      return;
    }
    (async function fetchAndSet() {
      const result = await fetch(`https://secure.everyaction.com/v2/forms/${formId}/progress`);
      const json = await result.json();
      if (!json || !json.totalSubmissionProgress) {
        return;
      }
      setSubmissionCount(json.totalSubmissionProgress);
    })();
  }, [formId]);

  return <>{typeof submissionCount !== 'number' ? children : submissionCount.toLocaleString()}</>;
};
