import styled from 'styled-components';

const ROW_GAP = '1.5rem';
const BUTTON_FONT_SIZE = '0.75rem';
const BUTTON_VERTICAL_PADDING = '0.5rem';
export const TRUNCATE_BUTTON_HEIGHT = `calc(${ROW_GAP} + ${BUTTON_FONT_SIZE} + ${BUTTON_VERTICAL_PADDING})`;

export const Truncate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${ROW_GAP};
`;

export const Content = styled.div`
  overflow: hidden;
  width: 100%;
  mask-image: ${props =>
    props.className === 'hasFade' &&
    'linear-gradient(to bottom, #000 0%, #000 50%, transparent 100%)'};
`;

export const Button = styled.button`
  font-family: var(--font-secondary);
  font-weight: 700;
  text-transform: uppercase;
  background: var(--color-neutral-200);
  font-size: ${BUTTON_FONT_SIZE};
  line-height: 1;
  white-space: nowrap;
  padding: ${BUTTON_VERTICAL_PADDING} 1rem;
  border-radius: 0.875rem;
  transition: background-color 0.1s ease-out;

  &:hover,
  &:focus-visible {
    background: var(--color-neutral-300);
  }
`;
