/**
 * Moves elements in EA's form_definition to reposition them.
 *
 * This moves elements around in the internal model that EA uses to build forms.
 * Note, EA behavior is unpredictable. Test carefully.
 */

/* eslint-disable camelcase */

/**
 * Finds an element by its name key in a nested tree of elements.
 * @param form_elements - A form_elements array as pulled off from the form definition.
 * @param identifier - A string to check element name values against.
 * @return object - an object representing of the html element.
 **/
const findInChildren = (
  form_elements: EveryActionFormElement | Array<EveryActionFormElement>,
  identifier: string,
): EveryActionFormElement | null => {
  if ('name' in form_elements && form_elements.name === identifier) {
    return { ...form_elements };
  }
  if ('children' in form_elements) {
    for (const child of form_elements.children || []) {
      if (child.name === identifier) {
        return { ...child };
      }
      const subChild = findInChildren(child, identifier);
      if (subChild) {
        return { ...subChild };
      }
    }
  }
  return null;
};

/**
 * Finds an element by its name key in form_definition objects.
 * This wraps findInChildren because the form_elements array doesn't have a children key itself.
 * @param form_definition- An ea form definition.
 * @param identifier - A string to check element name values against.
 * @return object - an object representing of the html element.
 **/
const findInFormElements = (
  form_definition: FormDefinition,
  identifier: string,
): EveryActionFormElement => {
  const child = form_definition.form_elements.reduce((accum, nextElement) => {
    return accum || findInChildren(nextElement, identifier);
  }, null);
  return child;
};

/**
 * Finds an element by its name key in form_definition objects.
 * @param fieldset- An EA object representation with a children key.
 * @param element - An EA object model to be added under the children key.
 **/
const addToFieldset = (fieldset: EveryActionFieldset, element: EveryActionFormElement) => {
  fieldset.children.push(element);
};

/**
 * Inserts an element into a position in a form_elements array.
 * Note! EA doesn't always follow the order of the array to build a form.
 * @param form_elements - A form_elements object as pulled off from the form definition.
 * @param element - An EA object model to be added under the children key.
 * @param identifier - A string to check element name values against.
 **/
const insertElementBefore = (
  form_elements: EveryActionFormElement | Array<EveryActionFormElement>,
  element: EveryActionFormElement,
  identifier: string,
) => {
  if (!('children' in form_elements)) {
    return;
  }
  if (form_elements.children.some(e => e.name === identifier)) {
    const idx = form_elements.children.findIndex(e => e.name === identifier);
    form_elements.children.splice(idx, 0, element);
    return;
  }
  form_elements.children.forEach(child => insertElementBefore(child, element, identifier));
};

/**
 * Marks all matching elements as hidden so they won't be displayed.
 * Removing might also work, but merely marking as hidden ensures that EA won't break as a result.
 * @param form_elements - A form_elements array as pulled off from the form definition.
 * @param element - An EA object model to be added under the children key.
 * @param identifier - A string to check element name values against.
 **/
const removeFromChildren = (
  form_elements: EveryActionFormElement | Array<EveryActionFormElement>,
  identifier: string,
) => {
  if ('type' in form_elements && form_elements.name === identifier) {
    form_elements.type = 'hidden';
  }
  if (!('children' in form_elements)) {
    return;
  }
  form_elements.children.forEach(e => {
    if (e.name === identifier) {
      e.type = 'hidden';
    }
  });
  form_elements.children.map(e => {
    removeFromChildren(e, identifier);
  });
};

/**
 * Marks all matching elements as hidden so they won't be displayed.
 * @param form_definition- An ea form definition.
 * @param identifier - A string to check element name values against.
 **/
const removeFromFormElements = (form_definition: FormDefinition, identifier: string) => {
  form_definition.form_elements.forEach(c => removeFromChildren(c, identifier));
};

const contactFieldset = {
  name: 'NameAndContactFieldset',
  title: 'Contact',
  type: 'fieldset',
  step: 1,
  children: [],
};

const addressFieldset = {
  name: 'AddressFieldset',
  title: 'Address',
  type: 'fieldset',
  step: 1,
  children: [],
};

/**
 * Inserts element into form_elements before identifier.
 * @param form_definition- An ea form definition.
 * @param identifier - A string to check element name values against.
 **/
const insertIntoFormElements = (
  form_definition: FormDefinition,
  element: EveryActionFormElement,
  identifier: string,
) => {
  form_definition.form_elements.forEach(e => insertElementBefore(e, element, identifier));
};

/**
 * Moves an element into a fieldset and hides it in its present location.
 * @param form_definition- An ea form definition.
 * @param fieldset- An EA object representation with a children key.
 * @param identifier - A string to check element name values against.
 **/
const moveToFieldset = (form_definition, fieldset, identifier) => {
  const element = findInFormElements(form_definition, identifier);
  removeFromFormElements(form_definition, identifier);
  addToFieldset(fieldset, element);
};

/**
 * Moves all elements under a key into a fieldset. Ignores hidden elements.
 * This allows us to catch all remaining questions, even if we don't know what they are in advance.
 * @param form_definition- An ea form definition.
 * @param fieldset- An EA object representation with a children key.
 * @param identifier - A string to check element name values against.
 **/
const moveAllDirectLeavesToFieldset = (
  form_definition: FormDefinition,
  fieldset: EveryActionFieldset,
  identifier: string,
) => {
  const element = findInFormElements(form_definition, identifier);
  if (!element) {
    return;
  }
  element.children.forEach(c => {
    if (c.type !== 'hidden') {
      addToFieldset(fieldset, { ...c });
    }
    c.type = 'hidden';
  });
};

// Performs all restructuring
const restructure = ({ form_definition, ...args }) => {
  const recipientInformation = findInFormElements(form_definition, 'RecipientInformation');
  recipientInformation.type = 'fieldset';
  removeFromFormElements(form_definition, 'RecipientInformation');
  insertIntoFormElements(form_definition, recipientInformation, 'CoverCostsAmount');
  return { form_definition, ...args };
};

export default restructure;
