import React from 'react';

const useIsFirstMountState = (): boolean => {
  const isFirstMount = React.useRef<boolean>(true);

  if (isFirstMount.current) {
    isFirstMount.current = false;

    return true;
  }

  return isFirstMount.current;
};

export default useIsFirstMountState;
