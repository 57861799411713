import React from 'react';
import { t } from '+/utils/textDictionary';
import * as Styled from './TransactionFeeCheckbox.styles';
import analyticsEvent from '+/utils/analyticsEvent';

export const TransactionFeeCheckbox = ({
  controller,
}: {
  controller: EveryActionModelController;
}) => {
  const transactionFee = controller.getTransactionFee();

  const handleChange = e => {
    e.stopPropagation();
    const toOn = !transactionFee;
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: `Toggle Fee Coverage ${toOn ? 'On' : 'Off'}`,
      simpleName: `${toOn ? 'Select' : 'Deselect'} fee coverage`,
    });
    controller.setTransactionFee(toOn);
  };

  if (!controller.enabledFeeCoverage() || controller.bankSelected()) {
    return null;
  }

  return (
    <Styled.Label>
      <input type="checkbox" checked={!!transactionFee} onChange={handleChange} />
      <span>
        {t('Widget.TransactionFeeCheckbox.Label')}
        {!!transactionFee && (
          <span>
            {' '}
            {t('Widget.TransactionFeeCheckbox.NewAmount')}{' '}
            <strong>{controller.getTotalAmountWithCurrencySymbol()}</strong>
            {controller.getMonthly() && t('Widget.TransactionFeeCheckbox.Monthly')}.
          </span>
        )}
      </span>
    </Styled.Label>
  );
};
