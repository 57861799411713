import React from 'react';
import Helmet from 'react-helmet';

import { getImageFilePath } from '+/utils/getImageFilePath';
import googleTagManagerHelper from '+/utils/googleTagManagerHelper';

const siteMetaDataByLanguage: BySupportedLanguage<SiteMetaData> = {
  'es-MX': {
    title: 'The Humane League México',
    twitter: {
      site: '@THL_Latino',
      creator: '@THL_Latino',
    },
  },
  'en-US': {
    title: 'The Humane League',
    twitter: {
      site: '@TheHumaneLeague',
      creator: '@TheHumaneLeague',
    },
  },
};

const webVitalsPolyfillScriptInnerHTML = googleTagManagerHelper.isWebVitalsTrackingEnabled && (
  <script key="metadata-script-webvitals">
    {googleTagManagerHelper.webVitalsPolyfillScriptInnerHTML}
  </script>
);

const googleTagManagerPreconnects = googleTagManagerHelper.isEnabled && [
  <link rel="preconnect" href="https://www.googletagmanager.com" key="metadata-preconnect-gtm" />,
  <link rel="preconnect" href="https://www.google-analytics.com" key="metadata-preconnect-ga" />,
  <link rel="preconnect" href="https://connect.facebook.net" key="metadata-preconnect-fb" />,
];

export const MetaData = ({
  title,
  description = '',
  robots,
  image,
  domainName,
  slug,
  type = 'website',
  language = 'en-US',
}: MetaDataComponent) => {
  const siteMetadata = React.useMemo<SiteMetaData>(
    () => siteMetaDataByLanguage[language],
    [language],
  );

  const googleTagManagerId = React.useMemo<string>(
    () => googleTagManagerHelper.idByLanguage[language],
    [language],
  );

  const googleTagManagerScriptInnerHTML = React.useMemo<string>(
    () => googleTagManagerHelper.getScriptInnerHTML(googleTagManagerId),
    [googleTagManagerId],
  );

  const imageFilePath: string = getImageFilePath(image);
  const siteUrl = `https://${domainName}/${slug === 'root' ? '' : slug}`;

  return (
    <Helmet htmlAttributes={{ lang: language }}>
      <title>{title || siteMetadata.title}</title>
      {webVitalsPolyfillScriptInnerHTML}
      {googleTagManagerPreconnects}
      {googleTagManagerHelper.isEnabled && (
        <script key="metadata-script-gtm">{googleTagManagerScriptInnerHTML}</script>
      )}
      <meta property="og:title" content={title || siteMetadata.title} />
      <meta property="twitter:title" content={title || siteMetadata.title} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      {imageFilePath && <meta property="og:image" content={imageFilePath} />}
      {imageFilePath && <meta property="twitter:image" content={imageFilePath} />}
      {imageFilePath && <meta property="twitter:card" content="summary_large_image" />}
      <link rel="canonical" href={siteUrl} />
      <meta property="og:url" content={siteUrl} />
      <meta property="twitter:url" content={siteUrl} />
      <meta property="og:type" content={type} />
      <meta name="robots" content={robots} />
      {siteMetadata.twitter.site && (
        <meta name="twitter:site" content={siteMetadata.twitter.site} />
      )}
      {siteMetadata.twitter.creator && (
        <meta name="twitter:creator" content={siteMetadata.twitter.creator} />
      )}
      <link rel="preconnect" href={process.env.REMOTE_FILE_PATH} />
    </Helmet>
  );
};

MetaData.defaultProps = {
  title: '',
  description: '',
  robots: '',
  image: null,
  type: 'website',
};
