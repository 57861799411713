export default {
  '&EAPetitionForm.FirstName': 'First Name',
  '&EAPetitionForm.LastName': 'Last Name',
  '&EAPetitionForm.Suffix': 'Suffix',
  '&EAPetitionForm.Prefix': 'Prefix',
  '&EAPetitionForm.MiddleName': 'Middle Name',
  '&EAPetitionForm.EmailAddress': 'Email Address',
  '&EAPetitionForm.MobilePhone': 'Mobile Phone',
  '&EAPetitionForm.Consent': 'Keep me updated on this and other campaigns.',
  '&EAPetitionForm.InfoConsent':
    "Keep this box checked if you'd like to receive email updates from The Humane League, including campaign updates and occasional action alerts. You may unsubscribe any time.",
  '&EAPetitionForm.AcceptPrivacyPolicyPrefix': 'I have read and accept the',
  '&EAPetitionForm.AcceptPrivacyPolicyLinkText': 'privacy policy',
  '&EAPetitionForm.AcceptPrivacyPolicyLinkUrl': 'https://thehumaneleague.org/privacy-policy',
  '&EAPetitionForm.AcceptPrivacyPolicySuffix': '.',
  '&EAPetitionForm.AcceptPrivacyPolicyErrorNotChecked':
    'You must accept the privacy policy to submit.',
  '&EAPetitionForm.ConsentSms': 'Send me occasional SMS alerts.',
  '&EAPetitionForm.InfoConsentSms':
    "Keep this box checked if you'd like to receive occasional SMS (text) alerts from The Humane League, including campaign updates and action alerts. You may opt out any time.",
  '&EAPetitionForm.PostalCode': 'Postal Code',
  '&EAPetitionForm.Country': 'Country',
  '&EAPetitionForm.Submit': 'Send',
  '&EAPetitionForm.SignNow': 'SIGN NOW',
  '&EAPetitionForm.CTAText': 'Take Action',
  '&EAPetitionForm.FormError': 'Please fix the errors marked above',
  '&EAPetitionForm.URLSanitized': 'Your %label cannot include a URL',
  '&EAAdvocacyForm.Subject': 'Subject',
  '&EAAdvocacyForm.Message': 'Message',
  '&EAAdvocacyForm.MessagePrefix': 'Dear Decision Maker,',
  '&EAAdvocacyForm.MessageSuffix': 'Sincerely,\n %name',
  '&EAAdvocacyForm.EditYourMessage': 'Edit your message',
  '&EAAdvocacyForm.ReadOrEditYourMessage': 'READ / EDIT YOUR MESSAGE',
  '&EAAdvocacyForm.DoneReadingOrEditingYourMessage': 'DONE',
  '&EAAdvocacyForm.MessageOrSubjectError': 'Your message has an error',
} as TextDictionary;
