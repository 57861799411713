import styled, { css } from 'styled-components';

import * as sharedStyles from '+/styles/reusable/inputStyles';

import { Button as ButtonComponent } from '+/components/Button';
import { Link as LinkComponent } from '+/components/Link';
import { ImageItem as ImageItemComponent } from '+/components/ImageItem';
import { Markdown as MarkdownComponent } from '+/components/Markdown';

interface ImageItemWrapperProps {
  inView: boolean;
}

export const ImageItemWrapper = styled.div<ImageItemWrapperProps>`
  display: flex;
  align-items: center;

  max-height: 0px;
  overflow: visible;

  opacity: 0;
  transform: translateX(-10rem);

  transition: transform 0.8s ease-out, opacity 0.8s;

  ${props =>
    props.inView &&
    css`
      opacity: 1;
      transform: translateX(0rem);
    `}

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

export const ImageItem = styled(ImageItemComponent)`
  max-height: 28.125rem;
`;

export const Markdown = styled(MarkdownComponent)`
  max-width: 25rem;

  font-size: 1.25rem;

  strong {
    font-size: 1.75rem;
    font-family: var(--font-tertiary);
  }
`;

interface DonationAmountIntervalProps {
  isRecurringDonation: boolean;
}

export const DonationAmountInterval = styled.span<DonationAmountIntervalProps>`
  visibility: ${props => (props.isRecurringDonation ? 'visible' : 'hidden')};
`;

export const DonationAmountInput = styled.input`
  width: 4rem;

  font-size: 2rem;

  text-align: right;

  // Remove the arrows from the input
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

export const DonationInputWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 0 1rem;

  background: var(--color-white);

  color: var(--color-neutral-400);
`;

export const DonationSubmitButton = styled(ButtonComponent)`
  font-size: 1.125rem;
`;

export const DonationSubmitLink = styled(LinkComponent)`
  &,
  &:visited,
  &:link,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

export const DonationInputContainer = styled.div`
  display: flex;

  padding-bottom: 0.625rem;
`;

export const RecurringDonationCheckbox = styled.input`
  ${sharedStyles.checkbox};
`;

export const RecurringDonationCheckboxLabelText = styled.span`
  ${sharedStyles.checkboxLabelText};

  &:before {
    border: none;
  }
`;

export const DonationSection = styled.div`
  margin-left: 2rem;
  padding-top: 1.875rem;
`;

export const DonationBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 1.875rem;

  background: var(--color-theme-base);

  color: var(--color-theme-base-inverse);

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 100%;
      right: 0;

      height: 8.75rem;

      background: var(--image-inverse-chevron-yellow);
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }
`;
