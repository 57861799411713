import React from 'react';

import * as Styled from './MediaWithForm.styles';
import { t } from '+/utils/textDictionary';
import { Icon } from '../Icon';
import { ICONS } from '+/utils/constants/icons';

export const PhotoCredit = ({
  description,
  photoCredit,
  isRightAligned,
}: {
  description: string | null;
  photoCredit?: string;
  isRightAligned?: boolean;
}) => {
  if (!photoCredit && !description) return null;

  return (
    <Styled.PhotoCredit>
      <Styled.PhotoCreditContainer isRightAligned={isRightAligned}>
        {!!description && (
          <Styled.PhotoDescription>
            <Icon
              path={ICONS.CARET_UP_FILLED.path}
              viewBox={ICONS.CARET_UP_FILLED.viewBox}
              size={18}
              color="currentColor"
            />
            <span>Above: </span>
            {description}
          </Styled.PhotoDescription>
        )}
        {!!photoCredit && (
          <Styled.PhotoCreditText>
            {t('Images.Photo')} {photoCredit}
          </Styled.PhotoCreditText>
        )}
      </Styled.PhotoCreditContainer>
    </Styled.PhotoCredit>
  );
};
