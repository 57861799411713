import React, { useContext, useEffect, useState } from 'react';
import { withFormik } from 'formik';
import { navigate } from 'gatsby';

import enUsPetitionDictionary from './EveryActionPetitionForm.dictionary.en-US';
import esMxPetitionDictionary from './EveryActionPetitionForm.dictionary.es-MX';
import {
  EveryActionPetitionForm,
  EveryActionPetitionContent,
} from './EveryActionPetitionForm.component';
import everyActionPetitionFormValidators from './EveryActionPetitionForm.validators';

import { FormError } from '+/components/FormError';
import { LanguageContext } from '+/contexts/LanguageContext';
import { addToDictionary } from '+/utils/textDictionary';
import { personalDataStore } from '+/utils/personalDataStore';
import { promisesStatus } from '+/utils/getASContent';
import { useGeoIpCountry } from '+/utils/hooks/useGeoIpCountry';
import { encodeSpecialCharacters, parser } from '+/utils/contentParser';
import { useEveryActionFormData } from '+/utils/hooks/useEveryActionFormData';
import { UserContext } from '+/contexts/UserContext';
import params from '+/utils/queryParams';
import { useRememberMe } from '+/contexts';

const addedLanguages: Array<SupportedLanguage> = [];

export default ({
  everyActionPetitionFormData,
  advanceFormSequence,
}: EveryActionPetitionFormContainerProps) => {
  const language: SupportedLanguage = useContext(LanguageContext);
  const { userData, setUserData } = useContext(UserContext);
  const formHelpers = useEveryActionFormData(everyActionPetitionFormData.formId);
  const [submitError, setSubmitError] = useState(false);
  const geoIpCountry = useGeoIpCountry();

  const { sourceCodeId, marketSource } = everyActionPetitionFormData;
  const { sourceid, ms } = params;

  const { formElements, submissionUrl, suppliedOptions, advocacyFields, formType } = formHelpers;
  const { subject: eaSubject, message: eaMessage, intro: eaIntro, outro: eaOutro } = advocacyFields;

  const isAdvocacyForm = formType === 'AdvocacyForm';

  if (!addedLanguages.includes(language)) {
    if (language === 'es-MX') {
      addToDictionary(esMxPetitionDictionary);
    } else {
      addToDictionary(enUsPetitionDictionary);
    }
    addedLanguages.push(language);
  }

  const navigateToThankYouPage = data => {
    if (!everyActionPetitionFormData.thankYouPage) {
      return;
    }
    navigate(`/${everyActionPetitionFormData.thankYouPage.slug}`, {
      state: {
        firstName: data.FirstName,
        lastName: data.LastName,
        emailAddress: data.EmailAddress,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const postForm = async (formValues, actions) => {
    const formData = new URLSearchParams();
    const defaults = {
      type: formType,
      XFromApp: 'ActionTag',
      ActionTagBaseUrl: 'https://static.everyaction.com/ea-actiontag/',
    };
    const correctedFormValues = { ...formValues, ...defaults };
    if (formValues.Country !== 'US') {
      correctedFormValues.SmsSubscribeMobilePhone = false;
    }

    setUserData({
      ...userData,
      firstName: correctedFormValues.FirstName,
      lastName: correctedFormValues.LastName,
      emailAddress: correctedFormValues.EmailAddress,
      postalCode: correctedFormValues.PostalCode,
      country: correctedFormValues.Country,
      mobilePhone: correctedFormValues.MobilePhone,
      acceptedPrivacyPolicy: correctedFormValues.AcceptedPrivacyPolicy,
      smsSubscribeMobilePhone: correctedFormValues.SmsSubscribeMobilePhone,
      yesSignMeUpForUpdatesForBinder: correctedFormValues.YesSignMeUpForUpdatesForBinder,
    });

    delete correctedFormValues.AcceptedPrivacyPolicy;
    Object.keys(correctedFormValues).forEach(key => formData.append(key, formValues[key]));
    if (isAdvocacyForm) {
      formData.append('Messages', `["${encodeSpecialCharacters(formValues.Message0)}"]`);
      formData.append('Subjects', `["${encodeSpecialCharacters(formValues.Subject0)}"]`);
      delete correctedFormValues.Subject0;
      delete correctedFormValues.Message0;
    }
    if (formData.get('SmsSubscribeMobilePhone') === 'false') {
      formData.set('MobilePhone', '');
    }

    if (sourceid || sourceCodeId) {
      formData.set('SourceCodeId', sourceid || sourceCodeId);
    }

    if (ms || marketSource) {
      formData.set('MarketSource', ms || marketSource);
    }

    const advanceToNextState = () => {
      if (advanceFormSequence) {
        advanceFormSequence();
      } else {
        navigateToThankYouPage(formValues);
      }
    };

    let url = '';
    if (isAdvocacyForm) {
      url = `https://advocator.ngpvan.com/${encodeURIComponent(submissionUrl)}/ngpForm`;
    } else {
      url = submissionUrl;
    }
    if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'development') {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
      })
        .catch(() => setSubmitError(true))
        .then(async (response: Response) => {
          if (!response) {
            setSubmitError(true);
            return;
          }
          const body = await response.json();
          // Error if errors in json response.
          if (response.ok && !body?.errors?.length) {
            advanceToNextState();
          } else {
            console.log(body?.errors);
            setSubmitError(true);
          }
        });
    } else {
      // eslint-disable-next-line no-console -- As a safety guard on production we log the form instead of POSTing
      console.log({
        url: submissionUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });
      advanceToNextState();
    }
  };

  const isReady = !!formElements && promisesStatus.finished;

  if (!isReady) return null;

  const formKeys = Object.keys(everyActionPetitionFormValidators(isAdvocacyForm).fields);
  const storedPersonalData = Object.keys(personalDataStore).reduce(
    (accum: Record<string, string>, key: string) =>
      formKeys.includes(key) ? { ...accum, [key]: personalDataStore[key] } : accum,
    {},
  );

  const makeAdvocateStudioSnippet = (type: 'SUPPORT' | 'MAIN', id?: string) => {
    if (!id) return undefined;

    const keyword = type === 'MAIN' ? 'main' : 'support';
    return `:advstu[${id}]{ messagePart=phrase_${keyword} }`;
  };

  const initialSubject = parser(
    makeAdvocateStudioSnippet('SUPPORT', everyActionPetitionFormData.advocateStudioProjectId) ||
      eaSubject?.value,
  );

  const initialMessage = parser(
    makeAdvocateStudioSnippet('MAIN', everyActionPetitionFormData.advocateStudioProjectId) ||
      eaMessage?.value,
  );

  const { state: rememberMeState } = useRememberMe();
  const { userData: rememberMeUserData } = rememberMeState;

  const WrappedForm = withFormik<
    {
      suppliedOptions: Record<string, unknown>;
      everyActionPetitionFormData: EveryActionPetitionFormComponent;
      formType: string;
      advocacyFields: AdvocacyFields;
    },
    EveryActionPetitionFormInputValues
  >({
    handleSubmit: postForm,
    validationSchema: everyActionPetitionFormValidators(isAdvocacyForm),
    mapPropsToValues: () => ({
      FirstName: rememberMeUserData?.firstName || userData.firstName || '',
      LastName: rememberMeUserData?.lastName || userData.lastName || '',
      EmailAddress: rememberMeUserData?.email || userData.emailAddress || '',
      PostalCode: rememberMeUserData?.postcode || userData.postalCode || '',
      Country: rememberMeUserData?.country || userData.country || geoIpCountry || '',
      MobilePhone: rememberMeUserData?.mobilePhone || userData.mobilePhone || '',
      YesSignMeUpForUpdatesForBinder: userData.yesSignMeUpForUpdatesForBinder ?? true,
      SmsSubscribeMobilePhone: userData.smsSubscribeMobilePhone ?? true,
      AcceptedPrivacyPolicy: userData.acceptedPrivacyPolicy ?? false,
      ...storedPersonalData,
      Subject0: initialSubject,
      Message0: initialMessage,
      intro: eaIntro,
      outro: eaOutro,
    }),
  })(EveryActionPetitionForm);

  if (submitError) return <FormError handleRetry={() => setSubmitError(false)} />;
  return (
    <EveryActionPetitionContent
      formType={formType}
      advocacyFields={advocacyFields}
      everyActionPetitionFormData={everyActionPetitionFormData}
      suppliedOptions={suppliedOptions}
      FormContent={WrappedForm}
    />
  );
};
