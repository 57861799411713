import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { ColorTheme } from '../ColorTheme';

import { ModalContent } from './ModalContent.component';

import { Section } from '+/components/Section';
import { mainTheme } from '+/styles/themes/mainTheme';
import { NoScrollContext } from '+/contexts/NoScrollContext';
import { ModalContext } from '+/contexts/ModalContext';

/**
 * @returns `true` if the modal has been seen before, `false` if it hasn't
 */
const getAndSetSeenStorage = (id: number): boolean => {
  if (typeof window === 'undefined') return false;

  const modalsSeenString = window.localStorage.getItem('modals_seen');

  let modalsSeen: number[];
  try {
    modalsSeen = JSON.parse(modalsSeenString);
  } catch {
    /* Allow this to fail silently */
  }
  if (!Array.isArray(modalsSeen)) modalsSeen = [];

  const hasBeenSeen = modalsSeen.includes(id);

  if (!hasBeenSeen) modalsSeen.push(id);

  window.localStorage.setItem('modals_seen', JSON.stringify(modalsSeen));

  return hasBeenSeen;
};

const getFormattedHashKey = (hashKey: string): string => `#modal-${hashKey}`;
const windowHasHash = (hashKey: string): boolean =>
  typeof window !== 'undefined' && window.location.hash === hashKey;

export const Modal = ({ modalData }: { modalData: ModalComponent }) => {
  const formattedHashKey = getFormattedHashKey(modalData.hashKey);

  const {
    onModalClose, // Function to call on closing the modal
    setDisplayedModal,
    displayedModal, // Name of the modal currently displayed, if any
  } = React.useContext(ModalContext);
  const modalName = `modal-${modalData.id}`;

  useEffect(() => {
    const showModal =
      modalData.onLoad === 'always' ||
      (modalData.onLoad === 'one-time' && !getAndSetSeenStorage(modalData.id)) ||
      windowHasHash(formattedHashKey);

    if (showModal) setDisplayedModal(modalName);
  }, []);

  const onDismiss = () => {
    setDisplayedModal(null);
    if (onModalClose) onModalClose();
  };

  const onHashChange = (e: HashChangeEvent) => {
    if (modalData.hashKey) {
      const newURL = new URL(e.newURL);

      if (newURL.hash === formattedHashKey) {
        setDisplayedModal(modalName);
        window.history.replaceState(null, '', newURL.pathname + newURL.search);
      }
    }
  };

  const noScrollController = React.useContext(NoScrollContext);

  React.useEffect(() => {
    if (typeof window === 'undefined') return undefined;

    window.addEventListener('hashchange', onHashChange);

    let resumeScrolling: null | (() => void);

    if (modalData.overlay) resumeScrolling = noScrollController.pauseScrolling();

    return () => {
      window.removeEventListener('hashchange', onHashChange);

      if (resumeScrolling) resumeScrolling();
    };
  }, []);

  if (displayedModal === modalName) {
    return (
      <ThemeProvider theme={mainTheme}>
        <ColorTheme colorTheme={modalData.colorTheme}>
          <ModalContent
            onClose={modalData.dismissable ? onDismiss : null}
            overlay={modalData.overlay}
            notched={modalData.notched}
            bordered={modalData.bordered}
            width={modalData.width}
          >
            {modalData.sections.map(sectionData => (
              <Section sectionData={sectionData} />
            ))}
          </ModalContent>
        </ColorTheme>
      </ThemeProvider>
    );
  }

  return null;
};
