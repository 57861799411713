import React from 'react';

import * as Styled from './MarkdownModalTrigger.styles';

import { ModalContext } from '+/contexts/ModalContext';

interface MarkdownModalTriggerProps {
  children: JSX.Element[];
  modalName: string;
}

export const MarkdownModalTrigger = (props: MarkdownModalTriggerProps) => {
  const { setDisplayedModal } = React.useContext(ModalContext);
  const setModal = () => setDisplayedModal(props.modalName);
  return (
    <Styled.MarkdownModalTriggerButton
      type="button"
      tabIndex={0}
      onKeyPress={setModal}
      onClick={setModal}
    >
      {props.children[0]}
    </Styled.MarkdownModalTriggerButton>
  );
};
