import styled, { css, keyframes } from 'styled-components';

interface GroupProps {
  columns: number;
}

export const PickerGroup = styled.div<GroupProps>`
  display: grid;
  grid-gap: 1rem;

  ${props =>
    props.columns === 2 &&
    css`
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    `}

  ${props =>
    props.columns === 1 &&
    css`
      grid-template-columns: 1fr;
    `}
`;

const corner = keyframes`
  0% {
    border-bottom: 1.5rem solid var(--color-white);
  }

  99% {
    // we can't animate to 0 so we cheat a little
    border-bottom: 0.1rem solid var(--color-white);
  }

  100% {
    border-bottom: 0;
  }
`;

const check = keyframes`
  0% {
    width: 1px;
    height: 1px;
  }

  100% {
    width: .875rem;
    height: .875rem;
  }
`;

interface LabelProps {
  isSelected: boolean;
  variant: string;
}

export const Label = styled.label<LabelProps>`
  // override default EA form styles
  && {
    position: relative;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-family: var(--font-secondary);
    letter-spacing: 0.015em;
    transition: all 0.2s ease-in-out 0s;
    white-space: nowrap;

    svg {
      transition: all 0.2s ease-in-out 0s;
      margin-right: 0.5rem;
      width: 1px;
      height: 1px;
      animation: ${check} 0.2s ease-in-out forwards;
    }

    ${props =>
      props.variant === 'notched' &&
      css`
        background: var(--color-neutral-250);
        border: none;
        color: var(--color-neutral-500);
        font-size: 1.75rem;
        height: 4rem;
        line-height: 4rem;
        padding: 0 1.5rem;

        svg {
          fill: var(--color-neutral-500);
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          border-bottom: 1.5rem solid var(--color-white);
          border-left: 1.5rem solid transparent;
          transition: all 0.2s ease-in-out 0s;
        }

        &:focus-within {
          &:after {
            animation: ${corner} 0.2s ease-in-out forwards;
          }
        }
      `}

    ${props =>
      props.variant === 'notched' &&
      !props.isSelected &&
      css`
        &:hover {
          background: var(--color-neutral-200);
          color: var(--color-primary-500);

          svg {
            fill: var(--color-primary-500);
          }
        }
      `}

    ${props =>
      props.variant === 'notched' &&
      props.isSelected &&
      css`
        background: var(--color-primary-500);
        color: var(--color-white);

        svg {
          fill: var(--color-white);
        }

        &:after {
          animation: ${corner} 0.2s ease-in-out forwards;
        }
      `};

    ${props =>
      props.variant === 'bordered' &&
      css`
        border: 1px solid var(--color-primary-500);
        background: var(--color-white);
        color: var(--color-primary-500);
        font-size: 0.938rem;
        height: 3.5rem;
        padding: 0;

        svg {
          fill: var(--color-primary-500);
        }
      `};

    ${props =>
      props.variant === 'bordered' &&
      !props.isSelected &&
      css`
        &:hover {
          background: var(--color-primary-500);
          color: var(--color-white);

          svg {
            fill: var(--color-primary-500);
          }
        }
      `}

    ${props =>
      props.variant === 'bordered' &&
      props.isSelected &&
      css`
        background: var(--color-primary-500);
        color: var(--color-white);

        svg {
          fill: var(--color-white);
        }
      `};
  }
`;

export const Input = styled.input`
  &&& {
    appearance: none !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
    z-index: initial;
    opacity: 1;

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:focus-visible {
      // apply browser default focus ring
      // https://css-tricks.com/copy-the-browsers-native-focus-styles/
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
`;
