import styled, { css } from 'styled-components';

export const VideoWithTimeline = styled.figure`
  -webkit-font-smoothing: subpixel-antialiased;
`;

export const Title = styled.h2<{ isVisible: boolean }>`
  text-align: center;
  font-family: var(--font-tertiary);
  font-style: italic;
  font-size: 1.625rem;
  line-height: 1.3;
  transition: opacity 0.5s ease-out;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.3125rem;
    opacity: ${props => (props.isVisible ? 1 : 0)};
  }

  em,
  i {
    color: var(--color-accent);
  }
`;
export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  padding-top: 3rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    aspect-ratio: unset;
    padding-top: 0;
  }
`;

export const Video = styled.video<{
  isPlaying: boolean;
  vidDimensions: { width: number; height: number };
}>`
  width: auto;
  height: 100%;
  mask-image: linear-gradient(to bottom, transparent 2%, black 35%);
  transition: opacity 0.3s ease-out;
  object-fit: cover;

  ${props =>
    !props.isPlaying &&
    css`
      opacity: 0.7;
    `}

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    height: auto;
    max-height: 100vh;
    mask-image: linear-gradient(to bottom, transparent 0%, black 35%, black 65%, transparent 100%);

    @media (max-aspect-ratio: ${props => props.vidDimensions.width / props.vidDimensions.height}),
      (orientation: portrait) {
      height: 100vh;
    }
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4.5rem;
  height: 4.5rem;
  margin: -2.25rem 0 0 -2.25rem;
  display: block;
  transition: opacity 0.2s ease-out;
  padding: 0;
  opacity: 0.8;
  background: var(--color-theme-accent);
  color: var(--color-accent-inverse);
  border-radius: 0.125rem;
  padding: 0.9375rem;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    opacity: 1;
  }

  svg {
    display: block;
  }
`;

export const ProgressContainer = styled.div`
  position: absolute;
  width: 80%;
  left: 50%;
  top: 7%;
  max-width: 1200px;
  transform: translateX(-50%);
  height: 1rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    bottom: 10%;
    top: auto;
    width: 90%;
  }
`;

export const ProgressBar = styled.div`
  height: 0.5rem;
  width: 100%;
  position: absolute;
  transform: translateY(-50%);
  left: 0;
  top: 50%;
`;

export const ProgressBarBackground = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
`;

export const ProgressBarSeek = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: scaleX(0);
  transform-origin: left center;
`;

export const ProgressBarCompleted = styled.div<{ isSeeking: boolean }>`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.3);
  height: 100%;
  transform: scaleX(0);
  transform-origin: left center;
  background: var(--color-theme-accent);
  ${props =>
    !props.isSeeking &&
    css`
      transition: 0.3s transform linear;
    `}
`;

export const ProgressBarSeekDot = styled.button<{ isSeeking: boolean }>`
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--color-theme-accent);
  top: 50%;
  left: 0;

  margin: -0.5rem 0 0 -0.5rem;
  outline: none;
  pointer-events: none;

  transition: ${props => (props.isSeeking ? '0s' : '0.3s')} left linear, 0.2s transform ease-out;

  ${ProgressBar}:hover & {
    transform: scale(1.5);
  }
`;

export const ProgressBarTickContainer = styled.div``;
export const ProgressBarTick = styled.button<{ isPast: boolean }>`
  font-size: 0.6875rem;
  color: var(${props => (props.isPast ? '--color-theme-accent' : '--color-white')});
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  padding: 0;
  border: 0;
  outline: 0;
  display: block;

  transition: color 0.2s ease-out;

  &:hover {
    color: var(--color-theme-accent);
  }

  &:not(:first-child):not(:last-child) {
    display: none;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: block;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 1px;
    padding: 0;
    height: 1rem;
    background: currentColor;
  }
`;

export const ProgressBarTickLabel = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding-top: 0.25rem;

  ${ProgressBarTick}:first-child & {
    transform: translateX(0%);
  }

  ${ProgressBarTick}:last-child & {
    transform: translateX(-100%);
  }

  ${ProgressBarTick}:first-child &,
  ${ProgressBarTick}:last-child & {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      visibility: hidden;
    }
  }
`;

export const CaptionContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 7%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:empty {
    display: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    bottom: 20%;
  }
`;
export const Caption = styled.figcaption`
  background: rgba(15, 19, 22, 0.6);
  font-size: 1rem;
  line-height: 1.3;
  padding: 0.9em 1.5em 1em 1.5em;
  color: var(--color-white);
  font-style: italic;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    background: rgba(19, 24, 29, 0.5);
    font-size: 1.125rem;
  }

  strong,
  b {
    color: var(--color-secondary-500);
    font-weight: inherit;
  }
`;

export const Seeker = styled.input`
  height: 1rem;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
`;

export const WarningScreen = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: var(--color-white);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 1.25rem;
  }
`;

export const WarningMessage = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
`;

export const WarningButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const WarningButton = styled.button`
  background: var(--color-secondary-500);
  color: var(--color-neutral-800);
  border-radius: 0.125rem;
  padding: 0.6875em 1em;
  font-family: var(--font-secondary);
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  transition: 0.1s background-color ease-out;
  outline: none;

  &:hover,
  &:focus-visible {
    background: var(--color-secondary-300);
  }

  svg {
    display: inline-block;
    height: 0.9em;
    width: 0.9em;
    margin-top: -0.1em;
    vertical-align: middle;
    margin-right: 0.25em;
  }
`;
