import React from 'react';
import { t } from '+/utils/textDictionary';
import * as Styled from './InHonorOrInMemoryOf.styles';

export const InHonorOrInMemoryOf = ({ controller }: { controller: EveryActionModelController }) => {
  const [honoreeName, setHonoreeName] = React.useState('');
  const includeRecipient = !!controller.getEcardNotification();

  const handleInHonorOrInMemoryOfChange = event => {
    controller.setInHonorOrInMemoryOf(event.target.value.toString());
  };

  const handleHonoreeChange = event => {
    setHonoreeName(event.target.value);
  };

  const handleHonoreeBlur = () => {
    controller.setHonoreeName(honoreeName);
  };

  const handleIncludeRecipientChange = () => {
    controller.setEcardNotification(!includeRecipient);
  };

  return (
    <div className="tribute__honor-memory" hidden={!controller.getEnableTributeGift()}>
      <style>
        {!controller.getEnableTributeGift() && '#RecipientInformationHider { display: none; }'}
        {!controller.getEcardNotification() && '#RecipientFields { display: none; }'}
      </style>
      <div className="tribute__options">
        <label className="tribute__radio" title={t('Widget.InHonorOrInMemoryOf.Honor')}>
          <input
            type="radio"
            name="InHonorOrInMemoryOfRadio"
            onChange={handleInHonorOrInMemoryOfChange}
            checked={controller.getInHonorOrInMemoryOf() === '2'}
            value="2"
          />
          <span>{t('Widget.InHonorOrInMemoryOf.Honor')}</span>
        </label>
        <label className="tribute__radio" title={t('Widget.InHonorOrInMemoryOf.Memory')}>
          <input
            type="radio"
            name="InHonorOrInMemoryOfRadio"
            onChange={handleInHonorOrInMemoryOfChange}
            checked={controller.getInHonorOrInMemoryOf() === '1'}
            value="1"
          />
          <span>{t('Widget.InHonorOrInMemoryOf.Memory')}</span>
        </label>
        <label className="tribute__honoree">
          {t('Widget.InHonorOrInMemoryOf.HonoreeName')} <small>({t('Optional')})</small>
          <input
            type="text"
            autoComplete="on"
            title={t('Widget.InHonorOrInMemoryOf.HonoreeName')}
            name="HonoreeNameInput"
            value={honoreeName}
            onBlur={handleHonoreeBlur}
            onChange={handleHonoreeChange}
          />
        </label>
        {/* Include ecard */}
        <Styled.IncludeRecipientLabel className="at-check">
          <input
            type="checkbox"
            checked={includeRecipient}
            onChange={() => handleIncludeRecipientChange()}
          />
          <span className="at-checkbox-title-container">
            <span className="at-checkbox-title">
              {t('Widget.InHonorOrInMemoryOf.IncludeRecipient')}
            </span>
          </span>
        </Styled.IncludeRecipientLabel>
      </div>
    </div>
  );
};
