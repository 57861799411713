import { personalDataStore } from '+/utils/personalDataStore';

interface MarkdownPersonalizedProps {
  children: JSX.Element;
}

export const MarkdownPersonalized = ({ children }: MarkdownPersonalizedProps) => children;

export const personalize = (text, userData) => {
  const lowerCaseString = text.toLowerCase();
  const replacementMap = {};

  // Maps stored data to variables in the markdown
  const addToReplacementMap = data => {
    if (data) {
      Object.keys(data).forEach(paramName => {
        const lowerCaseKey = paramName.toLowerCase();
        const stringifiedValue = `${data[paramName]}`.trim();
        if (stringifiedValue && lowerCaseString.includes(`%${lowerCaseKey}`)) {
          replacementMap[lowerCaseKey] = stringifiedValue;
        }
      });
    }
  };

  // Find variables in the markdown for which we have form user data
  addToReplacementMap(userData);

  // Find variables in the markdown for which we have stored personal data (overrides user data)
  addToReplacementMap(personalDataStore);

  let personalizedString = text;
  Object.entries(replacementMap).forEach(([paramName, paramValue]) => {
    const markdownParam = new RegExp(`%${paramName}`, 'gi');
    personalizedString = personalizedString.replace(markdownParam, paramValue);
  });

  // Use fallback text if any residual markdown variables remain
  if (personalizedString.match(/%([^\s])*/)) {
    return null;
  }

  return personalizedString;
};
