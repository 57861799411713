import React, { useState, useCallback } from 'react';

interface ContextInterface {
  alerts: Array<string>;
  addAlert(value: string): void;
  removeAlert(value: string): void;
}

export const FormAlertContext = React.createContext<ContextInterface>({
  alerts: [],
  addAlert: () => {},
  removeAlert: () => {},
});

export const FormAlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (alert: string) => {
    if (!alerts.includes(alert)) {
      setAlerts([...alerts, alert]);
    }
  };

  const removeAlert = (alert: string) => {
    if (alerts.includes(alert)) {
      let updatedAlerts = [...alerts];
      const alertIndex = updatedAlerts.indexOf(alert);
      updatedAlerts.splice(alertIndex, 1);
      setAlerts(updatedAlerts);
    }
  };

  const contextValue: ContextInterface = {
    alerts,
    addAlert: useCallback(alert => addAlert(alert), [alerts]),
    removeAlert: useCallback(alert => removeAlert(alert), [alerts]),
  };

  return <FormAlertContext.Provider value={contextValue}>{children}</FormAlertContext.Provider>;
};
