import { parseFullName } from 'parse-full-name';

export default class FormikNameController {
  values: {
    FirstName: string;
    LastName: string;
    MiddleName: string;
    Prefix: string;
    Suffix: string;
  };

  setFieldValue: (string, any) => void;

  constructor({ values, setFieldValue }) {
    this.values = values;
    this.setFieldValue = setFieldValue;
  }

  getFirstName(): string {
    return this.values.FirstName || '';
  }

  setFirstName(name): void {
    this.setFieldValue('FirstName', name);
  }

  getMiddleName(): string {
    return this.values.MiddleName || '';
  }

  setMiddleName(name): void {
    this.setFieldValue('MiddleName', name);
  }

  getLastName(): string {
    return this.values.LastName || '';
  }

  getPrefix(): string {
    return this.values.Prefix || '';
  }

  setPrefix(name): void {
    this.setFieldValue('Prefix', name);
  }

  setLastName(name): void {
    this.setFieldValue('LastName', name);
  }

  getSuffix(): string {
    return this.values.Suffix || '';
  }

  setSuffix(name): void {
    this.setFieldValue('Suffix', name);
  }

  getFullName(): string {
    const names = [];

    names.push(this.getPrefix());
    names.push(this.getFirstName());
    names.push(this.getMiddleName());
    names.push(this.getLastName());
    names.push(this.getSuffix());

    return names.filter(Boolean).join(' ');
  }

  inferMiddleName(): string {
    const name = parseFullName(
      // Supply dummy last name in case it is still blank
      `${this.getFirstName()} ${this.getMiddleName()} ${this.getLastName() || 'Johnson'}`,
      'all',
      0,
    );
    return (name.middle || '').trim();
  }

  inferPrefix(): string {
    const name = parseFullName(
      `${this.getFirstName()} ${this.getMiddleName()} ${this.getLastName()}`,
      'all',
      0,
    );
    return (name.title || '').trim();
  }

  inferSuffix(): string {
    const name = parseFullName(
      `${this.getFirstName()} ${this.getMiddleName()} ${this.getLastName()}`,
      'all',
      0,
    );
    return (name.suffix || '').trim();
  }
}
