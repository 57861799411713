import styled from 'styled-components';

import { textH2 } from '+/styles/reusable/textStyles';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  span {
    ${textH2};
    padding: 50px;
  }

  button {
    width: fit-content;
  }
`;
