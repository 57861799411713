import React from 'react';

interface MarkdownCounterProps {
  children?: JSX.Element;
  minimum?: number;
}

export const MarkdownCounter = ({ children, minimum }: MarkdownCounterProps) => {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (children[0]) {
      (async function fetchAndSet() {
        const result = await fetch(
          `https://secure.everyaction.com/v2/forms/${children[0]}/progress`,
        );
        const json = await result.json();
        if (!json) {
          return;
        }
        setCount(json.totalSubmissionProgress);
      })();
    }
  }, []);

  return count >= minimum ? <span>{count.toLocaleString()}</span> : null;
};

MarkdownCounter.defaultProps = {
  children: null,
  minimum: 1,
};
