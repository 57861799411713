import styled, { css } from 'styled-components';

import { Text } from '+/components/Text/Text.styles';
import { Content } from '+/styles/styledComponents/Form.styles';
import { textH1 } from '+/styles/reusable/textStyles';

export const MarkdownForm = styled.div<{ isPadded?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  h1 {
    ${textH1}
    letter-spacing: -0.02em;
    padding: 1rem 0;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding: 0 3rem;
    }
  }

  ${Content} {
    flex: 1;
    text-align: center;

    padding-left: 1.5rem;
    padding-right: 1.5rem;

    ${props =>
      !props.isPadded &&
      css`
        clip-path: none;
        padding-top: 0;
      `}

    p > em {
      font-family: var(--font-tertiary);
      font-size: 1.25rem;
      margin: 0.125rem 0;
      display: inline-block;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        margin: 0.5rem 0;
      }
    }

    button {
      margin: 0.5rem 0;
      background: var(--color-theme-light);
      width: 100%;

      :hover {
        background: var(--color-theme-dark);
      }

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        width: auto;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  ${Text} {
    max-width: 30rem;
    padding: ${props => (props.isPadded ? '3.5rem' : '0')} 0 2rem 0;

    p {
      margin-bottom: 1.5rem;
    }
  }
`;
