import React from 'react';
import { Tooltip } from '+/components/Tooltip';
import { t } from '+/utils/textDictionary';

// NOTE: Remember Me is THL's own implementation of a "remember my details" feature. It is not associated with EveryAction.
export const RememberMe = ({ checked, handleChange }) => {
  return (
    <React.Fragment>
      <label className="at-check">
        <input type="checkbox" onChange={handleChange} checked={checked} />{' '}
        <span className="at-checkbox-title-container">
          <span className="at-checkbox-title">Remember my details</span>
        </span>
        <Tooltip
          teaser={t('Widget.RememberMe.Tooltip.Teaser')}
          content={t('Widget.RememberMe.Tooltip.Content')}
          inline={true}
        />
      </label>
    </React.Fragment>
  );
};
