/*
 * Adds amount options with customized behavior.
 */

import React, { useEffect, useRef, useState, RefObject } from 'react';
import { AnimatePresence } from 'framer-motion';
import { t } from '+/utils/textDictionary';
import { PickerGroup, PickerButton } from '+/components/Picker';
import * as Styled from './MendedHeartSocietyAmountOptions.styles';
import { MHS_TIERS } from '+/utils/constants/mendedHeartSociety';

export const MendedHeartSocietyAmountOptions = ({
  controller,
  amounts,
  isRecurring,
  onCustom,
  customValue,
  selectedAmount,
  firstAmountButtonRef,
  customInputRef,
  handleAmountChange,
  handleCustomChange,
  handleOtherInputBlur,
  setToCustom,
}: {
  controller: EveryActionModelController;
  amounts: Array<number>;
  isRecurring: boolean;
  onCustom: boolean;
  customValue?: string;
  selectedAmount: number;
  firstAmountButtonRef: RefObject<HTMLInputElement>;
  customInputRef: RefObject<HTMLInputElement>;
  handleAmountChange: (number) => void;
  handleCustomChange: (number) => void;
  handleOtherInputBlur: () => void;
  setToCustom: () => void;
}) => {
  return (
    <PickerGroup columns={1}>
      {controller.getAmountOptions().map((amount, idx) => {
        const minimumFractionDigits = amount - Math.floor(amount) !== 0 ? 2 : 0;
        const { label } = MHS_TIERS.find(t => t.amount === amount) || { label: '' };

        return (
          <PickerButton
            checkmark={false}
            key={`${amount}, ${isRecurring}: ${idx}`}
            name="custom_widget_amount"
            isSelected={amount === controller.getSelectedAmount() && !onCustom}
            ref={idx === 0 ? firstAmountButtonRef : null}
            onChange={() => handleAmountChange(amount)}
            aria-label={`${label} – ${controller.getCurrencySymbol()}${amount.toLocaleString(
              undefined,
              { minimumFractionDigits },
            )}`}
          >
            <Styled.TierLabel>{label}</Styled.TierLabel>
            <Styled.SmallCurrency>{controller.getCurrencySymbol()}</Styled.SmallCurrency>
            <Styled.Amount>
              {amount.toLocaleString(undefined, { minimumFractionDigits })}
            </Styled.Amount>
            {isRecurring && <Styled.SmallLabel>{t('PerMonth.Abbreviation')}</Styled.SmallLabel>}
          </PickerButton>
        );
      })}
      <Styled.OtherWrapper>
        <PickerButton
          checkmark={false}
          name="custom_widget_amount"
          data-idf="amount-options-custom-button"
          onChange={() => setToCustom()}
          isSelected={onCustom}
          aria-label="Custom amount"
        >
          <Styled.CustomLabel>
            {t('Widget.MendedHeartSocietyAmountOptions.CustomLabel')}
          </Styled.CustomLabel>
        </PickerButton>
        <AnimatePresence>
          {onCustom && (
            <Styled.CustomInputWrapper
              key="custom-input-wrapper"
              initial={{ width: 'calc(0% - 0rem)' }}
              animate={{ width: 'calc(50% - .5rem)' }}
              exit={{ width: 'calc(0% - 0rem)' }}
              transition={{ duration: 0.25, delay: 0.2 }}
            >
              <Styled.CustomInputLabel
                key="custom-input-label"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.45, duration: 0.2 }}
              >
                <span>{controller.getCurrencySymbol()}</span>
                <Styled.CustomInput
                  ref={customInputRef}
                  onChange={e => handleCustomChange(e)}
                  value={customValue || ''}
                  type="number"
                  step="1"
                  min="0"
                  pattern="^\d*(\.\d{0,2})?$"
                  aria-label={t('Widget.AmountOptions.A11yCustomLabel')}
                  placeholder={selectedAmount.toString()}
                  onBlur={() => handleOtherInputBlur()}
                />
              </Styled.CustomInputLabel>
            </Styled.CustomInputWrapper>
          )}
        </AnimatePresence>
      </Styled.OtherWrapper>
    </PickerGroup>
  );
};
