import { TargetAndTransition, Variants } from 'framer-motion';

import { colors } from '+/styles/themes/colors';

interface ExpandedVariantOptions {
  index: number;
  length: number;
  isEven: boolean;
  isLast: boolean;
}

export const TitlePartVariants: Variants = {
  contracted: (options: ExpandedVariantOptions): TargetAndTransition => ({
    backgroundSize: '0% 100%',
    // color: options.color === 'secondary' ? colors.white : colors['neutral-800'],
    color: 'var(--color-theme-base-inverse)',

    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 0.25,
      delay: (options.length - options.index) * 0.25,
    },
  }),
  expanded: (options: ExpandedVariantOptions): TargetAndTransition => ({
    backgroundSize: '100% 100%',
    // color: options.color === 'secondary' ? colors['neutral-800'] : colors.white,
    color: 'var(--color-theme-accent-inverse)',

    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 0.25,
      delay: options.index * 0.25,
    },
  }),
};
