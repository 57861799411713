import React from 'react';
import * as Styled from './DotFilter.styles';

export const DotFilter = ({ incidentType, isActive, title, handleClick }: DotFilterComponent) => {
  return (
    <Styled.DotFilter isActive={isActive} onClick={() => handleClick()} type="button">
      {incidentType && <Styled.Dot incidentType={incidentType} />}
      <Styled.Title>{title}</Styled.Title>
    </Styled.DotFilter>
  );
};
