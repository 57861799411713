import React from 'react';

import * as Styled from './LogoTextAndButton.styles';

import { Button } from '+/components/Button';
import { ColorTheme } from '+/components/ColorTheme';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

export const LogoTextAndButton = ({
  logoTextAndButtonData,
}: {
  logoTextAndButtonData: LogoTextAndButtonComponent;
}) => {
  const LogoWrapper = logoTextAndButtonData.logoUrl
    ? ({ children }) => <Styled.Link href={logoTextAndButtonData.logoUrl}>{children}</Styled.Link>
    : React.Fragment;
  return (
    <ColorTheme colorTheme={ColorThemeNames.GreyFilledRedAccented}>
      <Styled.LogoTextAndButton>
        {logoTextAndButtonData.logo && (
          <LogoWrapper>
            <Styled.ImageItem imageItemData={logoTextAndButtonData.logo} />
          </LogoWrapper>
        )}
        {logoTextAndButtonData.text && <Styled.Text>{logoTextAndButtonData.text}</Styled.Text>}
        {logoTextAndButtonData.callToActionButton && (
          <Styled.Link href={logoTextAndButtonData.callToActionButton.uRL}>
            <Button narrow>{logoTextAndButtonData.callToActionButton.label}</Button>
          </Styled.Link>
        )}
        {logoTextAndButtonData.disclaimerText && (
          <Styled.DisclaimerText>{logoTextAndButtonData.disclaimerText}</Styled.DisclaimerText>
        )}
      </Styled.LogoTextAndButton>
    </ColorTheme>
  );
};
