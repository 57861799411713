import unified from 'unified';
import parse from 'remark-parse';
import directive from 'remark-directive';
import stringify from 'remark-stringify';
import visit from 'unist-util-visit';

import getASContent from '+/utils/getASContent';

const ASTERISK_REPLACE = '!asterisk!';
const UNDERSCORE_REPLACE = '!underscore!';
const POUND_REPLACE = '!pound!';
const COLON_REPLACE = '!colon!';
const START_DIRECTIVE_REPLACE = '!colon-space! ';

const decode = (content = '') =>
  content
    .replace(/\n$/, '')
    .replace(new RegExp(ASTERISK_REPLACE, 'g'), '*')
    .replace(new RegExp(UNDERSCORE_REPLACE, 'g'), '_')
    .replace(new RegExp(POUND_REPLACE, 'g'), '#')
    .replace(new RegExp(COLON_REPLACE, 'g'), ':')
    .replace(new RegExp(START_DIRECTIVE_REPLACE, 'g'), '');

const encode = (content = '') =>
  content
    .replace(/\*/g, ASTERISK_REPLACE)
    .replace(/_/g, UNDERSCORE_REPLACE)
    .replace(/#/g, POUND_REPLACE)
    // adds space to allow mid-word directives
    .replace(/(\S):([a-zA-Z]+?)\[/g, `$1${START_DIRECTIVE_REPLACE}:$2[`)
    // Replace all non-leading colons
    .replace(/(\S):/g, `$1${COLON_REPLACE}`);

// Encode special characters to prevent "invalid character" EveryAction form submission errors
export const encodeSpecialCharacters = content =>
  content.replace(/\\/g, '\\\\').replace(/\r/g, '\\r').replace(/\n/g, '\\n').replace(/"/g, '\\"');

// Goes through node tree and fetches from advocate studio for each node to
// transfom
const advocateStudioTransform = defaultKey => () => {
  const nodesToTransform = [];

  const addNodeToTransform = node => {
    if (node.name === 'advstu') {
      nodesToTransform.push(node);
    }
    return node;
  };

  const searchTreeForNodesToTransform = tree => {
    visit(tree, ['textDirective', 'leafDirective', 'containerDirective'], addNodeToTransform);
  };

  const handleTransformPromises = () =>
    nodesToTransform.map(node => {
      const { children = [] } = node || {};
      const code = children[0] && node.children[0].value;
      const { contextKey, messagePart } = node.attributes;

      if (!code) return null;

      // Rewrite node as fetched text
      // eslint-disable-next-line no-param-reassign
      Object.keys(node).forEach(objKey => delete node[objKey]);
      // eslint-disable-next-line no-param-reassign
      node.type = 'text';

      const content = getASContent(code, {
        contextKey: decode(contextKey || defaultKey),
        messagePart: decode(messagePart),
      });
      // eslint-disable-next-line no-param-reassign
      node.value = encode(content);

      return node;
    });

  return tree => {
    searchTreeForNodesToTransform(tree);
    handleTransformPromises();
  };
};

export const parser = (content, key) => {
  const encodedContent = content ? encode(content) : '';

  let result;
  const file = unified()
    .use(parse)
    .use(directive)
    .use(advocateStudioTransform(key))
    .use(stringify)
    .processSync(encodedContent);

  if (file) {
    // remove final line added because of root paragraph tag
    const decodedContent = decode(String(file));

    result = decodedContent;
  } else {
    result = '';
  }

  return result;
};
