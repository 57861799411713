import { parseFullName } from 'parse-full-name';

import params from '+/utils/queryParams';
import { analyticsContext } from '+/utils/analyticsContext';

const MANAGE_RECURRING_DONATION_STEP = 'ManageRecurringDonationStep';

export class EveryActionModelController {
  view: EveryActionView;

  donateForm: DonateFormComponent;

  amountChanged: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oldVals: any;

  oldStep?: number | string; // Steps are a number if EA or a string is custom.

  customStep?: string; // EA forms are always on a step, if this is defined, it takes precedence.

  invalidAmount: boolean;

  isLoggedInToFastAction: boolean;

  fastActionName: string;

  maxStepReached: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oldDatabag: any;

  isExistingRecurringDonor: boolean;

  lastCredsCheckedForDonorStatus?: string; // Creds last submitted. Used to observe changes.

  donorStatusQueryTimeout: number;

  constructor(view: EveryActionView, submit: () => void, donateForm: DonateFormComponent) {
    this.fastActionName = '';
    this.isLoggedInToFastAction = false;
    this.isExistingRecurringDonor = false;
    this.view = view;
    this.submit = submit;
    this.oldVals = { ...view.val() };
    this.donateForm = donateForm;
    this.maxStepReached = 0;
    this.customStep = '';
    this.oldDatabag = { ...view.options?.profile_databag?.changed };
  }

  areNewUpdates(): boolean {
    const vals = this.view.val();
    const databag = this.view.options?.profile_databag?.changed;

    if (
      Object.keys(vals).some(key => !Object.keys(this.oldVals).includes(key)) ||
      Object.keys(this.oldVals).some(key => !Object.keys(vals).includes(key)) ||
      Object.keys(vals).some(key => vals[key] !== this.oldVals[key]) ||
      this.getStep() !== this.oldStep ||
      databag !== this.oldDatabag
    ) {
      this.oldVals = { ...vals };
      this.oldStep = this.getStep();
      this.oldDatabag = databag;
      return true;
    }
    return false;
  }

  areAnyErrors(): boolean {
    return !this.view.step().view.every(v => v.renderFeedback().length === 0);
  }

  isInvalidAmount(): boolean {
    return !!this.invalidAmount;
  }

  // Wait for pause in user typing, then forward any changed creds to AWS function to check user recurring donor status;
  checkCredsForRecurringDonor(): void {
    const nextCredsToCheckForDonorStatus = `${this.getFirstName()} ${this.getLastName()} ${this.getEmailAddress()}`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const controller = this;
    // Normally wait to see if user will keep typing to avoid fetch requests. Skip wait if it looks like the end of an email address.
    const emailEndRegExp = /(\.com|\.edu|\.co\.uk|\.org)$/;
    const pauseLength =
      !(this.lastCredsCheckedForDonorStatus || '').match(emailEndRegExp) &&
      nextCredsToCheckForDonorStatus.match(emailEndRegExp)
        ? 0
        : 750;

    if (this.lastCredsCheckedForDonorStatus !== nextCredsToCheckForDonorStatus) {
      this.lastCredsCheckedForDonorStatus = nextCredsToCheckForDonorStatus;
      if (this.donorStatusQueryTimeout) clearTimeout(this.donorStatusQueryTimeout);
      // window.setTimeout returns correct type, setTimeout does not.
      this.donorStatusQueryTimeout = window.setTimeout(() => {
        const url = `https://fvk2pgdzra.execute-api.us-east-1.amazonaws.com/SPB-Recurring-Donation-Data?firstName=${this.getFirstName()}&lastName=${this.getLastName()}&email=${this.getEmailAddress()}`;
        fetch(url).then(r => {
          if (r.ok) {
            r.json().then(value => {
              controller.isExistingRecurringDonor = value;
            });
          }
        });
      }, pauseLength);
    }
  }

  setAmountChanged(value = true): void {
    this.amountChanged = value;
  }

  setIfChanged(newValues): void {
    const keysToCheck = Object.keys(newValues);
    // Check if any values are changed before committing.
    // EveryAction does some unexpected things on setval, so don't call
    // More often than needed.
    if (keysToCheck.some(key => newValues[key] !== this.view.val(key))) {
      this.view.setval(newValues);
    }
  }

  defaultFrequency(): 'recurring' | 'one-time' {
    return params.r === 'false' || this.donateForm.frequencyType === 'one-time'
      ? 'one-time'
      : 'recurring';
  }

  defaultAmountRecurring(): number {
    if (this.defaultFrequency() === 'recurring') {
      return params.recurringAm || params.am || this.donateForm.defaultDonationAmountRecurring;
    }
    return this.donateForm.defaultDonationAmountRecurring;
  }

  defaultAmountOneTime(): number {
    if (this.defaultFrequency() === 'one-time') {
      return params.am || this.donateForm.defaultDonationAmountOneTime;
    }
    return this.donateForm.defaultDonationAmountOneTime;
  }

  setSelectedAmountToDefault(): void {
    this.setSelectedAmount(
      this.getMonthly() ? this.defaultAmountRecurring() : this.defaultAmountOneTime(),
      true,
    );
  }

  // Return the value of the upsell value calculation
  // unless it is more or less than the max and min.
  getSuggestedUpsellAmount(): number {
    const performCalculation = (amount: number, equation: string): number => {
      const divisor = parseFloat((equation.match(/Amount \/ (\d*)/) || [])[1]);
      const addition =
        parseFloat((equation.match(/ \+ (\d*)/) || equation.match(/^(\d*)$/) || [])[1]) || 0;
      if (!divisor) {
        return addition;
      }
      return Math.ceil(amount / divisor + addition);
    };
    const amount = this.getSelectedAmount();
    const minimum = performCalculation(
      amount,
      this.donateForm.upsellModal.upsellValueCalculationMinimum || '3',
    );
    const maximum = performCalculation(
      amount,
      this.donateForm.upsellModal.upsellValueCalculationMaximum || '1000',
    );
    const target = performCalculation(
      amount,
      this.donateForm.upsellModal.upsellValueCalculation || 'Amount / 3',
    );
    return Math.min(maximum, Math.max(minimum, target));
  }

  onManageRecurringDonationStep(): boolean {
    return this.getStep() === MANAGE_RECURRING_DONATION_STEP;
  }

  shouldShowManageRecurringDonationStep(): boolean {
    return this.getMonthly() && this.isExistingRecurringDonor;
  }

  onFirstStep(): boolean {
    return this.getStep() === 0;
  }

  onFinalStep(): boolean {
    return this.view.steps.length - 1 === this.getStep();
  }

  getStep(): number | string {
    if (this.customStep) return this.customStep;
    return this.view.step().index;
  }

  getNextStep(): number | string {
    const steps: Array<number | string | null> = [
      0,
      1,
      this.shouldShowManageRecurringDonationStep() ? MANAGE_RECURRING_DONATION_STEP : null,
      2,
      3, // Generally this step won't exist.
    ].filter(v => v === 0 || Boolean(v)); // Filter out missing steps

    const nextStep = steps[steps.indexOf(this.getStep()) + 1];
    return nextStep;
  }

  getPrevStep(): number | string {
    const steps: Array<number | string | null> = [
      0,
      1,
      this.shouldShowManageRecurringDonationStep() ? MANAGE_RECURRING_DONATION_STEP : null,
      2,
      3, // Generally this step won't exist.
    ].filter(v => v === 0 || Boolean(v)); // Filter out missing steps

    const prevStep = steps[steps.indexOf(this.getStep()) - 1];
    return prevStep;
  }

  getMaxStepReached(): number {
    return this.maxStepReached;
  }

  // Advance to next step
  nextStep(): void {
    const nextStep = this.getNextStep();
    if (typeof nextStep === 'string') {
      this.customStep = nextStep;
    } else {
      this.customStep = null;
      this.view.nextStep();
      this.maxStepReached = this.view.step().index;
    }
    const mobileSafariHtml = document.querySelector('.ua-safari.ua-mobile');

    if (mobileSafariHtml) {
      mobileSafariHtml.classList.add('repaint');
      setTimeout(() => {
        mobileSafariHtml.classList.remove('repaint');
      }, 100);
    }
  }

  prevStep(): void {
    const prevStep = this.getPrevStep();
    if (typeof prevStep !== 'number') {
      this.customStep = prevStep;
      // Return to previous step;
      if (typeof this.getStep() === 'number') {
        this.view.prevStep();
      }
    } else {
      this.customStep = null;
      if (this.view.step().index !== prevStep) {
        this.view.setStep(prevStep);
      }
    }
    this.clearFeedback();
  }

  setStep(step): void {
    if (typeof step === 'number') {
      this.customStep = null;
    } else {
      this.customStep = step;
    }
    this.view.setStep(step);
    this.clearFeedback();

    if (this.view.step().index > this.maxStepReached) {
      this.maxStepReached = this.view.step().index;
    }

    this.view.step().view.subviews?.forEach(subview => {
      if (!this.view.step().view.includes(subview) && subview.clearFeedback) {
        subview.clearFeedback();
      }
    });
  }

  // Helper function to add symbol and correct decimals to any amount
  getAmountWithCurrencySymbol(amount: number): string {
    // Only add decmimal places if not a whole number
    const minimumFractionDigits = amount - Math.floor(amount) !== 0 ? 2 : 0;
    return `${this.getCurrencySymbol()}${amount.toLocaleString(undefined, {
      minimumFractionDigits,
    })}`;
  }

  // SelectedAmount = actual amount selected by donor
  getSelectedAmount(): number {
    return parseFloat(this.view.val('Amount'));
  }

  getSelectedAmountWithCurrencySymbol(): string {
    const selectedAmount = this.getSelectedAmount();
    return this.getAmountWithCurrencySymbol(selectedAmount);
  }

  setSelectedAmount(amount: number, isDefaultChange: boolean): void {
    this.amountChanged = isDefaultChange ? this.amountChanged : true;
    if (amount >= 3) {
      this.invalidAmount = false;
    } else {
      this.invalidAmount = true;
    }
    this.setIfChanged({ Amount: amount });
    analyticsContext.setContext('selectedDonationAmount', amount);
  }

  // TotalAmount = amount selected by donor + "cover my fees" opt-in
  getTotalAmount(): number {
    return this.getSelectedAmount() + (this.view.val('CoverCostsAmount') || 0);
  }

  getTotalAmountWithCurrencySymbol(): string {
    const totalAmount = this.getTotalAmount();
    return this.getAmountWithCurrencySymbol(totalAmount);
  }

  getCurrency(): string {
    return this.view.val('ProcessingCurrency');
  }

  setCurrency(shortName): void {
    this.setIfChanged({ ProcessingCurrency: shortName });
    analyticsContext.setContext('selectedDonationCurrency', shortName);
  }

  getMaxAmount(): number {
    let maxAmount = 999999.99;
    try {
      maxAmount =
        this.view.subviews.ContributionInformation.subviews.SelectAmount.options.definition
          .valueMax;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return maxAmount;
  }

  getMinAmount(): number {
    let minAmount = 5;
    try {
      minAmount =
        this.view.subviews.ContributionInformation.subviews.SelectAmount.options.definition
          .valueMin;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return minAmount;
  }

  getCurrencySymbol(): string {
    const currencyCode = this.view.val('ProcessingCurrency');
    return {
      USD: '$',
      CAD: '$',
      GBP: '£',
      EUR: '€',
    }[currencyCode];
  }

  getAmountOptions(): Array<number> {
    const queryStringNumOpts = parseInt(params.numOpts, 10);
    const inferredNumOpts = (
      this.donateForm.suggestedDonationAmountsOneTime ||
      this.donateForm.suggestedDonationAmountsRecurring ||
      []
    ).length;
    const numOpts = queryStringNumOpts || inferredNumOpts || 5;
    const fallbacks = [10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000].slice(0, numOpts);
    try {
      const amtopts =
        this.view.options.query_string.amtopts || this.view.options.query_string.amtOpts;
      const recurringamtopts =
        this.view.options.query_string.recurringamtopts ||
        this.view.options.query_string.recurringAmtOpts;
      const fromOneTimeQueryString = amtopts ? amtopts.split(',').map(a => parseInt(a, 10)) : [];
      const oneTimeAmtOptsArray =
        fromOneTimeQueryString[0] && fromOneTimeQueryString.slice(0, numOpts);

      //  If recurring amounts param is specified use that,
      //  else use default amtopts after numOpts
      const recurringAmtOptsArray = recurringamtopts
        ? recurringamtopts
            .split(',')
            .map(a => parseInt(a, 10))
            .slice(0, numOpts)
        : fromOneTimeQueryString[numOpts] && fromOneTimeQueryString.slice(numOpts, numOpts * 2);

      return this.getMonthly()
        ? recurringAmtOptsArray || this.getSuggestedDonationAmountsRecurring() || fallbacks
        : oneTimeAmtOptsArray || this.getSuggestedDonationAmountsOneTime() || fallbacks;
    } catch (e) {
      // return sensible defaults
      return fallbacks;
    }
  }

  getSuggestedDonationAmountsRecurring(): Array<number> {
    return this.donateForm.suggestedDonationAmountsRecurring || [];
  }

  getSuggestedDonationAmountsOneTime(): Array<number> {
    return this.donateForm.suggestedDonationAmountsOneTime || [];
  }

  usesCustomAmountOptions(): boolean {
    return !!this.view.options.query_string.amtopts;
  }

  setMonthly(value): void {
    this.setIfChanged({ IsRecurring: value });
    if (!this.amountChanged && this.onFirstStep()) {
      this.setSelectedAmountToDefault();
    }
    analyticsContext.setContext('selectedDonationFrequency', value);
  }

  getMonthly(): boolean {
    return this.view.val('IsRecurring');
  }

  isUS(): boolean {
    if (!this.noInternationalAlternative()) {
      return (
        !this.noUSAlternative() &&
        (params.currency === 'USD' ||
          (this.donateForm.defaultCurrency === 'USD' && !params.currency))
      );
    }
    return true;
  }

  isInternational(): boolean {
    return !this.isUS();
  }

  relevantFormId(): string {
    if (this.isUS()) return this.donateForm.usFormId;
    if (this.isInternational()) return this.donateForm.internationalFormId;
    return 'not found';
  }

  noInternationalAlternative(): boolean {
    return !this.donateForm.internationalFormId;
  }

  noUSAlternative(): boolean {
    return !this.donateForm.usFormId;
  }

  enabledFeeCoverage(): boolean {
    return this.donateForm.enabledFeeCoverage;
  }

  getAmountOptionsType(): string {
    return this.donateForm.amountOptionsType;
  }

  getFrequencyType(): string {
    return this.donateForm.frequencyType;
  }

  includesBothFrequencyTypes(): boolean {
    return this.getFrequencyType() === 'both';
  }

  setAddressLine1(address1): void {
    this.setIfChanged({ AddressLine1: address1 });
  }

  getAddressLine1(): string {
    return this.view.val('AddressLine1');
  }

  setMailingAddress(address1, locality, state, postcode, country): void {
    this.setIfChanged({
      Country: country,
      AddressLine1: address1,
      City: locality,
      StateProvince: state,
      PostalCode: postcode,
    });
  }

  getMailingAddress(): string {
    const address = [];
    const addressBothLines = `
      ${this.view.val('AddressLine1')}${
      this.view.val('AddressLine2') ? ` ${this.view.val('AddressLine2')},` : ','
    }
    `;

    address.push(addressBothLines);
    address.push(`${this.view.val('City')},`);
    address.push(this.view.val('StateProvince'));
    address.push(this.view.val('PostalCode'));

    return address.filter(Boolean).join(' ');
  }

  setEnableTributeGift(value): void {
    this.setIfChanged({ EnableTributeGift: value });
  }

  getEnableTributeGift(): boolean {
    return this.view.val('EnableTributeGift');
  }

  setInHonorOrInMemoryOf(value): void {
    this.setIfChanged({ InHonorOrInMemoryOf: value });
  }

  getInHonorOrInMemoryOf(): string {
    return this.view.val('InHonorOrInMemoryOf');
  }

  setEcardNotification(value): void {
    this.setIfChanged({ IncludeRecipient: value });
  }

  getEcardNotification(): string {
    return this.view.val('IncludeRecipient');
  }

  setHonoreeName(value): void {
    this.setIfChanged({ HonoreeName: value });
  }

  getHonoreeName(): string {
    return this.view.val('HonoreeName');
  }

  getPaymentMethod(): string {
    return this.view.val('PaymentMethod');
  }

  setPaymentMethod(value): void {
    const methodView = this.view.subviews.PaymentMethodSection.subviews.PaymentMethod;
    // creditcard, paypal, eft
    if (value === 'paypal') {
      methodView.authorizePayPalBraintree();
    } else if (value === 'applepay') {
      methodView.authorizeApplePay();
    } else {
      methodView.setPaymentMethod(value);
    }
  }

  getPrefix(): string {
    return this.view.val('Prefix') || '';
  }

  setPrefix(name): void {
    this.setIfChanged({ Prefix: name });
  }

  getFirstName(): string {
    return this.view.val('FirstName') || '';
  }

  setFirstName(name): void {
    this.setIfChanged({ FirstName: name });
  }

  getMiddleName(): string {
    return this.view.val('MiddleName') || '';
  }

  setMiddleName(name): void {
    this.setIfChanged({ MiddleName: name });
  }

  getLastName(): string {
    return this.view.val('LastName') || '';
  }

  setLastName(name): void {
    this.setIfChanged({ LastName: name });
  }

  getSuffix(): string {
    return this.view.val('Suffix') || '';
  }

  setSuffix(name): void {
    this.setIfChanged({ Suffix: name });
  }

  getFullName(): string {
    const names = [];

    names.push(this.getPrefix());
    names.push(this.getFirstName());
    names.push(this.getMiddleName());
    names.push(this.getLastName());
    names.push(this.getSuffix());

    return names.filter(Boolean).join(' ');
  }

  inferMiddleName(): string {
    const name = parseFullName(
      // Supply dummy last name in case it is still blank
      `${this.getFirstName()} ${this.getMiddleName()} ${this.getLastName() || 'Johnson'}`,
      'all',
      0,
    );
    return (name.middle || '').trim();
  }

  inferPrefix(): string {
    const name = parseFullName(
      `${this.getFirstName()} ${this.getMiddleName()} ${this.getLastName()}`,
      'all',
      0,
    );
    return (name.title || '').trim();
  }

  inferSuffix(): string {
    const name = parseFullName(
      `${this.getFirstName()} ${this.getMiddleName()} ${this.getLastName()}`,
      'all',
      0,
    );
    return (name.suffix || '').trim();
  }

  getEmailAddress(): string {
    return this.view.val('EmailAddress');
  }

  getCountry(): string {
    return this.view.val('Country');
  }

  getPostalCode(): string {
    return this.view.val('PostalCode');
  }

  // This returns true unless address fields are blank. (except line1)
  hasAddressFieldEntered(): boolean {
    return !!(
      this.view.val('AddressLine2') ||
      this.view.val('City') ||
      this.view.val('Locality') ||
      this.view.val('PostalCode')
    );
  }

  isUsMailingAddress(): boolean {
    return this.view.val('Country') === 'US';
  }

  bankSelected(): boolean {
    return this.getPaymentMethod() === 'eft';
  }

  creditCardSelected(): boolean {
    return this.getPaymentMethod() === 'creditcard';
  }

  bankOrCreditCardSelected(): boolean {
    return this.bankSelected() || this.creditCardSelected();
  }

  payPalSelected(): boolean {
    return this.getPaymentMethod() === 'paypal';
  }

  applePaySelected(): boolean {
    return this.getPaymentMethod() === 'applepay';
  }

  creditCardAvailable(): boolean {
    return this.view.paymentMethodConfiguration.isCcEnabled;
  }

  bankAvailable(): boolean {
    return this.view.paymentMethodConfiguration.isEftEnabled;
  }

  payPalAvailable(): boolean {
    return this.view.paymentMethodConfiguration.isPayPalEnabled;
  }

  applePayAvailable(): boolean {
    return this.view.paymentMethodConfiguration.isApplePayEnabled;
  }

  isUpsellEligible(): boolean {
    const currentAmount = this.getSelectedAmount();
    const { donateForm } = this;
    return (
      !!donateForm.upsellModal &&
      this.getMonthly() === false &&
      currentAmount >= (donateForm.upsellModal.upsellMinimumValueForDisplay || 3) &&
      currentAmount <= (donateForm.upsellModal.upsellMaximumValueForDisplay || 1000)
    );
  }

  getTransactionFee(): boolean {
    return this.view.val('CoverCostsAmount');
  }

  setTransactionFee(value: boolean): void {
    this.setIfChanged({ CoverCostsAmount: value });
  }

  calculateTransactionFee(): number {
    return 0;
  }

  submit(): void {
    this.submit();
  }

  setFastActionLoggedIn(value = true): void {
    this.isLoggedInToFastAction = value;
  }

  getFastActionLoggedIn(): boolean {
    return !!this.isLoggedInToFastAction;
  }

  getFastActionPrefilled(): boolean {
    return !!this.view.options.profile_databag?.changed;
  }

  setFastActionName(value: string): void {
    this.fastActionName = value;
  }

  getFastActionName(): string {
    return this.fastActionName;
  }

  isContactInformationPrefilled(): boolean {
    const requiredFields = [
      'FirstName',
      'LastName',
      'AddressLine1',
      'City',
      'StateProvince',
      'Country',
      'PostalCode',
      'EmailAddress',
    ];
    const dataBag = this.view.options?.profile_databag?.changed;
    return dataBag ? requiredFields.every(key => key in dataBag && !!dataBag[key]) : false;
  }

  isMobilePhonePrefilled(): boolean {
    const requiredFields = ['MobilePhone', 'MobilePhoneCountryCode'];
    const dataBag = this.view.options?.profile_databag?.changed;
    return dataBag ? requiredFields.every(key => key in dataBag && !!dataBag[key]) : false;
  }

  clearPrefill(): void {
    this.view.options.profile_databag.clear();
  }

  setMarketSource(value: string): void {
    this.view.options.query_string.ms = value;
  }

  clearValues(): void {
    this.fastActionName = '';
    this.isLoggedInToFastAction = false;
    this.view.clear();
  }

  clearFeedback(): void {
    Object.values(this.view.subviews).forEach(subview => {
      if (subview.clearFeedback) subview.clearFeedback();
    });
  }

  renderAddressFeedback(): void {
    this.view.subviews.ContactInformation.subviews.AddressLine1.renderFeedback();
    this.view.subviews.ContactInformation.subviews.AddressLine2.renderFeedback();
    this.view.subviews.ContactInformation.subviews.City.renderFeedback();
    this.view.subviews.ContactInformation.subviews.Country.renderFeedback();
    this.view.subviews.ContactInformation.subviews.PostalCode.renderFeedback();
    this.view.subviews.ContactInformation.subviews.StateProvince.renderFeedback();
  }

  renderFeedback(): void {
    this.view.renderFeedback();
  }

  getErrors(): Array<string> {
    return this.view.subviews.error_console?.form_errors;
  }

  getHeaderTitle(): string {
    return this.donateForm.headerTitle;
  }

  getHeaderCopy(): string {
    return this.donateForm.headerCopy;
  }

  setSmsSubscribeMobilePhone(value: boolean): void {
    this.setIfChanged({ SmsSubscribeMobilePhone: value, MobilePhone: this.getMobilePhone() });
    this.view.subviews.ContactInformation.subviews.MobilePhone.renderFeedback();
  }

  getSmsSubscribeMobilePhone(): boolean {
    return this.view.val('SmsSubscribeMobilePhone');
  }

  setMobilePhone(value: string): void {
    this.setIfChanged({ MobilePhone: value });
  }

  getMobilePhone(): string {
    return this.view.val('MobilePhone');
  }

  getMobilePhoneCountryCode(): string {
    return this.view.val('MobilePhoneCountryCode');
  }

  isUsMobilePhone(): boolean {
    return this.getMobilePhoneCountryCode() === 'us';
  }

  setEmailSubscribe(value: boolean): void {
    this.setIfChanged({
      YesSignMeUpForUpdatesForBinder: value,
      MobilePhone: this.getMobilePhone(),
    });
    this.view.subviews.ContactInformation.subviews.MobilePhone.renderFeedback();
  }

  getEmailSubscribe(): boolean {
    return this.view.val('YesSignMeUpForUpdatesForBinder');
  }
}
