import styled, { css } from 'styled-components';

interface TooltipProps {
  active: boolean;
  inline?: boolean;
}

export const Tooltip = styled.span<TooltipProps>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  z-index: ${props => (props.active ? 'var(--z-index-tooltip-active)' : 'var(--z-index-tooltip)')};
  outline: none;
  ${props =>
    props.inline
      ? css`
          position: relative;
          margin-left: 0.25rem;
          vertical-align: top;

          &:last-child {
            margin-right: -1.375rem;
          }
        `
      : css`
          transform: translateY(calc(-1.125rem - 100%));
          right: 0.9rem;
          position: absolute;
          top: 3.125rem;
        `};
`;

export const Trigger = styled.button`
  appearance: none;
  width: 1.125rem;
  height: 1.125rem;
  display: block;
  color: var(--color-neutral-350);
  transition: all 0.1s ease-out;
  display: block;
  margin: 0;
  padding: 0;
  outline: none;

  &:hover,
  &:focus-visible {
    transform: scale(1.1);
    color: var(--color-neutral-400);
  }
`;

interface ContentProps {
  yContentPosition: string;
  xContentPosition: string;
}

export const Content = styled.div<ContentProps>`
  display: block;
  position: absolute;
  left: ${props => (props.xContentPosition === 'left' ? '0' : 'auto')};
  right: ${props => (props.xContentPosition === 'right' ? '0' : 'auto')};
  top: ${props => (props.yContentPosition === 'top' ? 'auto' : 'calc(100% + 0.5em)')};
  bottom: ${props => (props.yContentPosition === 'bottom' ? 'auto' : 'calc(100% + 0.5em)')};
  font-size: 0.6875rem;
  line-height: 1.5;
  border: 1px solid var(--color-neutral-300);
  overflow: hidden;
  background: var(--color-white);
  padding: 1.8em;
  font-weight: normal;
  font-style: normal;
  color: var(--color-neutral-700);
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  max-width: 15.625rem;
  width: 50vw;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 67vw;
    font-size: 0.75rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;
