import styled from 'styled-components';
import { motion } from 'framer-motion';

import { ImageItem } from '+/components/ImageItem';
import { subtleZoomOutAnimationCSS } from '+/styles/reusable/animations';

export const BackgroundImageShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  box-shadow: 0 0 100vw var(--color-black);
`;

export const BackgroundImage = styled(ImageItem)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.25) saturate(0.5);
`;

interface BackgroundImageContainerProps {
  isLoaded: boolean;
}

export const BackgroundImageContainer = styled.div<BackgroundImageContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;

  ${props => props.isLoaded && subtleZoomOutAnimationCSS};
`;

export const StageWrapper = styled(motion.div)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
`;

export const ChevronDownWrapper = styled(motion.button)`
  position: absolute;
  left: 50%;
  bottom: 0;
  padding: 25px;
  transform: translateX(-50%);
  color: var(--color-neutral-250);
  z-index: 2;
  cursor: pointer;
`;

export const FullscreenSlideshow = styled.div<{ isAutoHeight?: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  height: ${props => (props.isAutoHeight ? 'auto' : '100%')};

  background: var(--color-neutral-800);

  overflow: hidden;

  transform: translateZ(0);
`;
