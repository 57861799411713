import React from 'react';

// Provides the everyaction div to load the everyaction form into.
export const EveryActionFormAnchor: React.FC<{ formId: string }> = ({ formId }) => (
  <div
    className="ngp-form"
    data-form-url={`https://actions.everyaction.com/v1/Forms/${formId}`}
    data-fastaction-endpoint="https://fastaction.ngpvan.com"
    data-inline-errors="true"
    data-fastaction-nologin="true"
    data-databag-endpoint="https://profile.ngpvan.com"
    data-databag="nobody"
    data-mobile-autofocus="false"
    role="form"
    aria-label="Donation form"
    title="Donation form"
  />
);
