export default (func, callback) =>
  (...args) => {
    try {
      func(...args);
    } catch (e) {
      if (process.env.NODE_ENV !== 'test') {
        console.error(e);
      }
      callback();
    }
  };
