import styled, { css } from 'styled-components';

const min = {
  w560: '(min-width: 560px)',
  w480: '(min-width: 480px)',
  w420: '(min-width: 420px)',
  w380: '(min-width: 380px)',
  w370: '(min-width: 370px)',
  w355: '(min-width: 355px)',
  h780: '(min-height: 780px)',
  h570: '(min-height: 570px)',
};

export const HeaderImageWrapper = styled.div`
  position: relative;
  clip-path: polygon(100% 0, 100% calc(100% - 30px), 50% 100%, 0 calc(100% - 30px), 0 0);
  width: 100%;
  height: 115px;
  background: var(--color-neutral-250);

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 4rem;
    background: linear-gradient(rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }

  @media ${min.h570} {
    height: 150px;
    clip-path: polygon(100% 0, 100% calc(100% - 40px), 50% 100%, 0 calc(100% - 40px), 0 0);
  }

  @media ${min.w355} {
    height: 170px;
    clip-path: polygon(100% 0, 100% calc(100% - 40px), 50% 100%, 0 calc(100% - 40px), 0 0);
  }

  @media ${min.w420} {
    height: 200px;
    clip-path: polygon(100% 0, 100% calc(100% - 55px), 50% 100%, 0 calc(100% - 55px), 0 0);
  }

  @media ${min.w480} {
    height: 230px;
    clip-path: polygon(100% 0, 100% calc(100% - 67px), 50% 100%, 0 calc(100% - 67px), 0 0);
  }

  @media ${min.w560} {
    height: 280px;
    clip-path: polygon(100% 0, 100% calc(100% - 80px), 50% 100%, 0 calc(100% - 80px), 0 0);
  }
`;

interface HeaderImageProps {
  isUpsellUsingHeroImage?: boolean;
}

export const HeaderImage = styled.img<HeaderImageProps>`
  display: block;
  position: absolute;
  left: 0;
  top: 0;

  // If the upsell is reusing the MediaWithForm hero image, ensure the focal point is centered
  // Else, simply cover the area with the custom UpsellImage
  ${props =>
    props.isUpsellUsingHeroImage
      ? css`
          max-width: none;
          width: 150%;
          height: auto;
          margin-top: -4.5%;
        `
      : css`
          object-fit: cover;
          width: 100%;
          height: 100%;
        `};
`;

export const PhotoCredit = styled.span`
  position: absolute;
  font-family: var(--font-primary);
  color: var(--color-white);
  font-size: 0.688rem;
  top: 0.5rem;
  left: 0.5rem;
`;

export const ContentWrapper = styled.div`
  padding: 0 calc(var(--modal-padding) / 4) 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  @media ${min.h570} {
    padding: 0.5rem calc(var(--modal-padding) / 4) 1rem;
  }

  @media ${min.w355} {
    padding: 0.5rem calc(var(--modal-padding) / 2) 1.5rem;
  }

  @media ${min.w380} {
    padding: 1rem var(--modal-padding) 1.5rem;
  }

  @media ${min.w560} {
    padding: 2rem var(--modal-padding) 2.25rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.4rem;
  font-family: var(--font-tertiary);
  font-style: italic;
  color: var(--color-neutral-600);
  text-align: center;
  font-weight: 500;
  letter-spacing: -0.02rem;
  margin-bottom: 0.3rem;

  @media ${min.w355}, ${min.h570} {
    margin-bottom: 0.5rem;
  }

  @media ${min.w355} {
    font-size: 1.625rem;
  }

  @media ${min.w560} {
    margin-bottom: 1rem;
  }
`;

export const Ask = styled.p`
  && {
    text-align: center;
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
    line-height: normal;

    @media ${min.w355}, ${min.h570} {
      margin-bottom: 1.25rem;
    }

    @media ${min.w355} {
      font-size: 1.063rem;
    }

    @media ${min.w560} {
      line-height: 1.65;
    }
  }
`;

export const Yes = styled.p`
  && {
    font-style: italic;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    line-height: normal;

    strong {
      font-weight: 700;
    }

    @media ${min.h570} {
      margin-bottom: 1.25rem;
    }

    @media ${min.w355} {
      font-size: 1.063rem;
      margin-bottom: 1.5rem;
    }

    @media ${min.w560} {
      line-height: 1.65;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-wrap: none;
  align-items: stretch;
  justify-content: center;

  button {
    min-width: 10rem;
    height: 3rem;
    padding: 0; // verify that this is necessary

    @media ${min.w370} {
      min-width: 11.25rem;
      height: 4rem;
      padding: 0 1.5em;
    }
  }
`;

export const InputLabel = styled.label`
  &&& {
    padding: 0;

    span {
      position: absolute;
      left: 0.875rem;
      font-size: 1.75rem;
      line-height: 1;
      top: 0.5rem;
      font-family: var(--font-primary);
      color: var(--color-neutral-500);

      @media ${min.w370} {
        top: 0.875rem;
      }
    }
  }
`;

export const Input = styled.input`
  &&& {
    // min-width: 110px;
    max-width: 140px;
    height: 3rem;
    font-size: 1.75rem;
    border-bottom-width: 3px;
    text-align: center;
    padding: 0; // make sure this is necessary

    @media ${min.w370} {
      padding: 0.8em 0.9em;
      height: 4rem;
    }
  }
`;

export const NoWrapper = styled.div`
  margin: 0.5rem 0 0 0;

  @media ${min.h570} {
    margin: 1rem 0 0 0;
  }

  @media ${min.w355} {
    margin: 1.5rem 0 0 0;
  }
`;

export const Countdown = styled.span`
  font-size: 0.875rem;
  color: var(--color-neutral-500);
  font-style: italic;
  line-height: normal;

  @media ${min.w380} {
    line-height: 1.65;
  }
`;
