import React from 'react';

import * as Styled from './fastAction.styles';

import FastActionLogoSvg from '+/images/fast-action.svg';
import { Icon } from '+/components/Icon';
import { ICONS } from '+/utils/constants/icons';
import analyticsEvent from '+/utils/analyticsEvent';

export const FastAction = ({
  isMini,
  controller,
}: {
  isMini?: boolean;
  controller: EveryActionModelController;
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const isFastActionLoggedIn = controller && controller.getFastActionLoggedIn();

  React.useEffect(() => {
    if (isFastActionLoggedIn) {
      analyticsEvent({
        eventTypeName: 'CustomFormEvent',
        targetElement: 'EveryActionForm',
        targetElementVariation: 'Donation',
        triggerAction: 'FastAction Log In',
        simpleName: 'Log in to FastAction',
      });
    }
  }, []);

  const openPopup = e => {
    e.preventDefault();
    window.open(e.target.href, 'fastactionhelp', 'width=515,height=540,toolbar=no');
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'FastAction Open Log In',
      simpleName: 'Open FastAction login modal',
    });
  };

  let list;

  const handleDropdownClick = () => {
    setExpanded(!expanded);
  };

  if (isFastActionLoggedIn) {
    list = (
      <Styled.DropdownMenu>
        <li>
          <a
            href="https://fastaction.ngpvan.com/profile"
            data-popup="true"
            target="_blank"
            id="fastaction-profile"
            role="button"
            rel="noreferrer"
          >
            Edit my <em>fast</em>
            <b>Action</b> Profile
          </a>
        </li>
        <li>
          <a
            href="https://fastaction.ngpvan.com/logout"
            data-popup="true"
            data-popup-width="1"
            data-popup-height="1"
            data-popup-top="0"
            data-popup-left="0"
            target="_blank"
            role="button"
            onClick={() => {
              // Wait for FastAction response before clearing.
              setTimeout(() => window.location.reload(), 500);
              analyticsEvent({
                eventTypeName: 'CustomFormEvent',
                targetElement: 'EveryActionForm',
                targetElementVariation: 'Donation',
                triggerAction: 'FastAction Log Out',
                simpleName: 'Log out of FastAction',
              });
            }}
            rel="noreferrer"
          >
            Log Out
          </a>
        </li>
      </Styled.DropdownMenu>
    );
  } else {
    list = (
      <Styled.DropdownMenu>
        <li>
          <a role="button" href="#fastaction-login">
            Log In
          </a>
        </li>
      </Styled.DropdownMenu>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.DropdownWrapper>
        <Styled.DropdownTrigger
          className="call-modal"
          onClick={handleDropdownClick}
          aria-label="Fast Action"
        >
          <Styled.DropdownTriggerLogo
            src={FastActionLogoSvg}
            alt="Fast Action"
            loading="lazy"
            width="80"
            height="13"
          />
          <Styled.DropdownTriggerArrowWrapper expanded={expanded}>
            <Icon path={ICONS.CARET_DOWN.path} size={8} color="var(--color-white)" />
          </Styled.DropdownTriggerArrowWrapper>
        </Styled.DropdownTrigger>
        {expanded && list}
      </Styled.DropdownWrapper>
      <Styled.InfoTrigger
        href="https://fastaction.ngpvan.com##whats-this"
        onClick={openPopup}
        aria-label="Information about Fast Action"
      >
        <Styled.InfoIconWrapper>
          <Icon
            path={ICONS.INFO.path}
            viewBox={ICONS.INFO.viewBox}
            transform={ICONS.INFO.transform}
            size={20}
            color="var(--color-white)"
          />
        </Styled.InfoIconWrapper>
      </Styled.InfoTrigger>
    </Styled.Wrapper>
  );
};

FastAction.defaultProps = {
  isMini: false,
};
