import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

import * as sharedStyles from '+/styles/reusable/inputStyles';
import { Button } from '+/components/Button';
import * as FormStyled from '+/styles/styledComponents/Form.styles';
import { inputError } from '+/styles/reusable/inputStyles';
import { textH3 } from '+/styles/reusable/textStyles';

const focusBorder = css`
  border-bottom-color: var(--color-neutral-500);
  outline: none;
`;

const neutralBorder = css`
  border: 1px solid var(--color-neutral-300);

  &:focus,
  &:focus-visible,
  :root &:focus-visible {
    ${focusBorder};
  }
`;

export const H3 = styled.h3`
  ${textH3};

  margin-bottom: 1rem;

  font-family: var(--font-tertiary);
  font-size: 1.6875rem;
  font-style: italic;
  font-weight: 400;

  color: var(--color-neutral-800);
  text-transform: none;
`;

export const TextyInput = styled.input`
  ${sharedStyles.textyInput}
`;

export const InputLabel = styled(FormStyled.InputLabel)`
  /* This needs to be put in here for the extra specificifity to override \`:root *\` */
  ${TextyInput} {
    ${neutralBorder};
  }
`;

export const MessageIntro = styled.div`
  color: var(--color-form-message-into-outro-text);
  text-align: left;
  margin-bottom: 1.2rem;
`;

export const MessageTextArea = styled(TextareaAutosize)`
  width: 100%;
  overflow: hidden;
  resize: none;
  background: none;

  line-height: 1.4;

  color: var(--color-neutral-800);

  &::placeholder {
    color: var(--color-neutral-400);
  }

  &:focus,
  &:focus-visible,
  :root &:focus-visible {
    outline: none;
  }
`;

export const MessageOutro = styled.div`
  color: var(--color-form-message-into-outro-text);
  white-space: pre-line;
  text-align: left;
  margin-top: 0.7rem;
`;

export const TextAreaContainer = styled.div`
  ${sharedStyles.textarea};

  max-height: 20em;
  overflow-y: auto;

  ${neutralBorder};

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: rgba(0, 0, 0, 0.05);
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:focus-within {
    ${focusBorder}
  }
`;

export const InputLabelText = styled.span`
  ${sharedStyles.inputLabelTextFloatingStart};

  position: relative;

  margin-bottom: 0.5rem;

  font-size: 0.8125rem;
  font-weight: 800;

  opacity: 1;
`;

export const InputError = styled.small`
  ${inputError}
`;

export const CustomFieldRow = styled(FormStyled.CustomFieldRow)`
  padding-bottom: 0.73125rem;
`;

export const DoneButton = styled(Button)`
  font-size: 1.125rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;
