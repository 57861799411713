import React, { useEffect, useRef } from 'react';
import { t } from '+/utils/textDictionary';
import { PickerGroup, PickerButton } from '+/components/Picker';
import * as Styled from './FrequencySelector.styles';
import { ICONS } from '+/utils/constants/icons';
import { Icon } from '+/components/Icon';
import analyticsEvent from '+/utils/analyticsEvent';

export const FrequencySelector = ({ controller }: { controller: EveryActionModelController }) => {
  const giveOnceRef = useRef<HTMLInputElement>(null);

  const onChangeOnce = () => {
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Toggle Frequency One-Time',
      simpleName: 'Select one-time donation',
    });
    controller.setMonthly(false);
  };

  const onChangeMonthly = () => {
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Toggle Frequency Monthly',
      simpleName: 'Select monthly donation',
    });
    controller.setMonthly(true);
  };

  const isMonthly = controller.getMonthly();

  // For a11y, focus on first frequency button
  // when the donor goes back to this step (otherwise focus would be stuck on prev "back" button)
  useEffect(() => {
    if (controller.getMaxStepReached() > 0 && giveOnceRef.current) {
      giveOnceRef.current.focus();
    }
  }, [controller.getStep()]);

  return (
    <Styled.Wrapper>
      <PickerGroup className="frequencyButtonGroup">
        <PickerButton
          variant="bordered"
          isSelected={!isMonthly}
          onChange={() => onChangeOnce()}
          name="custom_widget_frequency"
          ref={giveOnceRef}
          aria-label="Give once"
        >
          {t('Widget.FrequencySelector.GiveOnce')}
        </PickerButton>
        <PickerButton
          variant="bordered"
          isSelected={isMonthly}
          onChange={() => onChangeMonthly()}
          name="custom_widget_frequency"
          aria-label="Give monthly"
        >
          {t('Widget.FrequencySelector.GiveMonthly')}
        </PickerButton>
      </PickerGroup>
      <Styled.Upsell>
        <em>
          {isMonthly
            ? t('Widget.FrequencySelector.UpsellCompleteText')
            : t('Widget.FrequencySelector.UpsellText')}
        </em>
        {!isMonthly && (
          <Icon path={ICONS.ARROW_POINT_UP.path} size={20} color="var(--color-primary-500)" />
        )}
      </Styled.Upsell>
    </Styled.Wrapper>
  );
};
