import React from 'react';
import * as Styled from '+/styles/styledComponents/Form.styles';

interface EveryActionCustomSelectProps {
  title: string; // The label text
  name: string; // The input value
  options: { value: string; display: string; group?: string }[]; // value, label
  isTouched: boolean;
  error?: string;
  value: string;
  handleChange: () => void;
  handleBlur: () => void;
  className?: string;
}

export const EveryActionCustomSelect = ({
  title,
  name,
  options,
  value,
  error,
  isTouched,
  handleChange,
  handleBlur,
  className,
}: EveryActionCustomSelectProps) => {
  return (
    <Styled.CustomFieldRow className={className}>
      <Styled.InputLabel>
        {title}
        <Styled.Select name={name} value={value} onChange={handleChange} onBlur={handleBlur}>
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.display}
            </option>
          ))}
        </Styled.Select>
        {isTouched && <Styled.InputError>{error}</Styled.InputError>}
      </Styled.InputLabel>
    </Styled.CustomFieldRow>
  );
};
