export const camelize = (str: string): string =>
  str
    // replace underscores with spaces
    .replace(/_/g, ' ')
    // return leading underscores
    .replace(/^ {2}/m, '__')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');

export const camelizeObject = (obj: any): any => {
  if (typeof obj !== 'object') return obj;
  const newObject = {};
  const keys = Object.keys(obj);
  for (const key of keys) {
    newObject[camelize(key)] = camelizeObject(obj[key]);
  }
  return newObject;
};
