export const colors = {
  // Neutral
  white: '#FFF',
  black: '#000',
  'neutral-100': '#f9f9f9',
  'neutral-200': '#f5f5f5',
  'neutral-250': '#e6e6e6',
  'neutral-300': '#e0e0e0',
  'neutral-350': '#c0c4c7',
  'neutral-400': '#9e9e9e',
  'neutral-450': '#808080',
  'neutral-500': '#6d6e71', // AA
  'neutral-600': '#404040', // AAA
  'neutral-700': '#313f4b', // AAA
  'neutral-800': '#1d252c', // AAA

  // Primary
  'primary-100': '#FFF4F2',
  'primary-200': '#FFDAD4',
  'primary-300': '#ff543d', // AA+
  'primary-400': '#fe3b1f',
  'primary-500': '#ef4129', // AA+
  'primary-700': '#bd0b02', // AA

  // Secondary
  'secondary-300': '#ffcc4e',
  'secondary-500': '#ffb600',
  'secondary-700': '#f29616',
  'accent-500': '#00abc8',
  'accent-700': '#068BA3',

  // Danger
  red: '#bd0b02', // AA

  // Specific
  'form-message-into-outro-text': '#999da0',
  'form-input-label-text': '#61666a',
  'privacy-policy-link-text': '#5f6468',
  'privacy-policy-link-text-icon': '#acaeb1',
};
