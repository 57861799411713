import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { replaceStringWithLocationState } from '+/utils/replaceStringWithLocationState';
import { FormAlertContext } from '+/contexts/FormAlertContext';
import * as Styled from './EveryActionDonateFormHeader.styles';
import analyticsEvent from '+/utils/analyticsEvent';
import { FormHeader } from '+/components/FormHeader';
import { t } from '+/utils/textDictionary';

export const EveryActionDonateFormHeader = ({
  controller,
}: {
  controller: EveryActionModelController;
}) => {
  const { removeAlert } = useContext(FormAlertContext);
  const amount = controller.getSelectedAmountWithCurrencySymbol();
  const frequency = controller.getMonthly() ? ` ${t('Widget.FormHeader.Monthly')}` : '';
  const headerCopy = controller.getHeaderCopy();
  const isDonationAmountView = !controller.onFirstStep() && controller.getSelectedAmount();
  const errorMessage = t('Widget.FormErrors.MissingFields');

  let title = controller.getHeaderTitle();
  if (isDonationAmountView) {
    title = `${t('Widget.FormHeader.AmountPreText')} ${amount} ${frequency}`;
  }

  const handleChangeLinkClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    removeAlert(errorMessage);
    controller.setStep(0);
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Change Amount',
      simpleName: 'Change selected donation amount',
      context: {
        amount: controller.getSelectedAmount(),
        recurring: controller.getMonthly(),
      },
    });
  };

  // TODO: There will be more logic necessary for the upgrade feature, etc.

  return (
    <FormHeader
      headerTitle={
        <>
          <h2>{title}</h2>
          {!controller.onFirstStep() && (
            <Styled.ChangeLink
              role="button"
              tabIndex={0}
              onClick={e => handleChangeLinkClick(e)}
              aria-label={t('Widget.FormHeader.A11yChangeLabel')}
            >
              ({t('Widget.FormHeader.Change')})
            </Styled.ChangeLink>
          )}
        </>
      }
      headerCopy={
        <>
          {!isDonationAmountView && (
            <ReactMarkdown>{replaceStringWithLocationState(headerCopy)}</ReactMarkdown>
          )}
        </>
      }
    />
  );
};
