import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

import * as Styled from './AccordionMenu.styles';

import { useOutsideClick } from '+/utils/useOutsideClick';

const AccordionContext = createContext({} as AccordionContext);
const AccordionItemContext = createContext({} as AccordionItemContext);

export const Accordion = ({ children, isInline, initiallyActive }: AccordionMenuComponent) => {
  const [activeItem, setActiveItem] = useState<string | null>(!isInline ? initiallyActive : null);
  const accordionRef = useRef();

  useOutsideClick(accordionRef, () => {
    if (isInline) setActiveItem(null);
  });

  useEffect(() => {
    if (isInline) setActiveItem(null);
  }, [isInline]);

  const providerValue = React.useMemo(
    () => ({
      activeItem,
      setActiveItem,
      isInline,
    }),
    [activeItem, setActiveItem, isInline],
  );
  return (
    <AccordionContext.Provider value={providerValue}>
      <Styled.Accordion ref={accordionRef} isInline={isInline}>
        {children}
      </Styled.Accordion>
    </AccordionContext.Provider>
  );
};

Accordion.Item = ({ children, id }: AccordionMenuItemComponent) => (
  <AccordionItemContext.Provider value={{ id }}>
    <Styled.Item>{children}</Styled.Item>
  </AccordionItemContext.Provider>
);

Accordion.Header = function Header({ headerText }: AccordionMenuHeaderComponent) {
  const { activeItem, setActiveItem } = useContext(AccordionContext);
  const { id } = useContext(AccordionItemContext);
  return (
    <Styled.Header
      type="button"
      onClick={() => setActiveItem(id === activeItem ? null : id)}
      isActive={id === activeItem}
    >
      <Styled.HeaderText>{headerText}</Styled.HeaderText>
    </Styled.Header>
  );
};

Accordion.Content = function Content({ children, maxHeight }: AccordionMenuContentComponent) {
  const { activeItem, isInline } = useContext(AccordionContext);
  const { id } = useContext(AccordionItemContext);
  const isCurrentlyActive = activeItem === id;
  return (
    <Styled.Content
      as={motion.div}
      animate={{
        height: isCurrentlyActive ? 'auto' : 0,
        opacity: isCurrentlyActive || isInline ? 1 : 0,
      }}
      style={{
        boxShadow: isCurrentlyActive && isInline ? '0 2px 6px rgba(0, 0, 0, 0.3)' : '',
        overflowY: isCurrentlyActive && !!maxHeight ? 'scroll' : 'hidden',
      }}
      transition={{
        duration: 0.5,
        ease: [0.19, 1, 0.22, 1],
      }}
      key={`accordion-content-${id}`}
      isInline={isInline}
      maxHeight={maxHeight}
    >
      <Styled.ContentPadding>{children}</Styled.ContentPadding>
    </Styled.Content>
  );
};
