import React, { useContext } from 'react';

import * as Styled from './FormHeader.styles';

import { Icon } from '+/components/Icon';
import { ICONS } from '+/utils/constants/icons';
import { FormAlertContext } from '+/contexts';
import { Alert } from '+/components/Alert';

interface FormHeaderProps {
  maxWidth?: number;
  className?: string;
  headerTitle?: React.ReactNode;
  headerCopy?: React.ReactNode;
  padded?: boolean;
}

export const FormHeader = ({
  headerTitle,
  headerCopy,
  padded,
  maxWidth,
  className,
}: FormHeaderProps) => {
  const { alerts } = useContext(FormAlertContext);

  return (
    <Styled.Wrapper padded={padded} maxWidth={maxWidth} className={className}>
      <Icon size={47} color="var(--color-primary-400)" path={ICONS.MENDED_HEART.path} />
      {/* id "FormHeaderTitle" is used to scroll to top of form on small screens */}
      {headerTitle && <Styled.Title id="FormHeaderTitle">{headerTitle}</Styled.Title>}
      {headerCopy && <Styled.Copy>{headerCopy}</Styled.Copy>}

      {alerts && !!alerts.length && (
        <Styled.Alerts>
          {alerts.map(alert => (
            <Alert>{alert}</Alert>
          ))}
        </Styled.Alerts>
      )}
    </Styled.Wrapper>
  );
};

FormHeader.defaultProps = {
  headerTitle: '',
  headerCopy: '',
  className: '',
  maxWidth: null,
  padded: null,
};
