import React from 'react';
import * as Styled from '+/styles/styledComponents/Form.styles';

interface EveryActionCustomTextFieldProps {
  title: string; // The label text
  name: string; // The input value
  isTouched: boolean;
  isHidden: boolean;
  error?: string;
  value: string;
  handleChange: () => void;
  handleBlur: () => void;
  className?: string;
}

export const EveryActionCustomTextField = ({
  title,
  name,
  error,
  value,
  isTouched,
  isHidden,
  handleChange,
  handleBlur,
  className,
}: EveryActionCustomTextFieldProps) => {
  return (
    <Styled.CustomFieldRow isHidden={isHidden} className={className}>
      <Styled.InputLabel floating errored={isTouched && !!error}>
        <Styled.TextyInput
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={title}
        />
        <Styled.InputLabelText floating>{title}</Styled.InputLabelText>
        {isTouched && !!error && <Styled.InputError>{error}</Styled.InputError>}
      </Styled.InputLabel>
    </Styled.CustomFieldRow>
  );
};
