import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import scrollToLocation from '+/utils/scrollToLocation';

export const Link = ({ href, children, target, rel, className, title, label, onClick }: Link) => {
  const isDownload = href.endsWith('pdf');
  const isInternal =
    !href.startsWith('http') &&
    !isDownload &&
    !href.startsWith('mailto:') &&
    !href.startsWith('tel:') &&
    // eslint-disable-next-line no-script-url
    !href.startsWith('javascript:');
  const isHash = href.startsWith('#') && !href.startsWith('#modal-');
  const externalTarget = !target && !isInternal ? '_blank' : target;
  const externalRel = !rel && !isInternal ? 'noopener' : rel;
  const isOsanoLink = href.startsWith('#osano');

  const onClickCallback = React.useCallback(
    (event: React.MouseEvent): void => {
      if (isHash) {
        event.preventDefault();

        const elementToScrollTo = document.getElementById(href.substring(1));

        if (elementToScrollTo) scrollToLocation(elementToScrollTo);
      }

      if (onClick) onClick(event);
    },
    [isHash, onClick],
  );

  if (isOsanoLink && typeof window !== 'undefined' && window.Osano) {
    return (
      <button
        type="button"
        className={className}
        onClick={() => window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}
      >
        {children}
      </button>
    );
  }
  return isInternal ? (
    <GatsbyLink
      to={href}
      className={className}
      title={title}
      aria-label={label}
      onClick={onClickCallback}
    >
      {children}
    </GatsbyLink>
  ) : (
    <a
      href={href}
      target={!isHash && externalTarget}
      rel={!isHash && externalRel}
      className={className}
      title={title}
      aria-label={label}
      onClick={onClickCallback}
      download={isDownload}
    >
      {children}
    </a>
  );
};
