import React from 'react';
import { useParallax } from 'react-scroll-parallax';

import { HighlightHeading } from '../HighlightHeading';

import * as Animations from './Hero.animations';
import * as Styled from './Hero.styles';

import { Button } from '+/components/Button';
import { ICONS } from '+/utils/constants/icons';
import { useIsMobile } from '+/utils/responsive';
import { useMousePosition } from '+/utils/useMousePosition';
import { mapMouseCoordinatesToCenter } from '+/utils/mapMouseCoordinatesToCenter';
import { splitHeaderText } from '+/utils/splitHeaderText';

const AnimatedContentWrapper = ({
  index,
  prevAnimationDuration,
  children,
}: {
  index: number;
  prevAnimationDuration: number;
  children: React.ReactNode;
}) => (
  <Styled.AnimatedContent
    variants={Animations.ContentVariants}
    initial="hidden"
    animate="visible"
    custom={{
      index,
      delay: prevAnimationDuration - prevAnimationDuration / 3,
    }}
  >
    {children}
  </Styled.AnimatedContent>
);

const ScrollParallax = ({
  disabled,
  children,
}: {
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  const { ref } = useParallax({ speed: -25, disabled });

  return <Styled.ScrollParallaxWrapper ref={ref}>{children}</Styled.ScrollParallaxWrapper>;
};

ScrollParallax.defaultProps = {
  disabled: false,
};

export const Hero = ({ heroData }: { heroData: HeroComponent }) => {
  const isMobile = useIsMobile();
  const isCageParallaxEffectsEnabled = heroData.cageParallaxEffects && !isMobile;
  const mousePosition = useMousePosition(isCageParallaxEffectsEnabled);
  const highlightDuration = heroData.title ? splitHeaderText(heroData.title).length * 0.25 : 0;

  const mappedMousePosition = isCageParallaxEffectsEnabled
    ? mapMouseCoordinatesToCenter(mousePosition)
    : null;

  return (
    <Styled.Hero>
      <Styled.HeroWrapper>
        <Styled.ParallaxMediaItemWrapper
          custom={mappedMousePosition}
          variants={Animations.ParallaxMediaItemVariants}
          initial={isCageParallaxEffectsEnabled ? 'parallax' : 'none'}
          animate={isCageParallaxEffectsEnabled ? 'parallax' : 'none'}
        >
          <ScrollParallax disabled={!heroData.scrollParallaxEffects}>
            <Styled.ParallaxMediaItem mediaItemData={{ ...heroData.media, playsInline: true }} />
          </ScrollParallax>
        </Styled.ParallaxMediaItemWrapper>

        {isCageParallaxEffectsEnabled && (
          <>
            <Styled.ParallaxDustOne
              custom={mappedMousePosition}
              variants={Animations.ParallaxDustVariants}
              animate="oneParallax"
            />
            <Styled.ParallaxBars
              custom={mappedMousePosition}
              variants={Animations.ParallaxBarsVariants}
              animate="mouseParallax"
            />
            <Styled.ParallaxDustTwo
              custom={mappedMousePosition}
              variants={Animations.ParallaxDustVariants}
              animate="twoParallax"
            />
          </>
        )}

        {(heroData.media as ImageItem).photoCredit && (
          <Styled.MediaCredit>
            Photo: {(heroData.media as ImageItem).photoCredit}
          </Styled.MediaCredit>
        )}

        <Styled.HeroContent>
          <Styled.HeroContentWrapper>
            {heroData.title && (
              <Styled.HeroHighlightHeading>
                <HighlightHeading text={heroData.title} highlight />
              </Styled.HeroHighlightHeading>
            )}
            {heroData.text && (
              <AnimatedContentWrapper index={0} prevAnimationDuration={highlightDuration}>
                <Styled.Markdown>{heroData.text}</Styled.Markdown>
              </AnimatedContentWrapper>
            )}
            {heroData.callToActionButton && (
              <AnimatedContentWrapper index={1} prevAnimationDuration={highlightDuration}>
                <Styled.CTALink href={heroData.callToActionButton.uRL}>
                  <Styled.Button
                    big
                    minContent
                    endIcon={ICONS.CARET_RIGHT}
                    as={Button}
                  >
                    {heroData.callToActionButton.label}
                  </Styled.Button>
                </Styled.CTALink>
              </AnimatedContentWrapper>
            )}
          </Styled.HeroContentWrapper>
        </Styled.HeroContent>
      </Styled.HeroWrapper>
    </Styled.Hero>
  );
};
