import React, { useEffect, useState, useRef } from 'react';
import { useCountUp } from 'use-count-up';
import { motion } from 'framer-motion';

import * as Styled from './Counter.styles';

import { Markdown } from '+/components/Markdown';
import { Icon } from '+/components/Icon';
import { ICONS } from '+/utils/constants/icons';
import { useOnScreen } from '+/utils/useOnScreen';

export const Counter = ({
  counterData: {
    id,
    callToActionButton,
    decimalPlaces = 0,
    displayThreshold = 0,
    duration = 2,
    petitionId,
    endValue = 0,
    postfix,
    prefix,
    startValue = 0,
    counterText,
  },
}: {
  counterData: CounterComponent;
}) => {
  const counterRef = useRef(null);
  const isOnScreen = useOnScreen(counterRef);
  const [end, setEnd] = useState(endValue);

  const { value, reset } = useCountUp({
    decimalPlaces,
    duration,
    end,
    isCounting: isOnScreen,
    start: startValue,
    thousandsSeparator: ',',
  });

  useEffect(() => {
    if (!petitionId) {
      return;
    }
    (async function fetchAndSet() {
      const result = await fetch(`https://secure.everyaction.com/v2/forms/${petitionId}/progress`);
      const json = await result.json();
      if (!json) {
        return;
      }
      setEnd(json.totalSubmissionProgress);
    })();
  }, []);

  const punctuationRegex = /[.,]/g;

  return (
    <Styled.Counter ref={counterRef}>
      {end > displayThreshold && (
        <Styled.CounterValue
          as={motion.div}
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.75 }}
        >
          <div className="visually-hidden">
            {prefix}
            {end}
            {postfix}
          </div>
          {prefix && <Styled.Digit aria-hidden="true">{prefix}</Styled.Digit>}
          {value
            .toString()
            .split('')
            .map((digit, index) => (
              <Styled.Digit
                key={`counter-${id}-${index}`}
                aria-hidden="true"
                isPunctuation={punctuationRegex.test(digit)}
              >
                {digit}
              </Styled.Digit>
            ))}
          {postfix && <Styled.Digit aria-hidden="true">{postfix}</Styled.Digit>}
        </Styled.CounterValue>
      )}
      {counterText && (
        <Styled.Text>
          <Markdown>{counterText}</Markdown>
        </Styled.Text>
      )}
      {callToActionButton && (
        <Styled.CTALink href={callToActionButton.uRL}>
          <Styled.Button type="button">
            <span>{callToActionButton.label}</span>
            <span aria-hidden="true">
              <Icon
                path={ICONS.CARET_RIGHT.path}
                viewBox={ICONS.CARET_RIGHT.viewBox}
                size={20}
                color="currentColor"
              />
            </span>
          </Styled.Button>
        </Styled.CTALink>
      )}
    </Styled.Counter>
  );
};
