import styled from 'styled-components';

export const MarkdownModalTriggerButton = styled.button`
  margin: 0;
  padding: 0;

  color: var(--color-theme-invert);
  text-decoration: underline;

  filter: brightness(1);

  transition: filter 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: var(--color-theme-accent);
    filter: brightness(1.25);
  }
`;
