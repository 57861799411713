import React from 'react';

import { ICONS } from '+/utils/constants/icons';

import * as Styled from './StickyCallToAction.styles';
import { useShowWhenInScrollPercentage } from '+/utils/useShowWhenInScrollPercentage';

export const StickyCallToAction = ({
  stickyCallToActionData,
}: {
  stickyCallToActionData: StickyCallToActionComponent;
}) => {
  const isShown = useShowWhenInScrollPercentage(
    stickyCallToActionData.scrollPositionShow,
    stickyCallToActionData.scrollPositionHide,
  );

  return (
    <Styled.StickyCallToAction isShown={isShown}>
      <Styled.Logo />
      <Styled.Text>{stickyCallToActionData.text}</Styled.Text>
      <Styled.Link href={stickyCallToActionData.callToActionButton.uRL}>
        {/* Should endIconPath be dynamic? */}
        <Styled.Button endIcon={ICONS.CARET_RIGHT} narrow>
          {stickyCallToActionData.callToActionButton.label}
        </Styled.Button>
      </Styled.Link>
    </Styled.StickyCallToAction>
  );
};
