import styled, { css } from 'styled-components';

import { textH2, textH3, textH4 } from '+/styles/reusable/textStyles';
import { Dot } from '+/components/USDAViolationsDisplay/DotFilter/DotFilter.styles';
import { Toggle } from '+/components/Toggle';

interface ViolationsWrapperProps {
  noBG?: boolean;
}

export const Wrapper = styled.div<ViolationsWrapperProps>`
  min-height: 100%;
  ${props =>
    !props.noBG &&
    css`
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 80%, transparent 100%);
    `};
  color: var(--color-white);
  width: 100%;
  padding: calc(var(--height-nav) + 4vh) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-neutral-800);
`;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 1.5rem;

  // media query copied from THL.org
  @media (min-width: 1024px) {
    padding: 0 2.5rem;
  }
`;

export const ShowGraphicImageryWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const ShowGraphicImageryToggle = styled(Toggle)`
  ${textH3};

  font-size: var(--font-size-xs);

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: var(--font-size-sm);
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: var(--font-size-default);
  }
`;

export const Title = styled.h2`
  ${textH2}
  font-size: var(--font-size-lg);

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: var(--font-size-xl);
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: var(--font-size-xxl);
  }
`;

export const Subtitle = styled.h3`
  ${textH4}
  font-size: var(--font-size-default);
  margin-top: 0.5rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: var(--font-size-lg);
  }

  span {
    display: inline-block;
  }
`;

export const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    gap: 2.5rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    flex-direction: row;
    gap: 4.5rem;
    padding-top: 2.5rem;
  }
`;

export const Filters = styled.div`
  position: relative;
  z-index: 2;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    width: 35%;
    max-width: 21rem;
  }

  &:empty {
    display: none;
  }
`;

export const Violations = styled.div`
  position: relative;
  z-index: 1;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    flex: 1;
  }
`;

export const FilterStatus = styled.div`
  overflow: hidden;

  &:empty {
    display: none;
  }

  & > :last-child {
    padding-bottom: 1.75rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding-bottom: 2.25rem;
    }
  }
`;

export const FilterStatusTitle = styled.h4`
  font-size: 1rem;
  font-family: var(--font-secondary);
  font-weight: 700;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-right: 1rem;
  vertical-align: middle;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 1.125rem;
  }
`;

export const FilterStatusPreTitle = styled.p`
  ${textH4}
  font-size: 0.75rem;
  opacity: 0.75;
`;

export const FilterStatusDot = styled(Dot)`
  height: 1rem;
  width: 1rem;
`;

export const FilterClear = styled.button`
  font-family: var(--font-primary);
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--color-secondary-500);
  text-decoration: underline;
  padding: 0;

  &:hover {
    color: var(--color-white);
  }
`;

export const FilterStatusDescription = styled.p`
  font-size: 0.875rem;
  display: block;
  opacity: 0.9;
  max-width: 64em;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 0.9375rem;
  }
`;

export const FilterForm = styled.form`
  margin-bottom: 1rem;
`;

export const FilterFormSelect = styled.select`
  appearance: none;
  display: block;
  width: 100%;
  padding: 0 2em 0 0.5em;
  line-height: 2.5em;
  text-align: left;
  background: var(--color-white) var(--image-select-arrow) no-repeat right 0.75em center;
  color: var(--color-neutral-800);
  font-family: var(--font-secondary);
  font-weight: 700;
  text-transform: uppercase;
  height: 2.5em;
  font-size: 0.9375rem;
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-200);
  }
`;
