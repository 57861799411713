// Separate initial selectors from selectors used for finding added elements.
// This lets us verify that all expected initial selectors are present.
export const base = {};
export const usOnly = {};
export const optional = {};
const expanded = {};

/*
 * BASE
 */

base.FORM = 'form';

base.CONTRIBUTION_FIELDSET = '.at-fieldset.ContributionInformation';
base.CONTACT_FIELDSET = '.at-fieldset.ContactInformation';
base.PAYMENT_FIELDSET = '.at-fieldset.PaymentInformation';
base.TRIBUTE_FIELDSET = '.at-fieldset.TributeGift';
base.NAME_AND_CONTACT_FIELDSET = '.ContactInformation';
base.RECIPIENT_INFORMATION_FIELDSET = '.at-fieldset.RecipientInformation';
base.PREVIOUS_BUTTON = '.prevNext.prev';
base.NEXT_BUTTON = '.prevNext.next';
base.PREVIEW_ECARD_BUTTON = '.at-preview-ecard';

base.PAYMENT_TAB_CONTENT = '.PaymentInformation > .at-fields';
// base.NAME_ROW = '.FirstName.MiddleName.LastName.Suffix';
// base.FIRST_NAME_LABEL = 'label.FirstName';
// base.LAST_NAME_LABEL = 'label.LastName';
// base.MIDDLE_NAME_LABEL = 'label.MiddleName';
// base.PREFIX_LABEL = 'label.Prefix';
// base.SUFFIX_LABEL = 'label.Suffix';

base.CONTACT_ROW = '.EmailAddress.MobilePhone';
base.EMAIL_ADDRESS_LABEL = 'label.EmailAddress';
base.MOBILE_PHONE_LABEL = 'label.MobilePhone';
base.MOBILE_PHONE_INPUT = 'input[name=MobilePhone]';
base.EMAIL_SUBSCRIBE_LABEL = 'label.YesSignMeUpForUpdatesForBinder';

base.ADDRESS_STREET_FIELDS = '.AddressLine1.AddressLine2';
base.ADDRESS_LOCALE_FIELDS = '.Country.PostalCode.City.StateProvince';
base.ADDRESS_LINE_1_LABEL = 'label.AddressLine1';
base.CITY_LABEL = 'label.City';
base.POSTAL_CODE_LABEL = 'label.PostalCode';
base.STATE_PROVINCE_LABEL = 'label.StateProvince';

base.E_CARD_ROW = '.at-ecards';

base.RECIPIENT_NAME_ROW = '.RecipientFirstName.RecipientLastName';
base.RECIPIENT_HEADER = '.RecipientInfoHeaderHtml';
base.IN_MEMORY_OF_ITEM = '.form-item-inhonororinmemoryof';
base.RECIPIENT_EMAIL_ADDRESS = '.RecipientEmailAddress';
base.ECARD_SEND_COPY = '.NotificationSendCopy';
base.ECARD_SEND_DATE = '.NotificationSendDate';
base.ECARD_MESSAGE = '.NotificationMessage';

base.COVER_COSTS_AMOUNT_LABEL = 'label.CoverCostsAmount';

base.STEPS_CONTAINER = 'ol.at-steps';
base.QUANTITY_TAB = 'li.at-step:first-of-type';

base.FOOTER_HTML = '.at-form-submit .FooterHtml';
base.FORM_SUBMIT = '.at-form-submit > .step-prevNext';
base.ECARD_PREVIEW_MODAL = '.preview-modal';
base.ERROR_CONSOLE = '.at-error-console';

/*
 * Only on U.S. currency form.
 */
usOnly.ACCOUNT_WHATS_THIS = '.account-number-label .at-whatsthis';
usOnly.ROUTING_WHATS_THIS = '.routing-number-label .at-whatsthis';
usOnly.SMS_SUBSCRIBE_ROW = '.at-row.SmsSubscribeMobilePhone';
usOnly.SMS_SUBSCRIBE_LABEL = 'label.SmsSubscribeMobilePhone';
usOnly.SMS_SUBSCRIBE_INPUT = 'input[name=SmsSubscribeMobilePhone]';
usOnly.PAYMENT_METHOD_FIELDSET = '.at-fieldset.PaymentMethodSection';

/*
 * Not included in all FastAction enabled forms at start
 */
optional.VGS_CC = '.vgs-cc-iframe-wrapper';
optional.VGS_EXPIRATION = '.vgs-ccexpiration-iframe-wrapper';
// Included through reusable
optional.EMAIL_VALIDATION = '#emailValidation';
/*
 * EXPANSIONS
 * These don't need to be present initially for the modified form to load.
 */
expanded.FORM_CONTENTS = '#FormContents';
expanded.CONTACT_PREFIX_LABEL = '.ContactInformation label.Prefix';
expanded.CONTACT_FIRST_NAME_LABEL = '.ContactInformation label.FirstName';
expanded.CONTACT_MIDDLE_NAME_LABEL = '.ContactInformation label.MiddleName';
expanded.CONTACT_LAST_NAME_LABEL = '.ContactInformation label.LastName';
expanded.CONTACT_SUFFIX_LABEL = '.ContactInformation label.Suffix';

expanded.NAME_ROW_PREFIX_LABEL = '#NameRow .Prefix';
expanded.NAME_ROW_FIRST_NAME_LABEL = '#NameRow .FirstName';
expanded.NAME_ROW_MIDDLE_NAME_LABEL = '#NameRow .MiddleName';
expanded.NAME_ROW_LAST_NAME_LABEL = '#NameRow .LastName';
expanded.NAME_ROW_SUFFIX_LABEL = '#NameRow .Suffix';

expanded.ADDRESS_DETAILS = '#AddressFieldset';

expanded.CONTACT_EMAIL_ADDRESS_LABEL = '.ContactInformation label.EmailAddress';
expanded.CONTACT_MOBILE_PHONE_LABEL = '.ContactInformation label.MobilePhone';
expanded.CONTACT_COUNTRY_LABEL = '.ContactInformation label.Country';
expanded.CONTACT_POSTAL_CODE_LABEL = '.ContactInformation label.PostalCode';
expanded.CONTACT_CITY_LABEL = '.ContactInformation label.City';
expanded.CONTACT_STATE_PROVINCE_LABEL = '.ContactInformation label.StateProvince';
expanded.ADDRESS_ADDRESS_LABEL = '.ContactInformation .AddressLine1 > label';
expanded.ADDRESS_LINE2 = '.ContactInformation .AddressLine2';
expanded.ADDRESS_LINE2_INPUT = '.ContactInformation input[name="AddressLine2"]';

expanded.PAYMENT_INFO_TAB = '#PaymentInfoTabContent';
expanded.PAYMENT_TAB_CONTENT_CHILD = '#PaymentInfoTabContent > .at-row:first-child';
expanded.ECARD_ROW = '#ECardRow';

expanded.CREDIT_CARD_INFO_ROW = '#CreditCardInfoRow';
expanded.CREDIT_CARD_TAB_CONTENT = '#CreditCardTabContent';

expanded.DONATE_FORM_WRAPPER = '#DonateFormWrapper';
expanded.FORM_HEADER_TITLE = '#FormHeaderTitle';

expanded.UPDATE_MY_PROFILE_ROW = '.UpdateMyProfile';
expanded.UPDATE_MY_PROFILE_INPUT = 'input[name="updateMyProfile"]';

expanded.REMEMBER_ME = '.at-row.SmsSubscribeMobilePhone';

// base.BOMB = '💥';

export default { ...base, ...usOnly, ...optional, ...expanded };
