import React, { useContext, useEffect, useState } from 'react';
import { EveryActionDonateForm as EveryActionDonateFormComponent } from './EveryActionDonateForm.component';
import { UserContext } from '+/contexts/UserContext';

const EveryActionDonateForm = props => {
  const userContext = useContext(UserContext);

  return <EveryActionDonateFormComponent {...props} userContext={userContext} />;
};

export default EveryActionDonateForm;
