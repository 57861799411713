import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { ModalContent } from './ModalContent.component';

import { NoScrollContext } from '+/contexts/NoScrollContext';

export const getOrCreateRootIfNotExists = (rootModalContainerID: string): HTMLElement => {
  let modalRoot = document.getElementById(rootModalContainerID);

  // If the modalRoot doesn't exist yet (like in tests or storybook), just create it
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', rootModalContainerID);
    document.body.appendChild(modalRoot);
  }

  return modalRoot;
};

export const ModalPortal = (modalPortalData: ModalPortalComponent) => {
  const noScrollController = React.useContext(NoScrollContext);

  useEffect(() => {
    const resumeScrolling = noScrollController.pauseScrolling(true);

    return resumeScrolling;
  });

  return ReactDOM.createPortal(
    <ModalContent
      role={modalPortalData.role}
      onClose={modalPortalData.onClose}
      closeColor={modalPortalData.closeColor}
      fullscreen={modalPortalData.fullscreen}
      overlay={modalPortalData.overlay}
      themeColor={modalPortalData.themeColor}
      className={modalPortalData.className}
    >
      {modalPortalData.children}
    </ModalContent>,
    getOrCreateRootIfNotExists(modalPortalData.rootModalContainerID),
  );
};

ModalPortal.defaultProps = {
  onClose: () => {},
  rootModalContainerID: 'modal-target',
};
