import React from 'react';
import payPalLogo from '+/images/paymentMethods/paypal.svg';
import amExLogo from '+/images/paymentMethods/amex.svg';
import discoverLogo from '+/images/paymentMethods/discover.svg';
import mcLogo from '+/images/paymentMethods/mastercard.svg';
import visaLogo from '+/images/paymentMethods/visa.svg';
import * as Styled from './PaymentMethodLogos.styles';

export const PaymentMethodLogos = () => (
  <Styled.PaymentMethodsWrapper>
    <img src={visaLogo} alt="Visa" loading="lazy" />
    <img src={mcLogo} alt="Mastercard" loading="lazy" />
    <img src={amExLogo} alt="American Express" loading="lazy" />
    <img src={discoverLogo} alt="Discover" loading="lazy" />
    <img src={payPalLogo} alt="PayPal" loading="lazy" />
  </Styled.PaymentMethodsWrapper>
);
