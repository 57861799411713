import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import * as Styled from './Truncate.styles';

import { useWindowWidth } from '+/utils/useWindowWidth';
import { useWindowHeight } from '+/utils/useWindowHeight';

export const DEFAULT_TRUNCATE_HEIGHT = 180;
const TRUNCATE_ANIMATION_DURATION = 0.25;

export const Truncate = ({
  children,
  moreText = 'Read More',
  lessText = 'Read Less',
  minHeight = DEFAULT_TRUNCATE_HEIGHT,
  initialHeight = DEFAULT_TRUNCATE_HEIGHT,
  isCollapsable = false,
  isEnabled = true,
}: TruncateComponent) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [dynamicIsEnabled, setDynamicIsEnabled] = useState(false);
  const [contentChildrenHeight, setContentChildrenHeight] = useState(0);
  const contentRef = useRef(null);
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const formattedMinHeight = typeof minHeight === 'number' ? `${minHeight}px` : minHeight;

  const contentWrapperHeight =
    typeof initialHeight === 'number'
      ? initialHeight
      : parseInt(initialHeight, 10) || initialHeight;

  useEffect(() => {
    setTimeout(() => {
      if (contentRef.current && isTruncated) {
        const currentChildrenHeight =
          [...contentRef.current.children].reduce((acc, child) => acc + child.offsetHeight, 0) || 0;
        setContentChildrenHeight(currentChildrenHeight);
        setDynamicIsEnabled(currentChildrenHeight > contentRef.current.clientHeight);
      }
    }, TRUNCATE_ANIMATION_DURATION * 1000);
  }, [contentChildrenHeight, contentRef.current?.clientHeight, windowWidth, windowHeight]);

  const isCollapsableBool = isCollapsable === 'false' ? false : isCollapsable;

  const truncateContentVariants = {
    closed: {
      height: dynamicIsEnabled ? contentWrapperHeight : contentChildrenHeight,
    },
    open: { height: 'auto' },
  };

  return (
    <AnimatePresence initial={false}>
      <Styled.Truncate>
        <Styled.Content
          as={motion.div}
          animate={isTruncated ? 'closed' : 'open'}
          variants={truncateContentVariants}
          transition={{ duration: TRUNCATE_ANIMATION_DURATION }}
          ref={contentRef}
          // Using inline styling because motion.divs don't like custom props
          style={{ minHeight: formattedMinHeight || 'initial' }}
          // Using className here because inline styles don't autoprefix -webkit-mask-image
          className={isTruncated && isEnabled && dynamicIsEnabled && 'hasFade'}
        >
          {children}
        </Styled.Content>
        {isEnabled && dynamicIsEnabled && (isTruncated || (isCollapsableBool && !isTruncated)) && (
          <Styled.Button type="button" onClick={() => setIsTruncated(prevState => !prevState)}>
            {isTruncated ? moreText : lessText}
          </Styled.Button>
        )}
      </Styled.Truncate>
    </AnimatePresence>
  );
};
