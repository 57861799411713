import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

function createRectangle(width, height, color) {
  // Create a canvas element
  if (typeof document === 'undefined') return '';
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  // Get the 2D context of the canvas
  const ctx = canvas.getContext('2d');

  // Set the fill style to red
  ctx.fillStyle = color;

  // Draw a rectangle
  ctx.fillRect(0, 0, width, height);

  // Return the data URL for a PNG image
  return canvas.toDataURL('image/png');
}

interface HighlightHeadingPartProps {
  accentColor: string;
}

export const HighlightHeadingPart = styled(motion.span)<HighlightHeadingPartProps>`
  display: inline-block;

  // Fixes there being a thin line sometimes inbetween words
  margin-left: -1px;

  padding: 0.2rem 0.3rem;
  margin-top: -1px;

  color: var(--color-theme-base-inverse);

  line-height: 1;
  font-size: 1.6875rem;
  font-family: var(--font-secondary);
  font-weight: 700;
  text-transform: uppercase;

  background: 0 0;
  background-size: 100% 100%;
  display: inline-block;
  ${({ accentColor }) => css`
    background-image: url(${createRectangle(200, 100, accentColor)});
  `}}

  background-size: 0 100%;
  background-position: left center;
  background-repeat: no-repeat;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.125rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) and (min-height: 700px) {
    font-size: 2.7625rem;
  }
`;
