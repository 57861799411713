import React from 'react';
import { AnimatePresence } from 'framer-motion';

import { Modal } from '+/components/Modal';

export const ModalsController = (modalsControllerData: ModalsControllerComponent) => {
  return (
    <div id={modalsControllerData.id}>
      <AnimatePresence>
        {modalsControllerData.modals.map(modalComponent => (
          <Modal modalData={modalComponent} key={modalComponent.id} />
        ))}
      </AnimatePresence>
    </div>
  );
};
