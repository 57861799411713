import styled from 'styled-components';

export const NameSplitter = styled.div`
  padding-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Icon = styled.span`
  display: flex;
  background: var(--color-secondary-500);
  color: var(--color-neutral-800);
  font-weight: 700;
  font-style: normal;
  font-family: var(--font-secondary);

  border-radius: 50%;
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.5rem;
  font-size: 1.125rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  line-height: 1;
`;

export const Text = styled.div`
  font-size: 0.813rem;
  color: var(--color-neutral-600);
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font-primary);
`;

export const Question = styled.span`
  display: inline-block;
  width: 100%;

  strong {
    font-weight: 700;
  }
`;

export const Answer = styled.button`
  appearance: none;
  display: inline;
  color: var(--color-primary-500);
  text-decoration: underline;
  margin-right: 0.5rem;
  padding: 0;
  font-weight: bold;
`;
