import styled, { css, keyframes } from 'styled-components';

export const Header = styled.header<{
  hide?: boolean;
  position: string;
  isDropShadowEnabled: boolean;
}>`
  ${props =>
    props.isDropShadowEnabled &&
    css`
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.2) 32%,
        rgba(0, 0, 0, 0.1) 45%,
        rgba(0, 0, 0, 0.07) 54%,
        rgba(0, 0, 0, 0) 100%
      );
    `}

  color: var(--color-white);
  box-sizing: border-box;
  height: var(--height-nav);
  left: 0;
  line-height: 1;
  position: ${props => props.position};
  top: 0;
  transition: top 0.5s ease-in-out;
  width: calc(100% - var(--body-scrollbar-width-hidden));
  z-index: var(--z-index-nav);

  ${props =>
    props.hide &&
    css`
      top: -150px;
    `};
`;

export const Nav = styled.div<{ opaque?: boolean }>`
  align-items: center;
  background: none;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  transition: background 0.25s ease-in-out;
  width: 100%;
  padding: 0 1.5rem;
  height: var(--height-nav);
  gap: 1.5rem;

  ${props =>
    props.opaque &&
    css`
      background: var(--color-neutral-800);
    `};

  // media query copied from THL.org
  @media (min-width: 1024px) {
    padding: 0 2.5rem;
  }
`;

export const Logo = styled.div`
  flex-grow: 1;

  img {
    height: 100%;
    display: block;
    width: auto;
    max-height: 41px;
    max-width: 195px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      max-width: 279px;
    }
  }
  a {
    display: block;
  }
`;

export const LogoMobile = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

export const LogoDesktop = styled.div`
  ${LogoMobile} + & {
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: block;
    }
  }
`;

export const NavSection = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
  margin-right: -1rem;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-right: 0;
    gap: 2rem;
  }

  a {
    text-decoration: none;
    color: inherit;
    transition: all 0.15s;
  }
`;
export const MainNavigation = styled.div`
  display: none;
  font-family: var(--font-secondary);
  font-weight: 700;
  text-transform: uppercase;
  column-gap: 2rem;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: flex;
  }

  a {
    position: relative;
    padding: 0.75rem 0;
    line-height: 1;

    &:after {
      content: '';
      height: 0.125rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) scaleX(0);
      background: currentColor;
      transition: inherit;
    }

    &:hover {
      &:after {
        transform: translateX(-50%) scaleX(1);
      }
    }
  }
`;
export const SocialNavigation = styled.div`
  display: flex;
  column-gap: 1rem;

  a {
    &:hover {
      transform: scale(1.25);
    }
  }

  svg {
    height: 1.5rem;
    width: auto;
    display: block;

    path {
      fill: currentColor;
    }
  }
`;

interface OverflowMenuProps {
  isActive?: boolean | React.Dispatch<React.SetStateAction<boolean>>;
}

const topBarOpen = keyframes`
  0% {
    transform: translate(-50%, calc(-0.5rem - 50%));
  }
  50% {
    transform: translate(-50%,-50%);
  }
  100% {
    transform: scale(1.1) translate(-50%,-50%) rotate(45deg);
  }
`;

const bottomBarOpen = keyframes`
  0% {
    transform: translate(-50%, calc(0.5rem - 50%));
  }
  50% {
    transform: translate(-50%,-50%);
  }
  100% {
    transform: scale(1.1) translate(-50%,-50%) rotate(-45deg);
  }
`;

export const VeggieBurger = styled.button<OverflowMenuProps>`
  position: relative;
  z-index: var(--z-index-veggie-burger);
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.75rem;

  span {
    display: block;
    width: 1.25rem;
    height: 0.125rem;
    background: var(--color-white);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    animation-direction: reverse;

    &:nth-of-type(1) {
      transform: translate(-50%, calc(-0.5rem - 50%));
    }
    &:nth-of-type(3) {
      transform: translate(-50%, calc(0.5rem - 50%));
    }

    ${props =>
      props.isActive &&
      css`
        &:nth-of-type(2) {
          opacity: 0;
          transition: opacity 0.3s var(--ease-out-expo);
        }
        &:nth-of-type(1) {
          animation: ${topBarOpen} 0.3s var(--ease-out-expo) forwards;
        }
        &:nth-of-type(3) {
          animation: ${bottomBarOpen} 0.3s var(--ease-out-expo) forwards;
        }
      `}
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

export const OverflowMenu = styled.div<OverflowMenuProps>`
  position: fixed;
  z-index: var(--z-index-overflow-menu);
  right: 0;
  top: 0;
  background: var(--color-neutral-800);
  color: var(--color-white);
  height: 100%;
  width: 22.5rem;
  max-width: 100%;
  transition: all 0.3s var(--ease-out-expo);
  padding: var(--height-nav) 0;

  ${MainNavigation} {
    display: flex;
    flex-direction: column;

    a {
      padding: 1.75rem 3rem;
      border-top: 1px solid var(--color-neutral-700);

      &:after {
        transform-origin: 0 center;
      }

      &:hover {
        background: var(--color-neutral-700);
      }
    }
  }

  ${SocialNavigation} {
    padding: 1.75rem 3rem;
    border-top: 1px solid var(--color-neutral-700);
    column-gap: 1.5rem;
  }

  ${props =>
    !props.isActive
      ? css`
          transform: translateX(100%);
        `
      : css`
          box-shadow: 0 0 2rem #000;
        `}

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;
