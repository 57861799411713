import { useState, useEffect } from 'react';
import { RememberMeData } from './RememberMeContext';
import { deleteUserData } from './api';

const DISPLAY_REMEMBER_ME =
  typeof window !== `undefined` && window?.Osano?.cm?.personalization && process.env.GATSBY_SECRET;

export const REMEMBER_ME_COOKIE_REGEX = /lookupKey=([^;]+)/;

export const useDisplayRememberMe = () => {
  const [displayRememberMe, setDisplayRememberMe] = useState(false);

  useEffect(() => {
    const shouldDisplay = !!DISPLAY_REMEMBER_ME;

    setDisplayRememberMe(shouldDisplay);
  }, []);

  return displayRememberMe;
};

export const initializeStateWithCookieVal = (initCallback: () => void) => {
  // Initialize the checkbox with a checked value if Remember Me cookie exists (meaning user on this
  // browser previously chose "Remember Me").
  const cookies = document.cookie;
  const regex = REMEMBER_ME_COOKIE_REGEX;
  const match = cookies.match(regex);

  match && match[1] && initCallback();
};

export const handleDeleteUserData = (saveUserData: boolean) => {
  // delete user data if necessary
  const cookies = document.cookie;
  const regex = REMEMBER_ME_COOKIE_REGEX;
  const match = cookies.match(regex);

  match && match[1] && deleteUserData(match[1], saveUserData);
};

export const validate = (data: { [k: string]: string } | RememberMeData) => {
  const dataKeys = Object.keys(data);
  const interfaceKeys = [
    'firstName',
    'lastName',
    'address1',
    'postcode',
    'locality',
    'state',
    'country',
    'email',
  ];

  const valid = interfaceKeys.reduce((acc, current) => {
    if (dataKeys.indexOf(current) + 1) {
      return (acc += 1);
    }
    throw new Error('Missing key for ' + current);
  }, 0);

  return valid && data;
};
