import React, { useContext } from 'react';
import { FormAlertContext } from '+/contexts/FormAlertContext';
import scrollToLocation from '+/utils/scrollToLocation';
import selectors from '+/components/DonateForm/selectors';
import { breakpoints, dimensions } from '+/styles/themes/mainTheme';
import analyticsEvent from '+/utils/analyticsEvent';
import * as Styled from './FormNavigation.styles';
import { TextButton } from '+/components/TextButton';
import { t } from '+/utils/textDictionary';

interface Props {
  controller: EveryActionModelController;
  initialStep: boolean;
  stacked?: boolean;
}

export const BackButton = ({ controller, initialStep, stacked }: Props) => {
  if (initialStep) {
    return null;
  }

  const { removeAlert } = useContext(FormAlertContext);
  const errorMessage = t('Widget.FormErrors.MissingFields');

  const onBackClick = e => {
    scrollToLocation(
      document.querySelector(selectors.FORM_HEADER_TITLE),
      dimensions.heightNav.value,
      'both',
      breakpoints.lg.value,
    );
    e.preventDefault();
    removeAlert(errorMessage);
    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: `Back ${controller.getPrevStep()}`,
      simpleName: `Return to step ${controller.getPrevStep()}`,
      context: { amount: controller.getSelectedAmount(), recurring: controller.getMonthly() },
    });
    controller.prevStep();
  };

  return (
    <Styled.BackButtonWrapper stacked={stacked}>
      <TextButton
        onClick={e => {
          onBackClick(e);
        }}
      >
        {t('Widget.FormNavigation.Back')}
      </TextButton>
    </Styled.BackButtonWrapper>
  );
};
