import React from 'react';
import * as Styled from './Picker.styles';

interface PickerGroupProps {
  children?: React.ReactNode;
  className?: string;
  columns?: number;
}

export const PickerGroup = (props: PickerGroupProps) => {
  const { children, className, columns = 2 } = props;

  return (
    <Styled.PickerGroup className={className} columns={columns}>
      {children}
    </Styled.PickerGroup>
  );
};
