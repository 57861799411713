import React from 'react';
import { t } from '+/utils/textDictionary';
import { save } from '+/components/DonateForm/utilities/valueRestorer';
import params from '+/utils/queryParams';
import * as Styled from './CurrencySelector.styles';
import urlChanger from '+/utils/urlChanger';
import analyticsEvent from '+/utils/analyticsEvent';

const BITCOIN_URL = 'https://bitpay.com/234981/donate';

const uRLFor = (
  controller: EveryActionModelController,
  currency: string,
  requiresFormChange = true,
) => {
  const queryParams = {
    ...params,
    currency,
    storeKey: requiresFormChange ? save(controller) : null,
  };

  const queryString = Object.keys(queryParams)
    .filter(k => queryParams[k])
    .map(k => `${k}=${queryParams[k]}`)
    .join('&');
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }${window.location.pathname}?${queryString}`;
};

export const CurrencySelector = ({ controller }: { controller: EveryActionModelController }) => {
  const currencyOptions = [
    ['USD', t('Widget.CurrencySelector.USD')],
    ['CAD', t('Widget.CurrencySelector.CAD')],
    ['EUR', t('Widget.CurrencySelector.EUR')],
    ['BTC', t('Widget.CurrencySelector.Bitcoin')],
  ];

  const adjustCurrency = event => {
    const newCurrency = event.target.value;

    analyticsEvent({
      eventTypeName: 'CustomFormEvent',
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: `Select Currency ${newCurrency}`,
      simpleName: `Select ${newCurrency}`,
    });

    if (newCurrency === 'BTC') {
      window.open(BITCOIN_URL);
      return;
    }

    controller.setCurrency(newCurrency);

    if (controller.isInternational() && newCurrency === 'USD' && !controller.noUSAlternative()) {
      urlChanger.loadUrl(uRLFor(controller, newCurrency));
    } else if (controller.isUS() && newCurrency !== 'USD') {
      urlChanger.loadUrl(uRLFor(controller, newCurrency));
    } else if (controller.isInternational()) {
      // Update url with currency even if not switching form
      urlChanger.updateUrl(uRLFor(controller, newCurrency, false));
    }
  };

  if (
    (controller.isUS() && controller.noInternationalAlternative()) ||
    (controller.isInternational() && controller.noUSAlternative())
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <label htmlFor="currency-selector" className="visually-hidden">
        {t('Widget.CurrencySelector.Label')}
      </label>
      <Styled.CurrencySelector
        value={controller.getCurrency()}
        onChange={adjustCurrency}
        id="currency-selector"
      >
        {currencyOptions.map(([code, name]) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </Styled.CurrencySelector>
    </React.Fragment>
  );
};
