import React, { useContext } from 'react';

import { ColorTheme } from '../ColorTheme';

import * as Styled from './SocialIconGroup.styles';

import { IconByName } from '+/components/Icon';
import { ColorThemeContext } from '+/contexts';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

const filledColorThemes = [
  ColorThemeNames.RedFilled,
  ColorThemeNames.BlueFilled,
  ColorThemeNames.TealFilled,
  ColorThemeNames.GreenFilled,
];

export const SocialIconGroup = ({
  socialIconGroupData,
}: {
  socialIconGroupData: SocialIconGroupComponent;
}) => {
  const parentColorTheme = useContext(ColorThemeContext);
  const colorTheme = socialIconGroupData.colorTheme || parentColorTheme;
  const hasFilledColorTheme = filledColorThemes.includes(colorTheme as ColorThemeNames);
  const socialIconLength = socialIconGroupData.socialLinks.length;

  return (
    <ColorTheme colorTheme={colorTheme}>
      <Styled.SocialIconGroup hasFilledColorTheme={hasFilledColorTheme}>
        {socialIconGroupData.title && <Styled.Title>{socialIconGroupData.title}</Styled.Title>}
        <Styled.SocialLinks socialIconLength={socialIconLength}>
          {socialIconGroupData.socialLinks.map(socialLink => (
            <Styled.SocialLink href={socialLink.uRL} title={socialLink.label} key={socialLink.id}>
              <IconByName iconName={socialLink.icon} />
            </Styled.SocialLink>
          ))}
        </Styled.SocialLinks>
      </Styled.SocialIconGroup>
    </ColorTheme>
  );
};
