import React from 'react';
import { getImageFilePath } from '+/utils/getImageFilePath';
import * as Styled from './PullQuote.styles';

export const PullQuote = ({
  pullQuoteData: { quoteText, citation, photo },
}: {
  pullQuoteData: PullQuoteComponent;
}) => {
  return (
    <Styled.PullQuote>
      <Styled.Body>
        <Styled.Text>{quoteText}</Styled.Text>
        {photo && (
          <Styled.Photo>
            <Styled.PhotoImage
              src={getImageFilePath(photo)}
              alt={photo.title}
              loading="lazy"
              height="190"
              width="190"
            />
          </Styled.Photo>
        )}
      </Styled.Body>
      {citation && (
        <Styled.Footer>
          <Styled.Citation>{citation}</Styled.Citation>
        </Styled.Footer>
      )}
    </Styled.PullQuote>
  );
};
