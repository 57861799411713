import React from 'react';

import * as Styled from './Button.styles';

import { Icon } from '+/components/Icon';
import { IconConstant } from '+/utils/constants/icons';
import { ColorTheme } from '+/components/ColorTheme';

interface ButtonProps {
  color?: ColorThemeName;
  big?: boolean;
  block?: boolean;
  narrow?: boolean;
  monochrome?: boolean;
  endIcon?: IconConstant;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  minContent?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const ThemeWrapper = ({ colorTheme, children: wrapperChildren }) => (
  <ColorTheme colorTheme={colorTheme}>{wrapperChildren}</ColorTheme>
);

export const Button = (props: ButtonProps) => {
  const { color, big, endIcon, children, ...rest } = props;
  const Wrapper = color
    ? ({ children: wrapperChildren }) => (
        <ThemeWrapper colorTheme={color}>{wrapperChildren}</ThemeWrapper>
      )
    : React.Fragment;

  return (
    <Wrapper>
      <Styled.Button color={color} big={big} {...rest}>
        {children}
        {endIcon?.path && (
          <Styled.EndIconWrapper>
            <Icon path={endIcon?.path} viewBox={endIcon?.viewBox} size={big ? 16 : 12} />
          </Styled.EndIconWrapper>
        )}
      </Styled.Button>
    </Wrapper>
  );
};

Button.defaultProps = {
  color: undefined,
  big: false,
  block: false,
  narrow: false,
  monochrome: false,
  endIcon: undefined,
  onClick: undefined,
  disabled: false,
  minContent: false,
  type: 'button',
};
