import styled, { css } from 'styled-components';

const iframeStyles = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  outline: none;
`;

export const IframeWrapper = styled.div`
  background: var(--color-black);
  position: relative;
  width: 100%;
  height: 0;
  box-sizing: content-box;
  padding-top: 56.25%;

  iframe {
    ${iframeStyles}
  }

  *:not(iframe) {
    display: contents;
  }
`;

export const Iframe = styled.iframe`
  ${iframeStyles}
`;
