import { css } from 'styled-components';

export const globalHeadingStyles = css`
  font-weight: 700;
  font-family: var(--font-secondary);
  text-transform: uppercase;
  line-height: calc(0.9em + 0.75rem);
  letter-spacing: 0.02em;

  em,
  i {
    font-family: var(--font-tertiary);
    font-style: italic;
    text-transform: none;
    letter-spacing: normal;
  }
`;

export const textH1 = css`
  ${globalHeadingStyles}
  font-size: clamp(var(--font-size-xl), 1.3rem + 1.93vw, var(--font-size-xxxl));
`;

export const textH2 = css`
  ${globalHeadingStyles}
  font-size: var(--font-size-xxl);
`;

export const textH3 = css`
  ${globalHeadingStyles}
  font-size: var(--font-size-xl);
`;

export const textH4 = css`
  ${globalHeadingStyles}
  font-size: var(--font-size-lg);
`;

export const italicH5 = css`
  ${globalHeadingStyles}
  font-size: var(--font-size-md);
  font-family: var(--font-tertiary);
  font-weight: inherit;
  font-style: italic;
  text-transform: inherit;
`;
