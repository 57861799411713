import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { PrivacyPolicyLink } from '../PrivacyPolicyLink';

import { Button } from '+/components/Button';
import { TextButton } from '+/components/TextButton';
import * as EveryActionAdvocacyFormStyled from '+/components/EveryActionAdvocacyForm/EveryActionAdvocacyForm.styles';
import * as FormStyled from '+/styles/styledComponents/Form.styles';
import { t } from '+/utils/textDictionary';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

export const MessageStage = (props: StageProps) => {
  const {
    isSubmitting,
    submitForm,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    outro,
    intro,
    isValid,
    advanceToNextStage,
    goBackToLastStage,
    everyActionAdvocacyFormData,
  } = props;
  const onSubmitClick = React.useCallback(async () => {
    await submitForm();
    advanceToNextStage();
  }, [submitForm]);

  return (
    <FormStyled.Container>
      <FormStyled.Header>
        <EveryActionAdvocacyFormStyled.Markdown>
          {everyActionAdvocacyFormData.advocacyContentHeader}
        </EveryActionAdvocacyFormStyled.Markdown>
      </FormStyled.Header>
      <FormStyled.Body className="adv-form__body">
        <FormStyled.Section className="adv-form__section">
          <FormStyled.CustomFieldRow>
            <FormStyled.InputLabel fullWidth floating errored={!!errors.Subject0}>
              <FormStyled.TextyInput
                name="Subject0"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Subject0}
                placeholder={`${t('EAAdvocacyForm.Subject')}*`}
              />
              <FormStyled.InputLabelText floating>
                {t('EAAdvocacyForm.Subject')}*
              </FormStyled.InputLabelText>
              {touched.Subject0 && errors.Subject0 && (
                <FormStyled.InputError>{errors.Subject0}</FormStyled.InputError>
              )}
            </FormStyled.InputLabel>
          </FormStyled.CustomFieldRow>
          <FormStyled.CustomFieldRow>
            <FormStyled.InputLabel fullWidth floating errored={!!errors.Message0}>
              <FormStyled.Textarea as="div" style={{ maxHeight: '20em', overflowY: 'auto' }}>
                <div style={{ color: 'grey', textAlign: 'left' }}>
                  {intro || t('EAAdvocacyForm.MessagePrefix')}
                </div>
                <br />
                <br />
                <FormStyled.UnstyledTextarea
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  as={TextareaAutosize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Message0}
                  name="Message0"
                  style={{ width: '100%', overflow: 'hidden' }}
                  placeholder={`${t('EAAdvocacyForm.Message')}*`}
                />
                <br />
                <br />
                <div style={{ color: 'grey', whiteSpace: 'pre-line', textAlign: 'left' }}>
                  {
                    /* eslint-disable indent */
                    outro
                      ? outro.replace(
                          '[Your information here]',
                          `${values.FirstName} ${values.LastName}`,
                        )
                      : t('EAAdvocacyForm.MessageSuffix', {
                          name: `${values.FirstName} ${values.LastName}`,
                        })
                    /* eslint-enable indent */
                  }
                </div>
              </FormStyled.Textarea>
              <FormStyled.InputLabelText floating>
                {t('EAAdvocacyForm.Message')}*
              </FormStyled.InputLabelText>
              {touched.Message0 && errors.Message0 && (
                <FormStyled.InputError>{errors.Message0}</FormStyled.InputError>
              )}
            </FormStyled.InputLabel>
          </FormStyled.CustomFieldRow>
          <FormStyled.CustomFieldRow centered marginTop="1.5rem" marginBottom="1.425rem">
            <Button
              color={ColorThemeNames.RedFilled}
              disabled={!isValid || isSubmitting}
              narrow
              big
              type="submit"
              onClick={onSubmitClick}
            >
              {t('EAAdvocacyForm.Submit')}
            </Button>
          </FormStyled.CustomFieldRow>
          <FormStyled.CustomFieldRow centered>
            <TextButton small onClick={goBackToLastStage}>
              {t('EAAdvocacyForm.PreviousStep')}
            </TextButton>
          </FormStyled.CustomFieldRow>
        </FormStyled.Section>
      </FormStyled.Body>

      <PrivacyPolicyLink />
    </FormStyled.Container>
  );
};
