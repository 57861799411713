import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin: 0 0 2.5rem;
`;

export const Error = styled.small`
  position: absolute;
  left: 0;
`;

export const TributeLabel = styled.label`
  margin: 1.5rem 0;
`;
