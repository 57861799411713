import { RememberMeData } from './RememberMeContext';
import { decryptUserData } from './decrypt';
import { validate } from './helpers';

const getCookie = (
  lookupKey: string,
  expiration: string,
  domain: '.localhost' | '.thehumaneleague.org',
) =>
  `lookupKey=${lookupKey}; Expires=${expiration}; SameSite=Strict; Secure; Domain=${domain}; Path=/;`;

const REMEMBER_ME_URL = 'https://aqxpmbq4q7.execute-api.us-east-1.amazonaws.com/user';
const domain =
  typeof window !== `undefined` && window.location.href.includes('localhost')
    ? '.localhost'
    : '.thehumaneleague.org';

export const fetchUserData = async (lookupKey: string) => {
  try {
    const res = await fetch(
      `${REMEMBER_ME_URL}?${new URLSearchParams({
        lookupKey,
      })}`,
    );

    const data = await res.json();

    const decrypted = await decryptUserData(lookupKey, data.encryptionKey);

    return validate(JSON.parse(decrypted));
  } catch (e) {
    console.error('fetchUserData returned an error: ', e);
    return null;
  }
};

export const postUserData = async (data: RememberMeData, saveUserData: boolean) => {
  if (!saveUserData) {
    // Do not save user data if the user has not consented to do so.
    return null;
  }

  if (!validate(data)) {
    console.error('postUserData failed because the user data provided was not complete.');
    return null;
  }

  try {
    const res = await fetch(`${REMEMBER_ME_URL}`, {
      method: 'POST',
      body: JSON.stringify({ userData: data }),
    });

    const json = await res.json();

    if (!json?.lookupKey) {
      throw new Error(
        `Unable to create cookie: No lookupKey provided in response. Error: ${json.message}`,
      );
    }

    if (!json?.expiration) {
      throw new Error(
        `Unable to create cookie: No expiration date provided in response. Error: ${json.message}`,
      );
    }

    document.cookie = getCookie(json.lookupKey, json.expiration, domain);
  } catch (e) {
    console.error('postUserData returned an error: ', e);
    return null;
  }
};

export const deleteUserData = async (lookupKey: string, saveUserData: boolean) => {
  if (saveUserData) {
    // Do not delete user data if the user has not consented to do so.
    return null;
  }

  try {
    const res = await fetch(
      `${REMEMBER_ME_URL}?${new URLSearchParams({
        lookupKey,
      })}`,
      {
        method: 'DELETE',
      },
    );

    const json = await res.json();

    if (!json?.lookupKey) {
      throw new Error(
        `Unable to delete: No lookupKey provided in response. Error: ${json.message}`,
      );
    }

    if (!json?.expiration) {
      throw new Error(
        `Unable to delete: No expiration date provided in response. Error: ${json.message}`,
      );
    }

    document.cookie = getCookie(json.lookupKey, json.expiration, domain);
  } catch (e) {
    console.error('deleteUserData returned an error: ', e);
    return null;
  }
};
