import { t } from '+/utils/textDictionary';

const camelToSentence = str => {
  // add space between strings
  const result = str.replace(/([A-Z])/g, ' $1');
  // convert first character to uppercase and join it to the final string
  const final = result.charAt(0).toUpperCase() + result.slice(1);
  return final;
};

const buildFloatingLabels = input => {
  // grab the input's parent label
  const labelTag = input.closest('label');
  if (!labelTag) {
    return;
  }

  // don't apply floating labels to any of the payment method fields
  if (
    (input.hasAttribute('placeholder') && input.placeholder.includes('•')) ||
    labelTag.getAttribute('class').includes('at-cc-expiration')
  ) {
    return;
  }

  // grab the input's name
  const getName = input.getAttribute('name');

  // add a unique ID to the label using the input's unique name
  // we use this id to query the label next
  labelTag.id = `inputLabel-${getName}`;

  // only continue if we haven't added a floating label yet
  if (!!document.querySelector(`#inputLabel-${getName} span.floatingLabel`)) {
    return;
  }

  // grab the input's title
  // if there is no title, use the formatted name instead
  const getTitle = input.getAttribute('title');
  let title = getTitle || (getName ? camelToSentence(getName) : null);
  if (!title) {
    return;
  }

  // adjust title depending on which field and if required
  const required = title.includes('(required)') || input.hasAttribute('required');
  const disabled = input.hasAttribute('disabled');
  // don't mark extra name fields as (optional) because it takes up too much room
  const isExtraNameField = getName === 'Prefix' || getName === 'MiddleName' || getName === 'Suffix';
  if (!isExtraNameField) {
    if (getName === 'AddressLine2') {
      title = t('Widget.Address.AddressLine2');
    } else if (required) {
      title = title.replace('(required)', '');
    } else if (!disabled) {
      title = `${title} (optional)`;
    }
  }

  // create new label
  const newLabelElement = document.createElement('span');
  newLabelElement.className = 'floatingLabel';
  newLabelElement.textContent = title;
  newLabelElement.setAttribute('aria-hidden', 'true');

  // append the new label
  input.insertAdjacentElement('afterend', newLabelElement);

  // add extra attributes
  input.placeholder = title;
  labelTag.className += ' hasFloatingLabel';
};

const addFloatingLabels = () => {
  // the types of inputs we typically want to add floating labels to
  const textyInputSelectors = [
    'input[type="tel"]',
    'input[type="text"]',
    'input[type="url"]',
    'input[type="number"]',
    'input[type="color"]',
    'input[type="email"]',
    'textarea',
    'select',
  ];

  textyInputSelectors.map(selector => {
    const inputs = Array.from(document.querySelectorAll(`.js-donate-form ${selector}`));

    inputs.map(input => {
      buildFloatingLabels(input);
    });
  });
};

export default () => {
  addFloatingLabels();
};
