/**
 * Wraps elements in containers for additional styling options.
 */
import selectors from '../selectors';
const DIV = 'div';
const FIELDSET = 'fieldset';
const P = 'p';

const alreadyDefined = (id: string) => {
  return !!document.querySelector(`#${id}`);
};

const parentNodesAreDifferent = (array: Array<Element>): boolean => {
  return array.filter(node => array[0].parentElement !== node.parentElement).length > 0;
};

const depth = (node: Element) => {
  let depth = 0;
  let currentNode = node;
  while (currentNode.parentElement) {
    depth++;
    currentNode = currentNode.parentElement;
    if (!currentNode) {
      throw new Error('no current node');
    }
  }
  return depth;
};

const depthsAreDifferent = (array: Array<Element>) => {
  const firstDepth = depth(array[0]);
  const value = array.filter(node => depth(node) !== firstDepth).length > 0;
  return value;
};

const raiseToMinimum = (array: Array<Element>): Array<Element> => {
  const minimum = Math.min(...array.map(node => depth(node)));
  return array.map(node => {
    let diff = depth(node) - minimum;
    let newNode = node;
    while (diff > 0) {
      newNode = newNode.parentElement;
      if (!newNode) {
        throw new Error('no node');
      }
      diff--;
    }
    return newNode;
  });
};

const bumpToNext = (array: Array<Element>) => {
  return array.map(node => (node.parentElement ? node.parentElement : node));
};

const adjacent = (array: Array<Element>) => {
  const { length } = array.filter(node => !array.includes(node.nextElementSibling));
  return length === 1;
};

/*
 * Wraps all of the elements corresponding to the selector in a element given by the tag.
 * Applies the id and classes.
 */
const wrapIn = (selectorArray: Array<string>, tag: string, id: string, classes: Array<string>) => {
  try {
    if (alreadyDefined(id)) {
      return;
    }
    let currentElements = selectorArray.map(selector => {
      // if (!selector) { alert(`${idx} is empty`); }
      const element = document.querySelector(selector);
      // if (!element) { alert(`${selector} not found`); }
      return element;
    });
    while (parentNodesAreDifferent(currentElements)) {
      if (depthsAreDifferent(currentElements)) {
        currentElements = raiseToMinimum(currentElements);
      } else {
        currentElements = bumpToNext(currentElements);
      }
    }
    if (!adjacent(currentElements)) {
      throw new Error(`${selectorArray.join(',')} Elements not adjacent.`);
    }
    const newTag = document.createElement(tag);
    newTag.id = id;
    if (classes) {
      newTag.classList.add(...classes);
    }
    currentElements[0].parentElement.insertBefore(newTag, currentElements[0]);
    currentElements.forEach(element => {
      element.parentElement.removeChild(element);
      newTag.appendChild(element);
    });
  } catch (e) {
    console.warn(
      `Error wrapping ${selectorArray} in ${tag}${id ? `#${id}` : ''}${
        classes ? `.${classes.join('.')}` : ''
      }`,
    );
    console.warn(e);
    throw e;
  }
};

/*
 * Wraps all of the children of the element corresponding to the selector in a new tag.
 */
const wrapUnder = (selector: string, tag: string, id: string, classes: Array<string>) => {
  try {
    if (alreadyDefined(id)) {
      return;
    }
    const newParent = document.querySelector(selector);
    const newTag = document.createElement(tag);
    newTag.id = id;
    if (classes) {
      newTag.classList.add(...classes);
    }
    const children = Array.from(newParent.children);
    children.forEach(child => {
      newParent.removeChild(child);
      newTag.appendChild(child);
    });
    newParent.appendChild(newTag);
  } catch (e) {
    console.warn(
      `Error wrapping ${selector}'s children under ${tag}${id ? `#${id}` : ''}${
        classes ? `.${classes.join('.')}` : ''
      }`,
    );
    console.warn(e);
    throw e;
  }
};

export default (view: EveryActionView, controller: EveryActionModelController) => {
  wrapIn(
    [
      selectors.CONTRIBUTION_FIELDSET,
      selectors.TRIBUTE_FIELDSET,
      '#RecipientInformation_Value',
      selectors.RECIPIENT_INFORMATION_FIELDSET,
      selectors.CONTACT_FIELDSET,
      selectors.PAYMENT_FIELDSET,
      selectors.PAYMENT_METHOD_FIELDSET,
    ].filter(c => Boolean(document.querySelector(c))),
    DIV,
    'FormContents',
    [],
  );
  wrapIn([selectors.STEPS_CONTAINER], DIV, 'Steps', ['steps']);

  // wrapIn([selectors.NEXT_BUTTON], DIV, 'NextStep', ['button', 'button--primary', 'button--inline']);

  wrapIn([selectors.PREVIOUS_BUTTON], DIV, 'PrevStep', [
    'button',
    'button--link',
    'button--inline',
    'button--back',
  ]);

  wrapIn([selectors.PREVIEW_ECARD_BUTTON], DIV, 'PreviewEcard', ['button', 'button--text-link']);

  wrapUnder(selectors.RECIPIENT_NAME_ROW, DIV, 'RecipientNameRow', []);
  wrapUnder(selectors.E_CARD_ROW, DIV, 'ECardRow', ['field-row', 'tribute__ecards']);
  // EA uses style attributes to show and hide this fieldset,
  // so we wrap it in div to be able to toggle its visibility.
  wrapIn([selectors.RECIPIENT_INFORMATION_FIELDSET], DIV, 'RecipientInformationHider', []);
  wrapIn(
    [
      selectors.RECIPIENT_HEADER,
      selectors.RECIPIENT_NAME_ROW,
      selectors.RECIPIENT_EMAIL_ADDRESS,
      selectors.ECARD_ROW,
      selectors.ECARD_SEND_DATE,
      selectors.ECARD_SEND_COPY,
      selectors.ECARD_MESSAGE,
    ],
    DIV,
    'RecipientFields',
    [],
  );

  wrapUnder(selectors.IN_MEMORY_OF_ITEM, DIV, 'InHonorOrMemoryOfRow', ['tribute__honor-memory']);
  wrapIn([selectors.RECIPIENT_HEADER], P, 'RecipientInfoHeader', []);
  wrapIn(
    [
      selectors.CONTACT_PREFIX_LABEL,
      selectors.CONTACT_FIRST_NAME_LABEL,
      selectors.CONTACT_MIDDLE_NAME_LABEL,
      selectors.CONTACT_LAST_NAME_LABEL,
      selectors.CONTACT_SUFFIX_LABEL,
    ],
    DIV,
    'NameRow',
    ['field-row', 'field-row--auto'],
  );
  wrapUnder(selectors.ADDRESS_ADDRESS_LABEL, DIV, 'AddressRow', []);
  wrapIn(
    [selectors.ADDRESS_STREET_FIELDS, selectors.ADDRESS_LOCALE_FIELDS],
    FIELDSET,
    selectors.ADDRESS_DETAILS.replace('#', ''),
    [],
  );
  wrapIn(
    [selectors.POSTAL_CODE_LABEL, selectors.CITY_LABEL, selectors.STATE_PROVINCE_LABEL],
    FIELDSET,
    'CityAndState',
    ['field-row'],
  );

  wrapIn(
    [
      selectors.CONTACT_EMAIL_ADDRESS_LABEL,
      document.querySelector(selectors.EMAIL_VALIDATION) ? selectors.EMAIL_VALIDATION : null,
      selectors.CONTACT_MOBILE_PHONE_LABEL,
    ].filter(Boolean),
    DIV,
    'EmailPhoneRow',
    ['field-row'],
  );

  wrapIn([selectors.NAME_ROW_PREFIX_LABEL], DIV, 'PrefixInput', [
    'field-row__item',
    'field-row__item--prefix',
  ]);
  wrapIn([selectors.NAME_ROW_FIRST_NAME_LABEL], DIV, 'FirstNameInput', [
    'field-row__item',
    'field-row__item--firstname',
  ]);
  wrapIn([selectors.NAME_ROW_MIDDLE_NAME_LABEL], DIV, 'MiddleNameInput', [
    'field-row__item',
    'field-row__item--middlename',
  ]);
  wrapIn([selectors.NAME_ROW_LAST_NAME_LABEL], DIV, 'LastNameInput', [
    'field-row__item',
    'field-row__item--lastname',
  ]);
  wrapIn([selectors.NAME_ROW_SUFFIX_LABEL], DIV, 'SuffixInput', [
    'field-row__item',
    'field-row__item--suffix',
  ]);

  wrapIn([selectors.PAYMENT_FIELDSET], DIV, 'PaymentInfoTabs', ['tabs']);
  wrapUnder(selectors.PAYMENT_TAB_CONTENT, DIV, 'PaymentInfoTabContent', [
    'tabs__content',
    'tabs__content--padded',
  ]);
  wrapUnder(selectors.PAYMENT_TAB_CONTENT_CHILD, DIV, 'CreditCardInfoRow', ['field-row']);

  if (controller.isUS()) {
    wrapIn([selectors.ACCOUNT_WHATS_THIS], DIV, 'WhatsThisAccountNumber', ['tooltip__popup']);
    wrapIn([selectors.ROUTING_WHATS_THIS], DIV, 'WhatsThisRoutingNumber', ['tooltip__popup']);
  }

  if (document.querySelector(selectors.VGS_CC)) {
    wrapIn([selectors.VGS_CC], DIV, 'CreditCardNumber', ['input-wrap']);
    wrapIn([selectors.VGS_EXPIRATION], DIV, 'ExpirationDate', ['input-wrap']);
    wrapIn([selectors.CREDIT_CARD_INFO_ROW], DIV, 'CreditCardTabContent', []);
  }

  // wrapIn([selectors.FOOTER_HTML], DIV, 'SecurePaymentsText', ['secure-payments-message']);

  wrapUnder(selectors.FORM_SUBMIT, DIV, 'ButtonContainer', ['form--buttons']);

  wrapUnder(selectors.ECARD_PREVIEW_MODAL, DIV, 'EcardPreview', ['ecard-preview']);
  wrapIn([selectors.ERROR_CONSOLE], DIV, 'ErrorConsole', ['error-console']);
};
