import * as yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationFor = (validationName, isRequired) => {
  let validation = yup.string();
  switch (validationName) {
    case 'postalCode':
      break;
    case 'phone':
      validation = validation.matches(phoneRegExp, 'Phone number is not valid');
      break;
    case 'email':
      validation = validation.email();
      break;
  }
  if (isRequired) {
    validation = validation.required();
  }
  return validation;
};

const addValidators = (element, accum) => {
  if (element.validation || element.required) {
    accum[element.name] = validationFor(element.validation, element.required);
  }
  if (element.children) {
    element.children.forEach(child => addValidators(child, accum));
  }
};

export default {
  build: formElements => {
    const shape = formElements.reduce((accum, element) => {
      addValidators(element, accum);
      return accum;
    }, {});
    return yup.object().shape(shape);
  },
};
