export default {
  '&EAPetitionForm.Prefix': 'Título',
  '&EAPetitionForm.FirstName': 'Nombre',
  '&EAPetitionForm.MiddleName': 'Segundo nombre',
  '&EAPetitionForm.LastName': 'Apellido',
  '&EAPetitionForm.Suffix': 'Sufijo',
  '&EAPetitionForm.EmailAddress': 'Correo',
  '&EAPetitionForm.MobilePhone': 'Teléfono Móvil',
  '&EAPetitionForm.PostalCode': 'Código Postal',
  '&EAPetitionForm.Consent': 'Quiero recibir actualizaciones de The Humane League México',
  '&EAPetitionForm.Country': 'País',
  '&EAPetitionForm.NextStep': ' Continuar >',
  '&EAPetitionForm.PreviousStep': 'Volver',
  '&EAPetitionForm.Submit': 'Enviar',
  '&EAPetitionForm.InfoConsent':
    'Mantén esta casilla seleccionada si quieres recibir actualizaciones de nuestras campañas y sencillas acciones que puedes realizar por los animales. Puedes darte de baja en cualquier momento.',
  '&EAPetitionForm.InfoConsentSms':
    'Mantenga esta casilla marcada si desea recibir alertas SMS (texto) ocasionales de The Humane League, incluidas actualizaciones de campaña y alertas de acción. Puede optar por no participar en cualquier momento.',
  '&EAPetitionForm.ConsentSms': 'Enviarme alertas SMS ocasionales',
  '&EAPetitionForm.SignNow': 'ENVIAR',
  '&EAPetitionForm.AcceptPrivacyPolicyPrefix': 'He leído y acepto el',
  '&EAPetitionForm.AcceptPrivacyPolicyLinkText': 'aviso de privacidad',
  '&EAPetitionForm.AcceptPrivacyPolicyLinkUrl': 'https://thehumaneleague.mx/politica-de-privacidad',
  '&EAPetitionForm.AcceptPrivacyPolicyErrorNotChecked': 'Debe aceptar enviar.',
} as TextDictionary;
