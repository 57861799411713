import { THEME_COLORS } from '+/styles/themes/colorThemes';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

const colorKeywordToColorName = (keyword: string): string => {
  if (Object.values(ColorThemeNames).includes(keyword as ColorThemeNames)) {
    return keyword;
  }
  switch (keyword) {
    case 'primary':
      return ColorThemeNames.RedAccented;
    case 'secondary':
      return ColorThemeNames.YellowAccented;
    case 'accent':
      return ColorThemeNames.BlueAccented;
    case 'default':
      return ColorThemeNames.GreyAccented;
    case 'red':
      return ColorThemeNames.RedAccented;
    case 'yellow':
      return ColorThemeNames.YellowAccented;
    case 'blue':
      return ColorThemeNames.BlueAccented;
    case 'green':
      return ColorThemeNames.GreenAccented;
    case 'teal':
      return ColorThemeNames.TealAccented;
    case 'grey':
      return ColorThemeNames.GreyAccented;
    default:
      return 'inherit';
  }
};

export const getColorThemeVariables = (colorTheme: ColorThemeName): string | null => {
  const colorName = colorKeywordToColorName(colorTheme);
  if (colorName === 'inherit') {
    return null;
  }
  return `
  --color-theme-base: ${THEME_COLORS[colorName].base.default};
  --color-theme-base-accessible: ${THEME_COLORS[colorName].base.accessible};
  --color-theme-base-inverse: ${THEME_COLORS[colorName].base.inverse};
  --color-theme-base-dark: ${THEME_COLORS[colorName].base.dark};
  --color-theme-base-light: ${THEME_COLORS[colorName].base.light};
  --color-theme-base-danger: ${THEME_COLORS[colorName].base.danger};
  --color-theme-accent: ${THEME_COLORS[colorName].accent.default};
  --color-theme-accent-accessible: ${THEME_COLORS[colorName].accent.accessible};
  --color-theme-accent-inverse: ${THEME_COLORS[colorName].accent.inverse};
  --color-theme-accent-dark: ${THEME_COLORS[colorName].accent.dark};
  --color-theme-accent-light: ${THEME_COLORS[colorName].accent.light};
  --color-theme-accent-danger: ${THEME_COLORS[colorName].base.danger};
  // vv Depracated vv
  --color-theme: ${THEME_COLORS[colorName].accent.default};
  --color-theme-inverse: ${THEME_COLORS[colorName].accent.inverse};
  --color-theme-dark: ${THEME_COLORS[colorName].accent.dark};
  --color-theme-light: ${THEME_COLORS[colorName].accent.light};
  `;
};
