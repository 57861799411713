import styled from 'styled-components';

export const CurrencySelector = styled.select`
  && {
    width: auto;
    background-color: transparent;
    color: var(--color-neutral-500);
    border-bottom: none;
    position: absolute;
    top: 1.3125rem;
    right: 1.4rem;
    width: 4.2rem;
    padding: 0;
    height: auto;
    font-size: 1.125rem;
    line-height: normal;
    cursor: pointer;

    &:focus {
      // apply browser default focus ring
      // https://css-tricks.com/copy-the-browsers-native-focus-styles/
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
`;
