import React, { useContext, useState } from 'react';
import { withFormik } from 'formik';

import { FormError } from '../FormError';

import enUsAdvocacyDictionary from './EveryActionAdvocacyForm.dictionary.en-US';
import esMxAdvocacyDictionary from './EveryActionAdvocacyForm.dictionary.es-MX';
import { EveryActionAdvocacyForm } from './EveryActionAdvocacyForm.component';
import everyActionAdvocacyFormValidators from './EveryActionAdvocacyForm.validators';

import { encodeSpecialCharacters, parser } from '+/utils/contentParser';
import { useEveryActionFormData } from '+/utils/hooks/useEveryActionFormData';
import { promisesStatus } from '+/utils/getASContent';
import { personalDataStore } from '+/utils/personalDataStore';
import { addToDictionary } from '+/utils/textDictionary';
import { useGeoIpCountry } from '+/utils/hooks/useGeoIpCountry';
import params from '+/utils/queryParams';
import { LanguageContext } from '+/contexts/LanguageContext';
import { UserContext } from '+/contexts/UserContext';
import { handleDeleteUserData, postUserData, useRememberMe } from '+/contexts';

const addedLanguages: Array<SupportedLanguage> = [];

export default ({
  everyActionAdvocacyFormData,
  advanceFormSequence,
}: EveryActionAdvocacyFormContainerProps) => {
  const language: SupportedLanguage = useContext(LanguageContext);
  const { userData, setUserData } = useContext(UserContext);
  const formHelpers = useEveryActionFormData(everyActionAdvocacyFormData.formId);
  const [submitError, setSubmitError] = useState(false);
  const [currentStage, setCurrentStage] = useState(0);
  const [personalizedMessage, setPersonalizedMessage] = useState({ subject: '', message: '' });
  const geoIpCountry = useGeoIpCountry();
  const { sourceCodeId, marketSource } = everyActionAdvocacyFormData;
  const { sourceid, ms } = params;

  const { state: rememberMeState } = useRememberMe();
  const { userData: rememberMeUserData, saveUserData: rememberMeChecked } = rememberMeState;

  if (!addedLanguages.includes(language)) {
    if (language === 'es-MX') {
      addToDictionary(esMxAdvocacyDictionary);
    } else {
      addToDictionary(enUsAdvocacyDictionary);
    }

    addedLanguages.push(language);
  }

  const { formElements, submissionUrl, suppliedOptions, advocacyFields } = formHelpers;
  const { subject: eaSubject, message: eaMessage, intro: eaIntro, outro: eaOutro } = advocacyFields;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const postForm = async (formValues, actions) => {
    const formData = new URLSearchParams();
    const defaults = {
      type: 'AdvocacyForm',
      XFromApp: 'ActionTag',
      ActionTagBaseUrl: 'https://static.everyaction.com/ea-actiontag/',
    };

    // Save edited subject and message in case form fails to submit and the user needs to re-try
    setPersonalizedMessage({ subject: formValues.Subject0, message: formValues.Message0 });

    const correctedFormValues = { ...formValues, ...defaults };
    if (formValues.MobilePhone || formValues.Country !== 'US') {
      correctedFormValues.SmsSubscribeMobilePhone = false;
    }

    delete correctedFormValues.Subject0;
    delete correctedFormValues.Message0;
    delete correctedFormValues.AcceptedPrivacyPolicy;
    Object.keys(correctedFormValues).forEach(key => formData.append(key, formValues[key]));

    formData.append('Messages', `["${encodeSpecialCharacters(formValues.Message0)}"]`);
    formData.append('Subjects', `["${encodeSpecialCharacters(formValues.Subject0)}"]`);

    setUserData({
      ...userData,
      firstName: correctedFormValues.FirstName,
      lastName: correctedFormValues.LastName,
      emailAddress: correctedFormValues.EmailAddress,
      postalCode: correctedFormValues.PostalCode,
      country: correctedFormValues.Country,
      mobilePhone: correctedFormValues.MobilePhone,
    });

    if (sourceid || sourceCodeId) {
      formData.set('SourceCodeId', sourceid || sourceCodeId);
    }

    if (ms || marketSource) {
      formData.set('MarketSource', ms || marketSource);
    }

    if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'development') {
      await fetch(`https://advocator.ngpvan.com/${encodeURIComponent(submissionUrl)}/ngpForm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
      })
        .catch(() => setSubmitError(true))
        .then(async (response: Response) => {
          if (!response) {
            setSubmitError(true);
            return;
          }
          const body = await response.json();
          // Error if errors in json response.
          if (response?.ok && !body?.errors?.length) {
            if (advanceFormSequence) advanceFormSequence();
          } else {
            setSubmitError(true);
          }
        });
    } else {
      // eslint-disable-next-line no-console -- As a safety guard on production we log the form instead of POSTing
      console.log({
        url: `https://advocator.ngpvan.com/${encodeURIComponent(submissionUrl)}/ngpForm`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      rememberMeChecked &&
        postUserData(
          {
            firstName: rememberMeUserData?.firstName,
            lastName: rememberMeUserData?.lastName,
            address1: '',
            postcode: rememberMeUserData?.email,
            locality: '',
            state: '',
            country: rememberMeUserData?.postcode,
            email: rememberMeUserData?.country,
            address2: '',
            mobilePhone: rememberMeUserData?.mobilePhone || '',
          },
          rememberMeChecked,
        );
      !rememberMeChecked && handleDeleteUserData(rememberMeChecked);

      if (advanceFormSequence) advanceFormSequence();
    }
  };

  const initialSubject = parser(
    everyActionAdvocacyFormData.subject || (eaSubject && eaSubject.value),
  );

  const initialMessage = parser(everyActionAdvocacyFormData.body || (eaMessage && eaMessage.value));
  const isReady = !!formElements && promisesStatus.finished;

  if (!isReady) return null;

  const formKeys = Object.keys(everyActionAdvocacyFormValidators().fields);
  const storedPersonalData = Object.keys(personalDataStore).reduce(
    (accum: Record<string, string>, key: string) =>
      formKeys.includes(key) ? { ...accum, [key]: personalDataStore[key] } : accum,
    {},
  );

  const WrappedForm = withFormik<
    {
      suppliedOptions: Record<string, unknown>;
      intro: string;
      outro: string;
      everyActionAdvocacyFormData: EveryActionAdvocacyFormComponent;
      currentStage: number;
      setCurrentStage: (value: number) => void;
    },
    EveryActionAdvocacyFormInputValues
  >({
    handleSubmit: postForm,
    validationSchema: everyActionAdvocacyFormValidators(),
    mapPropsToValues: () => ({
      FirstName: rememberMeUserData?.firstName || userData.firstName || '',
      LastName: rememberMeUserData?.lastName || userData.lastName || '',
      EmailAddress: rememberMeUserData?.email || userData.emailAddress || '',
      PostalCode: rememberMeUserData?.postcode || userData.postalCode || '',
      Country: rememberMeUserData?.country || userData.country || geoIpCountry || '',
      MobilePhone: rememberMeUserData?.mobilePhone || userData.mobilePhone || '',
      ...storedPersonalData,
      Subject0: personalizedMessage.subject || initialSubject,
      Message0: personalizedMessage.message || initialMessage,
      YesSignMeUpForUpdatesForBinder: true,
      SmsSubscribeMobilePhone: true,
      AcceptedPrivacyPolicy: false,
    }),
  })(EveryActionAdvocacyForm);

  if (submitError)
    return (
      <FormError
        handleRetry={() => {
          setSubmitError(false);
          setCurrentStage(0);
        }}
      />
    );

  return (
    <WrappedForm
      intro={eaIntro}
      outro={eaOutro}
      everyActionAdvocacyFormData={everyActionAdvocacyFormData}
      suppliedOptions={suppliedOptions}
      currentStage={currentStage}
      setCurrentStage={setCurrentStage}
    />
  );
};
