import styled from 'styled-components';
import { Section } from '+/components/Section/Section.styles';

export const PullQuote = styled.blockquote`
  font-size: 1.375rem;
  margin: 3em auto;
  max-width: 22em;
  padding-top: 4.8rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 30.5em;
    font-size: 1.5625rem;
  }

  /* If direct child of a section we need to
   * provide horizontal padding for small screens
  */
  ${Section} > & {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: calc(22em + 3rem);

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      max-width: calc(30.5em + 3rem);
    }
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2.25rem;
`;

export const Text = styled.p`
  margin: 0;
  font-family: var(--font-tertiary);
  font-style: italic;
  font-size: 1em;
  letter-spacing: -0.015em;
  line-height: 1.5;
  position: relative;

  &::before {
    bottom: 100%;
    content: '“';
    display: block;
    left: 0;
    position: absolute;
    font-size: 4.8em;
    font-family: var(--font-secondary);
    font-weight: 700;
    font-style: normal;
    line-height: 0.25;
    color: var(--color-theme-accent);
  }
`;

export const Photo = styled.div`
  width: 11.875rem;
  height: 11.875rem;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: block;
  }
`;

export const PhotoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
`;

export const Footer = styled.footer`
  padding-top: 1rem;
`;

export const Citation = styled.cite`
  display: block;
  font-size: 0.875rem;
  font-style: normal;
`;
