import styled from 'styled-components';
import { REPORT_COLORS } from '+/components/USDAViolationsDisplay/utils/reportColors';

export const Dot = styled.span<{ incidentType: string }>`
  display: block;
  height: 1.25em;
  width: 1.25em;
  background: ${props => REPORT_COLORS[props.incidentType]};
  border-radius: 50%;
  display: inline-block;
  transition: transform 0.1s ease-out;
  flex-shrink: 0;
`;

export const DotFilter = styled.button<{ isActive: boolean }>`
  display: block;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6875)};
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  padding: 0.5rem 0;
  line-height: 1.25;
  display: flex;
  width: 100%;
  gap: 1em;
  align-items: center;
  transition: opacity 0.1s ease-out;
  text-align: left;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible,
  &:hover {
    opacity: 1;

    ${Dot} {
      transform: scale(1.2);
    }
  }
`;

export const Title = styled.span`
  font-family: var(--font-secondary);
`;
