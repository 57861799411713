import React, { useState, useEffect, useRef } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from 'react-youtube';
import getVideoId from 'get-video-id';
import { LoadingContext } from '+/contexts';
import { getImageFilePath } from '+/utils/getImageFilePath';
import { useIsMobile } from '+/utils/responsive';
import * as Styled from './VideoItem.styles';

export const VideoItem = ({
  videoItemData,
  loadBeforeReveal,
  ...props
}: {
  videoItemData: VideoItem;
  loadBeforeReveal?: Boolean;
}) => {
  const loadingContext = React.useContext(LoadingContext);
  const {
    poster,
    posterMobile,
    directUrl,
    directUrlMobile,
    hostedUrl,
    preload,
    autoplay,
    muted,
    controls,
    loop,
    handleOnEnded,
    playsInline,
  } = videoItemData;

  const videoRef = useRef(null);
  const isMobile = useIsMobile();

  if (!!hostedUrl) {
    const { service, id } = getVideoId(hostedUrl);

    switch (service) {
      case 'youtube':
        return (
          <Styled.IframeWrapper>
            <YouTube
              videoId={id}
              opts={{
                playerVars: {
                  autoplay: autoplay ? 1 : 0,
                  controls: controls ? 1 : 0,
                  loop: loop ? 1 : 0,
                  modestbranding: 1,
                  playsInline: playsInline ? 1 : 0,
                },
              }}
              onEnd={() => handleOnEnded()}
            />
          </Styled.IframeWrapper>
        );
      case 'vimeo':
        return (
          <Styled.IframeWrapper>
            <Vimeo
              controls={controls}
              video={id}
              autoplay={autoplay}
              loop={loop}
              muted={muted}
              onEnd={() => handleOnEnded()}
              playsInline={playsInline}
            />
          </Styled.IframeWrapper>
        );
      default:
        return null;
    }
  }

  const getVideoSrc = (): string => {
    if (isMobile && !!directUrlMobile) {
      return directUrlMobile;
    }
    return directUrl;
  };

  const getPosterSrc = (): string => {
    if (isMobile && !!posterMobile) {
      return getImageFilePath(posterMobile);
    }
    return getImageFilePath(poster);
  };

  useEffect(() => {
    videoRef.current.setAttribute('src', getVideoSrc());
    videoRef.current.setAttribute('poster', getPosterSrc());
    videoRef.current.load();
  }, []);

  if (loadBeforeReveal) {
    loadingContext.addLoadable(
      new Promise((resolve, reject) => {
        if (typeof document === 'undefined') return;
        const vid = document.createElement('video');
        switch (preload) {
          case 'auto':
            vid.onloadeddata = e => resolve(null);
            break;
          case 'metadata':
            vid.onloadedmetadata = e => resolve(null);
          case 'none':
            resolve(null);
            break;
          default:
            reject(null);
            break;
        }
        vid.onerror = e => reject(null);
        vid.src = getVideoSrc();
      }),
    );
  }

  return (
    <video
      {...props}
      controls={controls}
      poster={getPosterSrc()}
      autoPlay={autoplay}
      loop={loop}
      muted={muted}
      preload={preload}
      src={getVideoSrc()}
      playsInline={playsInline}
      onEnded={() => handleOnEnded()}
      ref={videoRef}
    />
  );
};
