import { useEffect, useState } from 'react';
import { debounce } from '+/utils/debounce';

export const useWindowHeight = () => {
  const isClient = typeof window !== `undefined`;

  const getHeight = () => {
    return isClient ? window.innerHeight : undefined;
  };

  const [windowHeight, setWindowHeight] = useState(getHeight());

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const debounceHandleResize = debounce(function handleResize() {
      setWindowHeight(window.innerHeight);
    }, 200);

    window.addEventListener('resize', debounceHandleResize);
    return () => {
      window.removeEventListener('resize', debounceHandleResize);
    };
  }, []);

  return { windowHeight };
};
