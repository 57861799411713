import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';

export const SmallCurrency = styled.span`
  &&& {
    padding: 0;
    font-size: 1rem;
    top: -0.125rem;
    margin: 0 1px 0 0;
    position: relative;

    &:before,
    &:after {
      display: none;
    }
  }
`;

export const SmallLabel = styled.span`
  &&& {
    padding: 0;
    font-size: 1rem;
    text-transform: lowercase;
    margin: 0 0 0 1px;
    bottom: -0.188rem;
    position: relative;

    &:before,
    &:after {
      display: none;
    }
  }
`;

export const OtherWrapper = styled.div`
  grid-column: 1 / span 2;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  background: var(--color-neutral-100);

  & > *:first-child {
    width: 50%;
    flex-grow: 1;
  }
`;

export const CustomLabel = styled.span`
  &&& {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 1.125rem;
    padding: 0;
    overflow: hidden;

    &:before,
    &:after {
      display: none;
    }
  }
`;

export const CustomInputWrapper = styled(motion.div)`
  width: 1px;
  overflow: hidden;
`;

export const CustomInputLabel = styled(motion.label)`
  &&& {
    padding: 0;
  }

  span {
    position: absolute;
    left: 0.875rem;
    font-size: 2rem;
    line-height: 1;
    top: 0.875rem;
    font-family: var(--font-primary);
    color: var(--color-neutral-500);
  }
`;

export const CustomInput = styled.input`
  &&& {
    height: 4rem;
    font-size: 2rem;
    border-bottom-width: 3px;
    text-align: center;
  }
`;
