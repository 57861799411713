import React, { useState, useEffect, useRef, useContext } from 'react';
import Helmet from 'react-helmet';
import { Loader } from '@googlemaps/js-api-loader';
import { Tooltip } from '+/components/Tooltip';
import { t } from '+/utils/textDictionary';
import selectors from '+/components/DonateForm/selectors';
import analyticsEvent from '+/utils/analyticsEvent';
import { FormFieldErrorsContext } from '+/contexts/FormFieldErrorsContext';
import { RememberMeData, useRememberMe } from '+/contexts';

let autoComplete;

const loader = new Loader({
  apiKey: 'AIzaSyAKPrittWSItkL4Ys4tsLqL6u20p4i0R-U',
  version: 'weekly',
  libraries: ['places'],
});

const handleRememberMeAutofill = (
  reveal,
  handlePlaceSelect,
  updateAddress,
  controller,
  rememberMeData: RememberMeData,
) => {
  const { address1, postcode, locality, state, country } = rememberMeData;

  handlePlaceSelect(updateAddress, controller, {
    address1,
    postcode,
    locality,
    state,
    country,
  });

  if (rememberMeData?.address2) {
    const address2s = document.getElementsByName('AddressLine2');

    if (address2s[0]) {
      (address2s[0] as HTMLInputElement).value = rememberMeData?.address2;
    }
  }

  reveal();
};

// handle when the script is loaded we will assign autoCompleteRef with google maps place autocomplete
const handleScriptLoad = (updateAddress, autoCompleteRef, controller) => {
  // assign autoComplete with Google maps place one time
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
    fields: ['address_components'],
    types: ['address'],
  });

  autoComplete.addListener('place_changed', () => {
    handlePlaceSelect(updateAddress, controller, null);
  });
};

const reveal = () => {
  const addressFieldset: HTMLElement = document.querySelector(selectors.ADDRESS_DETAILS);
  // add class to address fieldset so we can animate it in
  !!addressFieldset ? (addressFieldset.className += ' reveal') : null;
  // Reveal inputs to screen readers
  const elementsToReveal = document.querySelectorAll('fieldset#AddressFieldset *');

  elementsToReveal.forEach(element => {
    element.removeAttribute('aria-hidden');
    element.removeAttribute('tabindex');
  });
};

const revealAndFocus = controller => {
  reveal();
  const address2input: HTMLElement = document.querySelector(selectors.ADDRESS_LINE2_INPUT);
  // wait for input to appear
  setTimeout(function () {
    // focus on the 2nd address field
    address2input?.focus();
  }, 100);
};

const handlePlaceSelect = async (updateAddress, controller, rememberMeData = null) => {
  let address1 = '';
  let postcode = '';
  let locality = '';
  let state = '';

  if (rememberMeData) {
    const address1 = rememberMeData.address1;
    const postcode = rememberMeData.postcode;
    const locality = rememberMeData.locality;
    const state = rememberMeData.state;
    const country = rememberMeData.country;

    controller.setMailingAddress(address1, locality, state, postcode, country);
  } else {
    const place = autoComplete.getPlace();
    const { short_name: country } = place.address_components.find(c => c.types[0] === 'country');

    for (const component of place.address_components) {
      // Component.types are listed in order of specifity.
      // Some categories map to the same field.
      // Using the first generally works
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'postal_code_prefix': {
          if (!postcode) {
            postcode = component.long_name;
            break;
          }
          postcode = `${component.long_name}-${postcode}`;
          break;
        }

        case 'locality':
        case 'sublocality_level_1':
        case 'postal_town': {
          // If locality not set, use sublocality or postal town.
          locality = locality || component.long_name;
          break;
        }

        case 'administrative_area_level_2': {
          if (country !== 'GB') break;
          state = component.short_name;
          break;
        }

        case 'administrative_area_level_1': {
          state = state || component.short_name;
          break;
        }
      }
    }

    controller.setMailingAddress(address1, locality, state, postcode, country);
  }

  analyticsEvent({
    eventTypeName: 'CustomFormEvent',
    targetElement: 'EveryActionForm',
    targetElementVariation: 'Donation',
    triggerAction: `Address Autocompleted`,
    simpleName: 'Address filled with autocomplete',
  });

  updateAddress(address1);
  revealAndFocus(controller);
  controller.renderAddressFeedback();
};

export const MailingAddressAutocomplete = ({ controller }) => {
  const [address, setAddress] = useState(controller.getAddressLine1() || '');
  const [isAutocompleteExpanded, setIsAutoCompleteExpanded] = useState(false);
  const autoCompleteRef = useRef(null);

  const { errors, addErrors } = useContext(FormFieldErrorsContext);
  const addressError = 'Address is required.';
  const showError = errors.find(err => err.text === addressError);

  const { state } = useRememberMe();
  const { userData: rememberMeData } = state;

  useEffect(() => {
    const rememberMeDataExists = !!rememberMeData;

    if (rememberMeDataExists) {
      handleRememberMeAutofill(reveal, handlePlaceSelect, setAddress, controller, rememberMeData);
    }
  }, [rememberMeData]);

  // Figure out if autocomplete menu is visible so we can pass that information on to screen reader users
  const updateIsAutocompleteExpanded = () => {
    const autoCompleteMenu = document.getElementsByClassName('pac-container');
    setTimeout(function () {
      if (
        !!autoCompleteMenu &&
        autoCompleteMenu.length > 0 &&
        window.getComputedStyle(autoCompleteMenu[0]).getPropertyValue('display') !== 'none'
      ) {
        setIsAutoCompleteExpanded(true);
      } else {
        setIsAutoCompleteExpanded(false);
      }
    }, 500);
    // Longer timeout is necessary as we wait to get results from Google and the DOM to update
  };

  const handleOnChange = (val: string) => {
    controller.setAddressLine1(val);
    setAddress(val);
    // If we've already shown the error, check again to see if we can clear it
    if (showError) {
      controller.renderFeedback();
      addErrors(controller.getErrors());
      autoCompleteRef.current.focus();
    }
    updateIsAutocompleteExpanded();
  };

  const handleOnBlur = () => {
    // Restore autocomplete off;
    autoCompleteRef.current.setAttribute('autocomplete', 'off');
    if (address) {
      reveal();
    }
    updateIsAutocompleteExpanded();
  };

  const handleOnFocus = () => {
    // Hack: Set autocomplete to new password to not fill it in.
    // Chrome ignores 'off'.
    autoCompleteRef.current.setAttribute('autocomplete', 'new-password');
    updateIsAutocompleteExpanded();
  };

  const handleOnKeyDown = e => {
    // Don't try to submit form if user hits "enter" on google autocomplete
    if (e.key === 'Enter') {
      e.preventDefault();
    }
    updateIsAutocompleteExpanded();
  };

  useEffect(() => {
    if (controller.hasAddressFieldEntered()) {
      reveal();
    }
  }, [controller.hasAddressFieldEntered()]);

  useEffect(() => {
    loader.load().then(() => {
      handleScriptLoad(setAddress, autoCompleteRef, controller);
    });

    const elementsToHide = document.querySelectorAll('fieldset#AddressFieldset *');

    elementsToHide.forEach(element => {
      element.setAttribute('aria-hidden', 'true');
      element.setAttribute('tabindex', '-1');
    });
  }, []);

  useEffect(() => {
    // If autofill enters address, set it.
    if (!!controller.getAddressLine1() && !address) {
      setAddress(controller.getAddressLine1());
      reveal();
    }
  });

  useEffect(() => {
    // On Render reset error
    addErrors(controller.getErrors());
  });

  return (
    <React.Fragment>
      <Helmet>
        <link rel="preconnect" href="https://maps.googleapis.com" />
      </Helmet>
      <label className="search-location-input">
        <input
          type="text"
          ref={autoCompleteRef}
          onChange={e => handleOnChange(e.target.value)}
          onBlur={() => handleOnBlur()}
          onFocus={() => handleOnFocus()}
          onKeyDown={e => handleOnKeyDown(e)}
          title={t('Widget.Address.AddressLine1')}
          name="AutoMailingAddress"
          required
          value={address}
          autoComplete="off"
          role="combobox"
          aria-autocomplete="list"
          aria-expanded={isAutocompleteExpanded}
          aria-describedby="autocomplete-accessible-description"
        />
        <span className="totally-hidden" id="autocomplete-accessible-description">
          {t('Widget.Address.A11yDescription')}
        </span>
        <span className="visually-hidden" role="status" aria-live="polite">
          {isAutocompleteExpanded && t('Widget.Address.A11yResultsAvailable')}
        </span>
        {showError && <small className="error">{addressError}</small>}
        <Tooltip
          teaser={t('Widget.Tooltip.AddressTeaser')}
          content={t('Widget.Tooltip.AddressContent')}
        />
      </label>
    </React.Fragment>
  );
};
