/* Accented vs Filled
 * colorAccented have a light background with color features, like buttons and highlighting.
 * colorFilled have a color background with appropriately colored features for that background.
 *
 * Base vs Accents
 * base describes the background colors.
 * accents describes the features.
 *
 * Default vs inverse vs dark vs light
 * default is the main color
 * accessible is an accessible version of the main color, if any
 * inverse is anything on top of that, e.g. text on a button
 * dark and light are variations on the main color
 * danger is used for danger text, like error messages, against the relevant background.
 */
import {
  ColorThemeNames,
  DeprecatedDescriptiveThemeNames,
} from '+/styles/themes/colorThemes.interface';

export const THEME_COLORS = {
  [ColorThemeNames.GreyAccented]: {
    base: {
      default: 'var(--color-white)',
      accessible: 'var(--color-white)',
      inverse: 'var(--color-neutral-600)',
      dark: 'var(--color-neutral-200)',
      light: 'var(--color-white)',
      danger: 'var(--color-red)',
    },
    accent: {
      default: '#1d252c',
      accessible: '#1d252c',
      inverse: '#ffffff',
      dark: '#000000',
      light: '#282f36',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.GreyFilledRedAccented]: {
    base: {
      default: '#1d252c',
      accessible: '#1d252c',
      inverse: '#ffffff',
      dark: '#000000',
      light: '#282f36',
      danger: 'var(--color-white)',
    },
    accent: {
      default: '#fe3b1f',
      accessible: '#fe3b1f',
      inverse: '#ffffff',
      dark: '#bd0b02',
      light: '#ef4129',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.GreyFilledYellowAccented]: {
    base: {
      default: '#1d252c',
      accessible: '#1d252c',
      inverse: '#ffffff',
      dark: '#000000',
      light: '#282f36',
      danger: 'var(--color-white)',
    },
    accent: {
      default: '#ffb600',
      accessible: '#ffb600',
      inverse: '#1d252c',
      dark: '#f29616',
      light: '#ffcc4e',
      danger: 'var(--color-black)',
    },
  },
  [ColorThemeNames.GreyFilledGreenAccented]: {
    base: {
      default: '#1d252c',
      accessible: '#1d252c',
      inverse: '#ffffff',
      dark: '#000000',
      light: '#282f36',
      danger: 'var(--color-white)',
    },
    accent: {
      default: '#c2d500',
      accessible: '#c2d500',
      inverse: '#1d252c',
      dark: '#b2c400',
      light: '#cbda28',
      danger: '#1d252c',
    },
  },
  [ColorThemeNames.GreyFilledBlueAccented]: {
    base: {
      default: '#1d252c',
      accessible: '#1d252c',
      inverse: '#ffffff',
      dark: '#000000',
      light: '#282f36',
      danger: 'var(--color-white)',
    },
    accent: {
      default: '#00a0bd',
      accessible: '#00a0bd',
      inverse: '#ffffff',
      dark: '#008299',
      light: '#068ba3',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.GreyFilledTealAccented]: {
    base: {
      default: '#1d252c',
      accessible: '#1d252c',
      inverse: '#ffffff',
      dark: '#000000',
      light: '#282f36',
      danger: 'var(--color-white)',
    },
    accent: {
      default: '#009579',
      accessible: '#009579',
      inverse: '#ffffff',
      dark: '#007f67',
      light: '#32b59d',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.RedAccented]: {
    base: {
      default: 'var(--color-white)',
      accessible: 'var(--color-white)',
      inverse: 'var(--color-neutral-600)',
      dark: 'var(--color-neutral-200)',
      light: 'var(--color-white)',
      danger: 'var(--color-red)',
    },
    accent: {
      default: '#ef4129',
      accessible: '#d90d02',
      inverse: '#ffffff',
      dark: '#bd0b02',
      light: '#ef4129',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.RedFilled]: {
    base: {
      default: '#ef4129',
      accessible: '#d90d02',
      inverse: 'var(--color-white)',
      dark: '#bd0b02',
      light: '#ef4129',
      danger: 'var(--color-white)',
    },
    accent: {
      default: 'var(--color-neutral-600)',
      accessible: 'var(--color-neutral-600)',
      inverse: 'var(--color-white)',
      dark: 'var(--color-black)',
      light: 'var(--color-neutral-700)',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.YellowAccented]: {
    base: {
      default: 'var(--color-white)',
      accessible: 'var(--color-white)',
      inverse: 'var(--color-neutral-600)',
      dark: 'var(--color-neutral-200)',
      light: 'var(--color-white)',
      danger: 'var(--color-red)',
    },
    accent: {
      default: '#ffb600',
      accessible: '#ffb600',
      inverse: '#1d252c',
      dark: '#f29616',
      light: '#ffcc4e',
      danger: 'var(--color-red)',
    },
  },
  [ColorThemeNames.YellowFilledGreyAccented]: {
    base: {
      default: '#ffb600',
      accessible: '#ffb600',
      inverse: '#1d252c',
      dark: '#f29616',
      light: '#ffcc4e',
      danger: 'var(--color-red)',
    },
    accent: {
      default: 'var(--color-neutral-600)',
      accessible: 'var(--color-neutral-600)',
      inverse: 'var(--color-white)',
      dark: 'var(--color-black)',
      light: 'var(--color-neutral-700)',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.YellowFilledRedAccented]: {
    base: {
      default: '#ffb600',
      accessible: '#ffb600',
      inverse: '#1d252c',
      dark: '#f29616',
      light: '#ffcc4e',
      danger: 'var(--color-red)',
    },
    accent: {
      default: '#fe3b1f',
      accessible: '#fe3b1f',
      inverse: '#ffffff',
      dark: '#bd0b02',
      light: '#ef4129',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.GreenAccented]: {
    base: {
      default: 'var(--color-white)',
      accessible: 'var(--color-white)',
      inverse: 'var(--color-neutral-600)',
      dark: 'var(--color-neutral-200)',
      light: 'var(--color-white)',
      danger: 'var(--color-red)',
    },
    accent: {
      default: '#c2d500',
      accessible: '#c2d500',
      inverse: '#1d252c',
      dark: '#b2c400',
      light: '#cbda28',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.GreenFilled]: {
    base: {
      default: '#c2d500',
      accessible: '#c2d500',
      inverse: '#1d252c',
      dark: '#b2c400',
      light: '#cbda28',
      danger: 'var(--color-red)',
    },
    accent: {
      default: 'var(--color-neutral-600)',
      accessible: 'var(--color-neutral-600)',
      inverse: 'var(--color-white)',
      dark: 'var(--color-black)',
      light: 'var(--color-neutral-700)',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.TealAccented]: {
    base: {
      default: 'var(--color-white)',
      accessible: 'var(--color-white)',
      inverse: 'var(--color-neutral-600)',
      dark: 'var(--color-neutral-200)',
      light: 'var(--color-white)',
      danger: 'var(--color-red)',
    },
    accent: {
      default: '#009579',
      accessible: '#009579',
      inverse: '#ffffff',
      dark: '#007f67',
      light: '#32b59d',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.TealFilled]: {
    base: {
      default: '#009579',
      accessible: '#009579',
      inverse: '#ffffff',
      dark: '#007f67',
      light: '#32b59d',
      danger: 'var(--color-white)',
    },
    accent: {
      default: 'var(--color-neutral-600)',
      accessible: 'var(--color-neutral-600)',
      inverse: 'var(--color-white)',
      dark: 'var(--color-black)',
      light: 'var(--color-neutral-700)',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.BlueAccented]: {
    base: {
      default: 'var(--color-white)',
      accessible: 'var(--color-white)',
      inverse: 'var(--color-neutral-600)',
      dark: 'var(--color-neutral-200)',
      light: 'var(--color-white)',
      danger: 'var(--color-red)',
    },
    accent: {
      default: '#00a0bd',
      accessible: '#00a0bd',
      inverse: '#ffffff',
      dark: '#008299',
      light: '#068ba3',
      danger: 'var(--color-white)',
    },
  },
  [ColorThemeNames.BlueFilled]: {
    base: {
      default: '#00a0bd',
      accessible: '#00a0bd',
      inverse: 'var(--color-white)',
      dark: '#008299',
      light: '#068ba3',
      danger: 'var(--color-white)',
    },
    accent: {
      default: 'var(--color-neutral-600)',
      accessible: 'var(--color-neutral-600)',
      inverse: 'var(--color-white)',
      dark: 'var(--color-black)',
      light: 'var(--color-neutral-700)',
      danger: 'var(--color-white)',
    },
  },
};

const FILLED_VARIANTS = {
  [DeprecatedDescriptiveThemeNames.Default]: ColorThemeNames.RedFilled,
  [DeprecatedDescriptiveThemeNames.Primary]: ColorThemeNames.RedFilled,
  [DeprecatedDescriptiveThemeNames.Secondary]: ColorThemeNames.YellowFilledGreyAccented,
  [ColorThemeNames.RedAccented]: ColorThemeNames.RedFilled,
  [ColorThemeNames.YellowAccented]: ColorThemeNames.YellowFilledGreyAccented,
  [ColorThemeNames.GreyAccented]: ColorThemeNames.GreyFilledRedAccented,
  [ColorThemeNames.BlueAccented]: ColorThemeNames.BlueFilled,
  [ColorThemeNames.GreenAccented]: ColorThemeNames.GreenFilled,
  [ColorThemeNames.TealAccented]: ColorThemeNames.TealFilled,
};

const ACCENTED_VARIANTS = {
  [ColorThemeNames.RedFilled]: ColorThemeNames.RedAccented,
  [ColorThemeNames.GreyFilledRedAccented]: ColorThemeNames.RedAccented,
  [ColorThemeNames.GreyFilledYellowAccented]: ColorThemeNames.YellowAccented,
  [ColorThemeNames.GreyFilledTealAccented]: ColorThemeNames.TealAccented,
  [ColorThemeNames.GreyFilledBlueAccented]: ColorThemeNames.BlueAccented,
  [ColorThemeNames.GreyFilledGreenAccented]: ColorThemeNames.GreenAccented,
  [ColorThemeNames.YellowFilledRedAccented]: ColorThemeNames.RedAccented,
  [ColorThemeNames.YellowFilledGreyAccented]: ColorThemeNames.YellowAccented,
  [ColorThemeNames.BlueFilled]: ColorThemeNames.BlueAccented,
  [ColorThemeNames.GreenFilled]: ColorThemeNames.GreenAccented,
  [ColorThemeNames.TealFilled]: ColorThemeNames.TealAccented,
};

export const getFilledVariant = colorThemeName =>
  FILLED_VARIANTS[colorThemeName] || colorThemeName || 'inherit';
export const getAccentedVariant = colorThemeName =>
  ACCENTED_VARIANTS[colorThemeName] || colorThemeName || 'inherit';
