// Remove stylesheets from a page.
// Used to remove the EveryAction stylesheet (and all others).
//
const removeExistingStylesheets = () => {
  Array.from(document.querySelectorAll('link[rel=stylesheet]')).forEach(
    (stylesheet: HTMLLinkElement) => {
      if (
        stylesheet.href &&
        (stylesheet.href.includes('at.min.css') || stylesheet.href.includes('form-styles.css'))
      ) {
        stylesheet.remove();
      }
    },
  );
};

export default removeExistingStylesheets;
