import React, { useEffect, useRef } from 'react';

import { PersonalDetailsStage } from './PersonalDetailsStage';
import { MessageStage } from './MessageStage';
import { ThanksStage } from './ThanksStage';

import { ColorTheme } from '+/components/ColorTheme';
import scrollToLocation from '+/utils/scrollToLocation';
import { dimensions } from '+/styles/themes/mainTheme';

export const EveryActionAdvocacyForm = (props: EveryActionAdvocacyFormBaseProps) => {
  const formRef = useRef();

  useEffect(() => {
    if (formRef.current) scrollToLocation(formRef.current, dimensions.heightNav.value, 'up');
  }, [props.currentStage]);

  const [stages] = React.useState(() =>
    props.everyActionAdvocacyFormData.singleStep
      ? [PersonalDetailsStage, ThanksStage]
      : [PersonalDetailsStage, MessageStage, ThanksStage],
  );

  const StageComponent = React.useMemo(() => stages[props.currentStage], [props.currentStage]);

  const advanceToNextStage = React.useCallback(() => {
    if (props.currentStage < stages.length - 1) {
      props.setCurrentStage(props.currentStage + 1);
    }
  }, [props.currentStage]);

  const goBackToLastStage = React.useCallback(() => {
    if (props.currentStage > 0) {
      props.setCurrentStage(Math.min(props.currentStage - 1));
    }
  }, [props.currentStage]);

  return (
    <ColorTheme colorTheme={props.everyActionAdvocacyFormData?.colorTheme}>
      <div ref={formRef}>
        <StageComponent
          {...props}
          advanceToNextStage={advanceToNextStage}
          goBackToLastStage={goBackToLastStage}
        />
      </div>
    </ColorTheme>
  );
};
