import styled from 'styled-components';

export const SingleStepContentHeader = styled.header`
  max-width: 500px;

  font-size: 1.25rem;
  line-height: 1.875rem;

  color: var(--color-theme-base-inverse);
`;

export const ReadOrEditTextButtonText = styled.span`
  margin-left: 0.25rem;
`;

export const ReadOrEditTextButton = styled.button`
  display: flex;
  align-items: center;

  margin-bottom: 2rem;
  padding: 0.95em 1.2em 0.92em 1.2em;

  color: var(--color-theme-accent);
  font-size: 0.8438rem;
  line-height: 1;

  transition: transform 0.2s ease-in-out, background 0.2s, filter 0.2s ease-in-out;

  &:hover:not(:active),
  &:focus:not(:active) {
    border-radius: 4px;
    box-shadow: 0 0.2em 0.8em 0 rgb(0 0 0 / 9%);
    transform: scale(1.03);
    filter: brightness(1.1);
  }
`;

export const Link = styled.a`
  color: inherit;

  &:hover,
  &:focus-visible {
    color: var(--color-theme-accent);
  }

  &:active {
    color: var(--color-theme-accent-dark);
  }
`;

export const SingleStepInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
