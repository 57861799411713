import React, { useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import { MessageModal, requiredMessageModalFields } from '../MessageModal';
import { PrivacyPolicyLink } from '../PrivacyPolicyLink';

import * as Styled from './PersonalDetailsStage.styles';

import * as FormStyled from '+/styles/styledComponents/Form.styles';
import { Markdown } from '+/components/EveryActionAdvocacyForm/EveryActionAdvocacyForm.styles';
import { Button } from '+/components/Button';
import { Link } from '+/components/Link';
import { NameSplitter } from '+/components/NameSplitter';
import { Tooltip } from '+/components/Tooltip';
import FormikNameController from '+/utils/FormikNameController';
import { LanguageContext } from '+/contexts/LanguageContext';
import { t } from '+/utils/textDictionary';
import { Icon } from '+/components/Icon';
import { ICONS } from '+/utils/constants/icons';
import { useCountries } from '+/utils/hooks/useCountries';
import { UserContext } from '+/contexts/UserContext';
import {
  handleDeleteUserData,
  initializeStateWithCookieVal,
  postUserData,
  useRememberMe,
} from '+/contexts';
import { useDisplayRememberMe } from '+/contexts/RememberMe';

export const requiredPersonalDetailsFields = Object.freeze([
  'FirstName',
  'LastName',
  'EmailAddress',
  'Country',
  'AcceptedPrivacyPolicy',
]);
export const requiredSingleStepFields = Object.freeze([
  ...requiredPersonalDetailsFields,
  ...requiredMessageModalFields,
]);

export const PersonalDetailsStage = (props: StageProps) => {
  const {
    values,
    submitForm,
    isSubmitting,
    handleChange,
    handleBlur,
    errors,
    advanceToNextStage,
    touched,
    suppliedOptions,
    setFieldValue,
    validateForm,
    setFieldTouched,
    everyActionAdvocacyFormData: { singleStep, advocacyContentHeader, personalDetailsHeader },
    intro,
    outro,
  } = props;
  const displayRememberMe = useDisplayRememberMe();

  const { userData, setUserData } = useContext(UserContext);
  const { dispatch: rememberMeDispatch } = useRememberMe();

  const formHasErrors = Object.values(errors).some(errorMessage => errorMessage);
  const [displayFormError, setDisplayFormError] = useState(false);

  useEffect(() => {
    if (!formHasErrors) setDisplayFormError(false);
  }, [formHasErrors]);

  const requiredFields: Readonly<Array<string>> = React.useMemo(
    () => (singleStep ? requiredSingleStepFields : requiredPersonalDetailsFields),
    [singleStep],
  );

  const [isEditingMessage, setIsEditingMessage]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = React.useState(false);

  const isUS = values.Country === 'US';

  const [rememberMeChecked, updateRememberMeChecked] = useState(isUS);

  useEffect(() => {
    if (isUS) {
      updateRememberMeChecked(true);
    } else {
      initializeStateWithCookieVal(() => updateRememberMeChecked(true));
    }
  }, [isUS]);

  const handleRememberMeChange = () => {
    updateRememberMeChecked(!rememberMeChecked);
  };

  const startEditing: () => void = React.useCallback((): void => setIsEditingMessage(true), []);
  const stopEditing: () => void = React.useCallback((): void => setIsEditingMessage(false), []);

  const countriesList = useCountries();
  const language: string = useContext(LanguageContext);

  const onSubmitOrNextButtonClick = React.useCallback(async () => {
    // Remove AcceptedPrivacyPolicy requirement for United States (US)
    const localeRequiredFields = requiredFields.filter(
      field => values.Country !== 'US' || field !== 'AcceptedPrivacyPolicy',
    );
    const optionalFields = ['Prefix', 'MiddleName', 'Suffix', 'MobilePhone', 'PostalCode'];

    const noRequiredFieldErrors = localeRequiredFields.every(v => values[v] && !errors[v]);
    const noOptionalFieldErrors = optionalFields.every(v => (values[v] ? !errors[v] : true));

    const handleRememberMeSubmission = () => {
      rememberMeChecked &&
        postUserData(
          {
            firstName: values.FirstName,
            lastName: values.LastName,
            address1: '',
            postcode: values.PostalCode,
            locality: '',
            state: '',
            country: values.Country,
            email: values.EmailAddress,
            address2: '',
            mobilePhone: values.MobilePhone || '',
          },
          rememberMeChecked,
        );
      !rememberMeChecked && handleDeleteUserData(rememberMeChecked);
    };

    // All required fields have values and don't have errors; all optional fields with values don't have errors
    if (noRequiredFieldErrors && noOptionalFieldErrors) {
      if (singleStep) {
        await submitForm();
        handleRememberMeSubmission();
        rememberMeDispatch({
          type: 'updateSaveUserData',
          payload: rememberMeChecked,
        });
        advanceToNextStage();
      } else {
        // Update stored user data so we don't lose it as we move through a two-step form
        setUserData({
          ...userData,
          firstName: values.FirstName,
          lastName: values.LastName,
          emailAddress: values.EmailAddress,
          postalCode: values.PostalCode,
          country: values.Country,
          mobilePhone: values.MobilePhone,
        });

        advanceToNextStage();
      }
    } else {
      // Open the modal if there is an error in the modal but no errors on the base form
      if (
        singleStep &&
        !localeRequiredFields.filter(
          key =>
            errors[key] &&
            !requiredMessageModalFields.includes(key) &&
            requiredPersonalDetailsFields.includes(key),
        ).length &&
        requiredFields.filter(key => errors[key] && requiredMessageModalFields.includes(key)).length
      ) {
        startEditing();
      }

      validateForm();
      requiredFields.forEach(v => setFieldTouched(v, true));
      optionalFields.forEach(v => {
        if (values[v]) setFieldTouched(v, true);
      });

      handleRememberMeSubmission();
    }
  }, [singleStep, submitForm, requiredFields, advanceToNextStage, errors, values]);

  const controller = new FormikNameController({ values, setFieldValue });

  return (
    <FormStyled.Container>
      <FormStyled.Header>
        <Markdown>{personalDetailsHeader}</Markdown>
      </FormStyled.Header>
      {singleStep && (
        <Styled.SingleStepInfoContainer>
          <FormStyled.Header>
            <Styled.SingleStepContentHeader as={Markdown}>
              {advocacyContentHeader}
            </Styled.SingleStepContentHeader>
          </FormStyled.Header>

          <Styled.ReadOrEditTextButton onClick={startEditing} type="button">
            <Icon
              path={ICONS.CARET_RIGHT_FILLED.path}
              viewBox={ICONS.CARET_RIGHT_FILLED.viewBox}
              size="0.675rem"
            />
            <Styled.ReadOrEditTextButtonText>
              {t('EAAdvocacyForm.ReadOrEditYourMessage')}
            </Styled.ReadOrEditTextButtonText>
          </Styled.ReadOrEditTextButton>
          <AnimatePresence>
            {isEditingMessage && (
              <MessageModal
                messageModalData={{
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  values,
                  outro,
                  intro,
                }}
                onClose={stopEditing}
              />
            )}
          </AnimatePresence>
        </Styled.SingleStepInfoContainer>
      )}
      <FormStyled.Body className="adv-form__body">
        <FormStyled.Section className="cstm-form__section">
          <FormStyled.CustomFieldRow style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {typeof values.Prefix !== 'undefined' && (
              <FormStyled.InputLabel
                style={{ width: '25%', flexGrow: 1, flexBasis: '15%' }}
                floating
                errored={!!errors.Prefix}
              >
                <FormStyled.TextyInput
                  name="Prefix"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Prefix}
                  autoComplete="Prefix"
                  placeholder={t('EAAdvocacyForm.Prefix')}
                />
                <FormStyled.InputLabelText floating>
                  {t('EAAdvocacyForm.Prefix')}
                </FormStyled.InputLabelText>
                {touched.Prefix && errors.Prefix && (
                  <FormStyled.InputError>{errors.Prefix}</FormStyled.InputError>
                )}
              </FormStyled.InputLabel>
            )}
            <FormStyled.InputLabel
              style={{ width: '35%', flexGrow: 1, flexBasis: '50%' }}
              floating
              errored={!!errors.FirstName}
            >
              <FormStyled.TextyInput
                name="FirstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.FirstName}
                autoComplete="given-name"
                placeholder={`${t('EAAdvocacyForm.FirstName')}*`}
              />
              <FormStyled.InputLabelText floating>
                {t('EAAdvocacyForm.FirstName')}
                <sup>*</sup>
              </FormStyled.InputLabelText>
              {touched.FirstName && errors.FirstName && (
                <FormStyled.InputError>{errors.FirstName}</FormStyled.InputError>
              )}
              <NameSplitter
                controller={controller}
                namePartToCheck="FirstName"
                namePartToSplit="Prefix"
                position="start"
                nameLabel={t('EAAdvocacyForm.Prefix')}
              />
              <NameSplitter
                controller={controller}
                namePartToCheck="FirstName"
                namePartToSplit="MiddleName"
                position="end"
                nameLabel={t('EAAdvocacyForm.MiddleName')}
              />
            </FormStyled.InputLabel>
            {typeof values.MiddleName !== 'undefined' && values.MiddleName && (
              <FormStyled.InputLabel
                style={{ width: '45%', flexGrow: 1, flexBasis: '25%' }}
                floating
                errored={!!errors.MiddleName}
              >
                <FormStyled.TextyInput
                  name="MiddleName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.MiddleName}
                  autoComplete="given-name"
                  placeholder={t('EAAdvocacyForm.MiddleName')}
                />
                <FormStyled.InputLabelText floating>
                  {t('EAAdvocacyForm.MiddleName')}
                </FormStyled.InputLabelText>
                {touched.MiddleName && errors.MiddleName && (
                  <FormStyled.InputError>{errors.MiddleName}</FormStyled.InputError>
                )}
              </FormStyled.InputLabel>
            )}
            <FormStyled.InputLabel
              style={{ width: '45%', flexGrow: 1 }}
              floating
              errored={!!errors.LastName}
            >
              <FormStyled.TextyInput
                name="LastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.LastName}
                autoComplete="family-name"
                placeholder={`${t('EAAdvocacyForm.LastName')}*`}
              />
              <NameSplitter
                controller={controller}
                namePartToCheck="LastName"
                namePartToSplit="MiddleName"
                position="start"
                nameLabel={t('EAAdvocacyForm.MiddleName')}
              />
              <NameSplitter
                controller={controller}
                namePartToCheck="LastName"
                namePartToSplit="Suffix"
                position="end"
                nameLabel={t('EAAdvocacyForm.Suffix')}
              />
              <FormStyled.InputLabelText floating>
                {t('EAAdvocacyForm.LastName')}
                <sup>*</sup>
              </FormStyled.InputLabelText>
              {touched.LastName && errors.LastName && (
                <FormStyled.InputError>{errors.LastName}</FormStyled.InputError>
              )}
            </FormStyled.InputLabel>
            {typeof values.Suffix !== 'undefined' && values.Suffix && (
              <FormStyled.InputLabel
                style={{ width: '25%', flexGrow: 1, flexBasis: '15%' }}
                floating
                errored={!!errors.Suffix}
              >
                <FormStyled.TextyInput
                  name="Suffix"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Suffix}
                  autoComplete="suffix"
                  placeholder={t('EAAdvocacyForm.Suffix')}
                />
                <FormStyled.InputLabelText floating>
                  {t('EAAdvocacyForm.Suffix')}
                </FormStyled.InputLabelText>
                {touched.Suffix && errors.Suffix && (
                  <FormStyled.InputError>{errors.Suffix}</FormStyled.InputError>
                )}
              </FormStyled.InputLabel>
            )}
          </FormStyled.CustomFieldRow>
          <FormStyled.CustomFieldRow>
            <FormStyled.InputLabel fullWidth floating errored={!!errors.EmailAddress}>
              <FormStyled.TextyInput
                name="EmailAddress"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.EmailAddress}
                autoComplete="email"
                placeholder={`${t('EAAdvocacyForm.EmailAddress')}*`}
              />
              <FormStyled.InputLabelText floating>
                {t('EAAdvocacyForm.EmailAddress')}
                <sup>*</sup>
              </FormStyled.InputLabelText>
              {touched.EmailAddress && errors.EmailAddress && (
                <FormStyled.InputError>{errors.EmailAddress}</FormStyled.InputError>
              )}
            </FormStyled.InputLabel>
          </FormStyled.CustomFieldRow>
          {language !== 'es-MX' && (
            <FormStyled.CustomFieldRow>
              <FormStyled.InputLabel fullWidth floating errored={!!errors.MobilePhone}>
                <FormStyled.TextyInput
                  name="MobilePhone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.MobilePhone}
                  autoComplete="tel"
                  placeholder={t('EAAdvocacyForm.MobilePhone')}
                />
                <FormStyled.InputLabelText floating>
                  {t('EAAdvocacyForm.MobilePhone')}
                </FormStyled.InputLabelText>
                {touched.MobilePhone && errors.MobilePhone && (
                  <FormStyled.InputError>{errors.MobilePhone}</FormStyled.InputError>
                )}
              </FormStyled.InputLabel>
            </FormStyled.CustomFieldRow>
          )}
          <FormStyled.CustomFieldRow>
            <FormStyled.InputLabel floating errored={!!errors.PostalCode}>
              <FormStyled.TextyInput
                name="PostalCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.PostalCode}
                autoComplete="postal-code"
                placeholder={t('EAAdvocacyForm.PostalCode')}
              />
              <FormStyled.InputLabelText floating>
                {t('EAAdvocacyForm.PostalCode')}
              </FormStyled.InputLabelText>
              {touched.PostalCode && errors.PostalCode && (
                <FormStyled.InputError>{errors.PostalCode}</FormStyled.InputError>
              )}
            </FormStyled.InputLabel>
            <FormStyled.InputLabel floating errored={!!errors.Country}>
              <FormStyled.Select
                name="Country"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Country || ''}
                autoComplete="country"
                required
              >
                <option value="" disabled>
                  {t('EAAdvocacyForm.Country')}
                </option>
                {(suppliedOptions.table_countries && language === 'en-US'
                  ? suppliedOptions.table_countries
                  : countriesList
                ).map(o => (
                  <option key={o.value} value={o.value}>
                    {o.display}
                  </option>
                ))}
              </FormStyled.Select>
              <FormStyled.InputLabelText floating>
                {t('EAAdvocacyForm.Country')}
                <sup>*</sup>
              </FormStyled.InputLabelText>
              {touched.Country && errors.Country && (
                <FormStyled.InputError>{errors.Country}</FormStyled.InputError>
              )}
            </FormStyled.InputLabel>
          </FormStyled.CustomFieldRow>
          <FormStyled.CustomFieldRow>
            <FormStyled.CheckboxLabel>
              <FormStyled.Checkbox
                type="checkbox"
                name="YesSignMeUpForUpdatesForBinder"
                value={values.YesSignMeUpForUpdatesForBinder ? 1 : 0}
                onChange={event => {
                  setFieldValue('YesSignMeUpForUpdatesForBinder', event.target.checked);
                }}
                onBlur={handleBlur}
                checked={!!values.YesSignMeUpForUpdatesForBinder}
              />
              <FormStyled.CheckboxLabelText>
                {t('EAAdvocacyForm.Consent')}
                <Tooltip
                  content={t('EAAdvocacyForm.InfoConsent')}
                  teaser={t('EAAdvocacyForm.InfoConsent')}
                  inline
                />
              </FormStyled.CheckboxLabelText>
              {touched.YesSignMeUpForUpdatesForBinder && !!errors.YesSignMeUpForUpdatesForBinder && (
                /* eslint-enable indent */
                <FormStyled.InputError>
                  {errors.YesSignMeUpForUpdatesForBinder}
                </FormStyled.InputError>
                /* eslint-disable indent */
              )}
            </FormStyled.CheckboxLabel>
          </FormStyled.CustomFieldRow>
          {values.MobilePhone && values.Country === 'US' && (
            <FormStyled.CustomFieldRow>
              <FormStyled.CheckboxLabel>
                <FormStyled.Checkbox
                  type="checkbox"
                  name="SmsSubscribeMobilePhone"
                  value={values.SmsSubscribeMobilePhone ? 1 : 0}
                  onChange={event => {
                    setFieldValue('SmsSubscribeMobilePhone', event.target.checked);
                  }}
                  onBlur={handleBlur}
                  checked={!!values.SmsSubscribeMobilePhone}
                />
                <FormStyled.CheckboxLabelText>
                  {t('EAAdvocacyForm.ConsentSms')}
                  <Tooltip
                    content={t('EAAdvocacyForm.InfoConsentSms')}
                    teaser={t('EAAdvocacyForm.InfoConsentSms')}
                    inline
                  />
                </FormStyled.CheckboxLabelText>
                {touched.SmsSubscribeMobilePhone && !!errors.SmsSubscribeMobilePhone && (
                  <FormStyled.InputError>{errors.SmsSubscribeMobilePhone}</FormStyled.InputError>
                )}
              </FormStyled.CheckboxLabel>
            </FormStyled.CustomFieldRow>
          )}
          {displayRememberMe && (
            <FormStyled.CustomFieldRow>
              <FormStyled.CheckboxLabel>
                <FormStyled.Checkbox
                  type="checkbox"
                  name="RememberMeAction"
                  value={rememberMeChecked ? 1 : 0}
                  onChange={handleRememberMeChange}
                  checked={rememberMeChecked}
                />
                <FormStyled.CheckboxLabelText>
                  Remember my details{' '}
                  <Tooltip
                    teaser="Save your address details"
                    content="Safely remember your name, email, phone number, and mailing address to take action faster next time! Your personal information will be encrypted and stored securely in your browser. Uncheck this box if you prefer to re-enter your details each time."
                    inline={true}
                  />
                </FormStyled.CheckboxLabelText>
              </FormStyled.CheckboxLabel>
            </FormStyled.CustomFieldRow>
          )}
          {!isUS && (
            <FormStyled.CustomFieldRow>
              <FormStyled.CheckboxLabel>
                <FormStyled.Checkbox
                  type="checkbox"
                  name="AcceptedPrivacyPolicy"
                  value={values.AcceptedPrivacyPolicy ? 1 : 0}
                  onChange={event => {
                    setFieldValue('AcceptedPrivacyPolicy', event.target.checked);
                  }}
                  onBlur={handleBlur}
                  checked={!!values.AcceptedPrivacyPolicy}
                />
                <FormStyled.CheckboxLabelText>
                  {t('EAAdvocacyForm.AcceptPrivacyPolicyPrefix')}{' '}
                  <Styled.Link as={Link} href={t('EAAdvocacyForm.AcceptPrivacyPolicyLinkUrl')}>
                    {t('EAAdvocacyForm.AcceptPrivacyPolicyLinkText')}
                  </Styled.Link>
                  {t('EAAdvocacyForm.AcceptPrivacyPolicySuffix')}
                </FormStyled.CheckboxLabelText>
                {touched.AcceptedPrivacyPolicy && !!errors.AcceptedPrivacyPolicy && (
                  <FormStyled.InputError>{errors.AcceptedPrivacyPolicy}</FormStyled.InputError>
                )}
              </FormStyled.CheckboxLabel>
            </FormStyled.CustomFieldRow>
          )}

          <FormStyled.CustomFieldRow centered marginTop="1.5rem" marginBottom="1.425rem">
            <div>
              <Button
                narrow
                big
                type={singleStep ? 'submit' : 'button'}
                disabled={isSubmitting}
                onClick={() => {
                  onSubmitOrNextButtonClick();
                  setDisplayFormError(formHasErrors);
                }}
              >
                {singleStep ? t('EAAdvocacyForm.SignNow') : t('EAAdvocacyForm.NextStep')}
              </Button>
              {displayFormError && (
                <FormStyled.InputError>{t('EAAdvocacyForm.FormError')}</FormStyled.InputError>
              )}
            </div>
          </FormStyled.CustomFieldRow>
        </FormStyled.Section>
      </FormStyled.Body>

      <PrivacyPolicyLink />
    </FormStyled.Container>
  );
};
