import styled from 'styled-components';

export const Section = styled.div`
  padding: 3.25rem 0;
  text-align: center;
  background: var(--color-theme-base-dark);
  color: var(--color-theme-base-inverse);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin: 0 auto;
  position: relative;
  max-width: 38.75rem;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    max-width: 93.75rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
`;

export const BadgeList = styled.div`
  text-align: center;
  width: 100%;
  padding: 0 1.25rem;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin: 0;
    max-width: 51.5625rem;
    flex-grow: 1;
    padding: 0;
    width: auto;
  }

  &:last-child:first-child {
    margin: 0 auto;
  }
`;

export const BadgeListContent = styled.div`
  margin: 0 auto;
`;

export const BadgeLink = styled.a`
  display: inline-block;
`

export const BadgeItem = styled.img`
  width: auto;
  height: auto;
  max-width: 6rem;
  max-height: 4rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0.5rem;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    max-width: 8rem;
    max-height: 6rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin: 1rem;
  }
`;

export const Text = styled.div`
  width: 100%;
  margin: 1rem auto 0;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin: 0;
    max-width: 33.75rem;
  }
`;

export const TextContent = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 0 1rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    padding: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
`;
