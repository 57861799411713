import React from 'react';
import * as Styled from '+/styles/styledComponents/Form.styles';

interface EveryActionCustomPhoneFieldProps {
  isRequired: boolean;
  title: string; // The label text
  name: string; // The input value
  queryString: string;
  defaultCountryCode: string;
  isTouched: boolean;
  error?: string;
  value: string;
  options: [string, string][]; // value, label
  handleChange: () => void;
  handleBlur: () => void;
  className?: string;
}

export const EveryActionCustomPhoneField = ({
  title,
  name,
  queryString,
  defaultCountryCode,
  options,
  value,
  error,
  isRequired,
  isTouched,
  handleChange,
  handleBlur,
  className,
}: EveryActionCustomPhoneFieldProps) => {
  return (
    <Styled.CustomFieldRow className={className}>
      <Styled.InputLabel floating errored={isTouched && !!error}>
        <Styled.TextyInput
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          type="tel"
          placeholder={title}
        />
        <Styled.InputLabelText floating>{title}</Styled.InputLabelText>
        {isTouched && <Styled.InputError>{error}</Styled.InputError>}
      </Styled.InputLabel>
    </Styled.CustomFieldRow>
  );
};
