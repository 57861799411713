import React from 'react';

import * as Styled from './ImageWithText.styles';

import { useScrollPosition } from '+/utils/useScrollPosition';
import getWindowSize from '+/utils/getWindowSize';

const checkIfInView = (positionY, windowHeight) => positionY - windowHeight <= -300;

export const ImageWithText = ({
  imageWithTextData,
}: {
  imageWithTextData: ImageWithTextComponent;
}) => {
  const [inView, setInView] = React.useState(
    checkIfInView(typeof window !== 'undefined' ? window.scrollY : 0, getWindowSize().height),
  );
  const imageWithTextRef = React.useRef();

  // Fade in when within view range
  useScrollPosition(
    ({ currPos }) => setInView(checkIfInView(currPos.y, getWindowSize().height)),
    [],
    imageWithTextRef,
  );

  return (
    <Styled.ImageWithText
      ref={imageWithTextRef}
      imagePositioning={imageWithTextData.imagePositioning}
      inView={inView}
      className="image-with-text"
    >
      <Styled.ImageWithTextContent>
        {imageWithTextData.image && <Styled.MediaItem mediaItemData={imageWithTextData.image} />}
        <Styled.Markdown>{imageWithTextData.text}</Styled.Markdown>
      </Styled.ImageWithTextContent>
    </Styled.ImageWithText>
  );
};
