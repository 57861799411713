import { useState, useEffect } from 'react';

export const useGeoIpCountry = (countryCode?: string): string => {
  const [geoIpCountry, setGeoIpCountry] = useState(countryCode);
  useEffect(() => {
    (async function fetchAndSet() {
      const result = await fetch('https://inlyywnkaa.execute-api.us-east-1.amazonaws.com/geoip');
      const json = await result.json();
      if (!json || !json.country) {
        return;
      }
      setGeoIpCountry(json.country.iso_code);
    })();
  }, []);
  return geoIpCountry;
};
