import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

import { Link as LinkComponent } from '+/components/Link';
import { Markdown as MarkdownComponent } from '+/components/Markdown';
import { MediaItem as MediaItemComponent } from '+/components/MediaItem';
import DustOnePNG from '+/images/dust1.png';
import DustTwoPNG from '+/images/dust2.png';
import BarsPNG from '+/images/bars.png';

const heightBreakpoint = '700px';

export const ScrollParallaxWrapper = styled.div<{ ref }>`
  height: 100%;
`;

export const ParallaxMediaItemWrapper = styled(motion.div)`
  height: 60vh;
  width: 100%;
  position: relative;
  margin-bottom: -25%;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: -20%;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    top: 0;
    left: 0;

    position: absolute;

    margin: 0;

    height: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    display: block;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(29, 37, 44, 0) 0%, rgba(29, 37, 44, 1) 100%);
    background-size: 100% 100%;
    background-repeat: repeat-x;
    background-position: center bottom;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      background-image: linear-gradient(to right, var(--color-black) 0%, transparent 100%);
      width: 67%;
      height: 100%;
    }

    @media (min-width: ${props => props.theme.breakpoints.xl}) {
      display: none;
    }
  }
`;

export const ParallaxMediaItem = styled(MediaItemComponent)`
  height: calc(300px - 25vw + 25vh);
  width: 82%;
  max-width: unset;
  object-fit: cover;
  transform: scale(1.45);

  @media (min-width: ${props => props.theme.breakpoints.sm}) and (min-height: ${heightBreakpoint}) {
    height: calc(300px - 25vw + 40vh);
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 100%;
    width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;

export const ParallaxDustOne = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-image: url(${DustOnePNG});
  background-repeat: repeat;
`;

export const ParallaxDustTwo = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-image: url(${DustTwoPNG});
  background-repeat: repeat;
`;

export const ParallaxBars = styled(motion.div)`
  position: absolute;
  top: -10vh;
  left: -10vw;
  right: -10vw;
  bottom: -10vh;

  background-image: url(${BarsPNG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

export const MediaCredit = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
  transform: rotate(-90deg) translateY(-150%);
  transform-origin: bottom right;
  color: var(--color-neutral-450);
  font-size: 0.5625rem;
  line-height: 1;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    top: auto;
    bottom: 0;
    font-size: 0.6875rem;
    transform: rotate(-90deg) translateY(-150%) translateX(calc(100% + 11rem));
  }
`;

export const Markdown = styled(MarkdownComponent)`
  font-size: 1rem;

  color: var(--color-white);

  strong {
    color: var(--color-theme-accent);
  }

  @media (min-height: ${heightBreakpoint}) {
    font-size: 1.0625rem;
  }
`;

const KeyframesGlowpulse = keyframes`
  0% {
      opacity: .7
  }

  50% {
      opacity: 1
  }

  100% {
      opacity: .7
  }
`;

export const Button = styled.div`
  &:hover,
  &:focus {
    background: var(--color-theme-accent);
  }
`;

export const CTALink = styled(LinkComponent)`
  position: relative;
  width: min-content;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 0;
  }

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    box-shadow: 0 0 2.5em 0 var(--color-theme-accent);
    opacity: 90%;

    animation: ${KeyframesGlowpulse} 1.8s forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-play-state: running;
    animation-fill-mode: forwards;
  }

  &,
  &:visited,
  &:link,
  &:active,
  &:hover {
    text-decoration: none;
  }

  ${Button} {
    transition: background-color 80ms;
    font-size: 1.0625rem;

    @media (min-height: ${heightBreakpoint}) {
      font-size: 1.22rem;
    }

    &:after {
      transition: bottom 0.3s, right 0.3s;
    }

    &:hover:after {
      bottom: -2em;
      right: -2em;
    }
  }
`;

export const HeroContent = styled.div`
  position: relative;
  padding: 0 25px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0;
    max-width: 100%;
    width: 88%;
    margin: 5vh auto;
  }
`;

export const HeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 350px;
    max-width: 40%;
  }

  @media (min-height: ${heightBreakpoint}) {
    gap: 2.5rem;
  }
`;

export const Hero = styled.div`
  background: var(--color-neutral-800);
  color: var(--color-white);
  min-height: 97vh;
  padding-bottom: calc(6rem - 6vw);

  position: relative;

  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: auto;
    max-height: none;
    display: flex;
    align-items: center;
    padding: 4.375vw 0;
  }

  @media (min-height: 900px) {
    min-height: 890px;
  }
`;

export const HeroWrapper = styled.div`
  margin: 0 auto;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    justify-content: center;
    max-height: none;
    height: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    padding-left: 5%;
  }
`;

export const HeroHighlightHeading = styled.div`
  max-width: 22em;
  // Overwrite whatever theme to handle picture background.
  --color-theme-base-inverse: white;

  @media (min-height: ${heightBreakpoint}) {
    max-width: 18em;
  }
`;

export const AnimatedContent = styled(motion.div)`
  display: flex;
`;
