import styled, { css } from 'styled-components';

import { focusOutline } from '+/styles/reusable/focus';

export const ToggleSlider = styled.span`
  box-sizing: content-box;

  display: inline-block;
  position: relative;
  vertical-align: text-bottom;

  width: 2.5em;
  min-width: 2.5em;
  height: 1.25em;

  border: 2px solid var(--color-neutral-350);
  border-radius: 1em;

  background-color: var(--color-neutral-350);
  transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s;

  cursor: pointer;

  &::before {
    content: '';

    display: block;

    height: 1.25em;
    width: 1.25em;

    border-radius: 50%;

    transition: transform 0.2s ease 0s;
  }
`;

export const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider}:before {
    transform: translateX(1.25em);
  }
`;

interface ToggleLabelProps {
  isInverseDot?: boolean;
  isSquared?: boolean;
}

export const ToggleLabel = styled.label<ToggleLabelProps>`
  display: inline-flex;
  align-items: center;

  ${ToggleSlider} {
    &::before {
      background-color: var(--color-${props => (props.isInverseDot ? 'neutral-800' : 'white')});
    }
  }

  ${ToggleInput} {
    &:checked + ${ToggleSlider} {
      background-color: var(--color-theme-accent);
      border-color: var(--color-theme-accent);
    }

    &:focus-visible + ${ToggleSlider} {
      ${focusOutline};
    }
  }
  ${({ isSquared }) =>
    isSquared &&
    css`
      ${ToggleSlider} {
        border-radius: 0;

        &::before {
          border-radius: 0;
        }
      }
    `}
`;
