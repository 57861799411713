export const REPORT_COLORS = {
  'Live Bird Boiled Alive': '#ef4129',
  'Dead On Arrival': '#00abc8',
  'Live Bird Among Dead Birds': '#ffb600',
  'Operational Error': '#c2d500',
  'Poor Working Conditions': '#009779',
};

export const REPORT_EMOJI = {
  'Live Bird Boiled Alive': '🔴',
  'Dead On Arrival': '🔵',
  'Live Bird Among Dead Birds': '🟠',
  'Operational Error': '🟢',
  'Poor Working Conditions': '🟣',
};
