import styled from 'styled-components';

export const ChangeLink = styled.button`
  appearance: none;
  padding: 0;
  cursor: pointer;
  font-size: 0.875rem;
  color: var(--color-neutral-500);
  font-family: var(--font-primary);
  margin-left: 0.75rem;
  margin-right: -1rem;
  transform: translateY(-0.25rem);

  &:hover {
    color: var(--color-neutral-600);
    text-decoration: underline;
    text-decoration-color: var(--color-neutral-300);
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
`;
