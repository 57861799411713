import styled, { css } from 'styled-components';
import { SectionContents } from '../Section/Section.styles';
import { textH2, textH3, textH4 } from '+/styles/reusable/textStyles';

export const Text = styled.div<{ twoColumn?: boolean }>`
  color: var(--color-theme-base-inverse);

  ${({ theme, twoColumn }) =>
    twoColumn &&
    css`
      @media (min-width: ${theme.breakpoints.lg}) {
        columns: 2;
        column-gap: 11%;
      }
    `}

  ${SectionContents} > & {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: var(--font-size-md);
    }
  }

  /* Typography */
  h1 {
    display: none;
  }
  h2 {
    ${textH2}
    font-size: var(--font-size-xl);
    ${({ theme, twoColumn }) =>
      !twoColumn &&
      css`
        @media (min-width: ${theme.breakpoints.md}) {
          font-size: var(--font-size-xxl);
        }
      `}
  }
  h3 {
    ${textH3}
    font-size: var(--font-size-lg);
    ${({ theme, twoColumn }) =>
      !twoColumn &&
      css`
        @media (min-width: ${theme.breakpoints.md}) {
          font-size: var(--font-size-xl);
        }
      `}
  }
  h4 {
    ${textH4}
    font-size: var(--font-size-md);
    ${({ theme, twoColumn }) =>
      !twoColumn &&
      css`
        @media (min-width: ${theme.breakpoints.md}) {
          font-size: var(--font-size-lg);
        }
      `}
  }
`;
