import React from 'react';
import * as Styled from './Picker.styles';
import { ICONS } from '+/utils/constants/icons';
import { Icon } from '+/components/Icon';

interface PickerButtonProps {
  isSelected?: boolean;
  children?: any;
  onChange?: () => void;
  inputId?: string;
  name: string;
  variant?: 'notched' | 'bordered';
  checkmark?: boolean;
  className?: string;
}

export const PickerButton = React.forwardRef<HTMLInputElement, PickerButtonProps>(
  (
    {
      isSelected = false,
      children,
      onChange,
      inputId,
      name,
      variant = 'notched',
      checkmark = true,
      className,
      ...rest
    },
    ref,
  ) => {
    return (
      <Styled.Label
        isSelected={isSelected}
        htmlFor={inputId}
        variant={variant}
        className={className}
      >
        <Styled.Input
          type="radio"
          checked={isSelected}
          onChange={onChange}
          id={inputId}
          name={name}
          tabIndex={0}
          ref={ref}
          {...rest}
        ></Styled.Input>
        {checkmark && isSelected && <Icon path={ICONS.CHECK.path} />}
        {children}
      </Styled.Label>
    );
  },
);
