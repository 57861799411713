import React, { useEffect } from 'react';
import { RememberMeAction, RememberMeState, initState, reducer } from './reducer';
import { fetchUserData } from './api';

export const RememberMeContext = React.createContext<{
  state: RememberMeState;
  dispatch: React.Dispatch<RememberMeAction>;
}>(null);

export const RememberMeProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initState);

  useEffect(() => {
    const cookies = document.cookie;
    const regex = /lookupKey=([^;]+)/;
    const match = cookies.match(regex);

    const getData = async () => {
      const data = await fetchUserData(match[1]);
      dispatch({ type: 'updateUserData', payload: data });
    };

    match && match[1] && getData();
  }, []);

  if (
    (typeof window !== `undefined` && !window?.Osano?.cm?.personalization) ||
    !process.env.GATSBY_SECRET
  ) {
    return (
      <RememberMeContext.Provider value={{ state: initState, dispatch: () => null }}>
        {children}
      </RememberMeContext.Provider>
    );
  }

  return (
    <RememberMeContext.Provider value={{ state, dispatch }}>{children}</RememberMeContext.Provider>
  );
};

export function useRememberMe() {
  const context = React.useContext(RememberMeContext);

  if (context === undefined) {
    throw new Error('useRememberMe must be used within a RememberMeProvider');
  }

  return context;
}

export interface RememberMeData {
  firstName: string;
  lastName: string;
  address1: string;
  postcode: string;
  locality: string;
  state: string;
  country: string;
  email: string;
  address2?: string;
  mobilePhone?: string;
}

/**
 Logic:
 - If saveUserData is true, run post.
    - This creates a new lookupKey cookie every time, which means that as long as the user is coming
      donate/action pages more than once a year, the cookie won't expire (because a new one with a 
      new expiration date will be created on every visit.)
    - This also eliminates the need to do patch calls to update user data.
 - If saveUserData is false but userData exists, run delete.
 - If saveUserData is false but no userData exists, do nothing.
 */
