import React, { useContext, useEffect } from 'react';

import { t } from '+/utils/textDictionary';
import { Button } from '+/components/Button';
import { ICONS } from '+/utils/constants/icons';
import analyticsEvent from '+/utils/analyticsEvent';
import { FormAlertContext } from '+/contexts/FormAlertContext';
import visibleRequiredUnfilled from '+/components/DonateForm/utilities/visibleRequiredUnfilled';
import scrollToLocation from '+/utils/scrollToLocation';
import selectors from '+/components/DonateForm/selectors';
import { breakpoints, dimensions } from '+/styles/themes/mainTheme';
import { FormFieldErrorsContext } from '+/contexts/FormFieldErrorsContext';
import { ColorThemeNames } from '+/styles/themes/colorThemes.interface';

export const NextStep = ({ controller }: { controller: EveryActionModelController }) => {
  const { addAlert, removeAlert } = useContext(FormAlertContext);
  const { errors, addErrors, clearErrors } = useContext(FormFieldErrorsContext);

  const alertErrorMessage = t('Widget.FormErrors.MissingFields');

  const isVisible = (element: HTMLInputElement | HTMLElement): boolean =>
    getComputedStyle(element).display !== 'none' &&
    (!element.parentElement || isVisible(element.parentElement));

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    if (visibleRequiredUnfilled()) {
      controller.renderFeedback();
      const updatedErrors = controller.getErrors();
      addErrors(updatedErrors);
      addAlert(alertErrorMessage);
    } else {
      analyticsEvent({
        eventTypeName: 'CustomFormEvent',
        targetElement: 'EveryActionForm',
        targetElementVariation: 'Donation',
        triggerAction: `Advance ${controller.getNextStep()}`,
        simpleName: `Advance to step ${controller.getNextStep()}`,
        context: { amount: controller.getSelectedAmount(), recurring: controller.getMonthly() },
      });
      scrollToLocation(
        document.querySelector(selectors.FORM_HEADER_TITLE),
        dimensions.heightNav.value,
        'up',
        breakpoints.lg.value,
      );
      controller.nextStep();
      removeAlert(alertErrorMessage);
      controller.clearFeedback();
    }
  };

  const buttonText = t('Widget.NextStep.ButtonText');

  return (
    <Button
      big
      narrow
      block
      color={ColorThemeNames.RedAccented}
      endIcon={ICONS.CARET_RIGHT}
      onClick={e => handleClick(e)}
      aria-label={buttonText}
    >
      {buttonText}
    </Button>
  );
};
