import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Trigger = styled(motion.button)`
  appearnace: none;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TriggerText = styled.span`
  font-weight: bold;
  font-family: var(--font-primary);
  color: var(--color-white);
  border-bottom: 1px solid var(--color-secondary-500);
  margin-left: 0.25rem;
`;

export const ExpandedText = styled(motion.div)`
  font-size: 1.0625rem;
  color: var(--color-white);
  font-family: var(--font-primary);
  overflow: hidden;
`;
