import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { t } from '+/utils/textDictionary';
import * as Styled from './NameSplitter.styles';

const buildRegExp = (position, name) => {
  if (position === 'end') {
    return new RegExp(`${name}$`);
  }
  return new RegExp(`^${name}`);
};

/**
 * @method
 * @param controller - the everyaction controller
 * @param position - the expected position of the inferred name part, either 'start' or 'end'.
 * @param namePartToCheck - the part of the name from which to split another, 'FirstName' or 'LastName'
 * @param namePartToSplit - the part of the name to be removed, 'Prefix', 'MiddleName', or 'Suffix'
 * @param nameLabel - the title of the name to be rendered in the question.
 **/
export const NameSplitter = ({
  controller,
  position,
  namePartToCheck,
  namePartToSplit,
  nameLabel,
}: {
  controller: EveryActionModelController;
  position: string;
  namePartToCheck: string;
  namePartToSplit: string;
  nameLabel: string;
}) => {
  // Return if field isn't available. (This is configured in EA.)
  const splitNameLabel = document.querySelector(`#${namePartToSplit}Input`);
  if (!splitNameLabel) {
    return null;
  }

  // Store names that have been denied.
  const [deniedNames, setDeniedNames] = React.useState({});

  const name = controller[`get${namePartToCheck}`]();
  const inferredSplitName = controller[`infer${namePartToSplit}`]();
  const splitNameValue = controller[`get${namePartToSplit}`]();

  // Don't hide fields with filled-in values
  if (!!splitNameValue) {
    splitNameLabel.classList.toggle('opt-in', true);
  }

  // If name part exists to be split off
  const isNameSplitterVisible =
    name &&
    inferredSplitName &&
    !deniedNames[inferredSplitName] &&
    name.includes(inferredSplitName) &&
    !splitNameValue;

  const confirmYes = e => {
    e.preventDefault();
    // Show the field
    splitNameLabel.classList.toggle('opt-in', true);
    // Remove it from where it currently is.
    controller[`set${namePartToCheck}`](
      name.replace(buildRegExp(position, inferredSplitName), '').trim(),
    );
    // Add to where it should be.
    controller[`set${namePartToSplit}`](inferredSplitName);
  };
  const confirmNo = e => {
    e.preventDefault();
    // Don't ask again.
    setDeniedNames({ ...deniedNames, [inferredSplitName]: true });
  };

  return (
    <AnimatePresence>
      {isNameSplitterVisible && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{
            bounce: 0,
            duration: 0.1,
          }}
        >
          <Styled.NameSplitter>
            <Styled.Icon aria-hidden>?</Styled.Icon>
            <Styled.Text>
              <Styled.Question>
                {t('Widget.NameSplitter.QuestionStart')}
                <strong> {inferredSplitName} </strong>
                {t('Widget.NameSplitter.QuestionEnd', { name: nameLabel })}
              </Styled.Question>
              <Styled.Answer onClick={e => confirmYes(e)}>
                {t('Widget.NameSplitter.Yes')}
              </Styled.Answer>
              <Styled.Answer onClick={e => confirmNo(e)}>
                {t('Widget.NameSplitter.No')}
              </Styled.Answer>
            </Styled.Text>
          </Styled.NameSplitter>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
