import React, { useState } from 'react';

interface ModalContextInterface {
  displayedModal?: string;
  setDisplayedModal?: (string) => void;
  onModalClose?: () => void;
  setOnModalClose?: (closeFunction) => void;
}

export const ModalContext = React.createContext<ModalContextInterface>({
  displayedModal: '',
  setDisplayedModal: () => {},
  onModalClose: () => {},
  setOnModalClose: () => {},
});

export const ModalContextProvider = ({ children }) => {
  const [displayedModal, setDisplayedModal] = useState(null);
  const [onModalClose, setOnModalClose] = useState(() => null);

  const contextValue: ModalContextInterface = React.useMemo(
    () => ({
      displayedModal,
      setDisplayedModal,
      onModalClose,
      setOnModalClose,
    }),
    [displayedModal],
  );

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};
