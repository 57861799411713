import styled, { css } from 'styled-components';

import { textH2 } from '+/styles/reusable/textStyles';
import { TextHighlight, Word } from '+/components/TextHighlight/TextHighlight.styles';
import { getImageFilePath } from '+/utils/getImageFilePath';

const breakpointTablet = props => props.theme.breakpoints.md;
const breakpointDesktop = props => props.theme.breakpoints.lg;

const TextShadow = css`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 calc(2.5em + 0.5rem) calc(2.5em + 0.5rem) var(--color-black);
    background: var(--color-black);
    opacity: 0.4;
    z-index: -1;
    transform: scale(0.85);
  }
`;

export const TextOnMedia = styled.div<{ mediaItem? }>`
  background-color: var(--color-neutral-800);
  color: var(--color-white);
  padding: 50vh 0 25vh;
  overflow: hidden;
  position: relative;
  min-height: 100vh;

  ${props =>
    props.mediaItem &&
    css`
      background-image: url(${getImageFilePath(
        props.mediaItem.imageWebpMobile ||
          props.mediaItem.imageMobile ||
          props.mediaItem.imageWebp ||
          props.mediaItem.image,
      )});
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `};

  @media (min-width: ${breakpointDesktop}) {
    ${props =>
      props.mediaItem &&
      css`
        background-image: url(${getImageFilePath(
          props.mediaItem.imageWebp || props.mediaItem.image,
        )});
      `};
    min-height: unset;
    padding: 30vh 0;
  }
`;

export const Container = styled.div<{ layout?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 3rem;
  max-width: 60rem;
  padding: 0 1.5rem;
  margin: 0 auto;
  position: relative;
  z-index: 3;

  ${props =>
    props.layout !== 'one-column' &&
    css`
      @media (min-width: ${breakpointDesktop}) {
        flex-direction: ${props.layout === 'two-columns-left-headline' ? 'row' : 'row-reverse'};
        align-items: flex-start;
        justify-content: center;
        gap: 10%;
      }
    `}
`;

export const Headline = styled.div<{ layout?: string; hasText: boolean }>`
  ${TextShadow}

  margin: 0 auto;
  max-width: 45rem;
  flex: 1;

  ${props =>
    props.layout !== 'one-column' &&
    props.hasText &&
    css`
      @media (min-width: ${breakpointDesktop}) {
        text-align: ${props.layout === 'two-columns-left-headline' ? 'right' : 'left'};
      }
    `}

  h2 {
    ${textH2}

    line-height: 1.25;
    letter-spacing: -0.015em;

    @media (min-width: ${breakpointTablet}) {
      font-size: var(--font-size-xxxl);
    }

    ${props =>
      props.layout !== 'one-column' &&
      css`
        @media (min-width: ${breakpointDesktop}) {
          line-height: 1.2;
        }
      `}

    ${TextHighlight} {
      ${Word} {
        padding: 0 0.3em;
        margin: 0 -0.15em;
      }
    }
  }
`;

export const Text = styled.div<{ layout?: string; hasHeadline: boolean }>`
  ${TextShadow}

  text-align: left;
  flex: 1;
  margin: 0 auto;
  max-width: 38.75rem;

  @media (min-width: ${breakpointTablet}) {
    font-size: 1.125rem;
  }

  ${props =>
    props.layout !== 'one-column' &&
    props.hasHeadline &&
    css`
      @media (min-width: ${breakpointDesktop}) {
        text-align: ${props.layout === 'two-columns-right-headline' ? 'right' : 'left'};
      }
    `}

  ${props =>
    props.layout === 'one-column' &&
    css`
      @media (min-width: ${breakpointDesktop}) {
        text-align: center;
      }
    `}

  strong {
    color: var(--color-theme-accent);
  }
`;

export const MediaContainer = styled.div<{ ref }>`
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const Media = styled.img`
  left: 0;
  top: 0;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
`;

export const PhotoCredit = styled.span`
  ${TextShadow}
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  margin: 1.5rem 0.5rem;
  font-size: 0.6875rem;
  color: rgba(255, 255, 255, 0.6);
  transform: rotate(-90deg) translate(100%, 0);
  transform-origin: right bottom;

  &:after {
    transform: scale(0.875, 0.4375);
    opacity: 0.25;
  }
`;
