import React from 'react';

import { FormWrapper } from '+/components/FormWrapper';
import { EveryActionDonateForm } from '+/components/DonateForm';
import { CustomEveryActionForm } from '+/components/CustomEveryActionForm';
import { EveryActionAdvocacyForm } from '+/components/EveryActionAdvocacyForm';
import { TrackedSweetenerForm } from '+/components/TrackedSweetenerForm';
import { EveryActionPetitionForm } from '+/components/EveryActionPetitionForm';
import { MarkdownForm } from '+/components/MarkdownForm';

function isDonateForm(formData: MappableForm): formData is DonateFormComponent {
  return formData.__typename === 'Directus_donate_forms';
}

function isCustomEveryActionForm(
  formData: MappableForm,
): formData is CustomEveryActionFormComponent {
  return formData.__typename === 'Directus_custom_every_action_forms';
}

export function isTrackedSweetenerForm(
  formData: MappableForm,
): formData is TrackedSweetenerFormComponent {
  return formData.__typename === 'Directus_tracked_sweetener_forms';
}

function isEveryActionAdvocacyForm(
  formData: MappableForm,
): formData is EveryActionAdvocacyFormComponent {
  return formData.__typename === 'Directus_every_action_advocacy_forms';
}

function isEveryActionPetitionForm(
  formData: MappableForm,
): formData is EveryActionPetitionFormComponent {
  return formData.__typename === 'Directus_every_action_action_forms';
}

function isMarkdownForm(formData: MappableForm): formData is EveryActionPetitionFormComponent {
  return formData.__typename === 'Directus_markdown_forms';
}

export const FormMap = ({
  formData,
  advanceFormSequence,
}: {
  formData: MappableForm;
  advanceFormSequence?: () => void;
}) => {
  if (isDonateForm(formData)) {
    return (
      <FormWrapper id="DonateFormWrapper">
        <EveryActionDonateForm
          donateFormData={formData}
          advanceFormSequence={advanceFormSequence}
        />
      </FormWrapper>
    );
  }

  if (isEveryActionAdvocacyForm(formData)) {
    return (
      <FormWrapper id="AdvocacyFormWrapper">
        <EveryActionAdvocacyForm
          everyActionAdvocacyFormData={formData}
          advanceFormSequence={advanceFormSequence}
        />
      </FormWrapper>
    );
  }

  if (isEveryActionPetitionForm(formData)) {
    return (
      <FormWrapper id="PetitionFormWrapper">
        <EveryActionPetitionForm
          everyActionPetitionFormData={formData}
          advanceFormSequence={advanceFormSequence}
        />
      </FormWrapper>
    );
  }

  if (isCustomEveryActionForm(formData)) {
    return (
      <FormWrapper id="CustomFormWrapper">
        <CustomEveryActionForm
          customEveryActionFormData={formData}
          advanceFormSequence={advanceFormSequence}
        />
      </FormWrapper>
    );
  }

  if (isTrackedSweetenerForm(formData)) {
    return (
      <FormWrapper id="SweetenerFormWrapper">
        <TrackedSweetenerForm
          trackedSweetenerFormData={formData}
          advanceFormSequence={advanceFormSequence}
        />
      </FormWrapper>
    );
  }

  if (isMarkdownForm(formData)) {
    return (
      <FormWrapper id="MarkdownFormWrapper">
        <MarkdownForm markdownFormData={formData} />
      </FormWrapper>
    );
  }

  return <>{JSON.stringify(formData)}</>;
};

FormMap.defaultProps = {
  advanceFormSequence: undefined,
};
