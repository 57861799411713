import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0.875rem 0 2.5rem;

  .frequencyButtonGroup {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1.25fr);
    grid-gap: 0;

    @media (min-width: ${props => props.theme.breakpoints.xs}) {
      grid-gap: 1rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
  }
`;

export const Upsell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0 0 0;

  em {
    display: inline-block;
    font-family: var(--font-primary);
    font-style: italic;
    color: var(--color-neutral-600);
    font-size: 0.813rem;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 0.875rem;
    }
  }

  svg {
    margin-left: 0.25rem;
  }
`;
