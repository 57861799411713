import styled from 'styled-components';

import { Link as LinkComponent } from '+/components/Link';
import { IconByName as IconComponent } from '+/components/Icon';

export const SocialLinks = styled.div``;

export const SocialLink = styled(LinkComponent)`
  display: inline-block;

  padding: 0.25rem;
  margin: 0 0.25rem;

  background: var(--color-neutral-500);

  &:hover {
    background: var(--color-white);
  }
`;

export const SocialIcon = styled(IconComponent)`
  width: 1.25rem;
  height: 1.25rem;

  color: var(--color-black);
`;

export const FooterLinks = styled.div`
  text-align: center;
`;

export const FooterLink = styled(LinkComponent)`
  display: inline-block;

  margin: 1rem 0.625rem;

  color: var(--color-white);
  font-size: 0.75rem;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Copyright = styled.div`
  text-align: right;

  font-size: 0.75rem;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    text-align: center;
  }
`;

export const Footer = styled.footer`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 100%;
  align-items: center;
  justify-items: stretch;
  width: 100%;
  padding: 1rem 1.25rem;
  background: var(--color-black);
  position: relative;
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    display: flex;
    flex-direction: column;
    padding: 2.25rem 1.25rem 1rem;
  }
`;
