import { TargetAndTransition, Variants } from 'framer-motion';

import { MappedMouseCoordinates } from '+/utils/mapMouseCoordinatesToCenter';

export const ParallaxMediaItemVariants: Variants = {
  parallax: (mappedCoordinates: MappedMouseCoordinates): TargetAndTransition => ({
    x: `${mappedCoordinates.x * 1.25}%`,
    y: `${mappedCoordinates.y * 1.25}%`,
    scale: 1.25,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.25,
      delay: 0,
    },
  }),
  none: {
    x: 0,
    y: 0,
    scale: 1,
  },
};

export const ParallaxDustVariants: Variants = {
  oneParallax: (mappedCoordinates: MappedMouseCoordinates): TargetAndTransition => ({
    x: `${mappedCoordinates.x * 2.5}%`,
    y: `${mappedCoordinates.y * 2.5}%`,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.75,
      delay: 0,
    },
  }),
  twoParallax: (mappedCoordinates: MappedMouseCoordinates): TargetAndTransition => ({
    x: `${mappedCoordinates.x * 0.5}%`,
    y: `${mappedCoordinates.y * 0.5}%`,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      duration: 1,
      delay: 0,
    },
  }),
};

export const ParallaxBarsVariants: Variants = {
  mouseParallax: (mappedCoordinates: MappedMouseCoordinates): TargetAndTransition => ({
    x: `${mappedCoordinates.x * 10}%`,
    y: `${mappedCoordinates.y * 10}%`,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.5,
      delay: 0,
    },
  }),
};

export const ContentVariants: Variants = {
  hidden: (): TargetAndTransition => ({
    y: 20,
    opacity: 0,
  }),
  visible: ({ index, delay }: { index: number; delay: number }): TargetAndTransition => ({
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.25, 0.1, 0.25, 1],
      delay: delay + index * 0.25,
      opacity: { duration: 1.2 },
      y: { duration: 0.8 },
    },
  }),
};
