export const save = (controller: EveryActionModelController) => {
  const values = controller.view.val();
  const storeKey = String(new Date().getTime());
  window.localStorage.setItem(storeKey, JSON.stringify(values));
  return storeKey;
};

export const restore = (controller: EveryActionModelController, storeKey: string) => {
  const valueString = window.localStorage.getItem(storeKey);
  if (!valueString) {
    return;
  }
  const values = JSON.parse(valueString);
  controller.view.setval(values);
  window.localStorage.removeItem(storeKey);
};
