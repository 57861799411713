import React from 'react';

import * as Styled from './LoadingIndicator.styles';

import { NoScrollContext } from '+/contexts/NoScrollContext';

export const LoadingIndicator = props => {
  const noScrollController = React.useContext(NoScrollContext);

  React.useEffect(() => {
    const resumeScrolling = noScrollController.pauseScrolling();

    return resumeScrolling;
  }, []);

  return (
    <Styled.LoadingIndicator {...props}>
      <Styled.MendedHeart />
    </Styled.LoadingIndicator>
  );
};
