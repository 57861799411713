import styled from 'styled-components';

import {
  ButtonContainer,
  Content,
  SweetenerForm,
} from '../TrackedSweetenerForm/TrackedSweetenerForm.styles';
import { Picture } from '../ImageItem/ImageItem.styles';
import { Overlay } from '../Modal/ModalContent.styles';

import { CustomFieldRow } from '+/styles/styledComponents/Form.styles';

export const SequenceModal = styled.div`
  ${Overlay} {
    background-color: rgba(142, 145, 156, 0.75);
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    ${SweetenerForm} {
      flex-direction: column;

      ${Content} {
        align-items: center;
        margin-top: -85px;
        background: var(--color-theme-base);
        padding-top: 0;
        padding: 0 2rem 0.75rem;

        h2 {
          text-align: center;
          padding-top: 1.25rem;
          position: relative;
          overflow: hidden;
          width: 100%;

          ::before {
            content: '';
            min-width: 500px;
            width: 100%;
            height: 100px;
            position: absolute;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
            background: radial-gradient(var(--color-neutral-350) 0%, rgba(255, 255, 255, 0) 50%);
          }
        }
      }

      ${Picture} img {
        min-width: 300px;
      }
    }

    ${ButtonContainer} {
      margin-top: 1rem;
      justify-content: center;

      ${CustomFieldRow} {
        padding-bottom: 0.25rem;
      }
    }
  }
`;
