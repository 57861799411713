import React from 'react';
import { TextHighlight } from '+/components/TextHighlight';

interface MarkdownHighlightProps {
  startColorTheme?: ColorThemeName;
  colorTheme?: ColorThemeName;
  delay?: number;
  duration?: number;
  reset?: string;
  font?: string;
  children: JSX.Element;
}

export const MarkdownHighlight = ({
  colorTheme,
  delay,
  duration,
  reset,
  font,
  children,
}: MarkdownHighlightProps) => {
  const highlightText = children[0];
  const shouldStayOnOnceOn = reset !== 'true';

  if (!highlightText) return null;

  return (
    <TextHighlight
      colorTheme={colorTheme}
      delay={delay}
      duration={duration}
      shouldStayOnOnceOn={shouldStayOnOnceOn}
      highlightText={highlightText}
      font={font}
    />
  );
};
