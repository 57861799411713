import React from 'react';

import { ComponentMapWithoutABTests } from '+/components/ComponentMap';
import { isPrerendering } from '+/utils/environment';
import { LoadingContext } from '+/contexts';
import * as aBTestController from '+/utils/aBTestController';

export const ABTest = ({ aBTestData }: { aBTestData: ABTestComponent }) => {
  const loadingContext: LoadingController = React.useContext(LoadingContext);
  // Don't prerender variants. Instead, show loading animation.
  loadingContext.addLoadable(
    // Never count as fully loaded on prerender.
    new Promise<undefined | null>(resolve => {
      if (!isPrerendering) {
        resolve(null);
      }
    }),
  );

  if (isPrerendering) return null;
  aBTestController.setup(aBTestData.id, aBTestData.startAt, aBTestData.endAt);
  const variantNumber = aBTestController.getVariant();

  const conditions = [aBTestData.variantAComponents, aBTestData.variantBComponents];
  const conditionComponents = conditions[variantNumber - 1];

  return (
    <>
      {conditionComponents.map(componentData => (
        <ComponentMapWithoutABTests componentData={componentData} />
      ))}
    </>
  );
};
